import { Component, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm, subformComponentProviders } from "ngx-sub-form";
import { Subject } from "rxjs";
import { UserRoles } from "./user-model";

@Component({
  selector: 'user-permissions-form',
  template: `
      <form [formGroup]="form.formGroup">
        <div class="field-checkbox">
          <input style="height: 13px; width: 20px;" type="checkbox" binary="true" [formControlName]="form.formControlNames.admin">
          <label [for]="form.formControlNames.admin">Admin</label>
        </div>
        <br>
        <div class="field-checkbox">
          <input style="height: 13px; width: 20px;" type="checkbox" binary="true" [formControlName]="form.formControlNames.readOnly">
          <label [for]="form.formControlNames.readOnly">Read Only</label>
        </div>
        <br>
        <br>
      </form>
    `,
  providers: subformComponentProviders(UserPermissionsFormComponent)
})
export class UserPermissionsFormComponent {

  private input$: Subject<UserRoles | undefined> = new Subject();

  constructor() { }

  @Input() set userProfile(userProfile: UserRoles | undefined) {
    this.input$.next(userProfile);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() userProfileUpdated: Subject<UserRoles> = new Subject();

  public form = createForm<UserRoles, UserRoles>(this, {
    formType: FormType.SUB,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userProfileUpdated,
    formControls: {
      admin: new UntypedFormControl(false),
      readOnly: new UntypedFormControl(false)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

}