import { TemperatureDerationCatalog } from "./temperature-deration-catalog.model";

export class GradeCatalog {
  public constructor(init?: Partial<GradeCatalog>) {
    Object.assign(this, init);
  }

  id: string;
  name: string;
  minimumApiYieldStrength: number;
  poissonsRatio: number;
  thermalExpansionCoefficient: number;
  ultimateTensileStrength: number;
  youngsModulus: number;
  temperatureDeration: TemperatureDerationCatalog;
  strengthCalculationOption: string;
  density: number;
  specificHeatCapacity: number;
}

export class GradeLookupResult {
  public constructor(init?: Partial<GradeLookupResult>) {
    Object.assign(this, init);
  }

  id: string;
  name: string;
}
