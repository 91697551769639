export type TemperatureProfile = BhctTempProfile | CustomTempProfile | UndisturbedTempProfile | PericalLinkTempProfile;

export enum TemperatureProfileType {
  BHCT = 'BottomHoleCirculatingTemperature',
  CUSTOMTEMP = 'CustomTemperatures',
  UNDISTURBED = 'Undisturbed',
  THERMALLINK = 'ThermalLink'
}

export interface TemperaturePoint {
  measuredDepth: number;
  temperature: number;
}

export interface BhctTempProfile {
  type: TemperatureProfileType.BHCT;
  bottomMd: number;
}

export interface CustomTempProfile {
  type: TemperatureProfileType.CUSTOMTEMP;
  temperaturePoints: TemperaturePoint[];
}

export interface UndisturbedTempProfile {
  type: TemperatureProfileType.UNDISTURBED;
}

export interface PericalLinkTempProfile {
  type: TemperatureProfileType.THERMALLINK;
  thermalOperationId: string;
}
