<form [formGroup]="form.formGroup">
  <p-tabView>
    <p-tabPanel header="Composition %">
      <div class="defaultButton">
        <img
          (click)="applyDefaults()"
          title="Apply Defaults"
          src="../../assets/icons/default-icon-white.png"
        />
      </div>
      <div class="grid">
        <div class="col">
          <div class="field">
            <label for="c1">C</label><label class="sub">1</label>
            <p-inputNumber
              id="c1"
              [formControlName]="form.formControlNames.c1"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('c1', $event)"

            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.c1.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="c2">C</label><label class="sub">2</label>
            <p-inputNumber
              id="c2"
              [formControlName]="form.formControlNames.c2"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('c2', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.c2.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="c3">C</label><label class="sub">3</label>
            <p-inputNumber
              id="c3"
              [formControlName]="form.formControlNames.c3"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('c3', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.c3.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="nC4">nC</label><label class="sub">4</label>
            <p-inputNumber
              id="nC4"
              [formControlName]="form.formControlNames.nC4"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('nC4', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.nC4.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="iC4">iC</label><label class="sub">4</label>
            <p-inputNumber
              id="iC4"
              [formControlName]="form.formControlNames.iC4"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('iC4', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.iC4.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="nC5">nC</label><label class="sub">5</label>
            <p-inputNumber
              id="nC5"
              [formControlName]="form.formControlNames.nC5"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('nC5', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.nC5.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
        </div>

        <div class="col">
          <div class="field">
            <label for="iC5">iC</label><label class="sub">5</label>
            <p-inputNumber
              id="iC5"
              [formControlName]="form.formControlNames.iC5"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('iC5', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.iC5.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="nC6">nC</label><label class="sub">6</label>
            <p-inputNumber
              id="nC6"
              [formControlName]="form.formControlNames.nC6"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('nC6', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.nC6.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="n2">N</label><label class="sub">2</label>
            <p-inputNumber
              id="n2"
              [formControlName]="form.formControlNames.n2"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('n2', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.n2.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="cO2">CO</label><label class="sub">2</label>
            <p-inputNumber
              id="cO2"
              [formControlName]="form.formControlNames.cO2"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('cO2', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.cO2.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="h2S">H</label><label class="sub">2</label
            ><label>S</label>
            <p-inputNumber
              id="h2S"
              [formControlName]="form.formControlNames.h2S"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('h2S', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.h2S.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>
          <div class="field">
            <label for="h2O">H</label><label class="sub">2</label
            ><label>O</label>
            <p-inputNumber
              id="h2O"
              [formControlName]="form.formControlNames.h2O"
              required
              [minFractionDigits]="1"
              (onInput)="calculateTotalPercent('h2O', $event)"
            ></p-inputNumber>
            <p *ngIf="form.formGroup.controls.h2O.invalid" class="error">
              Allowed range: 0 - 100 %
            </p>
          </div>

          <label class="{{ totalError ? 'error' : '' }}"
            >Total: {{ totalPercent }} %</label
          >
          <br *ngIf="totalError" />
          <label *ngIf="totalError" class="error"
            >Total percent must be equal to 100%</label
          >
          <br *ngIf="totalError" />
          <label *ngIf="totalError" class="error"
            >{{ percentLabel }}: {{ difference }} %</label
          >
        </div>
      </div>
      <p-fieldset legend="VLE Heavy Weight Hydrocarbons (maximum of three)">
        <br />
        <app-heavies
          [formControlName]="
            form.formControlNames.vleHeavyWeightHydrocarbonComponents
          "
          (ngModelChange)="calculateTotalPercent(null, null)"
        ></app-heavies>
      </p-fieldset>
    </p-tabPanel>
    <p-tabPanel header="Options">
      <app-vle-tuning-parameters
        [formControlName]="form.formControlNames.vleTuningParameters"
      ></app-vle-tuning-parameters>
    </p-tabPanel>
  </p-tabView>
</form>

<!--
<pre>{{ formGroupValues | json }}</pre> -->
