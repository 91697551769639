export enum FluidType {
  BRINE = 'brine',
  STANDARDMUD = 'standardMud',
  HYDROCARBON = 'hydrocarbon',
  BLACKOIL = 'blackOil',
  BASEOIL = 'baseOil',
  CEMENTSLURRY = 'cementSlurry',
  VLE = 'vle',
  ADVANCEDMUD = 'advancedMud',
  CO2FLUID = 'co2Fluid'
}

export interface Fluid {
  id: string;
  isInUse: boolean;
  state: Partial<BaseFluid>;
}

export interface Brine {
  readonly type: FluidType.BRINE;
  name: string;
  brineType: string;
  nominalDensity: number;
  plasticViscosity: number;
  viscosityTemperature: number;
}

export interface StandardMud {
  readonly type: FluidType.STANDARDMUD;
  name: string;
  baseFluid: string;
  nominalDensity: number;
  densityTemperature: number;
  plasticViscosity: number;
  rheologyTemperature: number;
  yieldPoint: number;
}

export interface Hydrocarbon{
  readonly type: FluidType.HYDROCARBON;
  name: string;
  oilApiGravity: number;
  gasComposition: GasComposition;
}

export interface BlackOil {
  readonly type: FluidType.BLACKOIL;
  name: string;
  oilApiGravity: number;
  gasComposition: GasComposition;
}

export interface GasComposition {
  c1: number;
  c2: number;
  c3: number;
  nC4: number;
  iC4: number;
  nC5: number;
  iC5: number;
  nC6: number;
  averageMolWeightC7ToC10: number;
  c7C10: number;
  co2: number;
  h2S: number;
  n2: number;
}

export interface Vle  {
  readonly type: FluidType.VLE;
  name: string;
  c1: number;
  c2: number;
  c3: number;
  nC4: number;
  iC4: number;
  nC5: number;
  iC5: number;
  nC6: number;
  n2: number;
  cO2: number;
  h2S: number;
  h2O: number;
  vleHeavyWeightHydrocarbonComponents: VleHeavyWeightHydrocarbonComponent[];
  vleTuningParameters: VleTuningParameters;
}

export interface CO2Fluid  {
  readonly type: FluidType.CO2FLUID;
  id: string; //TODO: This was the only way I could get the ID down into the subform?
  name: string;
  co2withImpurities: boolean;
  h2O: number;
  cO: number;
  h2s: number;
  sO2: number;
  h2SO4: number;
  n2: number;
  nO2: number;
  o2: number;
  h2: number;
  ar: number;
  cH4: number;
  c2: number;
  c3: number;
  nC4: number;
  iC4: number;
  nC5: number;
  iC5: number;
  nC6: number;
  c7: number;
  c8: number;
}

export interface VleHeavyWeightHydrocarbonComponent {
  name: string;
  fraction: number;
  molecularWeight: number;
  specificGravity: number;
}

export interface VleTuningParameters {
  acor: number;
  bcor: number;
  vcor: number;
  dcor: number;
  scor: number;
  kiOption: number;
  c7PlusCorrelations: number;
}


export interface BaseOil {
  readonly type: FluidType.BASEOIL;
  name: string;
  a0: number;
  a1: number;
  a2: number;
  b0: number;
  b1: number;
  b2: number;
}

export interface CementSlurry {
  readonly type: FluidType.CEMENTSLURRY;
  name: string;
  density: number;
  mixWaterDensity: number;
  densityTemperature: number;
  fann600: number;
  fann300: number;
  fann200: number;
  fann100: number;
  fann6: number;
  fann3: number;
  rheologyTemperature: number;
}

export interface AdvancedMud {
  readonly type: FluidType.ADVANCEDMUD;
  name: string;
  waterFraction: number;
  oilFraction: number;
  baseOil: string;
  baseBrine: Partial<Fluid>;
  highDensitySolidsFraction: number;
  highDensityMudSolidsType: string;
  lowDensitySolidsFraction: number;
  lowDensityMudSolidsType: string;
  plasticViscosity: number;
  yieldPoint: number;
  rheologyTemperature: number;
  nominalDensity: number;
  densityTemperature: number;
}

export type BaseFluid =
  Partial<Brine> |
  Partial<StandardMud> |
  Partial<Hydrocarbon> |
  Partial<BlackOil> |
  Partial<BaseOil> |
  Partial<CementSlurry> |
  Partial<Vle> |
  Partial<AdvancedMud> |
  Partial<CO2Fluid>;

  export class FluidsUi {
    public constructor(init?: Partial<FluidsUi>) {
      this.selectedFluid = init && init.selectedFluid || null;
      this.drawerOpen = init && init.drawerOpen || true;
      this.expandedFluids = init && init.expandedFluids || [];
    }
    selectedFluid: Partial<Fluid>;
    drawerOpen: boolean;
    expandedFluids: FluidType[];
  }

