import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DashboardWorkspaceIdxModel } from 'src/app/core/components/dashboard/models/dashboard.model';
import { Output, EventEmitter } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CloneWorkspacesDialogComponent } from '../clone-workspaces-dialog/clone-workspaces-dialog.component';
import { DashboardApiService } from '../services/dashboard-api.service';

@Component({
  selector: 'workspace-tabs-cmp',
  templateUrl: './workspace-tabs.component.html',
  styleUrls: ['./workspace-tabs.component.scss'],
  providers: [DialogService]
})
export class DashboardWorkspaceTabsComponent implements OnInit {

  private _workspaces: Array<DashboardWorkspaceIdxModel>;
  private _selectedTabIndex: number;

  public activeWorkspaceTab: MenuItem;
  public selectedEditTab: MenuItem;
  public workspaceOptions: MenuItem[];
  public workspaceContextMenu: Array<MenuItem>;
  public workspaceTabs: Array<MenuItem>;
  public editFromContextMenu: boolean;

  ref: DynamicDialogRef | undefined;

  handleInput(event: KeyboardEvent): void{
    event.stopPropagation();
  } 

  @ViewChild('editNameBox') editNameBox: ElementRef;

  @Input()
  public set selectedTabIndex(value: number) {
    if (value) {
      this._selectedTabIndex = value;
    }
  }

  public get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }

  @Input()
  public set workspaces(value: Array<DashboardWorkspaceIdxModel>) {
    if (value) {
      this._workspaces = value;
    }
  }

  public get workspaces(): Array<DashboardWorkspaceIdxModel> {
    return this._workspaces;
  }

  @Output()
  public onWorkspaceInsert: EventEmitter<{ idx: number }>;

  @Output()
  public onWorkspaceDelete: EventEmitter<number>;

  @Output()
  public onWorkspaceRename: EventEmitter<{ idx: number, name: string }>;

  @Output()
  public onWorkspaceSelect: EventEmitter<number>;

  @Output()
  public onWorkspaceIdx: EventEmitter<{ fromIdx: number, toIdx: number }>;

  @Output()
  public onWorkspacesCloned: EventEmitter<any>;

  constructor(
    private renderer: Renderer2,
    public _dialogService: DialogService,
    private _dashboardService: DashboardApiService
  ) {
    this.onWorkspaceInsert = new EventEmitter();
    this.onWorkspaceDelete = new EventEmitter();
    this.onWorkspaceRename = new EventEmitter();
    this.onWorkspaceSelect = new EventEmitter();
    this.onWorkspaceIdx = new EventEmitter();
    this.onWorkspacesCloned = new EventEmitter();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.editNameBox?.nativeElement && !this.editFromContextMenu) {
        this.selectedEditTab = null;
      }
    });
  }

  ngOnInit() {
    this.workspaceOptions = [
      {
        id: "add",
        icon: 'pi pi-plus',
        command: (e) => {
          this.insertWorkspace(this.workspaces.length + 1);
        },
        tooltip: "Add New Workspace"
      },
      {
        id: "clone",
        icon: 'pi pi-clone',
        command: (e) => {
          this.ref = this._dialogService.open(CloneWorkspacesDialogComponent, {
            header: 'Clone Workspace',
            width: '400px'
          });
          this.ref.onClose.subscribe((dashboardId) => {
            if (dashboardId) {
              this._dashboardService.cloneDashboard(dashboardId).subscribe(res => {
                this.onWorkspacesCloned.emit();
              });
            }
          });
        },
        tooltip: "Copy tabs and components from another design"
      }
    ];
  }

  ngOnChanges() {
    this.convertDashboardWorkspacesToMenuItems();
    this.activeWorkspaceTab = this.workspaceTabs?.find(wt => wt.id == this._selectedTabIndex?.toString());
  }

  public insertWorkspace(idx: number): void {
    this.onWorkspaceInsert.emit({ idx });
    this.selectWorkspace(idx);
  }

  public deleteWorkspace(workspaceIndex: number): void {
    this.onWorkspaceDelete.emit(workspaceIndex);
    this.selectWorkspace(0);
  }

  public selectWorkspace(workspaceIndex: number): void {
    if (workspaceIndex == 0 || this._selectedTabIndex === workspaceIndex) {
      return;
    }
    this.activeWorkspaceTab = this.workspaceTabs?.find(wt => wt.id == workspaceIndex?.toString());
    this.onWorkspaceSelect.emit(workspaceIndex);
  }

  public renameWorkspace(workspaceIndex: string, dashboardName: string) {
    this.onWorkspaceRename.emit({ idx: parseInt(workspaceIndex), name: dashboardName });
    this.selectedEditTab = null;
  }

  public moveWorkspaceIdx(fromIdx: number, toIdx: number) {
    this.onWorkspaceIdx.emit({ fromIdx: fromIdx, toIdx: toIdx });
  }

  public onContextMenu(e: any, workspace: MenuItem) {
    this.workspaceContextMenu = <Array<MenuItem>>[
      {
        label: 'Delete Workspace', icon: 'pi pi-fw pi-trash', disabled: this.workspaces?.length === 1,
        command: (e) => { this.deleteWorkspace(parseInt(workspace.id)); }
      },
      {
        label: 'Rename Workspace', icon: 'pi pi-fw pi-pencil',
        command: (e) => { this.editWorkspace(workspace, true); }
      },
      {
        label: 'Insert Workspace', icon: 'pi pi-fw pi-arrow-up',
        command: (e) => {
          this.insertWorkspace(parseInt(workspace.id));
        }
      },
      {
        label: 'Move Left', icon: 'pi pi-fw pi-arrow-left',
        command: (e) => {
          this.moveWorkspaceIdx(parseInt(workspace.id), parseInt(workspace.id) - 1);
        }
      },
      {
        label: 'Move Right', icon: 'pi pi-fw pi-arrow-right',
        command: (e) => {
          this.moveWorkspaceIdx(parseInt(workspace.id), parseInt(workspace.id) + 1);
        }
      }
    ];
  }

  public editWorkspace(workspaceTab: MenuItem, calledFromContextMenu: boolean) {
    this.editFromContextMenu = calledFromContextMenu;
    this.selectedEditTab = workspaceTab;
  }

  private convertDashboardWorkspacesToMenuItems() {
    this.workspaceTabs = this.workspaces?.map(ws => {
      return <MenuItem>{
        label: ws.name,
        id: ws.index.toString(),
        command: (event: any) => {
          if (event.originalEvent.target.type !== "text") {
            this.selectWorkspace(ws.index);
          }
        }
      }
    });
  }

}
