import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { Observable, Subscription, catchError, forkJoin, lastValueFrom, map } from 'rxjs';
import { OperationEnvelopeUi, PressureResults, TemperatureResults } from '../../models/thermal-results.model';
import { BaseOperation } from '../../models/thermal-operation.model';
import { FluidsService } from '../../services/fluids.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { SelectItem } from 'primeng/api';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { zipObject } from 'lodash';

@Component({
  selector: 'app-operation-envelope',
  templateUrl: './operation-envelope.component.html',
  styleUrls: ['./operation-envelope.component.scss']
})
export class OperationEnvelopeComponent implements OnInit, OnDestroy {

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _fluidService: FluidsService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();

    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Operation Envelope")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight - 15;
      }
    }));
  }

  private _subscriptions: Subscription;
  private _pressureResults: PressureResults[];
  private _temperatureResults: TemperatureResults[];
  private _fluids: any[];
  private _defaultEnvelope = {
    x: [-70, -65, -60, -55, -50, -45, -40, -35, -30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 87.8],
    y:  [74.851189, 84.29903685, 94.62512064, 105.8790509, 118.1110952, 131.3721561, 145.7137646, 161.1880918, 177.8479785, 195.7469833, 214.9394527, 235.480611, 257.4266742, 280.8349879, 305.7641921, 332.2744152, 360.4275016, 390.2872765, 421.9198558, 455.3940105, 490.7815977, 528.1580776, 567.6031453, 609.2015188, 653.0439521, 699.2285857, 747.8628336, 799.0661885, 852.9747501, 909.7494477, 969.5939808, 1032.81054, 1069.930683]
  };

  public isLoading: boolean = true;
  public operations: Partial<BaseOperation>[];
  public phaseEnvelopes: any[] = [];
  public needToCalculate: boolean;
  public calculationError: string = "Please calculate Perical to see results.";
  public resultsGrid: any[] = [];
  public resultsGridPhaseEnvelope: any[] = [];
  public resultsGridOperationData: any[] = [];
  public tableHeight: string = '550px';
  public cols: any[] = [];
  public tableName: string = 'Operation Envelope';
  public moveUpDisabled: boolean = true;
  public moveDownDisabled: boolean = false;
  public componentHeight: number;

  @ViewChild('toolbarDiv', {static: false}) toolbarDiv: ElementRef;

  public singleMultiple: SelectItem[] = [
    { label: 'Single', value: 'singleOperation' },
    { label: 'Multiple', value: 'multipleOperation' }
  ];

  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];

  public envelopeOperation: SelectItem[] = [
    { label: 'Phase Envelope', value: 'phaseEnvelope' },
    { label: 'Operation', value: 'operation' }
  ];

  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'operationEnvelopePlot';
  public downloadPlotName = 'operation_envelope_plot';

    //State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.operationEnvelopeStore = this._storeService.createStore(this.componentId, new OperationEnvelopeUi);
    }
    get componentId(): string {
      return this._componentId;
    }
    public operationEnvelopeStore: Store;

  async ngOnInit(): Promise<void> {

    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.yAxisTitle = `Pressure (${uu.pressure})`
    this.xAxisTitle = `Temperature (${uu.temperature})`

    this.cols = [
      { field: 'pressure', header: `Pressure (${uu.pressure})`, valueFormatter: (params: any) => this.formatDecimal(params) },
      { field: 'temperature', header: `Temperature (${uu.temperature})`, valueFormatter: (params: any) => this.formatDecimal(params) }
    ];

    switch (uu.temperature) {
      case '°F':
        this._defaultEnvelope.x = this._defaultEnvelope.x;
        break;
      case '°C':
        this._defaultEnvelope.x = this._defaultEnvelope.x.map(x => (x - 32) * 5 / 9);
        break;
      case 'K':
        this._defaultEnvelope.x = this._defaultEnvelope.x.map(x => (x - 32) * 5 / 9 + 273.15);
        break;
    }

    switch (uu.pressure) {
      case 'psi':
        this._defaultEnvelope.y = this._defaultEnvelope.y;
        break;
      case 'bar':
        case 'atm':
        this._defaultEnvelope.y = this._defaultEnvelope.y.map(x => x * 0.0689476);
        break;
      case 'kPa':
        this._defaultEnvelope.y = this._defaultEnvelope.y.map(x => x * 6.89476);
        break;
    }

    this.getData();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  private signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_THERMAL_RESULTS || data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.plot.data = [];
      // this.operationEnvelopeStore.update(state => ({...state, selecetedSingleMultiple: 'singleOperation'}));
      this.getData();
    }
  }

  private getData(): void {
    this.isLoading = true;
    this.plot.data = [];
    this.operations = [];
    this.moveUpDisabled = true;
    this.moveDownDisabled = false;
    const sources: Observable<any>[] = [
      this._thermalOperationsService.getThermalTemperatureResults(true) as Observable<any>,
      this._thermalOperationsService.getThermalPressureResults(true) as Observable<any>,
      this._thermalOperationsService.getThermalOperations() as Observable<any>,
    ];

    forkJoin(sources).pipe(
      map(([temperatureResults, pressureResults, operations]) => {
        if (temperatureResults.length === 0) {
          this.calculationError = "Please calculate Perical to see results.";
          this.needToCalculate = true;
          this.isLoading = false;
          return;
        }

        const sortByOperationOrder = (a, b) => {
          return operations.findIndex(x => x.name === a.name) - operations.findIndex(x => x.name === b.name);
        };
        
        temperatureResults.sort(sortByOperationOrder);
        pressureResults.sort(sortByOperationOrder);

        this.operations = temperatureResults.map(x => {
          return {
            name: x.name,
            operationId: x.operationId,
          }
        });

        this._pressureResults = pressureResults;
        this._temperatureResults = temperatureResults;

        this.getPhaseEnvelope(temperatureResults[0].operationId, 0);
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  private getMultipleData(): void {
    this.isLoading = true;

    if (!this._temperatureResults || this._temperatureResults?.length == 0) {
      this.needToCalculate = true;
      this.calculationError = "Please calculate Perical to see results.";
      this.isLoading = false;
      return;
    }

    const operationsWithCalculatedResults = this._temperatureResults.filter(x => x.phases[0].temperatureResultsTable.rows.length > 0);
    const sources = operationsWithCalculatedResults.map(x => this._thermalOperationsService.getThermalOperationById(x['operationId']));
    forkJoin(sources).pipe(
      map((operations) => {
        this.operations = operations;
        const fluidSources: Observable<any>[] = [];
        operations.forEach(x => {
          if (x.inside['fluid']?.id) {
            fluidSources.push(this._fluidService.getFluidById(x.inside['fluid'].id));
          }

          if (x.inside.type == 'shutIn' && !x.previousOperationId.startsWith('00000000')) {
            let previousOperationFluidId = operations.find(op => op.id == x.previousOperationId).inside['fluid']?.id;
            fluidSources.push(this._fluidService.getFluidById(previousOperationFluidId));
          }
        });

        forkJoin(fluidSources).pipe(
          map((fluids) => {
            fluids.forEach((x, index) => {
              if (!x) {
                fluids[index] = {
                  state: {
                    co2withImpurities: false
                  },
                  id: operations[index].inside['fluid']?.id,
                };
              }
            });

            this._fluids = fluids.filter(x => x.state.type == 'co2Fluid').filter(x => operations.some(y => y.inside['fluid']?.id === x.id));
            this._fluids = this._fluids.filter((x, index, self) =>
              index === self.findIndex(t => (
                t.id === x.id
              ))
            );

            let fluidsForSources = this._fluids.filter(x => x.state['co2withImpurities']);
            if (fluidsForSources.length == 0) {

              if (this._fluids.length > 0) {
                this.getPhaseEnvelopeForMultipleData();
              } else {
                this.calculationError = "Please select GERG2008 as the CO2 EOS option to see results.";
                this.needToCalculate = true;
                this.isLoading = false;
                this.plot.data = [];
                return;
              }
            }

            let fluidEnvelopeSources = fluidsForSources.map(x => this._fluidService.getPhaseEnvelope(x.id));
            forkJoin(fluidEnvelopeSources).pipe(
              map((fluidEnvelopes) => {
                let gergFluids = this._fluids.filter(x => x.state['co2withImpurities']);
                this.phaseEnvelopes = fluidEnvelopes.map((x, index) => {
                  return {
                    fluid: gergFluids[index],
                    fluidEnvelope: x
                  }
                });

                this.getPhaseEnvelopeForMultipleData();

              }),
              catchError(err => {
                this.isLoading = false;
                return err;
              })).subscribe();
          }),
          catchError(err => {
            this.isLoading = false;
            return err;
          })).subscribe();
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  private getPhaseEnvelopeForMultipleData(): void {
    let fluidsNotInEnvelope = this._fluids.filter(x => !this.phaseEnvelopes.some(y => y.fluid.id === x.id));
    fluidsNotInEnvelope.forEach(x => {
      this.phaseEnvelopes.push({
        fluid: x,
        fluidEnvelope: this._defaultEnvelope
      });
    });

    this.phaseEnvelopes.forEach(x => {
      x['operations'] = this.operations.filter(y => y.inside['fluid']?.id == x.fluid.id && y.inside['flow'].co2EosOption == 'GERG2008');
      x.fluid.state.name = x.fluid.state.name + ' - Phase Envelope';
    });

    this.operationEnvelopeStore.update(state => ({...state, selectedPhaseEnvelope: this.phaseEnvelopes[0]}));

    let shutInOperations = this.operations.filter(x => x.inside.type == 'shutIn');

    shutInOperations.forEach(s => {
      this.phaseEnvelopes.forEach(p => {
        p.operations.forEach(op => {
          if (op.id == s.previousOperationId) {
            s.inside = op.inside;
            p.operations.push(s);
          }
        });
      })
    })

    this.doStuffWithMultipleData();
  }

  private doStuffWithMultipleData(e?: any): void {
    this.plot.data = [];
    let pEnvelope = e ?? this.phaseEnvelopes[0];
    const fluidEnvelopeTrace = {
      x: pEnvelope.fluidEnvelope.x,
      y: pEnvelope.fluidEnvelope.y,
      name: pEnvelope.fluid.state.name,
      color: '#FF0000'
    };

    this.plot.data.push(fluidEnvelopeTrace);

    pEnvelope.operations.forEach(operation => {
      let resultIndex = this._temperatureResults.findIndex(x => x.name === operation.name);
      const temperatures = this._temperatureResults[resultIndex].phases[0].temperatureResultsTable.rows.map(x => x[2]);
      const pressures = this._pressureResults[resultIndex].phases[0].pressureResultsTable.rows.map(x => x[2]);
      let legendGroupName = 'group' + resultIndex;
      const tempPressureLine = {
        x: temperatures,
        y: pressures,
        name: this._temperatureResults[resultIndex].name,
        legendgroup: legendGroupName,
      };

      this.addWellheadAndPerfsToPlot(operation, resultIndex, pEnvelope.operations, legendGroupName);

      this.plot.data.push(tempPressureLine);
    });

    this.calculationError = "";
    this.needToCalculate = false;
    this.isLoading = false;
  }

  private async addWellheadAndPerfsToPlot(operation, resultIndex, ops, legendGroupName: string) {
    let isFirstOperation = ops ? ops.length >= 1 ? ops.indexOf(operation) === 0 : false : true;

    let perforationDepth = operation.inside['perforation']?.measuredDepth;
    if (!perforationDepth) {
      perforationDepth = (await lastValueFrom(this._thermalOperationsService.getThermalOperationById(operation.previousOperationId))).inside['perforation']?.measuredDepth;
    }
    let rowX = this._temperatureResults[resultIndex].phases[0].temperatureResultsTable.rows.find(x => (perforationDepth - x[0]) < 0.001)[2];
    let rowY = this._pressureResults[resultIndex].phases[0].pressureResultsTable.rows.find(x => (perforationDepth - x[0]) < 0.001)[2];
    const shoePoint = {
      x: [rowX],
      y: [rowY],
      name: 'Perforations',
      mode: 'markers',
      marker: {
        size: 10,
        color: '#FF0000'
      },
      showlegend: isFirstOperation ? true : false,
      legendgroup: legendGroupName
    };

    const wellheadPoint = {
      x: [this._temperatureResults[resultIndex].phases[0].temperatureResultsTable.rows[0][2]],
      y: [this._pressureResults[resultIndex].phases[0].pressureResultsTable.rows[0][2]],
      name: 'Wellhead',
      mode: 'markers',
      marker: {
        size: 10,
        color: '#999999',
      },
      showlegend: isFirstOperation ? true : false,
      legendgroup: legendGroupName
    };

    this.plot.data.push(wellheadPoint);
    this.plot.data.push(shoePoint);
  }

  public onPhaseEnvelopeSelected(e): void {
    this.isLoading = true;
    this.operationEnvelopeStore.update(state => ({...state, selectedPhaseEnvelope: e.value}));
    this.plot.data = [];
    this.phaseEnvelopes.forEach(phaseEnvelope => {
      if (phaseEnvelope.fluid.id === e.value.fluid.id) {
        this.doStuffWithMultipleData(phaseEnvelope);
      }
    });
  }

  public modeToggle(e) {
    // this.moveUpDisabled = true;
    // this.moveDownDisabled = false;
    this.plot.data = [];
    this.operationEnvelopeStore.update(state => ({...state, selectedSingleMultiple: e}));
    if (e === 'singleOperation') {
      this.operationEnvelopeStore.update(state => ({...state, selectedOperation: this.operations[0]}));
      this.getData();
    } else {
      this.calculationError = "";
      this.needToCalculate = false;
      this.operationEnvelopeStore.update(state => ({...state, selectedResultsDisplay: 'plot', selectedPhaseEnvelope: this.phaseEnvelopes[0]}));
      this.getMultipleData();
    }
  }

  public onOperationSelected(e): void {
    this.isLoading = true;
    if (this._temperatureResults.length == 0) {
      this.needToCalculate = true;
      this.calculationError = "Please calculate Perical to see results.";
    }

    this.operationEnvelopeStore.update(state => ({...state, selectedOperation: e.value}));
    let resultIndex = this._temperatureResults.findIndex(x => x.name === e.value.name);
    let operationId = this._temperatureResults[resultIndex].operationId;

    this.moveUpDisabled = resultIndex === 0;
    this.moveDownDisabled = resultIndex === this._temperatureResults.length - 1;

    this.plot.data = [];
    this.getPhaseEnvelope(operationId, resultIndex);
  }

  private getPhaseEnvelope(operationId: string, resultIndex: number): void {
    this._thermalOperationsService.getThermalOperationById(operationId).subscribe(async operation => {
      let previousOperation;
      let perforationDepth;
      if (!operation.inside['flow'] || !operation.inside['flow']?.co2EosOption || operation.inside['flow']?.co2EosOption != 'GERG2008') {
        if (operation.inside.type == 'shutIn' && !operation.previousOperationId.startsWith('00000000')) {
          previousOperation = await lastValueFrom(this._thermalOperationsService.getThermalOperationById(operation.previousOperationId));
          if (previousOperation.inside['flow']?.co2EosOption != 'GERG2008') {
            this.returnCalculateGerg2008Error(operation);
          } else {
            perforationDepth = operation.inside['perforation']?.measuredDepth ?? previousOperation.inside['perforation']?.measuredDepth;
            this.getFluidEnvelope(previousOperation.inside['fluid'].id, resultIndex, operation, perforationDepth);
          }
        } else if (operation.inside['flow']?.co2EosOption == 'ImportFile') {
          this.returnCalculateGerg2008Error(operation);

          // this.needToCalculate = false;
          // this.isLoading = false;
          // perforationDepth = operation.inside['perforation']?.measuredDepth;
          // this.plotData(null, resultIndex, operation);
        } else {
          this.returnCalculateGerg2008Error(operation);
        }
      }

      if (this.plot.data.length > 1) {
        return;
      }

      if (operation.inside.type != 'shutIn' && operation.inside['flow']?.co2EosOption != 'ImportFile') {
        perforationDepth = operation.inside['perforation'].measuredDepth;
        this.getFluidEnvelope(operation.inside['fluid'].id, resultIndex, operation, perforationDepth);
      }
    });
  }

  private returnCalculateGerg2008Error(operation: any) {
    this.calculationError = "Please select GERG2008 as the CO2 EOS option to see results.";
    this.needToCalculate = true;
    this.isLoading = false;
    this.plot.data = [];
    this.operationEnvelopeStore.update(state => ({...state, selectedOperation: this.operations.find(x => x.name === operation.name)}));
    return;
  }

  private getFluidEnvelope(fluidId, resultIndex, operation, perforationDepth) {
    this._fluidService.getFluidById(fluidId).subscribe({
      next: (fluid) => {
        if (fluid.state.type == 'co2Fluid') {
          if (fluid.state['co2withImpurities']) {
            this._fluidService.getPhaseEnvelope(fluidId).subscribe({
              next: (res) => {
                if (this.plot.data.length == 2) {
                  return;
                }
                this.plotData(res, resultIndex, operation);
              }});
          }
          else {
            this.plotData(this._defaultEnvelope, resultIndex, operation);
          }
          this.calculationError = "";
          this.needToCalculate = false;
        } else {
          this.calculationError = "Please select GERG2008 as the CO2 EOS option to see results.";
          this.needToCalculate = true;
          this.isLoading = false;
          this.plot.data = [];
          return;
        }
    }});
  }

  private plotData(data, resultIndex, operation): void {
    if (data) {
      data['name'] = 'Phase Envelope';
      this.plot.data.push(data);
    }

    const temperatures = this._temperatureResults[resultIndex].phases[0].temperatureResultsTable.rows.map(x => x[2]);
    const pressures = this._pressureResults[resultIndex].phases[0].pressureResultsTable.rows.map(x => x[2]);
    let legendGroupName = 'group' + resultIndex;

    const tempPressureLine = {
      x: temperatures,
      y: pressures,
      name: this._temperatureResults[resultIndex].name,
      legendgroup: legendGroupName,
    };

    const pressTempRes = this.createPoints(pressures, temperatures);
    this.resultsGridOperationData = pressTempRes.map(r => zipObject(this.cols.map(c => c.field), r));

    this.addWellheadAndPerfsToPlot(operation, resultIndex, false, legendGroupName);
    this.plot.data.push(tempPressureLine);

    const results = this.createPoints(data['y'], data['x']);
    this.resultsGridPhaseEnvelope = results.map(r => zipObject(this.cols.map(c => c.field), r));

    this.resultsGrid = this.operationEnvelopeStore.state.selectedEnvelopeOperation === 'phaseEnvelope' ? this.resultsGridPhaseEnvelope : this.resultsGridOperationData;
    this.operations = this._temperatureResults.map(x => {
      return {
        name: x.name,
        operationId: x.operationId,
      };
    });
    this.operationEnvelopeStore.update(state => ({...state, selectedOperation: this.operations.find(x => x.name === operation.name)}));

    this.isLoading = false;
  }

  public selectedTableChange(e: Event): void {
    this.operationEnvelopeStore.update(state => ({...state, selectedEnvelopeOperation: e['value']}));

    this.resultsGrid = e['value'] === 'phaseEnvelope' ? this.resultsGridPhaseEnvelope : this.resultsGridOperationData;
  }

  public onSelectedResultsDisplayUpdated(e): void {
    this.operationEnvelopeStore.update(state => ({...state, selectedResultsDisplay: e.value}));
  }

  createPoints(x: number[], y: number[]): [number, number][] {
    if (x.length !== y.length) {
      throw new Error("The 'x' and 'y' arrays must have the same length.");
    }

    const points: [number, number][] = [];

    for (let i = 0; i < x.length; i++) {
      const point: [number, number] = [x[i], y[i]];
      points.push(point);
    }

    return points;
  }

  private formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  onUpDownButtonClick(e): void {
    this.isLoading = true;
    let currentOperationIndex = this._temperatureResults.findIndex(x => x.name === this.operationEnvelopeStore.state.selectedOperation.name);
    let nextOperationIndex = e === 'up' ? currentOperationIndex - 1 : currentOperationIndex + 1;
    this.moveUpDisabled = nextOperationIndex - 1 < 0;
    this.moveDownDisabled = nextOperationIndex >= this._temperatureResults.length - 1;

    if (nextOperationIndex < 0 || nextOperationIndex >= this._temperatureResults.length) {
      return;
    }
    let operationId = this._temperatureResults[nextOperationIndex].operationId;

    this.plot.data = [];
    this.getPhaseEnvelope(operationId, nextOperationIndex);
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
