<div class="env-banner" [attr.data-env]="environment"><span class="env-banner__text">{{environment}}</span></div>

<app-header></app-header>

<div style="height: calc(100vh - 45px);">
  <main style="height: 100%;">

    <div class="container" *ngIf="auth.isLoading$ | async; else loaded">
      <app-loader show="true"></app-loader>
    </div>

    <ng-template #loaded>
      <router-outlet></router-outlet>
      <p-toast></p-toast>

      <p-toast position="top-center" key="idle" (onClose)="onIdleWarningClose()" [baseZIndex]="5000" >
        <ng-template let-message pTemplate="message" let-closeFn="closeFn">
          <div class="flex flex-column align-items-start" style="flex: 1">
            <div class="flex align-items-center gap-2">
              <span class="font-bold text-900">{{message.summary}}</span>
            </div>
            <div class="font-medium text-lg text-900">{{idleState}}</div>
          </div>
        </ng-template>
      </p-toast>

    </ng-template> 

  </main>
</div>