<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div *ngIf="safetyFactorResultsStore" #toolbarDiv class="p-toolbar-group-left">
    <div class="mr-2">
      <p-selectButton
        [options]="modes"
        [(ngModel)]="safetyFactorResultsStore.state.selectedMode"
        (onChange)="onModeChange($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div class="mr-2">
      <p-selectButton
        *ngIf="safetyFactorResultsStore.state?.selectedMode !== 'minimum'"
        [options]="resultsDisplay"
        [(ngModel)]="safetyFactorResultsStore.state.selectedResultDisplay"
        (onChange)="onSelectedResultsDisplayChange($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div
      *ngIf="safetyFactorResultsStore.state?.selectedResultDisplay === 'grid'"
      class="p-mr-2 checkboxClass"
    >
      <p-checkbox
        [(ngModel)]="safetyFactorResultsStore.state.includeCharacters"
        label="Show Details"
        (onChange)="toggleCharacterView($event)"
        binary="true"
      ></p-checkbox>
    </div>
    <div
      class="flex grid-nogutter"
      *ngIf="
        safetyFactorResultsStore.state?.selectedResultDisplay === 'plot' &&
        safetyFactorResultsStore.state?.selectedMode !== 'minimum'
      "
    >
      <div class="mr-2">
        <p-selectButton
          [options]="depthView"
          [(ngModel)]="safetyFactorResultsStore.state.selectedDepthView"
          (onChange)="depthViewToggle($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>
    <div class="mr-2">
      <app-single-load-results
        [loadCases]="loadCases"
        (loadCaseSelected)="setLoadCase($event)"
        [stateStore]="safetyFactorResultsStore"
        [signalRChange]="signalRChange"
      ></app-single-load-results>
    </div>
  </div>

  <app-single-load-plot
    [yAxisTitle]="yAxisTitle"
    [xAxisTitle]="xAxisTitle"
    [columnDefs]="columnDefs"
    [data]="results"
    *ngIf="safetyFactorResultsStore.state?.selectedResultDisplay === 'plot'"
    [selectedDepthView]="safetyFactorResultsStore.state?.selectedDepthView"
    [depthUnit]="depthUnit"
    [plotName]="plotName"
    [plotTitle]="'Safety Factor Results'"
    [componentHeight]="componentHeight"
  ></app-single-load-plot>

  <div>
    <br>
    <app-single-load-grid
      *ngIf="safetyFactorResultsStore.state?.selectedResultDisplay === 'grid'"
      [apiCollapseType]="apiCollapseType"
      [designFactors]="designFactors"
      [selectedLoad]="safetyFactorResultsStore.state?.selectedLoadCase"
      [rowData]="results"
      [columnDefinitions]="columnDefinitions"
      tableName="Safety Factor Results"
      [tableHeight]="tableHeight"
      gridName="Safety Factor Results"
      [mode]="safetyFactorResultsStore.state?.selectedMode"
    ></app-single-load-grid>
  </div>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br />
    <br />
  </p-dialog>
</app-tool-loader>
