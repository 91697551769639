import { AfterContentInit, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FullEvacIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';

interface FullEvacIppForm {
  type: InternalPressureProfileType.FULLEVAC
}

@Component({
  selector: 'app-full-evac-ipp',
  template: ``,
  providers: subformComponentProviders(FullEvacIppComponent)
})
export class FullEvacIppComponent implements AfterContentInit {

  public form = createForm<FullEvacIPP, FullEvacIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.FULLEVAC)
    },
    toFormGroup: (obj: FullEvacIPP | null): FullEvacIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: InternalPressureProfileType.FULLEVAC
      }
    },
    fromFormGroup: (formValue: FullEvacIppForm): FullEvacIPP | null => {
      const { type, ...commonValues} = formValue;

      return { type: InternalPressureProfileType.FULLEVAC, ...commonValues }
    }
  });

  constructor() { }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(InternalPressureProfileType.FULLEVAC);
  }
}
