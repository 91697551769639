import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { ApbResultsComponent } from '../perinix/components/apb-results/apb-results.component';
import { ApbInputsComponent } from '../perinix/components/apb-inputs/apb-inputs.component';
import { ApbResultsGridComponent } from '../perinix/components/apb-results-grid/apb-results-grid.component';

@NgModule({
  declarations: [
    ApbResultsComponent,
    ApbInputsComponent,
    ApbResultsGridComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [

  ]
})
export class PerinixModule { }
