import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription, forkJoin, lastValueFrom, timer } from 'rxjs';
import { catchError, debounce, map } from 'rxjs/operators';
import { FormationCementCatalog } from 'src/app/catalogs/models/formations-cement-catalog.model';
import { FormationsService } from 'src/app/catalogs/shared/services/formations-cement-catalog.service';
import { FormationTop } from 'src/app/perical/models/formation.model';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { UdtService } from 'src/app/shared/services/udt.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { FormationTopService } from '../../services/formation-top.service';
import { WellTypeService } from '../../services/well-type-datums.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { Utilities } from 'src/app/core/services/utilities';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { FormationTopsUi } from '../../models/formation-tops.model';
import { map as map1} from "lodash";

@Component({
  selector: 'app-formation-tops',
  templateUrl: './formation-tops.component.html',
  styleUrls: ['./formation-tops.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class FormationTopsComponent implements OnInit, AfterViewInit, OnDestroy {

  public uu: UserUnitsModel;
  public columnDefs: Array<any>;
  public tableHeight: string;
  public _formations: Array<FormationCementCatalog>;
  private _subscriptions: Subscription;
  public formationTopsForm: UntypedFormGroup;
  public tablePlot: SelectItem[];
  public tablePlotSelected: string;
  private _udtPlotData: any;
  public northingUdt: SelectItem[];
  // public northingUdtSelected: string;
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'formationsPlot';
  public downloadPlotName = 'formation_tops_plot';
  public userRoles: UserRoles;
  public componentHeight: number;

  private defaultFormation: FormationCementCatalog;
  private _formationTops: Array<FormationTop>
  private _depths: number[];
  private _northing: number[];

  get formationTops(): UntypedFormArray {
    return this.formationTopsForm.get("formationTops") as UntypedFormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  // Validation
  public isLoading: boolean;
  public totalDepth: number;
  public mudlineDepth: number;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.formationTopsStore = this._storeService.createStore(this._componentId, new FormationTopsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public formationTopsStore: Store;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _wellTypeService: WellTypeService,
    private _trajectoryService: TrajectoryService,
    private _formationsService: FormationsService,
    private _formationTopService: FormationTopService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private udtService: UdtService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.formationTopsForm = this._formBuilder.group({
      formationTops: this._formBuilder.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);

    this.columnDefs = [
      { field: 'measuredDepth', header: `Formation Top (${this.uu.longLengths}) TVD` },
      { field: 'formationTop', header: 'Formation' }
    ];

    this.tablePlot = [
      { label: 'Table', value: 'table' },
      { label: 'Plot', value: 'plot' }
    ];

    this.northingUdt = [
      { label: 'Northing', value: 'northing' },
      { label: 'UDT', value: 'udt' }
    ];

    this.getData();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: { action: string}) {
    if (data.action == PeriforOnChangeMessages.REFRESH_FORMATIONS_CATALOG || data.action == PeriforOnChangeMessages.REFRESH_UDT_PLOT) {
      this.formationTops.clear();
      this.getData(false);
    }
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Formation Tops") {
        this.tableHeight = (e.itemHeight - 105) + 'px';
        this.componentHeight = e.itemHeight - 90;
      }
    }));
  }

  public onTablePlotSelect(e) {
    this.formationTopsStore.update((state) => ({
      ...state,
      tablePlotSelected: e.value
    }));
  }

  public onNorthingUdtSelect(e) {
    this.formationTopsStore.update((state) => ({
      ...state,
      northingUdtSelected: e.value
    }));
    this.plotFormation(this._depths, this._northing);
  }

  public onAddFormation(): void {
    if (this.formationTops.invalid) {
      return;
    }
    this.formationTops.insert(this.formationTops.length, this.newFormation(true, this.formationTops.length));

    this.handleSaveFormationRow(this.formationTops.value[this.formationTops.length - 1]);
  }

  public onDeleteFormation(idx: number): void {
    let formationId = this.formationTops.controls[idx].value.id;
    if (formationId.length < 36) {
      this.formationTops.removeAt(idx)
    } else {
      this._confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected formation section?',
        accept: async () => {
          if (formationId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._formationTopService.deleteFormation(formationId));
            this.getData(true);
          } else {
            this._formationTops = this._formationTops.filter(val => val.id !== formationId);
          }
          this.formationTops.removeAt(idx);
        }
      });
    }
  }

  getData(isSignalR?: boolean) {
    const sources: Observable<any>[] = [
      this._trajectoryService.getTrajectoryPoints() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>,
      this._formationsService.getFormationCement() as Observable<any>,
      this._formationTopService.getAllFormationTops() as Observable<any>,
      this.udtService.getUndisturbedTemperaturePlot() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([trajectory, wellType, formations, formationTops, udt]) => {
        const waterDepth = wellType.waterDepth ? wellType.waterDepth : 0;
        this.mudlineDepth = (Math.trunc(wellType.drillFloorElevation + waterDepth) * 100) / 100;
        this.totalDepth = trajectory[trajectory.length - 1].trueVerticalDepth;
        this._formations = formations.filter(x => x.discriminator == 'formation').sort((a, b) => a.name.localeCompare(b.name));
        this._formationTops = formationTops;
        this._udtPlotData = udt;
        this.defaultFormation = this._formations.find(x => x.name == 'Default') ?? this._formations[0];
        const depths = map1(trajectory, 'trueVerticalDepth');
        const northing = map1(trajectory, 'northing');

        this.populateFormData(isSignalR);
        this._depths = depths;
        this._northing = northing;
        this.plotFormation(depths, northing);

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  private plotFormation(depths, northing) {
    this.plot.data = [];
    let minX;
    let maxX;
    if (this.formationTopsStore.state.northingUdtSelected == 'northing') {
      minX = Math.min(...northing) - 230;
      maxX = Math.max(...northing) + 230;
    }
    else {
      minX = Math.min(...this._udtPlotData.x) - 20;
      maxX = Math.max(...this._udtPlotData.x) + 20;
    }

    let formationColors: string[] = [
      '#743E07',
      '#9C560F',
      '#D27617',
      '#8E5D2A',
      '#6D4F31',
      '#A36A2F',
      '#EC8316',
      '#A96F34'
    ];

    let i = 0;
    this._formationTops.forEach(formTop => {
      const top = {
        name: formTop.formation['name'] + ' Top',
        y: [formTop.formationTop, formTop.formationTop],
        x: [minX - 0.5, maxX + 0.5],
        mode: 'lines',
        hoverinfo: 'none',
        line: {
          color: formationColors[i]
        },
      }
      i++;
      if (i >= formationColors.length) { i = 0; }
      this.plot.data.push(top);
    });

    if (this.formationTopsStore.state.northingUdtSelected == 'northing') {
      const trajectory = {
        name: "Northing",
        y: depths,
        x: northing,
        line: {
          color: '#017BEE'
        },
        mode: 'lines'
      }

      this.plot.data.push(trajectory);
    } else {
      const udtData = {
        name: 'UDT',
        x: this._udtPlotData.x,
        y: this._udtPlotData.y,
        line: {
          color: '#FF0000'
        },
      }

      this.plot.data.push(udtData);
    }

    this.xAxisTitle = this.formationTopsStore.state.northingUdtSelected == 'northing' ? `Northing (${this.uu.longLengths})` : `UDT (${this.uu.temperature})`;
    this.yAxisTitle = `TVD (${this.uu.longLengths})`;

    this.isLoading = false;
  }

  getPlaceholderText(data: any) {
    if (data == null) {
      this.formationTops.clear();
      this.getData(false);
    }
    return this._formations.find(x => x.id == data.id) ? data.name : data.name + ' (local only)';
  }

  public showJson(data: any) {
    return JSON.stringify(data, null, '\t');
  }

  private populateFormData(isSignalR: boolean) {
    let formationTop: Array<{ id: any, formationTop: any, formation: any }>;

    if (this._formationTops.length === 0) {
      let defaultFormationTop: FormationTop = {
        formation: this.defaultFormation,
        formationTop: this.mudlineDepth,
        id: Utilities.generateFakeGuid()
      }

      this._formationTopService.setFormationTop(defaultFormationTop).subscribe(res => {
        this._formationTopService.getAllFormationTops().subscribe(res => {
          this._formationTops = res;
          this.populateFormData(isSignalR);
        });
      });
    }

    this._formationTops.forEach((data, idx) => {
      let formationTopFg = this.newFormation(false, idx);
      if (!isSignalR) {
        this.formationTops.push(formationTopFg);
      }
      let expandedData = { ...data };
      formationTop = [{ id: expandedData.id, formationTop: expandedData.formationTop, formation: expandedData.formation }];
      this.formationTops?.controls[idx]?.setValue(formationTop[0], { emitEvent: false });
    });
  }

  private newFormation(isNewFormation: boolean, idx: number): UntypedFormGroup {
    let defaultDepth = this.formationTops.getRawValue()[this.formationTops.value.length - 1]?.formationTop + 100 ?? this.mudlineDepth;
    let formationTopFg = new UntypedFormGroup({
      id: new UntypedFormControl(Utilities.generateFakeGuid()),
      formationTop: new UntypedFormControl({ value: defaultDepth, disabled: this.userRoles.readOnly || idx == 0 }, [Validators.required, Validators.min(this.mudlineDepth), Validators.max(this.totalDepth)]),
      formation: new UntypedFormControl({ value: this._formations ? this.defaultFormation : null, disabled: this.userRoles.readOnly }, Validators.required),
    });

    let debouncedValueChanges = formationTopFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSaveFormationRow(v, formationTopFg)));

    if (isNewFormation && this.formationTops.value.length < 1) {
      formationTopFg.controls.formationTop.patchValue(this.mudlineDepth);
    }

    formationTopFg.markAllAsTouched();

    return formationTopFg;
  }

  private async handleSaveFormationRow(v: any, formationTopRecord?: UntypedFormGroup): Promise<void> {
    if (v.formationTop < this.mudlineDepth || v.formationTop > this.totalDepth) {
      return;
    }
    if (formationTopRecord?.valid ?? true) {
      if (!v.formationTop) {
        const rawData = this.formationTops.getRawValue();
        v = rawData.find(x => x.id == v.id);
      }
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        await lastValueFrom(this._formationTopService.setFormationTop(new FormationTop(v)));
      } else {
        await lastValueFrom(this._formationTopService.editFormationTop(new FormationTop(v)));
      }
      this.getData(true);
    }
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }

}
