<form [formGroup]="form.formGroup" bg-dark>

  <div class="p-grid">
    <div class="p-col">
  <p-fieldset legend="Mud Pit Environment">
    <div class="p-grid">
      <div class="p-col">
        <div class="p-fluid">
        <div class="p-field">
          <label for="isNewMudTemperature">Starting Conditions</label>
          <div class="p-inputGroup">
            <div class="p-grid p-nogutter">
              <div class="p-md-2">
            <p-dropdown
              [options]="startingConditions"
              [formControlName]="form.formControlNames.isNewMudTemperature"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
              (onChange)="disableConfiguration($event)"
            ></p-dropdown>
          </div>

          <div class="p-col-3">
            <input
              id="newMudTemperature"
              pInputText
              [formControlName]="form.formControlNames.newMudTemperature"
              required
              [attr.disabled]="startWithNewMudPit"
            />
          </div>
            <span class="p-inputgroup-addon newMudTemperatureAddon">°F</span>
          </div>
        </div>
        </div>

        <p-fieldset legend="Wind/Ventilation Conditions">
          <div class="p-field">
            <label>Air Temperature</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="form.formControlNames.airTemperature"
              />
              <span class="p-inputgroup-addon">°F</span>
            </div>
          </div>

          <div class="p-field">
            <label>Air Velocity</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="form.formControlNames.airVelocity"
              />
              <span class="p-inputgroup-addon">mph</span>
            </div>
          </div>
        </p-fieldset>

      </div>
    </div>
    </div>

  </p-fieldset>
</div>

<div class="p-col">

  <p-fieldset legend="Mud Pit Configuration">
    <div class="p-grid">
      <div class="p-col">

        <div class="p-field">
          <label>Number Of Mud Tanks</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.numberOfTanks"
              (change)="getTotalMudVolume('numberOfTanks', $event)"
              [attr.disabled]="startWithNewMudPit"
            />
          </div>
        </div>

      <p-fieldset legend="Per Tank Details">
        <div class="p-field">
          <label>Nominal Volume</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.nominalVolume"
              (change)="getTotalMudVolume('nominalVolume', $event)"
              [attr.disabled]="startWithNewMudPit"
            />
            <span class="p-inputgroup-addon">bbls</span>
          </div>
        </div>

        <div class="p-field">
          <label>Percentage Volume Active</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.activeVolumeFraction"
              (change)="getTotalMudVolume('activeVolumeFraction', $event)"
              [attr.disabled]="startWithNewMudPit"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </p-fieldset>

        <br>

        <b><u><label>Total Mud Volume: {{ totalMudVolumeBbls }} bbls  ({{ totalMudVolumeCubicFeat }} ft³)</label></u></b>

        <br>
        <br>

        <div class="p-field">
          <label>Exposed Surface Area</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.exposedSurfaceArea"
              [attr.disabled]="startWithNewMudPit"
            />
            <span class="p-inputgroup-addon">ft²</span>
          </div>
        </div>

        <div class="p-field">
          <label>Agitator Power Rating</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.agitatorPower"
              [attr.disabled]="startWithNewMudPit"
            />
            <span class="p-inputgroup-addon">hp</span>
          </div>
        </div>

      </div>
    </div>
  </p-fieldset>
</div>
</div>


</form>
