<div class="loadCaseDetails">
  <app-tool-loader [isLoading]="!_load">
    <form [formGroup]="form.formGroup">
      <br />
      <div class="grid">
        <div class="col-6" style="display: grid">
          <label for="name">Load Name</label>
          <input
            type="string"
            pInputText
            [formControlName]="form.formControlNames.name"
            id="name"
            style="width: 250px"
            [readOnly]="_load?.name === 'Initial Condition'"
            [ngStyle]="
              disableStyle ? { 'user-select': 'none', opacity: 0.35 } : {}
            "
          />
        </div>
        <div class="col-6 flex justify-content-end align-items-center">
          <validation-error
            [isValid]="
              isControlInvalid(form.formGroup, 'internalPressureProfile')
            "
            [errorMsg]="
              getControlErrors(form.formGroup, 'internalPressureProfile')
            "
          ></validation-error>
          <button
            pButton
            pRipple
            *ngIf="!form.formGroupErrors"
            label="Save"
            [disabled]="form.formGroup.invalid || form.formGroup.disabled"
            icon="pi pi-check"
            class="p-button-success p-button-outlined"
            (click)="manualSave$$.next()"
          ></button>
          <button
            pButton
            pRipple
            *ngIf="form.formGroupErrors"
            label="Save"
            [disabled]="true"
            icon="pi pi-times-circle"
            class="p-button-success p-button-outlined"
          ></button>
          <button
            pButton
            pRipple
            label="Cancel"
            icon="pi pi-times"
            class="p-button-outlined p-button-danger"
            (click)="cancelEdit$.next()"
          ></button>
        </div>
      </div>
      <div class="grid">
        <div class="col">
          <app-internal-pressure-profiles
            [isReadOnly]="isReadOnly"
            #ipp
            [formControlName]="form.formControlNames.internalPressureProfile"
            [currentString]="currentString"
            [cement]="cement"
            [thermalOperations]="thermalOperations"
            [wellType]="wellType"
            [shoeDepth]="shoeDepth"
            [userUnits]="userUnits"
            [wellTd]="wellTd"
          ></app-internal-pressure-profiles>
        </div>
        <div class="col">
          <app-external-pressure-profiles
            [isReadOnly]="isReadOnly"
            #epp
            [formControlName]="form.formControlNames.externalPressureProfile"
            [shoeDepth]="shoeDepthTvd"
            [currentString]="currentString"
            [cement]="cement"
            [porePressures]="porePressures"
            [thermalOperations]="thermalOperations"
            [userUnits]="userUnits"
            [wellTd]="wellTd"
          ></app-external-pressure-profiles>
        </div>
        <div class="col">
          <p
            *ngIf="form.formGroup.errors?.customTempAboveInfluxDepth"
            class="error"
          >
            Temperature Profile must be specified to Influx Depth
          </p>
          <br *ngIf="form.formGroup.errors?.customTempAboveInfluxDepth" />
          <app-temperature-profiles
            [isReadOnly]="isReadOnly"
            #tp
            [formControlName]="form.formControlNames.temperatureProfile"
            [currentString]="currentString"
            [loadName]="_load?.name"
            [thermalOperations]="thermalOperations"
            [wellType]="wellType"
            [shoeDepth]="shoeDepth"
            [userUnits]="userUnits"
            [wellTd]="wellTd"
          ></app-temperature-profiles>
        </div>
      </div>
    </form>
  </app-tool-loader>
</div>

<!-- <pre>{{ form.formGroupErrors | json }}</pre>
<pre>{{ form.formGroup.value | json }}</pre> -->
