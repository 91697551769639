import { Component, Input, OnInit } from '@angular/core';
import {  UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { GreenCementTest, PreInstallLoadType } from 'src/app/perivis/models/load-case.model';

export interface GreenCementTestForm {
  type: PreInstallLoadType;
  PumpPressure: number;
}

@Component({
  selector: 'app-green-cement-test-details',
  template: `
    <form [formGroup]="form.formGroup" bg-dark>
      <div class="field">
          <label for="tempAtPerfs">Pump Pressure</label>
          <div class="p-inputgroup">
            <input
              [type]="'number'"
              pInputText
              [formControlName]="form.formControlNames.PumpPressure"
            />
            <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
          </div>
          <p *ngIf="form.formGroup.controls.PumpPressure.value && form.formGroup.controls.PumpPressure.invalid" class="error">Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureUnit }}</p>
        </div>
    </form>
  `,
  styleUrls: ['./green-cement-test-details.component.scss'],
  providers: subformComponentProviders(GreenCementTestDetailsComponent)
})
export class GreenCementTestDetailsComponent implements OnInit {

  constructor() { }

  @Input() userUnits: UserUnitsModel;

  public pressureUnit: string;
  public pressureValidation: { min: number, max: number };

  ngOnInit(): void {
    this.pressureUnit = this.userUnits.pressure;

    
    switch (this.pressureUnit) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 30000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 2068 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 206800 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 2041 };
        break;
    }
    this.form.formGroup.controls.PumpPressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required]);
  }

  public form = createForm<GreenCementTestForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      PumpPressure: new UntypedFormControl(null)
    },
    toFormGroup: (obj: GreenCementTest | null): GreenCementTestForm => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        PumpPressure: obj.PumpPressure
      }
    },
    fromFormGroup: (formValue: GreenCementTestForm): GreenCementTest => {
      return {
        type: PreInstallLoadType.GREENCEMENTTEST,
        PumpPressure: formValue.PumpPressure
      }
    }
  })
}
