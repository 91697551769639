<form [formGroup]="form.formGroup">
  <div class="defaultButton">
    <img
      (click)="applyDefaults()"
      title="Apply Defaults"
      src="../../assets/icons/default-icon-white.png"
    />
  </div>
  <div class="grid">
    <div class="col">
      <div class="field">
        <label for="baseFluid">Base Fluid</label>
        <p-dropdown
          [disabled]="isReadOnly"
          [options]="baseFluids"
          [formControlName]="form.formControlNames.baseFluid"
          id="baseFluid"
          appendTo="body"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="field">
        <label for="nominalDensity">Nominal Density</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="nominalDensity"
            [formControlName]="form.formControlNames.nominalDensity"
            required
            [minFractionDigits]="1"
            (onBlur)="setDefaultPvYp($event, false)"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ densityUnits }}</span>
        </div>
        <p *ngIf="form.formGroup.controls.nominalDensity.invalid" class="error">
          Allowed range: {{ densityValidation.min }} -
          {{ densityValidation.max }} {{ densityUnits }}
        </p>
      </div>
      <div class="field">
        <label for="densityTemperature">Density Temperature</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="densityTemperature"
            [formControlName]="form.formControlNames.densityTemperature"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ tempUnit }}</span>
        </div>
        <p
          *ngIf="form.formGroup.controls.densityTemperature.invalid"
          class="error"
        >
          Allowed range: {{ temperatureValidation.min }} -
          {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>
    </div>
    <div class="col">
      <div class="field">
        <label for="plasticViscosity">Plastic Viscosity</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="plasticViscosity"
            [formControlName]="form.formControlNames.plasticViscosity"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">cP</span>
        </div>
        <p
          *ngIf="form.formGroup.controls.plasticViscosity.invalid"
          class="error"
        >
          Allowed range: 0 - 2000 cP
        </p>
      </div>
      <div class="field">
        <label for="yieldPoint">Yield Point</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="yieldPoint"
            [formControlName]="form.formControlNames.yieldPoint"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">lbf/100ft²</span>
        </div>
        <p *ngIf="form.formGroup.controls.yieldPoint.invalid" class="error">
          Allowed range: 0 - 2000 lbf/100ft²
        </p>
      </div>
      <div class="field">
        <label for="rheologyTemperature">Rheology Temperature</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="rheologyTempDefault"
            [formControlName]="form.formControlNames.rheologyTemperature"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ tempUnit }}</span>
        </div>
        <p
          *ngIf="form.formGroup.controls.rheologyTemperature.invalid"
          class="error"
        >
          Allowed range: {{ temperatureValidation.min }} -
          {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>
    </div>
  </div>
</form>
