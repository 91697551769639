import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DesignFactorsService {

  constructor(private http: HttpClient) { }

  getDesignFactors(): Observable<any> {
    return this.http.get(`${PeriforApiEndpoint}/designFactors`);
  }

  updateDesignFactors(designFactors: any) {
    return this.http.put(`${PeriforApiEndpoint}/designFactors`, designFactors, httpOptions);
  }
}
