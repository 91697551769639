export class Design {

  public constructor(init?: Partial<Design>) {
    Object.assign(this, init);
  }

  id: string;
  name?: string;

}
