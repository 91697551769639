import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { forkJoin, lastValueFrom, Observable, Subscription } from "rxjs";
import { PeriforOnChangeMessages, SignalRService } from "src/app/shared/services/signal-r.service";
import { WellConfigService } from "src/app/shared/services/well-config.service";
import { WellheadModel } from "src/app/wellbore-inputs/components/wellhead/wellhead.model";
import { WellheadService } from "src/app/wellbore-inputs/services/wellhead.service";
import { StressResultsService } from "../../services/stress-results.service";
import { UserUnitsModel } from "src/app/core/components/user-units/user-units.model";
import { Units } from "src/app/core/services/unit-library";
import { GridItemResizedMessage } from "src/app/shared/models/mediator-messages.model";
import { MediatorService } from "src/app/shared/services/mediator.service";
import { StorageKeys, StoreService } from "src/app/core/services/store.service";
import { LiftOffResultsUi } from "../../models/perivis-results-state.model";
import { Store } from "@ngneat/elf";

@Component({
  selector: "app-liftoff-results",
  templateUrl: "./liftoff-results.component.html",
  styles: [`
    p-dialog {
      user-select: none;
    }

    label {
      padding-left: 5px;
    }

    .lockdownRating {
      font-size: 12px;
      color: rgb(239, 83, 80);
    }
  `],
})
export class LiftoffResultsComponent implements OnInit, OnDestroy {
  public resultsDisplay: SelectItem[];
  public results: any[];
  public isLoading: boolean;
  public errorMsg: string;
  public configMissing: boolean;
  public wellheadDisabled: boolean = true;
  public hangerLiftoffForce: number;
  public userUnits: UserUnitsModel;
  public columnDefinitions: Array<any>;
  public annotations: any[] = [];
  public shapes: any[] = [];
  public forceUnit: string;
  public tableHeight: string;
  public lockdownRating: number;

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'liftoffPlot';
  public downloadPlotName = 'liftoff_results_plot';
  public plot = {
    data: [],
    layout: {
      yaxis: {
        title: {
          text: ''
        },
      }
    },
    config: {
      scrollZoom: false
    }
  };
  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.liftoffResultsStore = this._storeService.createStore(this.componentId, new LiftOffResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public liftoffResultsStore: Store;

  private _subscriptions: Subscription;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService,
    private _wellheadService: WellheadService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == 'Lift-Off Results') {
        this.tableHeight = (e.itemHeight - 100) + 'px';
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.forceUnit = Units.lib[this.userUnits.force].symbol;

    this.yAxisTitle = `Hanger Lift-Off Force (${this.forceUnit})`;

    this.checkConfig();

    this.resultsDisplay = [
      { label: 'Plot', value: 'plot' },
      { label: 'Grid', value: 'grid' }
    ];
    
    this.columnDefinitions = [
      {
        header: 'Load Case', field: 'loadCaseName',
        valueFormatter: (params: any) => params
      },
      {
        header: `Hanger Lift-Off Force (${this.forceUnit})`, field: 'hangerLiftoffForce',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
    
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, async d => this.signalRfunc(d));
   
    await this.refreshResults(true);
  }

  async signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_RESULTS || data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      await this.refreshResults(true);
    }
  }

  async onSelectedResultDisplayUpdated(e): Promise<void> {
    this.liftoffResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
    await this.refreshResults(true);
  }

  public async refreshResults(isLoading?: boolean): Promise<void> {
    this.isLoading = isLoading;

    try {
      const sources = [
        this._wellheadService.getWellheadForTubular(),
        this._wellConfigService.getTubular() as Observable<any>,
        this._wellConfigService.getTubulars(),
        this._stressResultsService.getLiftoffResults()
      ];

      const [wellhead, currentString, tubulars, liftoffResults] = await lastValueFrom(forkJoin(sources));

      this.processWellheadData(wellhead, currentString, tubulars, liftoffResults);

    } catch (ex) {
      this.handleError("Well Configuration Missing: Please specify at least one string");
    } finally {
      this.isLoading = false;
    }
  }

  private validateLiftoffResults(liftoffResults: any[]): boolean {
    if (!liftoffResults.length || !liftoffResults[0].loadCaseName) {
      this.handleError("Please calculate Perivis to see results");
      return false;
    }
    if (liftoffResults[0].loadCaseName === "Initial Condition" && liftoffResults[0].hangerLiftoffForce === 0) {
      this.handleError("Please make sure that Wellhead inputs are specified and recalculate Perivis");
      return false;
    }
    return true;
  }

  private processWellheadData (
    wellhead: WellheadModel,
    currentString: any,
    tubulars: any[],
    liftoffResults: any[]
  ): void {
    this.lockdownRating = wellhead.hangerLockdownRating;
    this.hangerLiftoffForce = -wellhead.hangerLockdownRating;

    const stringIndex = tubulars.findIndex(x => x.id === currentString.id);
    this.wellheadDisabled = stringIndex === 0 || tubulars[stringIndex].type === 'Liner';

    if (!this.wellheadDisabled && !this.validateLiftoffResults(liftoffResults)) {
      return;
    }
    
    this.errorMsg = this.wellheadDisabled ? "Not applicable for outermost string or liners" : '';

    this.clearResults();

    if (!this.wellheadDisabled) {
      this.parseResults(liftoffResults);
      this.results = liftoffResults;
    }

    this.setHangerRating(wellhead, this.wellheadDisabled);
  }

  private handleError(message: string): void {
    this.errorMsg = message;
    this.clearResults();
  }

  private clearResults(): void {
    this.results = [];
    this.plot.data = [];
  }

  private parseResults(results: any[]) {
    if (results === undefined) {
      return;
    }
    this.plot.config.scrollZoom = true;
    const traceArray = {
      x: [],
      y: [],
      type: "bar",
      width: 0.55,
    };

    results.forEach((result) => {
      traceArray.x.push(result.loadCaseName);
      traceArray.y.push(result.hangerLiftoffForce);
    });

    this.plot.data = [traceArray];
  }

  private setHangerRating(wellhead: WellheadModel, wellheadDisabled: boolean): void {
    if (!wellheadDisabled) {
      this.shapes = [
        {
          type: "line",
          xref: "paper",
          x0: 0,
          y0: -wellhead.hangerLockdownRating,
          x1: 1,
          y1: -wellhead.hangerLockdownRating,
          line: {
            color: "rgb(239, 83, 80)",
            width: 2,
            dash: "dot",
          },
        },
      ];

      this.annotations = [
        {
          showarrow: false,
          text: "Wellhead Hanger Lockdown Rating: " + wellhead.hangerLockdownRating + ` ${this.forceUnit}`,
          font: {
            color: "rgb(239, 83, 80)"
          },
          xref: "paper",
          align: "right",
          x: 1,
          xanchor: "right",
          y: -wellhead.hangerLockdownRating,
          yanchor: "bottom",
        },
      ];
    } else {
      this.shapes = [];
      this.annotations = [];
    }
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0
      ? (Math.trunc(value * divisor) / divisor).toLocaleString("en-US")
      : null;
  }

  private checkConfig() {
    this._wellConfigService.getStringsList().subscribe((data) => {
      this.configMissing = data.length < 1;
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.signalRfunc = null;
  }
}
