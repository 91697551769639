import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BlackOil, FluidType, GasComposition } from 'src/app/perical/models/fluid.model';

export interface BlackOilForm {
  type: FluidType.BLACKOIL;
  name: string;
  isOil: boolean;
  isGas: boolean;
  oilApiGravity: number;
  gasComposition: GasComposition;
}

@Component({
  selector: 'app-black-oil',
  templateUrl: './black-oil.component.html',
  styleUrls: ['./black-oil.component.scss'],
  providers: subformComponentProviders(BlackOilComponent)
})
export class BlackOilComponent {

  constructor() { }

  @Input()
  public isReadOnly: boolean;

  public idealGases = [
    { label: 'CO2', value: 'co2' },
    { label: 'N2', value: 'n2' },
    { label: 'CH4', value: 'ch4' }
  ];

  public form = createForm<BlackOil, BlackOilForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      isOil: new UntypedFormControl(null),
      isGas: new UntypedFormControl(null),
      oilApiGravity: new UntypedFormControl(35, [Validators.required, Validators.max(200)]),
      gasComposition: new UntypedFormControl(null)
    },
    toFormGroup: (obj: BlackOil | null): BlackOilForm | null => {
      this.setOilGasValidity();

      if (!obj) {
        return null;
      }

      const { oilApiGravity, gasComposition, ...commonValues } = obj;

      return {
        ...commonValues,
        oilApiGravity: oilApiGravity ?? null,
        gasComposition: gasComposition ?? null,
        isOil: oilApiGravity ? true : false,
        isGas: gasComposition ? true : false
      };
    },
    fromFormGroup: (formValue: BlackOilForm): BlackOil | null => {
      const { isOil, isGas, gasComposition, oilApiGravity, ...commonValues } = formValue;

      this.setOilGasValidity();

      switch (true) {
        case (isOil && !isGas):
          return { oilApiGravity, gasComposition: null, ...commonValues };
        case (isOil && isGas):
          return { oilApiGravity, gasComposition, ...commonValues };
        case (!isOil && isGas):
          return { oilApiGravity: null, gasComposition, ...commonValues };
      }
    }
  });

  private setOilGasValidity(): void {
    const hasOil = this.form.formGroup.value.oilApiGravity > 0;
    const hasGas = this.form.formGroup.value.gasComposition ? true : false;

    if (hasOil && !hasGas) {
      this.form.formGroup.controls.gasComposition.setValidators(null);
      // this.formGroupControls.oilApiGravity.setValidators(Validators.required);
    }

    if (!hasOil && hasGas) {
      this.form.formGroup.controls.oilApiGravity.setValidators(null);
      // this.formGroupControls.gasComposition.setValidators(Validators.required);
    }

    if (!hasOil && !hasGas) {
      this.form.formGroup.controls.oilApiGravity.setValidators(null);
      this.form.formGroup.controls.gasComposition.setValidators(null);
    }

    this.form.formGroup.controls.gasComposition.updateValueAndValidity({ emitEvent: false });
    this.form.formGroup.controls.oilApiGravity.updateValueAndValidity({ emitEvent: false });
  }

  public isGasChange(e) {
    if (e.checked) {
      this.setGasComposition();
    } else {
      this.form.formGroup.controls.gasComposition.setValidators(null);
      this.form.formGroup.controls.gasComposition.setValue(null);
    }

    if (!e.checked && !this.form.formGroup.controls.oilApiGravity.value || this.form.formGroup.controls.oilApiGravity.value === 0) {
      this.form.formGroup.controls.oilApiGravity.setValue(35);
      this.form.formGroup.controls.isOil.setValue(true);
    }
  }

  public isOilChange(e) {
    if (e.checked) {
      this.form.formGroup.controls.oilApiGravity.setValue(35);
    } else {
      this.form.formGroup.controls.oilApiGravity.setValue(0);
    }

    if (!e.checked && !this.form.formGroup.controls.gasComposition.value) {
      this.setGasComposition();
      
      this.form.formGroup.controls.isGas.setValue(true);
    }
  }

  private setGasComposition(): void {
    const gasComposition = {
      c1: 100,
      c2: 0,
      c3: 0,
      nC4: 0,
      iC4: 0,
      nC5: 0,
      iC5: 0,
      nC6: 0,
      averageMolWeightC7ToC10: 120,
      c7C10: 0,
      co2: 0,
      h2S: 0,
      n2: 0
    }
    this.form.formGroup.controls.gasComposition.setValue(gasComposition);
  }
}
