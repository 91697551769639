import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { ExternalPressureProfileType, PorePressureEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

@Component({
  selector: 'app-pore-pressure-epp',
  template: `
  <form [formGroup]="form.formGroup">
    <div class="generalContainer">
      <div
        *ngIf="
          form.formGroup.errors?.porePressureNotSpecified
        "
      >
        <label class="error field col-12"
          >Pore Pressure profile not specified.</label
        >
      </div>
      <label
        class="error field col-12"
        *ngIf="
          !form.formGroup.errors?.porePressureNotSpecified &&
          form.formGroup.errors?.porePressureDepth
        "
      >
        Pore Pressure profile must be extended to the shoe of the string.
      </label>
    </div>
  </form>
  `,
  providers: subformComponentProviders(PorePressureEppComponent)
})
export class PorePressureEppComponent implements OnChanges {
  @Input() porePressures: PoreFrac[];
  @Input() shoeDepth: number;
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public porePressureNotSpecified: boolean;
  public porePressureDepth: boolean;
  public porePressureLastPoint: number;

  public form = createForm<PorePressureEPP>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.POREPRESSURE)
    }
  });

  constructor() { }

  ngAfterViewInit(): void {
    this.porePressureLastPoint = this.porePressures.length > 0 ? this.porePressures[this.porePressures.length - 1].trueVerticalDepth : 0;
    this.form.formGroup.addValidators([this.porePressureDepthValidator(this.porePressureLastPoint, this.shoeDepth), this.porePressureNotSpecifiedValidator(this.porePressures)]);
  }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(ExternalPressureProfileType.POREPRESSURE);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.porePressures) {
      this.porePressureLastPoint = this.porePressures.length > 0 ? this.porePressures[this.porePressures.length - 1].trueVerticalDepth : 0;
      this.form.formGroup.addValidators([this.porePressureDepthValidator(this.porePressureLastPoint, this.shoeDepth), this.porePressureNotSpecifiedValidator(this.porePressures)]);
    }
  }

  porePressureDepthValidator(porePressureLastPoint, shoeDepth): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return porePressureLastPoint < shoeDepth ? { porePressureDepth: true } : null;
    }
  }

  porePressureNotSpecifiedValidator(porePressures): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return porePressures.length < 2 ? { porePressureNotSpecified: true } : null;
    }
  }

}
