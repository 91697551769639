<form [formGroup]="form.formGroup">
  <p-toolbar>
    <div class="mr-2">
      <label>Show Plot</label>
      <p-inputSwitch (onChange)="tablePlotToggle()"></p-inputSwitch>
    </div>

    <div class="p-toolbar-group-right mr-2 defaultButton">
      <img
        (click)="applyDefaults()"
        title="Apply Defaults"
        src="../../assets/icons/default-icon-white.png"
      />
    </div>
  </p-toolbar>

  <div class="grid">
    <div class="col">
      <div [style.display]="!showPlot ? 'block' : 'none'">
        <div class="field">
          <label for="nominalDensity">Density</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="nominalDensity"
              [formControlName]="form.formControlNames.density"
              required
              [minFractionDigits]="1"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">{{ densityUnits }}</span>
          </div>
          <p *ngIf="form.formGroup.controls.density.invalid" class="error">
            Allowed range: {{ densityValidation.min }} -
            {{ densityValidation.max }} {{ densityUnits }}
          </p>
        </div>
        <div class="field">
          <label for="mixWaterDensity">Mix-Water Density</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="mixWaterDensity"
              [formControlName]="form.formControlNames.mixWaterDensity"
              required
              [minFractionDigits]="1"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">{{ densityUnits }}</span>
          </div>
          <p
            *ngIf="form.formGroup.controls.mixWaterDensity.invalid"
            class="error"
          >
            Allowed range: {{ mixWaterDensityValidation.min }} -
            {{ mixWaterDensityValidation.max }} {{ densityUnits }}
          </p>
        </div>
        <div class="field">
          <label for="densityTemperature">Density Temperature</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="densityTemperature"
              [formControlName]="form.formControlNames.densityTemperature"
              required
              [minFractionDigits]="1"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">{{ tempUnit }}</span>
          </div>
          <p
            *ngIf="form.formGroup.controls.densityTemperature.invalid"
            class="error"
          >
            Allowed range: {{ temperatureValidation.min }} -
            {{ temperatureValidation.max }} {{ tempUnit }}
          </p>
        </div>
      </div>

      <div *ngIf="showPlot">
        <div [style.min-height]="!showPlot ? '0px' : '450px'">
          <app-xy-line-plot
            [plotData]="plot.data"
            [xAxisTitle]="xAxisTitle"
            [yAxisTitle]="yAxisTitle"
            [plotName]="plotName"
            [downloadPlotName]="downloadPlotName"
            [reverseAutoRange]="true"
            [plotTitle]="'Fluids'"
          ></app-xy-line-plot>
        </div>
      </div>

      <br *ngIf="showPlot" />
      <br *ngIf="showPlot" />

      <p-fieldset legend="Rheology">
        <div class="grid">
          <div class="col">
            <div class="field">
              <label for="r600">R600</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r600"
                  [formControlName]="form.formControlNames.fann600"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann600.invalid &&
                  form.formGroup.controls.fann600.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>

          <div class="col">
            <div class="field">
              <label for="r300">R300</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r300"
                  [formControlName]="form.formControlNames.fann300"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann300.invalid &&
                  form.formGroup.controls.fann300.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>

          <div class="col">
            <div class="field">
              <label for="r200">R200</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r200"
                  [formControlName]="form.formControlNames.fann200"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann200.invalid &&
                  form.formGroup.controls.fann200.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col">
            <div class="field">
              <label for="r100">R100</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r100"
                  [formControlName]="form.formControlNames.fann100"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann100.invalid &&
                  form.formGroup.controls.fann100.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>

          <div class="col">
            <div class="field">
              <label for="r6">R6</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r6"
                  [formControlName]="form.formControlNames.fann6"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann6.invalid &&
                  form.formGroup.controls.fann6.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>

          <div class="col">
            <div class="field">
              <label for="r3">R3</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="r3"
                  [formControlName]="form.formControlNames.fann3"
                  [minFractionDigits]="1"
                  (ngModelChange)="calculateRheologyProperties()"
                ></p-inputNumber>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.fann3.invalid &&
                  form.formGroup.controls.fann3.value
                "
                class="error"
              >
                Allowed range: 0.1 - 500
              </p>
            </div>
          </div>
        </div>

        <div class="field">
          <label for="rheologyTemperature">Rheology Temperature</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="rheologyTemperature"
              [formControlName]="form.formControlNames.rheologyTemperature"
              [minFractionDigits]="1"
              required
            ></p-inputNumber>
            <span class="p-inputgroup-addon">{{ tempUnit }}</span>
          </div>
          <p
            *ngIf="form.formGroup.controls.rheologyTemperature.invalid"
            class="error"
          >
            Allowed range: {{ temperatureValidation.min }} -
            {{ temperatureValidation.max }} {{ tempUnit }}
          </p>
        </div>

        <div class="grid">
          <div class="col">
            <div class="fluid">
              <div class="field">
                <label>PV:&nbsp; {{ plasticViscosity }} &nbsp;cP</label>
              </div>
              <div class="field">
                <label label>YP:&nbsp; {{ yieldPoint }} &nbsp;lbf/100ft²</label>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="field">
              <label>n':&nbsp; {{ nPrime | number }}</label>
            </div>
            <div class="field">
              <label>K':&nbsp; {{ kPrime | number }} &nbsp;lb*s</label
              ><label class="sup">&nbsp; n'/ft²</label>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
</form>
