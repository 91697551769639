import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PoreFrac } from "../../models/poreFrac.model";

export function porePressureMustBeToShoeValidator(porePressures: PoreFrac[], shoeDepth: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let porePressureLastPoint: number;
    let porePressureDepth: boolean;
    porePressureLastPoint = porePressures.length > 0 ? porePressures[porePressures.length - 1].trueVerticalDepth : 0;
    porePressureDepth = porePressureLastPoint < shoeDepth;
    return porePressureDepth ? { porePressureMustBeToShoe: true } : null;
  }
}

export function porePressureNotSpecifiedValidator(porePressures: PoreFrac[]): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    return porePressures.length < 2 ? { porePressureNotSpecified: true} : null;
  }
}
