export class TemperatureDerationCatalog {
    public constructor(init?: Partial<TemperatureDerationCatalog>) {
        Object.assign(this, init);
    }

    id: string;
    name: string;
    temperatureDerationProfile: {
        temperature: number,
        derationFactor: number
    }
}

export class TemperatureDerationLookupResult {
    public constructor(init?: Partial<TemperatureDerationLookupResult>) {
        Object.assign(this, init);
    }

    id: string;
    name: string;
    temperatureDerationProfile: {
        temperature: number,
        derationFactor: number
    }
}