import { UntypedFormGroup } from "@angular/forms";
import { Tubular } from "../models";
import { WellComponent } from "../models/well-component";

interface IPipeSection {
    top: number,
    btm: number,
    diameter: number
}

export function getWellboreOutsideString(tubularString: WellComponent, wellConfig: Array<WellComponent>): {priorShoeMd: number, wellboreSections: Array<IPipeSection>} {

    var wellbore = { priorShoeMd: 0, wellboreSections: [] };
    let wellConfigIdx = wellConfig.findIndex(wc => wc.id == tubularString.id);
    let previousString = wellConfig[wellConfigIdx - 1];
  
    if (!previousString) {
        wellbore.wellboreSections.push({top:0, btm: tubularString.shoeMd, diameter: tubularString.holeSize });
        return wellbore;
    }

    // Looks like this takes the open hole holesize at the bottom section of the wellbore?
    let bottomOfWellboreSection = tubularString.shoeMd;
    if (previousString.shoeMd < bottomOfWellboreSection && tubularString.holeSize > 0) {
        wellbore.wellboreSections.push({ top: previousString.shoeMd, btm: bottomOfWellboreSection, diameter: tubularString.holeSize });
        bottomOfWellboreSection = previousString.shoeMd;
        wellbore.priorShoeMd = bottomOfWellboreSection; //TODO: May not need this client side...
    }

    let wellConfigBeforeCurrentString = wellConfig.slice(0, wellConfigIdx);

    //Add in topMd to string sections (doing this on the client because we don't have the top depth)
    for (let ts of wellConfigBeforeCurrentString) {
        let topMd = ts.hangerMd;
        for(let ss of ts.stringSections){
            ss.topMeasuredDepth = topMd;
            topMd = ss.bottomMeasuredDepth;
        }
    }

    for (let ts of wellConfigBeforeCurrentString.reverse()) {
        if (ts.hangerMd >= tubularString.shoeMd) {
            continue;
        }
        for (let ss of ts.stringSections.reverse()) {
            var topOfWellboreSection = ss.topMeasuredDepth;
            if (Math.abs(topOfWellboreSection - bottomOfWellboreSection) < 0.0001 || topOfWellboreSection > bottomOfWellboreSection) {
                continue;
            }
            wellbore.wellboreSections.splice(0, 0, { top: topOfWellboreSection, btm: bottomOfWellboreSection, diameter: ss.pipe.insideDiameter });
            bottomOfWellboreSection = topOfWellboreSection;
        }
        if (Math.abs(ts.hangerMd - tubularString.hangerMd) < 0.0001) {
            break;
        }
    }

    return wellbore;

}

export function getWellboreMinimumDiameter(topMd: number, bottomMd: number, wellbore: Array<IPipeSection>): number {
    let wellboreDiameters = wellbore.filter(x => x.btm >= topMd && x.top <= bottomMd).map(ws => ws.diameter).filter(x => !!x); // Last filter pulls undefined, "", null, 0
    return Math.min(...wellboreDiameters);
}

/**
 * Returns boolean indicating if pipe will get stuck in the wellbore.
 * If no constriction is not found, return false;
 * If a constriction is found, returns true.
 * @param {WellComponent} - Well components
 * @param {Array<IWellbore>}} - Collection of wellbore sections
 */
export function checkFitInWellbore(tubular: WellComponent, wellbore: Array<IPipeSection>): boolean {
    let stringSections: Array<IPipeSection> = tubular.stringSections.map((ss, i) => {
        return {
            top: (i == 0) ? tubular.hangerMd : tubular.stringSections[i - 1].bottomMeasuredDepth,
            btm: ss.bottomMeasuredDepth,
            diameter: ss.pipe.outsideDiameter
        };
    });
    return stringSections.every(ss => ss.diameter <= getWellboreMinimumDiameter(ss.top, ss.btm, wellbore));
}

export function getMinimumDiameterFittingWellbore(tubular: WellComponent, stringSection: Tubular, wellbore: Array<IPipeSection>): number {
    let stringSections: Array<IPipeSection> = tubular.stringSections.map((ss, i) => {
        return {
            top: (i == 0) ? tubular.hangerMd : tubular.stringSections[i - 1].bottomMeasuredDepth,
            btm: ss.bottomMeasuredDepth,
            diameter: ss.pipe.insideDiameter
        };
    });
    let stringSectionWithTop = stringSections.find(ss => ss.btm == stringSection.bottomMeasuredDepth && ss.diameter == stringSection.pipe.insideDiameter);
    return  getWellboreMinimumDiameter(stringSectionWithTop.top, stringSectionWithTop.btm, wellbore);
}

export function stringSectionsMustFitInWellbore(control: UntypedFormGroup) {
    let wellConfiguration = control?.parent?.getRawValue() as Array<WellComponent>; //Using RawValue so we can get the disabled inputs.
    let tubularString = control?.getRawValue() as WellComponent;
    if (tubularString && wellConfiguration && !control.pristine) { // Checking pristine avoids doing this when the form is loading
        let wellboreProfile = getWellboreOutsideString(tubularString, wellConfiguration);
        let fitsInWellbore = checkFitInWellbore(tubularString, wellboreProfile.wellboreSections);
        if (!fitsInWellbore) {
            return { error: "A string section in this tubular will get stuck in the wellbore. Check your design." }
        }
    }
    return null;
}
