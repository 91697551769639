
<div class="flex">
  <div class="mr-2">
    <p-selectButton
      [options]="resultsDisplay"
      [(ngModel)]="apbResultsStore.state.selectedResultsDisplay"
      (onChange)="onSelectedResultsDisplayUpdated($event)"
      [allowEmpty]="false"
    ></p-selectButton>
  </div>

  <p-selectButton
    [options]="apbAfe"
    [(ngModel)]="apbResultsStore.state.apbAfeSelected"
    *ngIf="apbResultsStore.state?.selectedResultsDisplay == 'plot'"
    (onChange)="onApbAfeSelected($event)"
    [allowEmpty]="false"
  ></p-selectButton>
</div>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <br *ngIf="apbResultsStore.state?.selectedResultsDisplay == 'grid'">
  <ng-table-results-cmp
    tableName="Annular Pressure Build-Up Results"
    [tableData]="apbResults"
    [columnDefinitions]="cols"
    [tableHeight]="tableHeight"
    *ngIf="apbResultsStore.state?.selectedResultsDisplay == 'grid'"
  ></ng-table-results-cmp>

  <app-xy-line-plot
    *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'plot'"
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [reverseAutoRange]="true"
    [legendBelowPlot]="true"
    [plotTitle]="'APB Results'"
  ></app-xy-line-plot>
</app-tool-loader>
