import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { ExternalPressureProfileType, FluidToShoeEPP, GeneralEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface FluidToShoeEPPForm {
  type: ExternalPressureProfileType.FLUIDTOSHOE;
  generalEpp: GeneralEPP;
}

@Component({
  selector: 'app-fluid-to-shoe-epp',
  template: `
  <form [formGroup]="form.formGroup">
    <div class="generalContainer">
      <app-general-epp
        [formControlName]="form.formControlNames.generalEpp"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-general-epp>
        <label class="field col-12"
          >Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}</label
        >
    </div>
  </form>
  `,
  providers: subformComponentProviders(FluidToShoeEppComponent)
})
export class FluidToShoeEppComponent implements OnInit {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;

  public form = createForm<FluidToShoeEPP, FluidToShoeEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.FLUIDTOSHOE),
      generalEpp: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: FluidToShoeEPP): FluidToShoeEPPForm => {
      return {
        type: ExternalPressureProfileType.FLUIDTOSHOE,
        generalEpp: { fluidDensity: obj.fluidDensity, hangerPressure: obj.hangerPressure }
      };
    },
    fromFormGroup: (formValue: FluidToShoeEPPForm): FluidToShoeEPP => {
      return {
        type: ExternalPressureProfileType.FLUIDTOSHOE,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity
      };
    }
  });

  constructor() { }

  ngOnInit(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
  }
}
