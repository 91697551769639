<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader class="udt" [isLoading]="isLoading" positionUnset="true">
    
  <div class="packers">
    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>

    <p-dialog
      [draggable]="false"
      [(visible)]="isWellConfigMissing"
      [closable]="false"
    >
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well
      Configuration Missing: Please specify at least one string <br />
      <br />
    </p-dialog>

    <p-dialog
      [draggable]="false"
      [visible]="packerData && packerData.isFullyCemented"
      [closable]="false"
    >
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> String is
      fully cemented <br />
      <br />
    </p-dialog>

    <div class="grid grid-nogutter">
      <div class="col-fixed" style="width:220px">
        <p-orderList
          [dragdrop]="selectedPacker[0]?.measuredDepth !== null"
          [value]="packerData?.packers"
          [(selection)]="selectedPacker"
          optionLabel="type"
          (onSelectionChange)="onPackerSelect($event)"
          (onReorder)="onReorder()"
        >
          <ng-template pTemplate="header">
            <div>
                <button [disabled]="packerData?.packers.length < 2" pButton pRipple title="Sort packers Bottom Up" icon="pi pi-sort-amount-up-alt" class="sortButtons" (click)="onSortUp()"></button>
                <button [disabled]="packerData?.packers.length < 2" pButton pRipple title="Sort packers Top Down" icon="pi pi-sort-amount-down-alt" class="sortButtons" (click)="onSortDown()"></button>
              <p-button
                class="p-button-success p-button-sm addPacker"
                label="Add Packer"
                (click)="onAddPacker()"
              ></p-button>
              <span class="tooltip">
                <i
                  title="Packers are set in the order listed below"
                  class="pi pi-info-circle"
                ></i>
              </span>
            </div>
            <label class="packerOrderLabel" *ngIf="packerOrderLabel">
              {{ packerOrderLabel }}
            </label>
          </ng-template>
          <ng-template let-packer pTemplate="item">
            <div class="grid grid-nogutter align-items-center">
              <div class="col-8 nameClass">{{ packer.name }}</div>
              <div class="col-4 buttonsClass flex justify-content-end">
                <i
                  class="pi pi-trash p-button p-button-text p-button-sm p-button-danger"
                  pTooltip="Delete Packer"
                  (click)="onDeletePacker(packer)"
                ></i>
              </div>
            </div>
          </ng-template>
        </p-orderList>
      </div>

      <div class="col mr-2 ml-2">
        <div class="flex-1">
          <app-packer-data
            *ngIf="userUnits"
            [packer]="selectedPackerDetails$ | async"
            (packerUpdate)="savePacker($event)"
            [userUnits]="userUnits"
            [disabled]="packerData?.packers.length < 1"
            [packersComponentHeight]="componentHeight"
          ></app-packer-data>
        </div>
      </div>
    </div>
  </div>
  </app-tool-loader>
</p-scrollPanel>