export function getChildLevel(menuLevel: string): string {
    switch (menuLevel) {
      case "companies":
        return 'projects';
      case 'projects':
        return 'sites';
      case 'sites':
        return 'wells';
      case 'wells':
        return 'wellbores';
      case 'wellbores':
        return "designs";
    }
  }