<form [formGroup]="form.formGroup" bg-dark>
  <div class="grid">
    <div class="col">
      <div class="field">
        <label for="fluid">Injection Gas</label>
        <p-dropdown
          [options]="fluids"
          [formControlName]="form.formControlNames.fluid"
          optionLabel="name"
          dataKey="id"
          appendTo="body"
          [pTooltip]="form.formGroup.value.fluid | json"
          tooltipPosition="top"
          showDelay="100"
          hideDelay="500"
        >
          <ng-template pTemplate="selectedItem">
            {{ form.formGroup.value.fluid?.state.name }}
          </ng-template>
          <ng-template let-fluid pTemplate="item">
            <div [pTooltip]="fluid | json">
              {{ fluid.name }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="field">
        <label for="gasLiftValveDepth">GLV Depth</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.gasLiftValveDepth"
          />
          <span class="p-inputgroup-addon">ft</span>
        </div>
      </div>
      <div class="field">
        <label for="wellheadPressure">Injection Pressure</label>
        <div class="p-inputgroup">
          <input
            id="wellheadPressure"
            pInputText
            [formControlName]="form.formControlNames.injectionPressure"
            required
          />
          <span class="p-inputgroup-addon">psi</span>
        </div>
      </div>
      <div class="field">
        <label for="inletTemperature">Inlet Temperature</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.inletTemperature"
          />
          <span class="p-inputgroup-addon">°F</span>
        </div>
      </div>
      <div class="field">
        <label for="gasRate">Gas Rate</label>
        <div class="p-inputgroup">
          <input pInputText [formControlName]="form.formControlNames.gasRate" />
          <span class="p-inputgroup-addon">MMscf/day</span>
        </div>
      </div>
    </div>
  </div>
</form>
