// From http://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
// This will parse a delimited string into an array of
// arrays. The default delimiter is the comma, but this
// can be overriden in the second argument.
export function stringToArray(strData: string, delimiter: string = ",") {

  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp((
    '(\\' + delimiter + '|\\r?\\n|\\r|^)' +
    '(?:"([^"]*(?:""[^"]*)*)"|' +
    '([^"\\' + delimiter + '\\r\\n]*))'), 'gi');
  // The array has default empty first row.
  const arrData = [[]];
  // Create an array to hold our individual pattern matching groups.
  let arrMatches = null;

  // tslint:disable-next-line: no-conditional-assignment
  while (arrMatches = objPattern.exec(strData)) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];
    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length &&
      strMatchedDelimiter !== delimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }
    let strMatchedValue = void 0;
    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }
    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }
  // Return the parsed data.
  return arrData;
}

/** 
 * Parses text string fromt the clipboard in the fastest most nieve way possible. 
 * Essentially does the same thing as stringToArray, but in a not crazy way.
 */
export function  clipboardStringParser(event: ClipboardEvent): Array<any> {
  let clipboardData = event.clipboardData;
  let pastedText = clipboardData.getData('text').replace('/\r', '');
  let row_data = pastedText.split('\n').filter(x => x != "");
  let data = row_data.map(r => r.replace('\r', '').split('\t'));
  return data.length > 0 ? data : null;
}
