import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { ExternalPressureProfileType, GeneralEPP, MudAndPorePressureEPP } from '../../models/external-pressure-profile.model';
import { porePressureMustBeToShoeValidator, porePressureNotSpecifiedValidator } from 'src/app/perivis/shared/validators/pore-pressure.validator';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface MudAndPorePressureEPPForm {
  type: ExternalPressureProfileType.MUDANDPOREPRESSURE;
  generalEpp: GeneralEPP;
}

@Component({
  selector: 'app-mud-and-pore-pressure-epp',
  templateUrl: './mud-and-pore-pressure-epp.component.html',
  styles: [`
    .pi {
      font-size: 1rem !important;
    }
  `],
  providers: subformComponentProviders(MudAndPorePressureEppComponent)
})
export class MudAndPorePressureEppComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() porePressures: PoreFrac[];
  @Input() shoeDepth: number;
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;

  public form = createForm<MudAndPorePressureEPP, MudAndPorePressureEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.MUDANDPOREPRESSURE),
      generalEpp: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: MudAndPorePressureEPP): MudAndPorePressureEPPForm => {
      return {
        type: ExternalPressureProfileType.MUDANDPOREPRESSURE,
        generalEpp: obj
      };
    },
    fromFormGroup: (formValue: MudAndPorePressureEPPForm): MudAndPorePressureEPP => {
      return {
        type: ExternalPressureProfileType.MUDANDPOREPRESSURE,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity
      };
    }
  });

  constructor() { }

  ngOnInit(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
  }

  ngAfterViewInit(): void {
    this.form.formGroup.addValidators([porePressureMustBeToShoeValidator(this.porePressures, this.shoeDepth), porePressureNotSpecifiedValidator(this.porePressures)]);
  }

  ngOnChanges(): void {
    this.form.formGroup.updateValueAndValidity();
  }
}
