<form [formGroup]="form.formGroup">
  <div class="grid grid-nogutter">
    <div class="col-11">
      <div class="grid grid-nogutter">
        <div class="col-4">
          <div class="col-headers">Name</div>
        </div>
        <div class="col-2">
          <div class="col-headers">Fraction %</div>
        </div>
        <div class="col-3">
          <div class="col-headers">Molecular Weight</div>
        </div>
        <div class="col-3">
          <div class="col-headers">Specific Gravity</div>
        </div>
      </div>
    </div>
    <!-- <div class="p-col-1">
      <div class="col-headers"></div>
    </div> -->
  </div>
  <div
    [formArrayName]="form.formControlNames.vleHeavyWeightHydrocarbonComponents"
    *ngFor="
      let vleHeavyWeightHydrocarbonComponent of form.formGroup.controls
        .vleHeavyWeightHydrocarbonComponents.controls;
      let i = index
    "
    class="grid grid-nogutter align-items-center justify-content-center"
  >
    <div class="col-11">
      <app-heavy [formControl]="vleHeavyWeightHydrocarbonComponent"></app-heavy>
    </div>
    <div class="col-1">
      <button
        (click)="removeHeavy(i)"
        [disabled]="form.formGroup.disabled"
        class="pi pi-trash p-button p-button-text p-button-sm p-button-danger delete-btn"
        pTooltip="Delete Heavy"
      ></button>
    </div>
  </div>
  <div class="col add-btn">
    <button
      (click)="addHeavy()"
      class="p-button p-button-success p-button-sm w-full"
      icon="pi pi-plus"
      pTooltip="Add Heavy"
      label="Add Heavy"
      pButton
      [disabled]="
        form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.controls
          .length >= 3
      "
    ></button>
  </div>
</form>

<!--
<pre>{{ formGroupValues | json }}</pre> -->
