import { Component, Input, OnInit } from '@angular/core';
import { WellComponent } from '../well-configuration/models';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'schematic-info',
  templateUrl: './schematic-info.component.html',
  styleUrls: ['./schematic-info.component.scss']
})
export class SchematicInfoComponent implements OnInit {

  @Input()
  public tubular: WellComponent;
  public units: { long: string, short: string };

  constructor(private _store: StoreService) {
  }

  async ngOnInit(): Promise<void> {
    this.tubular = null;
    var currentUserUnits = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.units = {
      long: currentUserUnits.longLengths,
      short: currentUserUnits.shortLengths
    };
  }

  public handleClose(): void {
    this.tubular = null;
  }

}
