<form [formGroup]="form.formGroup">
  <div class="field">
    <label for="pipeRoughness">Pipe Roughness</label>
    <div class="p-inputgroup">
      <input
        id="pipeRoughness"
        pInputText
        [formControlName]="form.formControlNames.pipeRoughness"
      />
      <label class="p-inputgroup-addon">{{ userUnits.shortLengths }}</label>
    </div>
    <p class="error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
      Allowed range: {{ pipeRoughnessValidation.min }} -
      {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths }}
    </p>
  </div>
  <div class="field">
    <label for="multiphaseCorrelation">Multi Phase Correlation</label>
    <p-dropdown
      [disabled]="isReadOnly"
      [options]="multiphaseCorrelations"
      [formControlName]="form.formControlNames.multiphaseCorrelation"
      optionLabel="name"
      optionValue="value"
      appendTo="body"
    ></p-dropdown>
  </div>
  <div class="field" [hidden]="gasHidden || gasModelHidden">
    <label for="gasModel">Gas Model</label>
    <p-dropdown
      [disabled]="isReadOnly"
      [options]="gasModels"
      [formControlName]="form.formControlNames.gasModel"
      optionLabel="name"
      optionValue="value"
      appendTo="body"
    ></p-dropdown>
  </div>
  <div class="field" [hidden]="!co2FluidSelected">
    <label for="co2EosOption">CO2 EOS</label>
    <p-dropdown
      [disabled]="isReadOnly"
      [options]="co2EosOptions"
      [formControlName]="form.formControlNames.co2EosOption"
      optionLabel="name"
      optionValue="value"
      appendTo="body"
    ></p-dropdown>
  </div>
  <div class="field" [hidden]="oilHidden || gasHidden">
    <label for="gorSelected">GOR</label>
    <div class="p-inputgroup">
      <p-dropdown
        [disabled]="isReadOnly"
        id="gorSelected"
        [options]="gorOptions"
        optionLabel="label"
        optionValue="value"
        appendTo="body"
        (onChange)="onGorTypeChange($event)"
        [disabled]="!oilGas[0] || !oilGas[1]"
        class="gorDropdown"
      ></p-dropdown>
      <input
        (change)="updateGor(gor)"
        id="gorValue"
        [disabled]="gorEnabled"
        pInputText
        value="{{ gor }}"
      />
      <span class="p-inputgroup-addon">{{ gorUnit }}</span>
    </div>
  </div>
  <div class="field" [hidden]="oilHidden || co2FluidSelected">
    <label for="oilFlowRate">Oil Flow Rate</label>
    <div class="p-inputgroup">
      <input
        [attr.disabled]="oilRateDisabled"
        (change)="updateGor('oil')"
        pInputText
        [formControlName]="form.formControlNames.oilFlowRate"
      />
      <span class="p-inputgroup-addon">{{ flowUnit }}</span>
    </div>
    <p
      *ngIf="
        form.formGroup.controls.oilFlowRate.value &&
        form.formGroup.controls.oilFlowRate.invalid
      "
      class="error"
    >
      Allowed range: {{ oilFlowRateValidation.min }} -
      {{ oilFlowRateValidation.max }} {{ flowUnit }}
    </p>
  </div>
  <div class="field" [hidden]="gasHidden">
    <label for="gasFlowRate">Gas Flow Rate</label>
    <div class="p-inputgroup">
      <input
        [attr.disabled]="gasRateDisabled"
        (change)="updateGor('gas')"
        pInputText
        [formControlName]="form.formControlNames.gasFlowRate"
      />
      <span class="p-inputgroup-addon">{{ gasFlowUnit }}</span>
    </div>
    <p
      *ngIf="
        form.formGroup.controls.gasFlowRate.value &&
        form.formGroup.controls.gasFlowRate.invalid
      "
      class="error"
    >
      Allowed range: {{ gasFlowRateValidation.min }} -
      {{ gasFlowRateValidation.max }} {{ gasFlowUnit }}
    </p>
  </div>
  <div class="field" [hidden]="co2FluidSelected">
    <label for="waterFlowRate">Water Flow Rate</label>
    <div class="p-inputgroup">
      <input
        pInputText
        [formControlName]="form.formControlNames.waterFlowRate"
      />
      <span class="p-inputgroup-addon">{{ flowUnit }}</span>
    </div>
    <p
      *ngIf="
        form.formGroup.controls.waterFlowRate.value &&
        form.formGroup.controls.waterFlowRate.invalid
      "
      class="error"
    >
      Allowed range: {{ waterFlowRateValidation.min }} -
      {{ waterFlowRateValidation.max }} {{ flowUnit }}
    </p>
  </div>
</form>
<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->
