<form *ngIf="designForm" [formGroup]="designForm">
  <div class="field">
    <label>Name</label>
    <span class="p-float-label">
      <input type="text" pInputText autofocus formControlName="name" id="name" />
      <p *ngIf="
          designForm.controls.name.value.length > 0 &&
          designForm.controls.name.value.length < 3
        " class="error">
        Must be longer than three characters
      </p>
    </span>
  </div>

  <p-fieldset legend="Well Type and Datums">
    <div formGroupName="wellType" class="wellTypeDiv">
      <div class="field">
        <label>Type</label>
        <div>
          <p-dropdown formControlName="type" [options]="wellTypes"></p-dropdown>
        </div>
      </div>

      <p-fieldset legend="Elevation">
        <div class="field wellheadDepthClass">
          <label for="drillFloorElevation">Drill Floor Elevation {{ depthRelativeTo }}</label>
          <div class="p-inputgroup">
            <p-inputNumber (onFocus)="onInputFocus($event)" id="drillFloorElevation"
              formControlName="drillFloorElevation" [minFractionDigits]="1"></p-inputNumber>
          </div>
        </div>
        <p *ngIf="wtc.controls.drillFloorElevation.invalid" class="error">Wellhead must be below or at DFE</p>
        <br *ngIf="wtc.controls.drillFloorElevation.invalid">
        <br />

        <div *ngIf="wtc.controls.type.value != 'Subsea'" class="field wellheadDepthClass">
          <label for="wellheadElevation">{{ wellheadType }} {{ wellheadElevationRelativeTo }}</label>
          <div class="p-inputgroup">
            <p-inputNumber (onFocus)="onInputFocus($event)" id="wellheadElevation" formControlName="wellheadElevation"
              [minFractionDigits]="1"></p-inputNumber>
          </div>
        </div>
        
        <p *ngIf="wtc.controls.wellheadElevation.invalid" class="error">{{
          wellheadError }}</p>
        <div *ngIf="wtc.controls.type.value == 'Subsea'" class="field wellheadDepthClass">
          <label for="wellheadDepth">{{ wellheadType }} {{ wellheadElevationRelativeTo }}</label>
          <div class="p-inputgroup">
            <p-inputNumber (onFocus)="onInputFocus($event)" id="wellheadDepth" formControlName="wellheadDepth"
              [minFractionDigits]="1"></p-inputNumber>
          </div>
        </div>
        <p *ngIf="wtc.controls.wellheadDepth.invalid" class="error">Wellhead must be above the seafloor</p>

      </p-fieldset>
      <br *ngIf="wtc.controls.type.value != 'Land'">
      <p-fieldset class="offshoreFieldset" *ngIf="waterDepthVisible" legend="Offshore">
        <div class="field waterDepthClass">
          <label for="waterDepth">Water Depth ({{ lengthUnit }})</label>
          <div class="p-inputgroup">
            <p-inputNumber (onFocus)="onInputFocus($event)" id="waterDepth" formControlName="waterDepth"
              [minFractionDigits]="1"></p-inputNumber>
          </div>
        </div>
        <p *ngIf="wtc.controls.waterDepth.invalid" class="error">Wellhead must be above the seafloor</p>
        <label>Mudline Depth: {{ mudlineDepth }} ({{ lengthUnit }})</label>
      </p-fieldset>
    </div>
  </p-fieldset>

  
  <!-- <p-fieldset legend="Design Template" class="col-12">
    <div class="field col-12">
      <input
        style="height: 13px; width: 20px;"
        type="checkbox"
        binary="true"
        (change)="loadCasesCheckboxChange($event)"
      >
      <label style="font-size: 14px; padding-left: 5px;">Auto-generate Load Cases</label>
    </div>

    <div class="field col-12">
      <p-dropdown
        [disabled]="!autoGenerateLoads"
        [options]="designTemplates"
        appendTo="body"
      ></p-dropdown>
    </div>
  </p-fieldset> -->

  <p-fieldset legend="Dashboard Template" class="col-12">
    <div class="col-12">
      <label for="designTemplate">Apply a set of tabs and components based on an existing design (optional):</label>
      <br />
      <br />
      <p-treeSelect
        [options]="config.data.treeItems"
        appendTo="body"
        id="designTemplate"
        fromControlName="designTemplate"
        placeholder="Select a design"
        filter="true"
        filterBy="data.label"
        showClear="true"
        (onNodeSelect)="onTemplateSelect($event.node?.data.id)"
        (onClear)="onTemplateClear()"
        class="col-12"
      >
        <ng-template pTemplate="default" let-node>
          <img
            *ngIf="node?.data.level != 'designs'"
            class="level-icon"
            [src]="node?.data.icon"
          />
          {{ node?.data.label }}
        </ng-template>
        <ng-template pTemplate="value" let-node>
          <div *ngIf="!node">Select a design</div>
          {{ node?.data?.label }}
        </ng-template>
      </p-treeSelect>
      <div *ngIf="templateWorkspaces">
        <br>
        <p-fieldset legend="Tabs in Selected Dashboard Template:">
          <div
            *ngFor="let tab of templateWorkspaces.workspaces"
            class="inline-flex"
          >
            <p-chip
              [pTooltip]="showComponentList(tab.uiComponents)"
              [escape]="false"
              >{{ tab.name }}</p-chip
            >
          </div>
        </p-fieldset>
      </div>
    </div>
  </p-fieldset>

  <br>
  <div style="float: right">
    <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel"
      (click)="onCancel()"></button>
    <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success"
      [disabled]="designForm.invalid" label="Save" (click)="onSave()"></button>
  </div>
</form>
