import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { LinearInterpSingle } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { InternalPressureProfileType, LostReturnsWaterIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface LostReturnsWaterIppForm {
  type: InternalPressureProfileType.LOSTRETURNSWATER;
  fractureDepth: UomQuantityInput;
  fractureGradient: UomQuantityInput;
  fluidDensity: UomQuantityInput;
  fractureMargin: UomQuantityInput;
}

@Component({
  selector: 'app-lost-returns-water-ipp',
  templateUrl: './lost-returns-water-ipp.component.html',
  providers: subformComponentProviders(LostReturnsWaterIppComponent)
})
export class LostReturnsWaterIppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() fracPressures: PoreFrac[];
  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public defaultFracGradient: number;
  public densityLabel: string;
  public densityValidation: { min: number, max: number };
  public fractureGradientValidation: { min: number, max: number };
  public marginOfErrorValidation: { min: number, max: number };

  public form = createForm<LostReturnsWaterIPP, LostReturnsWaterIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.LOSTRETURNSWATER),
      fractureDepth: new UntypedFormControl(null),
      fractureGradient: new UntypedFormControl(null),
      fluidDensity: new UntypedFormControl(null),
      fractureMargin: new UntypedFormControl(null)
    },
    toFormGroup: (obj: LostReturnsWaterIPP | null): LostReturnsWaterIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        fractureDepth: { control: obj.fractureDepth },
        fractureGradient: { control: obj.fractureGradient },
        fluidDensity: { control: obj.fluidDensity },
        fractureMargin: { control: obj.fractureMargin }
      }
    },
    fromFormGroup: (formValue: LostReturnsWaterIppForm): LostReturnsWaterIPP | null => {
      const { fractureDepth, fractureGradient, fluidDensity, fractureMargin, ...commonValues } = formValue;
      return {
        fractureDepth: +fractureDepth?.control,
        fractureGradient: +fractureGradient?.control,
        fluidDensity: +fluidDensity?.control,
        fractureMargin: +fractureMargin?.control,
        ...commonValues
      }
    }
  })

  constructor(private _trajectoryService: TrajectoryService) { }

  ngAfterViewInit(): void {
    this.getEmwDefaultFrac(this.currentString.shoeMd);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 1.04, max: 25 };
        this.marginOfErrorValidation = { min: 0, max: 10 };
        this.fractureGradientValidation = { min: 0, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 124, max: 2995 };
        this.marginOfErrorValidation = { min: 0, max: 1198 };
        this.fractureGradientValidation = { min: 0, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
        this.densityValidation = { min: 0.12, max: 3 };
        this.marginOfErrorValidation = { min: 0, max: 1.2 };
        this.fractureGradientValidation = { min: 0, max: 3 };
        break;
      case 'kg/l':
    }

    this.validators = {
      fractureDepth: [Validators.min(this.currentString.hangerMd), Validators.max(this.currentString.shoeMd), Validators.required],
      fractureGradient: [Validators.min(this.fractureGradientValidation.min), Validators.max(this.fractureGradientValidation.max), Validators.required],
      fluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required],
      fractureMargin: [Validators.min(this.marginOfErrorValidation.min), Validators.max(this.marginOfErrorValidation.max), Validators.required]
    }

    if (changes.currentString) {
      this.getEmwDefaultFrac(this.currentString.shoeMd);
    }
  }

  getEmwDefaultFrac(depth): void {
    this._trajectoryService.getTvdFromMd(depth).subscribe(tvd => {
      if (this.fracPressures?.length > 1) {
        if (depth < this.currentString?.hangerMd) {
          return;
        }
        this.defaultFracGradient = +LinearInterpSingle(tvd, this.fracPressures, this.userUnits).toFixed(2);
      } else {
        this.defaultFracGradient = this.currentString.annularFluid?.state.nominalDensity + 2;
      }
      if (!this.form.formGroup.controls.fractureGradient.value?.control) {
        this.form.formGroup.controls.fractureGradient.setValue( { control: this.defaultFracGradient } );
      }
    });
  }
}
