import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { InternalPressureProfileType, PericalLinkIPP } from '../../models/internal-pressure-profile.model';

interface PericalLinkIPPForm {
  type: InternalPressureProfileType.PERICALLINK;
  thermalOperation: Partial<BaseOperation>;
}

@Component({
  selector: 'app-perical-link-ipp',
  template: `
    <form [formGroup]="form.formGroup">
      <div class="fluid">
        <div class="field col-12">
          <label for="thermalOperation">Thermal Operation</label>
          <p-dropdown
            id="thermalOperation"
            [options]="thermalOperations"
            [formControlName]="form.formControlNames.thermalOperation"
            dataKey="id"
            appendTo="body"
            optionLabel="name"
          ></p-dropdown>
        </div>
      </div>
    </form>
    `,
  providers: subformComponentProviders(PericalLinkIppComponent)
})
export class PericalLinkIppComponent implements AfterViewInit, OnChanges {

  @Input() thermalOperations: Partial<BaseOperation>[];

  public form = createForm<PericalLinkIPP, PericalLinkIPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.PERICALLINK),
      thermalOperation: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: PericalLinkIPP): PericalLinkIPPForm => {
      if (!obj) { return null;}
      return {
        type: InternalPressureProfileType.PERICALLINK,
        thermalOperation: !obj.thermalOperationId ? (!this.thermalOperations ? null : this.thermalOperations[0]) : this.thermalOperations.find(op => op.id === obj.thermalOperationId)
      };
    },
    fromFormGroup: (formValue: PericalLinkIPPForm): PericalLinkIPP => {
      const { type, thermalOperation, ...commonValues} = formValue;
      return { type: InternalPressureProfileType.PERICALLINK, thermalOperationId: thermalOperation.id, ...commonValues }
    }
  });

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }

}
