<form [formGroup]="form.formGroup">
  <label class="labelTxt">
    {{ label }}
    <validation-error
      [isValid]="isControlInvalid()"
      [errorMsg]="getControlErrors()"
    ></validation-error>
  </label>
  <div class="p-inputgroup">
    <p-inputNumber
      #pnInput
      [formControlName]="form.formControlNames.control"
      [min]="min"
      [max]="max"
      [minFractionDigits]="minDecimals"
      [maxFractionDigits]="maxDecimals"
      [title]="title"
      (onFocus)="onInputFocus($event)"

    >
    </p-inputNumber>
    <span *ngIf="unitLabel" class="p-inputgroup-addon unitLabel">
      {{ unitLabel }}
    </span>
  </div>
</form>
