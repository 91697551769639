<form [formGroup]="form.formGroup">
  <p-fieldset legend="Tuning Parameters for Peng-Robinson EOS">
    <div class="field">
      <label for="acor">ACOR</label>
      <p-inputNumber
        id="acor"
        [formControlName]="form.formControlNames.acor"
        required
      ></p-inputNumber>
    </div>
    <div class="field">
      <label for="bcor">BCOR</label>
      <p-inputNumber
        id="bcor"
        [formControlName]="form.formControlNames.bcor"
        required
      ></p-inputNumber>
    </div>
    <div class="field">
      <label for="vcor">VCOR</label>
      <p-inputNumber
        id="vcor"
        [formControlName]="form.formControlNames.vcor"
        required
      ></p-inputNumber>
    </div>
    <div class="field">
      <label for="dcor">DCOR</label>
      <p-inputNumber
        id="dcor"
        [formControlName]="form.formControlNames.dcor"
        required
      ></p-inputNumber>
    </div>
    <div class="field">
      <label for="scor">SCOR</label>
      <p-inputNumber
        id="scor"
        [formControlName]="form.formControlNames.scor"
        required
      ></p-inputNumber>
    </div>
  </p-fieldset>

  <br />
  <label>Correlation option to initialize binary Interaction K-Values:</label>

  <div class="p-field">
    <label for="kiOption">K</label><label class="sub">i</label
    ><label>&nbsp;Option</label>
    <p-inputNumber
      id="kiOption"
      [formControlName]="form.formControlNames.kiOption"
      required
    ></p-inputNumber>
  </div>
  <br />

  <label>Correlation options for C</label><label class="sub">7</label
  ><label
    >+ component critical properties, acentric and shift factors and binary
    interaction parameters:
  </label>

  <div class="p-field">
    <label for="c7PlusCorrelations">C</label><label class="sub">7</label
    ><label>+ Correlations</label>
    <p-inputNumber
      id="c7PlusCorrelations"
      [formControlName]="form.formControlNames.c7PlusCorrelations"
      required
    ></p-inputNumber>
  </div>

</form>
