import { Directive, HostListener, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[resizer]'
})
export class NgxResizerDirective implements OnInit {

  public width: number;
  public oldX = 300;
  public grabber = false;

  @Input()
  public maxWidth: number = 600;
  public minWidth: number = 200;

  @Output() 
  public onResizeDone = new EventEmitter();

  constructor(private el: ElementRef) { }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return;
    }
    this.resizer(event.clientX - this.oldX);
    this.oldX = event.clientX;
    event.preventDefault();
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false;
    event.preventDefault();
  }

  resizer(offsetX: number) {
    this.width -= offsetX;
    if (this.width > this.maxWidth || this.width < this.minWidth){
      return;
    }
    this.el.nativeElement.parentNode.style.width = this.width + "px";
    this.onResizeDone.emit();
  }

  @HostListener('mousedown', ['$event']) onResize(event: MouseEvent, resizer?: Function) {
    this.grabber = true;
    this.oldX = event.clientX;
    event.preventDefault();
  }

  ngOnInit() {
    this.width = parseInt(this.el.nativeElement.parentNode.offsetWidth);
  }

}