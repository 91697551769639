import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin, lastValueFrom, Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PipesService } from 'src/app/catalogs/shared/services/pipes-catalog.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { DrillstringService } from '../../services/drillstring.service';
import { DrillstringModel } from './drillstring.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-drillstring',
  templateUrl: './drillstring.component.html',
  styleUrls: ['./drillstring.component.scss']
})
export class DrillstringComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  private _designId: string;
  private drillstringId: string;
  private calledFromSignalR = false;
  private resetData = false;
  private _shortLengthUnit: string;
  private _weightUnit: string;

  public isLoading = false;
  public drillstringForm: UntypedFormGroup;
  public drillPipes: Array<{ label: string, value: any }> = [];
  public hwdps: Array<{ label: string, value: any }> = [];
  public collars: Array<{ label: string, value: any }> = [];
  public currentStringName: string;
  public isTubingOrTieback = false;
  public disabledMessage: string;
  public configMissing = false;
  public longLengthUnit: string;

  @Input() private componentId: string;

  get isDialogVisible(): boolean {
    return this.isTubingOrTieback || this.configMissing;
  }

  constructor(
    private _store: StoreService,
    private _formBuilder: UntypedFormBuilder,
    private _pipesCatalogService: PipesService,
    private _drillstringService: DrillstringService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService
  ) {
    this.drillstringForm = this._formBuilder.group({
      tubularStringId: new UntypedFormControl(null),
      drillPipe: new UntypedFormControl(null),
      heavyWeightDrillPipe: new UntypedFormControl(null),
      collar: new UntypedFormControl(null),
      hwdpLength: new UntypedFormControl(0),
      collarLength: new UntypedFormControl(0)
    });
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this._designId = (await this._store.get<any>(StorageKeys.DESIGN)).id;
    const units = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnit = units.longLengths;
    this._shortLengthUnit = units.shortLengths === 'in' ? '"' : units.shortLengths;
    this._weightUnit = units.linearDensity === 'lb/ft' ? '#' : units.linearDensity;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
    this.getData();

    if (!this.calledFromSignalR) {
      const debouncedOnChange = this.drillstringForm.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      );
      this._subscriptions.add(debouncedOnChange.subscribe(formData => this.onFormChange(formData)));
    }
  }

  private signalRfunc(data: any): void {
    if ([PeriforOnChangeMessages.REFRESH_PIPES_CATALOG, PeriforOnChangeMessages.REFRESH_STRING_INPUTS, PeriforOnChangeMessages.REFRESH_TUBULARS_LIST].includes(data.action)) {
      this.isLoading = true;
      this.calledFromSignalR = true;
      this.drillstringForm.reset({ emitEvent: false });
      this.drillstringForm.controls.hwdpLength.setValue(0, { emitEvent: false });
      this.drillstringForm.controls.collarLength.setValue(0, { emitEvent: false });
      this.getData();
    }
  }

  private getData(): void {
    this._wellConfigService.getStringsList().subscribe({
      next: (tubulars) => {
        if (tubulars.length === 0) {
          this.configMissing = true;
          this.disabledMessage = "Well Configuration Missing: Please specify at least one string";
          this.isLoading = false;
          return;
        }

        const sources: Observable<any>[] = [
          this._pipesCatalogService.getPipes(),
          this._drillstringService.getDrillstringForTubular(),
          this._wellConfigService.getTubular(),
          this._wellConfigService.getTubulars()
        ];

        forkJoin(sources).pipe(
          map(([pipes, drillstring, currentString, tubulars]) => {
            const stringIndex = tubulars.findIndex(x => x.id === currentString.id);
            this.isTubingOrTieback = ['Tubing', 'Tieback'].includes(tubulars[stringIndex].type);
            this.disabledMessage = this.isTubingOrTieback ? `Not applicable for ${tubulars[stringIndex].type.toLowerCase()}` : '';

            this.toggleFormDisabledState(this.isTubingOrTieback);

            this.drillstringId = drillstring.id;
            this.drillstringForm.controls.tubularStringId.setValue(currentString.id, { emitEvent: false });
            let openHole = currentString.holeSize;

            if (currentString.type === 'Tieback') {
              const linerIndex = tubulars.findIndex(t => t.hangerMd === currentString.shoeMd);
              openHole = tubulars[linerIndex].holeSize;
            }

            this.currentStringName = `For the ${openHole}${this._shortLengthUnit} Hole Section`;

            this.drillPipes = this.filterAndMapPipes(pipes, 'drillPipe', openHole, this._shortLengthUnit, this._weightUnit);
            this.hwdps = this.filterAndMapPipes(pipes, 'hwdp', openHole, this._shortLengthUnit, this._weightUnit);
            this.collars = this.filterAndMapPipes(pipes, 'collars', openHole, this._shortLengthUnit, this._weightUnit);

            this.drillstringForm.patchValue(drillstring);
            this.isLoading = false;
          }),
          catchError(err => {
            this.isLoading = false;
            throw err;
          })
        ).subscribe();
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private filterAndMapPipes(pipes: any[], discriminator: string, openHole: number, shortLengthUnit: string, weightUnit: string) {
    return pipes
      .filter(p => p.discriminator === discriminator && p.outsideDiameter <= openHole)
      .map(p => ({
        label: `OD: ${p.outsideDiameter}${shortLengthUnit}, Weight: ${p.weightPerFoot}${weightUnit}, ID: ${p.insideDiameter}${shortLengthUnit}`,
        value: p
      }));
  }

  private async onFormChange(formData: DrillstringModel): Promise<void> {
    if (!this.resetData && this.drillstringForm.pristine) {
      return;
    }

    if (this.drillstringForm.valid && !this.isLoading) {
      formData.designId = this._designId;

      if (this.drillstringId) {
        formData.id = this.drillstringId;
        await lastValueFrom(this._drillstringService.updateDrillstringForTubular(formData));
      } else {
        const res = await lastValueFrom(this._drillstringService.setDrillstring(formData));
        this.drillstringId = res.toString();
      }
    }
    this.resetData = false;
  }

  public resetDrillPipe(): void {
    this.resetData = true;
    this.drillstringForm.controls.drillPipe.setValue(null);
  }

  public resetHwdp(): void {
    this.resetData = true;
    this.drillstringForm.controls.heavyWeightDrillPipe.setValue(null, { emitEvent: false });
    this.drillstringForm.controls.hwdpLength.setValue(0);
  }

  public resetCollar(): void {
    this.resetData = true;
    this.drillstringForm.controls.collar.setValue(null, { emitEvent: false });
    this.drillstringForm.controls.collarLength.setValue(0);
  }

  private toggleFormDisabledState(isDisabled: boolean): void {
    isDisabled ? this.drillstringForm.disable() : this.drillstringForm.enable();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
