<form [formGroup]="form.formGroup">
  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [default]="currentString.annularFluid?.state.nominalDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
      (focusout)="updateFluidDensityGenFluidProfile($event)"
      [validators]="validators.fluidDensity"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <p-dropdown
      [formControlName]="form.formControlNames.pressureLocation"
      id="pressureLocation"
      [options]="topPressureLocations"
      (onChange)="updateFluidProfileLabel($event)"
    ></p-dropdown>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="{{ fluidProfilePressureLocation }}"
      [unitLabel]="userUnits.pressure"
      [formControlName]="form.formControlNames.topPressure"
      [control]="'topPressure'"
      [default]="hydrostaticHangerPressure"
      title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
      [validators]="validators.topPressure"
    ></uom-quantity-input>
  </div>
</form>
