<form [formGroup]="form.formGroup">
  <div class="defaultButton">
    <img (click)="applyDefaults()" title="Apply Defaults" src="../../assets/icons/default-icon-white.png" />
  </div>
  <div class="grid">
    <div class="col">
      <div class="fluid">
        <div class="field">
          <label for="a0">a0 x 10</label><label class="sup">4</label>
            <p-inputNumber
              id="a0"
              [formControlName]="form.formControlNames.a0"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
        <div class="field">
          <label for="a1">a1 x 10</label><label class="sup">8</label>
            <p-inputNumber
              id="a1"
              [formControlName]="form.formControlNames.a1"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
        <div class="field">
          <label for="a2">a2 x 10</label><label class="sup">13</label>
            <p-inputNumber
              id="a2"
              [formControlName]="form.formControlNames.a2"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="fluid">
        <div class="field">
          <label for="b0">b0 x 10</label>
            <p-inputNumber
              id="b0"
              [formControlName]="form.formControlNames.b0"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
        <div class="field">
          <label for="b1">b1 x 10</label><label class="sup">6</label>
            <p-inputNumber
              id="b1"
              [formControlName]="form.formControlNames.b1"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
        <div class="field">
          <label for="b2">b2 x 10</label><label class="sup">12</label>
            <p-inputNumber
              id="b2"
              [formControlName]="form.formControlNames.b2"
              required
              [minFractionDigits]="1"
              ></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
</form>
