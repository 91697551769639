<form [formGroup]="createTreeLevelForm">
  <div class="field">
    <br>
    <span class="p-float-label">
      <input pInputText formControlName="name" id="name" (keyup)="handleKeyUp($event)" autofocus/>
      <p *ngIf="createTreeLevelForm.controls.name.value.length > 0 && createTreeLevelForm.controls.name.value.length < 3"
        class="error">Must be longer than three characters</p>
      <label for="levelName">{{ levelName | titlecase }} Name</label>
    </span>
  </div>
  <br>
  <p *ngIf="levelName == 'company'" class="error">After creating a new Company, make sure it's selected in the Admin panel under your account.</p>
  <br><br>
  <div style="float:right">
    <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success"
      [disabled]="createTreeLevelForm.invalid" label="Save" (click)=onSave()></button>
    <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel"
      (click)=onCancel()></button>
  </div>
</form>
