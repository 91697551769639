<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div #toolbarDiv class="p-toolbar-group-left">
      <div class="mr-2">
        <p-selectButton
          [options]="modes"
          [(ngModel)]="displacementResultsStore.state.selectedMode"
          (onChange)="onModeChange($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
      <div class="mr-2" *ngIf="displacementResultsStore.state?.selectedMode === 'multiple'">
        <p-selectButton
          [options]="resultsDisplay"
          [(ngModel)]="displacementResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
      <div class="flex grid-nogutter" *ngIf="displacementResultsStore.state?.selectedResultDisplay === 'plot'">
        <div class="mr-2">
          <p-selectButton
          [options]="depthView"
          [(ngModel)]="displacementResultsStore.state.selectedDepthView"
          (onChange)="depthViewToggle($event)"
          [allowEmpty]="false"
          ></p-selectButton>
        </div>
      </div>
      <div class="mr-2">
        <app-single-load-results
          [loadCases]="loadCases"
          (loadCaseSelected)="setLoadCase($event)"
          [stateStore]="displacementResultsStore"
          [signalRChange]="signalRChange"
        ></app-single-load-results>
      </div>
    </div>

    <app-single-load-plot
      [yAxisTitle]="yAxisTitle"
      [xAxisTitle]="xAxisTitle"
      [columnDefs]="columnDefs"
      [data]="results"
      *ngIf="displacementResultsStore.state?.selectedResultDisplay === 'plot'"
      [selectedDepthView]="displacementResultsStore.state.selectedDepthView"
      [loadCaseResultField]="displacementResultsStore.state.selectedLoadCase"
      [depthUnit]="longLengthUnit"
      [plotName]="plotName"
      [plotTitle]="'Forces/Displacements'"
      [componentHeight]="componentHeight"
    ></app-single-load-plot>

    <div>
      <br>
      <app-single-load-grid
        *ngIf="displacementResultsStore.state?.selectedResultDisplay === 'grid'"
        [rowData]="results"
        [columnDefinitions]="columnDefinitions"
        tableName="Forces/Displacements"
        [tableHeight]="tableHeight"
      ></app-single-load-grid>
    </div>

    <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
      <i class="pi pi-exclamation-circle"></i>
      <label>{{ errorMsg }}</label>
      <br> <br>
    </p-dialog>

  </app-tool-loader>
