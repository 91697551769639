<body>
  <div class="grid justify-content-center align-content-center vertical-container grid-nogutter" style="height: 100%">
    <div class="col-3">
      <p-card>
        <div class="flex flex-column align-content-center">

          <img class="img" src="../../../assets/images/Perifor-with-Well-Circle.png" />

          <ng-container>
            <p>Please wait... Redirecting... </p>
          </ng-container>

        </div>
      </p-card>
    </div>
  </div>
</body>
