<app-tool-loader [isLoading]="isLoading" [positionUnset]="true">
  <form [formGroup]="form.formGroup">
    <div class="field">
      <label for="type">Type</label>
      <p-dropdown
        [disabled]="isReadOnly"
        [options]="operationTypes"
        [formControlName]="form.formControlNames.type"
        optionLabel="name"
        appendTo="body"
        optionValue="value"
        [autoDisplayFirst]="false"
        (onChange)="onTypeChanged($event)"
      ></p-dropdown>
    </div>
    <!-- <div class="field" *ngIf="formGroupValues.type == OperationType.CIRCULATION
        || formGroupValues.type == OperationType.DRILLING
        || formGroupValues.type == OperationType.CEMENTING">
      <div class="p-inputgroup">
        <p-checkbox
        label=" Circulate through Mud Pits"
        [formControl]="formControlNames['useMudPits']"
        id="useMudPits"
        binary="true"
        (onChange)="enableDisableMudPits($event)"
        ></p-checkbox>
      </div>
    </div> -->

    <div [ngSwitch]="form.formGroup.value.type" *ngIf="!isLoading">
      <app-production-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.PRODUCTION"
        [formControlName]="form.formControlNames.productionOperation"
        [fluids]="fluids$"
        [perforation]="perforations"
        [userUnits]="user.units"
      ></app-production-operation-details>
      <app-injection-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.INJECTION"
        [formControlName]="form.formControlNames.injectionOperation"
        [fluids]="fluids$"
        [perforations]="perforations"
        [surfaceTemperature]="surfaceTemperature"
        [user]="user"
      ></app-injection-operation-details>
      <app-shut-in-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.SHUTIN"
        [formControlName]="form.formControlNames.shutInOperation"
        [currentString]="currentString"
        [userUnits]="user.units"
        [isAnnulus]="tabType == 'annulus'"
      ></app-shut-in-operation-details>
      <app-circulation-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.CIRCULATION"
        [formControlName]="form.formControlNames.circulationOperation"
        [fluids]="fluids$"
        [userUnits]="user.units"
        [udt]="udtProfile"
        [tubingShoe]="tubingShoe"
      ></app-circulation-operation-details>
      <app-gas-lift-details
        *ngSwitchCase="OperationType.GASLIFT"
        [formControlName]="form.formControlNames.gasLiftOperation"
        [fluids]="fluids$"
      ></app-gas-lift-details>
      <app-drilling-operation-details
        [wellConfig]="tubulars"
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.DRILLING"
        [formControlName]="form.formControlNames.drillingOperation"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        (drillingRangeChanged)="onDrillingDepthRangeChange($event)"
        [userUnits]="user.units"
        [currentStringId]="currentString.id"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-drilling-operation-details>
      <app-run-casing-and-circulate-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.RUNCASINGANDCIRCULATE"
        [formControlName]="form.formControlNames.runCasingAndCirculate"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="user.units"
        [currentString]="currentString.id"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-run-casing-and-circulate-details>
      <app-trip-pipe-and-circulate-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.TRIPPIPEANDCIRCULATE"
        [formControlName]="form.formControlNames.tripPipeAndCirculate"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="user.units"
        [currentString]="currentString"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-trip-pipe-and-circulate-details>
      <app-cementing-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.CEMENTING"
        [formControlName]="form.formControlNames.cementingOperation"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="user.units"
        [currentString]="currentString"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
        [surfaceTemperature]="surfaceTemperature"
      ></app-cementing-operation-details>
    </div>
  </form>
</app-tool-loader>

<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->
