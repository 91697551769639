export type InternalPressureProfile = CustomIPP | PericalLinkIPP | DisplacementFluidIPP | PressureTestIPP | SssvIPP |
  GeneralFluidIPP | FullEvacIPP | PartialEvacByMdIPP | WCDIPP | LostReturnsFluidDropIPP | LostReturnsWaterIPP |
  CirculatedKickGasIPP | CirculatedKickLiquidIPP | CasingFullOfGasIPP;

export enum InternalPressureProfileType {
  DISPLACEMENTFLUID = 'DisplacementFluid',
  PRESSURETEST = 'PressureTest',
  FULLEVAC = 'FullEvacuation',
  FLUIDDROP = 'FluidDrop',
  LOSTRETURNSWATER = 'LostReturnsWithWater',
  GASKICK = 'GasKick',
  LIQUIDKICK = 'LiquidKick',
  GENERALFLUID = 'GeneralFluidProfile',
  PARTIALEVAC = 'PartialEvacuationByMd',
  CUSTOMIPP = 'CustomInternalPressures',
  WCDIPP = 'WorstCaseDischarge',
  PERICALLINK = 'ThermalLink',
  SSSV = 'SubSurfaceSafetyValveTest',
  CFOG = 'CasingFullOfGas'
}

export interface CustomIPP {
  readonly type: InternalPressureProfileType.CUSTOMIPP;
  pressurePoints: PressurePoint[];
}

export interface PressurePoint {
  measuredDepth: number;
  pressure: number;
}

export interface PericalLinkIPP {
  readonly type: InternalPressureProfileType.PERICALLINK;
  thermalOperationId: string;
}

export interface DisplacementFluidIPP {
  readonly type: InternalPressureProfileType.DISPLACEMENTFLUID;
}

export interface PressureTestIPP {
  readonly type: InternalPressureProfileType.PRESSURETEST;
  fluidDensity: number;
  plugMd?: number;
  surfacePressure: number;
}

export interface GeneralFluidIPP {
  readonly type: InternalPressureProfileType.GENERALFLUID;
  fluidDensity: number;
  pressureLocation: boolean;
  topPressure: number;
}

export interface FullEvacIPP {
  readonly type: InternalPressureProfileType.FULLEVAC;
}

export interface PartialEvacByMdIPP {
  readonly type: InternalPressureProfileType.PARTIALEVAC;
  fluidLevelMd: number;
  fluidDensity: number;
}

export interface WCDIPP {
  readonly type: InternalPressureProfileType.WCDIPP;
  gasOilGradient: number;
  seawaterGradient: number;
}

export interface LostReturnsFluidDropIPP {
  readonly type: InternalPressureProfileType.FLUIDDROP;
  fluidDensity: number;
  lossDepth: number;
  marginOfError: number;
  porePressure: number;
  isPressureEnteredAsPpg: boolean;
}

export interface LostReturnsWaterIPP {
  readonly type: InternalPressureProfileType.LOSTRETURNSWATER;
  fractureDepth: number;
  fractureGradient: number;
  fluidDensity: number;
  fractureMargin: number;
}

export interface CirculatedKickGasIPP {
  readonly type: InternalPressureProfileType.GASKICK;
  drillPipeOd: number;
  fractureDepth: number;
  fractureGradient: number;
  fractureMarginOfError: number;
  gasGravity: number;
  influxDepth: number;
  kickIntensity: number;
  kickVolume: number;
  maxMudWeight: number;
}

export interface CirculatedKickLiquidIPP {
  readonly type: InternalPressureProfileType.LIQUIDKICK;
  drillPipeOd: number;
  fractureDepth: number;
  fractureGradient: number;
  fractureMarginOfError: number;
  liquidDensity: number;
  influxDepth: number;
  kickIntensity: number;
  kickVolume: number;
  maxMudWeight: number;
}

export interface SssvIPP {
  readonly type: InternalPressureProfileType.SSSV;
  upperStageSurfacePressure: number;
  upperStageFluidDensity: number;
  upperStagePlugMd: number;
  lowerStageSurfacePressure: number;
  lowerStageFluidDensity: number;
  lowerStagePlugMd: number;
}

export interface CasingFullOfGasIPP {
  readonly type: InternalPressureProfileType.CFOG;
  influxDepth: number;
  porePressure: number;
  marginOfError: number;
  gasGradient: number;
  fractureDepth: number;
  fractureGradient: number;
  fractureMarginOfError: number;
  useGasGravity: boolean;
  gasGravity: number;
}
