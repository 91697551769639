import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { debounceTime, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { Design } from '../../../shared/models/design.model';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { ChangeSelectedTubular, DashboardItemAddedMessage, GridItemResizedMessage, OpenSearchComponentDropdown, ToggleWellSchematicSidebarMessage } from 'src/app/shared/models/mediator-messages.model';
import { CalculationsApiService } from './calculations-api.service';
import { LauncherSharedService } from '../../services/launcher-shared.service';
import { UsersService } from '../../services/users-service';
import { Tubular } from 'src/app/wellbore-inputs/components/well-configuration/models';
import { User } from '../user-admin-page/user-model';
import { MenuItem } from 'primeng/api';
import { StorageKeys, StoreService } from '../../services/store.service';
import { Store } from '@ngneat/elf';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();
  private _subscriptions: Subscription;
  private _schematicOpen: boolean = false;
  public selectedTubular: Tubular;
  public tubulars: Array<Tubular>;

  public calculatingPerivis: boolean;
  public calculatingPerical: boolean;
  public calculatingPerinix: boolean;
  public currentDesign: Design;
  public isLoading: boolean;
  public components: any[];
  public selectedItems: any;
  public calcItem: any;
  private _selectedTubularId: string;

  public designStore: Store;

  @Input()
  public user: User;

  @Input()
  public smallerScreen: boolean;

  calcButtons: MenuItem[] = [
    {
      label: 'Perical'
    },
    {
      label: 'Perinix'
    },
    {
      label: 'Perivis'
    }
  ];

  @ViewChild('search')
  searchElement: any;

  constructor(
    private _launcherSharedService: LauncherSharedService,
    private _calcApiService: CalculationsApiService,
    private _userService: UsersService,
    private _wellConfigService: WellConfigService,
    private _signalRService: SignalRService,
    private _storeService: StoreService,
    private _mediator: MediatorService
  ) {
    this._subscriptions = new Subscription();

    const origOnKeydown = Dropdown.prototype.onKeydown;
    Dropdown.prototype.onKeydown = function (event: KeyboardEvent, search: boolean) {
      if (event.which == 9) {
        this.hide();
        return;
      }
      origOnKeydown.apply(this, [event, search]);
    };
  }

  async ngOnInit(): Promise<void> {

    this._selectedTubularId = await this._storeService.get<string>(StorageKeys.TUBULAR_ID);
    this.designStore = this._storeService.getStore(StorageKeys.DESIGN);

    // Ensure cleanup with takeUntil
    this.designStore.pipe(takeUntil(this._destroy$)).subscribe(d => {
      this.calculatingPerinix = false;
      this.calculatingPerivis = false;
      this.calculatingPerical = false;
      this.currentDesign = d;
      this.getStringList();
    });

    this._subscriptions.add(
      this._launcherSharedService.getComponents().subscribe(data => {
        this.components = data?.map(cmp => {
          return { key: cmp.key, name: cmp.name };
        });
        this.components.sort((a, b) => a.name.localeCompare(b.name));
      })
    );

    this._subscriptions.add(
      this._mediator.of(ChangeSelectedTubular).subscribe((e) => {
        if (e.selectedTubular.id == this.selectedTubular?.id) {
          return;
        }
        this._selectedTubularId = e.selectedTubular.id;
        this.setSelectedTubular();

        this._storeService.set(StorageKeys.TUBULAR_ID, e.selectedTubular.id || "");
        this._userService.updateCurrentAppIds({ tubularId: e.selectedTubular.id }).subscribe();
      })
    );
    const hub = this._signalRService.getConnectionToNotificationHub();
    hub.on(SignalRService.ON_PFB_CHANGE, async (data: any) => {
      let design = await this._storeService.get<Design>(StorageKeys.DESIGN);
      if (design.id != data.designId) {
        return;
      }
      if (data.action == PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
        this.getStringList();
      }
      if (data.action === PeriforOnChangeMessages.APB_CALCULATIONS_FINISHED) {
        this.calculatingPerinix = false;
      }
      if (data.action === PeriforOnChangeMessages.STRESS_CALCULATIONS_FINISHED) {
        this.calculatingPerivis = false;
      }
      if (data.action === PeriforOnChangeMessages.THERMAL_CALCULATIONS_FINISHED) {
        this.calculatingPerical = false;
      }
    });


    this._subscriptions.add(
      this._mediator.of(OpenSearchComponentDropdown).pipe(debounceTime(100)).subscribe(() => {
        this.searchElement.show();
      })
    );
  }

  public onCalculate(e) {
    if (e.value.label === 'Perical') {
      this.onCalculateThermal();
    }
    if (e.value.label === 'Perinix') {
      this.onCalculateApb();
    }
    if (e.value.label === 'Perivis') {
      this.onCalculateStress();
    }

    this.calcItem = null;
  }

  async getStringList() {
    this.isLoading = true;

    this.tubulars = await lastValueFrom(this._wellConfigService.getStringsList());

    this.setSelectedTubular();

    this.isLoading = false;
  }

  public async addUiComponent(uiComponentName: string) {
    this._mediator.publish(new DashboardItemAddedMessage(uiComponentName['itemValue'].key));
    this.selectedItems = [];
  }

  setSelectedTubular() {
    setTimeout(() => {
      this.selectedTubular = this.tubulars.find(t => t.id === this._selectedTubularId);
      if (!this.selectedTubular?.id && this.tubulars.length !== 0) {
        this.selectedTubular = this.tubulars[0];
        this._selectedTubularId = this.tubulars[0].id;
        this.tubularSelectionChanged();
      }
    }, 300);
  }

  async onCalculateStress() {
    this.calculatingPerivis = true;
    await lastValueFrom(this._calcApiService.calculateStress());
  }

  async onCalculateThermal() {
    this.calculatingPerical = true;
    await lastValueFrom(this._calcApiService.calculateThermal());
  }

  async onCalculateApb() {
    this.calculatingPerinix = true;
    await lastValueFrom(this._calcApiService.calculateApb());
  }

  public undo() {
    document.execCommand('undo');
  }

  toggleSchematic() {
    this._mediator.publish(new ToggleWellSchematicSidebarMessage());
    this._mediator.publish(new GridItemResizedMessage("none", 0, 0));

    this._schematicOpen = !this._schematicOpen;
    this._launcherSharedService.setData(this._schematicOpen);
  }

  tubularSelectionChanged() {
    if (this.selectedTubular) {
      this._storeService.set(StorageKeys.TUBULAR_ID, this.selectedTubular.id || "");
      this._userService.updateCurrentAppIds({ tubularId: this.selectedTubular.id }).subscribe();
    }
  }

  get isPericalDisabled(): boolean {
    return this.calculatingPerical || !this.selectedTubular || this.user.roles.readOnly;
  }

  get isPerinixDisabled(): boolean {
    return this.calculatingPerinix || this.calculatingPerical || !this.selectedTubular || this.user.roles.readOnly;
  }

  get isPerivisDisabled(): boolean {
    return this.calculatingPerivis || !this.selectedTubular || this.user.roles.readOnly;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this._destroy$.next(); // Emit a value to trigger unsubscription
    this._destroy$.complete(); // Complete the subject to clean up
  }

}
