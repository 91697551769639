import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PoreFrac } from '../models/poreFrac.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class PoreAndFracService {

  constructor(private http: HttpClient) { }

  getPorePressures(): Observable<PoreFrac[]> {
    return this.http.get<PoreFrac[]>(`${PeriforApiEndpoint}/porePressure`).pipe(
      map(data => data.map(data => new PoreFrac(data)))
    );
  }

  getFractureGradient(): Observable<PoreFrac[]> {
    return this.http.get<PoreFrac[]>(`${PeriforApiEndpoint}/fracturePressure`).pipe(
      map(data => data.map(data => new PoreFrac(data)))
    );
  }

  setPorePressures(porePressure: PoreFrac[]) {
    return this.http.put<PoreFrac[]>(`${PeriforApiEndpoint}/porePressure`, porePressure);
  }

  setFractureGradient(fracPressure: PoreFrac[]) {
    return this.http.put<PoreFrac[]>(`${PeriforApiEndpoint}/fracturePressure`, fracPressure);
  }
}
