<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div #toolbarDiv class="p-toolbar-group-left">
    <div class="mr-2">
      <p-selectButton
      [options]="modes"
      [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedMode"
      (onChange)="modeToggle($event)"
      [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div class="mr-2">
      <p-selectButton
      *ngIf="connectionSafetyFactorResultsStore.state?.selectedMode !== 'minimum'"
        [options]="resultsDisplay"
        [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedResultDisplay"
        (onChange)="onSelectedResultDisplayUpdated($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'grid'" class="p-mr-2 checkboxClass">
      <p-checkbox [(ngModel)]="connectionSafetyFactorResultsStore.state.includeCharacters" label="Show Details" (onChange)="toggleCharacterView($event)" binary="true"></p-checkbox>
    </div>
    <div class="flex grid-nogutter" *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'plot' && connectionSafetyFactorResultsStore.state?.selectedMode !== 'minimum'">
      <div class="mr-2">
        <p-selectButton
        [options]="depthView"
        [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedDepthView"
        (onChange)="depthViewToggle($event)"
        [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>
    <div class="mr-2">
      <app-single-load-results
        [loadCases]="loadCases"
        (loadCaseSelected)="setLoadCase($event)"
        [stateStore]="connectionSafetyFactorResultsStore"
        [signalRChange]="signalRChange"
      ></app-single-load-results>
    </div>
  </div>

  <app-single-load-plot
    [yAxisTitle]="yAxisTitle"
    [xAxisTitle]="xAxisTitle"
    [columnDefs]="columnDefs"
    [data]="results"
    *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'plot'"
    [selectedDepthView]="connectionSafetyFactorResultsStore.state?.selectedDepthView"
    [depthUnit]="depthUnit"
    [plotName]="plotName"
    [plotTitle]="'Connection Safety Factor Results'"
    [componentHeight]="componentHeight"
  ></app-single-load-plot>

  <div>
    <br>
    <app-single-load-grid
      *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'grid'"
      [rowData]="results"
      [designFactors]="designFactors"
      [columnDefinitions]="columnDefinitions"
      [selectedLoad]="connectionSafetyFactorResultsStore.state.selectedLoadCase"
      tableName="Connection Safety Factor Results"
      [tableHeight]="tableHeight"
      gridName="Connection Safety Factor Results"
      [mode]="connectionSafetyFactorResultsStore.state?.selectedMode"
    ></app-single-load-grid>
  </div>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>
