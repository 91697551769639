import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-cement-catalog',
  template: `
    <app-tool-loader [isLoading]="false" positionUnset="true">
      <app-formations-cement-catalog [catalogType]="'cement'" [componentId]="componentId"></app-formations-cement-catalog>
    </app-tool-loader>
  `
})
export class CementCatalogComponent {
    // State
    @Input()
    public componentId: string;
  constructor() { }
}
