<p-dialog
  [draggable]="false"
  [(visible)]="configMissing"
  [closable]="false"
>
  <i class="pi pi-exclamation-circle"></i> {{ disabledMessage }} <br />
  <br />
</p-dialog>

<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form *ngIf="designFactorsForm" [formGroup]="designFactorsForm" bg-dark>
      <div class="grid grid-nogutter">
        <div class="col">
          <p-fieldset legend="Pipe">
            <div class="field">
              <label for="barlowBurst">Barlow Burst</label>
              <p-inputNumber
                formControlName="barlowBurst"
                [minFractionDigits]="1"
                autocomplete="off"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.barlowBurst.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="apiCollapse">API Collapse</label>
              <p-inputNumber
                formControlName="apiCollapse"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.apiCollapse.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="pureTension">Pure Tension</label>
              <p-inputNumber
                formControlName="pureTension"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.pureTension.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="pureCompression">Pure Compression</label>
              <p-inputNumber
                formControlName="pureCompression"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.pureCompression.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="vonMisesBurst">Von Mises Burst</label>
              <p-inputNumber
                formControlName="vonMisesBurst"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.vonMisesBurst.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="vonMisesCollapse">Von Mises Collapse</label>
              <p-inputNumber
                formControlName="vonMisesCollapse"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="designFactorsForm.controls.vonMisesCollapse.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>
          </p-fieldset>
        </div>

        <div class="col" [formGroup]="designFactorsForm.get('connection')">
          <p-fieldset legend="Connection">
            <div class="field">
              <label for="burst">Burst/Leak</label>
              <p-inputNumber
                formControlName="burstLeak"
                [minFractionDigits]="1"
                class="w-full"
                id="conBurstLeak"
              ></p-inputNumber>
              <allowed-range
                [invalid]="connection.controls.burstLeak.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="collapse">Collapse/Leak</label>
              <p-inputNumber
                formControlName="collapse"
                [minFractionDigits]="1"
                class="w-full"
                id="conCollapse"
              ></p-inputNumber>
              <allowed-range
                [invalid]="connection.controls.collapse.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="tension">Tension</label>
              <p-inputNumber
                formControlName="tension"
                [minFractionDigits]="1"
                class="w-full"
                id="conTension"
              ></p-inputNumber>
              <allowed-range
                [invalid]="connection.controls.tension.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>

            <div class="field">
              <label for="compression">Compression</label>
              <p-inputNumber
                formControlName="compression"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <allowed-range
                [invalid]="connection.controls.compression.invalid"
                from="0.1"
                to="10"
              ></allowed-range>
            </div>
          </p-fieldset>
        </div>
      </div>
      <br>
    </form>
  </app-tool-loader>
</p-scrollPanel>
