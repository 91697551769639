import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StressOptions } from '../models/stress-options.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class StressOptionsService {

  constructor(private http: HttpClient) { }

  getStressOptions(): Observable<StressOptions> {
    return this.http.get<StressOptions>(`${PeriforApiEndpoint}/perivisOptions`)
      .pipe(map(r=>  new StressOptions({...r})));
  }

  setStressOptions(stressOptions: StressOptions) {
    return this.http.put(`${PeriforApiEndpoint}/perivisOptions`, stressOptions);
  }
}
