<div class="perforations-form">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <p-dialog [(visible)]="configMissing" [closable]="false">
    <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well
    Configuration Missing: Please specify at least one string <br />
    <br />
  </p-dialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <!-- <p-dialog [closable]="false">
      <i style="font-size: 10px;" class="pi pi-exclamation-circle"></i> String is fully cemented
      <br> <br>
    </p-dialog> -->

    <form [formGroup]="perforationsForm" novalidate>
      <ng-container formArrayName="perforations">
        <p-table
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="perforations.controls"
          class="perforations"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="componentId"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn>
                {{ col.header }}
              </th>
              <th>
                <p-button
                  [disabled]="configMissing || perforations.invalid"
                  label="Add"
                  class="p-button-success p-button-sm addButton"
                  (click)="onAddPerforation()"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-perforationFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <input pInputText formControlName="name" title="Name" />
                  <validation-error
                    [isValid]="isControlInvalid(perforationFg, 'name')"
                    [errorMsg]="getControlErrors(perforationFg, 'name')"
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="measuredDepth"
                    [minFractionDigits]="1"
                    title="Depth"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(perforationFg, 'measuredDepth')"
                    [errorMsg]="
                      getControlErrors(perforationFg, 'measuredDepth')
                    "
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="temperature"
                    [maxFractionDigits]="2"
                    title="Temperature"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(perforationFg, 'temperature')"
                    [errorMsg]="getControlErrors(perforationFg, 'temperature')"
                  ></validation-error>
                </td>

                <td style="width: 95px">
                  <button
                    pButton
                    class="p-button-text p-button-sm p-button-danger delete-btn"
                    title="Delete Perforation"
                    icon="pi pi-trash"
                    (click)="onDeletePerforation(rIdx)"
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
