export class PericalOptions {
    public constructor(init?: Partial<PericalOptions>) {
        Object.assign(this, init);
        this.maximumGridSpacing = init?.maximumGridSpacing || 200;
        this.disableInterfaceRefinement = init?.disableInterfaceRefinement || false;
        this.successiveRelationFactor = init?.successiveRelationFactor || 0.3;
        this.latentHeatDampingFactor = init?.latentHeatDampingFactor || 0.02;
        this.includeLatentHeatPhaseChange = init?.includeLatentHeatPhaseChange || false;
    }

    id?: string;
    maximumGridSpacing: number;
    disableInterfaceRefinement: boolean;
    successiveRelationFactor: number;
    latentHeatDampingFactor: number;
    includeLatentHeatPhaseChange: boolean;
}