<form [formGroup]="form.formGroup">
  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [default]="nextStringMudWeight"
      [validators]="validators.fluidDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
    <!-- (focusout)="getDefaultFluidDensity('nextStringMudWeightFluidDrop')" -->
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Loss Depth"
      [unitLabel]="userUnits.longLengths"
      [formControlName]="form.formControlNames.lossDepth"
      [control]="'lossDepth'"
      [default]="nextShoeDepth"
      [validators]="validators.lossDepth"
      title="Must be at or below shoe: {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
      (focusout)="getEmwDefaultPore($event.target.value)"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Pore Pressure"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.porePressure"
      [control]="'porePressure'"
      [default]="defaultEmw"
      [validators]="validators.porePressure"
      title="Allowed range: {{ porePressureValidation.min }} - {{ porePressureValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Margin of Error"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.marginOfError"
      [control]="'marginOfError'"
      [default]="'0'"
      [validators]="validators.marginOfError"
      title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
</form>
