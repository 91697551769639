<form [formGroup]="form.formGroup">
    <div class="grid">
        <p-fieldset legend="Influx Parameters" class="col-6 leftFieldset">
            <div class="field col-12">
                <uom-quantity-input
                label="Influx Depth"
                [unitLabel]="userUnits.longLengths"
                [formControlName]="form.formControlNames.influxDepth"
                [control]="'influxDepth'"
                [default]="nextShoeDepth"
                [validators]="validators.influxDepth"
                title="Must be at or below shoe: {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
              ></uom-quantity-input>
            </div>

            <div class="field col-12">
                <uom-quantity-input
                label="Pore Pressure"
                [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.porePressure"
                [control]="'porePressure'"
                [default]="defaultEmw"
                [validators]="validators.porePressure"
              ></uom-quantity-input>
            </div>

            <div class="field col-12">
                <uom-quantity-input
                label="Margin of Error"
                [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.marginOfError"
                [control]="'marginOfError'"
                [default]="'0'"
                [validators]="validators.marginOfError"
              ></uom-quantity-input>
            </div>

            <div class="field col-12">
                <input
                    style="height: 13px; width: 20px;"
                    type="checkbox"
                    binary="true"
                    [formControlName]="form.formControlNames.useGasGravity"
                    (change)="gasGravityChange()"
                >
                <label style="font-size: 14px; padding-left: 5px;">Use Gas Gravity</label>
            </div>

            <div class="field col-12" [style.display]="!gasGravityDisabled ? 'block' : 'none'">
                <uom-quantity-input
                label="Gas Gradient"
                [unitLabel]="gasGradientLabel"
                [formControlName]="form.formControlNames.gasGradient"
                [control]="'gasGradient'"
                [default]="gasGradientDefault"
                [validators]="validators.gasGradient"
                title="Allowed range: {{ gasGradientValidation.min }} - {{ gasGradientValidation.max }} {{ gasGradientLabel }}"
              ></uom-quantity-input>
            </div>

            <div class="field col-12" [style.display]="gasGravityDisabled ? 'block' : 'none'">
                <uom-quantity-input
                label="Gas Gravity"
                [formControlName]="form.formControlNames.gasGravity"
                [default]="'0.6'"
                [validators]="validators.gasGravity"
              ></uom-quantity-input>
            </div>
        </p-fieldset>

        <p-fieldset legend="Fracture Pressure" class="col">
            <div class="field col-12">
                <uom-quantity-input
                label="Fracture Depth"
                [unitLabel]="userUnits.longLengths + ' MD'"
                [formControlName]="form.formControlNames.fractureDepth"
                [control]="'fractureDepth'"
                [default]="shoeDepth"
                [validators]="validators.fractureDepth"
                title="Allowed range: {{ currentString.hangerMd }} - {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
                ></uom-quantity-input>
            </div>

            <div class="field col-12">
                <uom-quantity-input
                label="Fracture Gradient"
                [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.fractureGradient"
                [control]="'fractureGradient'"
                [default]="defaultFracGradient"
                [validators]="validators.fractureGradient"
                title="Allowed range: {{ fractureGradientValidation.min }} - {{ fractureGradientValidation.max }} {{ densityLabel }}"
                ></uom-quantity-input>
            </div>

            <div class="field col-12">
                <uom-quantity-input
                label="Margin of Error"
                [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.fractureMarginOfError"
                [control]="'fractureMarginOfError'"
                [default]="'0'"
                [validators]="validators.fractureMarginOfError"
                title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}"
                ></uom-quantity-input>
            </div>
        </p-fieldset>
    </div>
</form>