import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';

import { ConnectionsCatalogComponent } from '../catalogs/connections-catalog/connections-catalog.component';
import { GradesCatalogComponent } from '../catalogs/grades-catalog/grades-catalog.component';
import { PipesCatalogComponent } from '../catalogs/pipes-catalog/pipes-catalog/pipes-catalog.component';
import { FormationsCementCatalogComponent } from '../catalogs/formations-cement-catalog/formations-cement-catalog.component';
import { FormationsCatalogComponent } from '../catalogs/formations-cement-catalog/formations-catalog/formations-catalog.component';
import { CementCatalogComponent } from '../catalogs/formations-cement-catalog/cement-catalog/cement-catalog.component';
import { TemperatureDerationComponent } from '../catalogs/temperature-deration/temperature-deration.component';

const components =  [
  GradesCatalogComponent,
  PipesCatalogComponent,
  ConnectionsCatalogComponent,
  FormationsCementCatalogComponent,
  FormationsCatalogComponent,
  CementCatalogComponent,
  TemperatureDerationComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ...components
  ]
})
export class CatalogsModule { }
