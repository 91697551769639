import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { SelectItem } from 'primeng/api';
import { WellType } from 'src/app/shared/models/wellType.model';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { GeneralFluidIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';

interface GeneralFluidIppForm {
  type: InternalPressureProfileType.GENERALFLUID;
  pressureLocation: boolean;
  topPressure: UomQuantityInput;
  fluidDensity: UomQuantityInput;
}

@Component({
  selector: 'app-general-fluid-ipp',
  templateUrl: './general-fluid-ipp.component.html',
  providers: subformComponentProviders(GeneralFluidIppComponent)
})
export class GeneralFluidIppComponent implements OnChanges {

  @Input() currentString: any;
  @Input() wellType: WellType;
  @Input() userUnits: UserUnitsModel;

  public hydrostaticHangerPressure: number;
  public fluidProfilePressureLocation: string;
  public hydrostaticWellheadPressure: number;
  public validators: any;
  public densityLabel: string;
  public pressureLabel: string;
  public pressureValidation: { min: number, max: number };
  public densityValidation: { min: number, max: number };

  public topPressureLocations: Array<SelectItem> = [
    { label: 'Pressure specified at Hanger', value: false },
    { label: 'Pressure specified at Wellhead', value: true }
  ];

  public form = createForm<GeneralFluidIPP, GeneralFluidIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.GENERALFLUID),
      pressureLocation: new UntypedFormControl(false),
      topPressure: new UntypedFormControl(null),
      fluidDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: GeneralFluidIPP | null): GeneralFluidIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        pressureLocation: obj.pressureLocation,
        topPressure: { control: obj.topPressure },
        fluidDensity: { control: obj.fluidDensity }
      };

    },
    fromFormGroup: (formValue: GeneralFluidIppForm): GeneralFluidIPP | null => {
      const { pressureLocation, fluidDensity, topPressure, ...commonValues } = formValue;
      return {
        pressureLocation,
        topPressure: +topPressure?.control,
        fluidDensity: +fluidDensity?.control,
        ...commonValues
      };
    }
  });

  constructor(private _trajectoryService: TrajectoryService) { }

  ngOnChanges(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
    this.pressureLabel = this.userUnits.pressure;

    switch (this.pressureLabel) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 30000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 2068 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 206800 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 2041 };
        break;
    }

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 1.04, max: 25 };
        break;
      case 'kg/m³':
        case 'g/L':
        this.densityValidation = { min: 124, max: 2995 };
        break;
      case 'g/cm³':
        case 'kg/l':
        this.densityValidation = { min: 0.12, max: 3 };
        break;
    }

    this.validators = {
      topPressure: [Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required],
      fluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required],
    };

    if (this.currentString.type === 'Casing') {
      this.topPressureLocations = this.topPressureLocations.filter(x => x.label !== 'Pressure specified at Hanger');
      this.fluidProfilePressureLocation = 'Wellhead Pressure';
      this.form.formGroup.controls.pressureLocation.disable();
    } else {
      this.fluidProfilePressureLocation = 'Hanger Pressure';
      this.form.formGroup.controls.pressureLocation.enable();
    }

    this._trajectoryService.getTvdFromMd(this.currentString.hangerMd).subscribe(tvd => {
      this.hydrostaticHangerPressure = GetMudHydrostaticPressure(tvd, this.currentString?.annularFluid.state.nominalDensity, this.userUnits);
      if (!this.form.formGroup.controls.topPressure.value?.control) {
        this.form.formGroup.controls.topPressure.setValue({ control: this.hydrostaticHangerPressure });
      }
    });

    this.fluidProfilePressureLocation = this.form.formGroup.controls.pressureLocation.value ? 'Wellhead Pressure' : 'Hanger Pressure';
  }

  public updateFluidDensityGenFluidProfile(e) {
    const wellheadDepth: number = this.wellType.wellheadDepth ?? this.wellType.wellheadDepthFromDrillFloor;
    const topDepth = this.fluidProfilePressureLocation === "Wellhead Pressure" ? wellheadDepth : this.currentString.hangerMd;
    this._trajectoryService.getTvdFromMd(topDepth).subscribe(tvd => {
      let hydrostaticWellheadPressure = GetMudHydrostaticPressure(tvd, this.form.formGroup.controls.fluidDensity.value?.control, this.userUnits);

      this.form.formGroup.controls.topPressure.setValue({ control: hydrostaticWellheadPressure });
    });
  }

  updateFluidProfileLabel(e): void {
    const wellheadDepth: number = this.wellType.wellheadDepth ?? this.wellType.wellheadDepthFromDrillFloor;
    if (e.value) {
      this.fluidProfilePressureLocation = "Wellhead Pressure";

      this._trajectoryService.getTvdFromMd(wellheadDepth).subscribe((tvd) => {
        this.hydrostaticWellheadPressure = GetMudHydrostaticPressure(tvd, this.currentString?.annularFluid.state.nominalDensity, this.userUnits);

        this.form.formGroup.controls.topPressure.setValue({ control: this.hydrostaticWellheadPressure });
      });
    } else {
      this.fluidProfilePressureLocation = "Hanger Pressure";
      this.form.formGroup.controls.topPressure.setValue({ control: this.hydrostaticHangerPressure });
    }
  }
}
