import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UdtService } from 'src/app/shared/services/udt.service';
import { LoadCaseService } from '../../services/load-case.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { PressTempUi } from '../../models/perivis-results-state.model';

@Component({
  selector: 'app-pressures-temperatures',
  templateUrl: './pressures-temperatures.component.html'
})
export class PressuresTemperaturesComponent implements OnInit, OnDestroy {
  public plot = {
    data: []
  };

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'pressuresTemperaturesPlot';
  public downloadPlotName = 'press_temp_plot';
  public isLoading: boolean;
  public resultsView: SelectItem[] = [
    { label: 'IPP', value: 'internalPressurePoints' },
    { label: 'EPP', value: 'externalPressurePoints' },
    { label: 'Temp', value: 'temperaturePoints' }
  ];;

  private data: any;
  private udtData: any;
  private depthUnit: string;
  private temperatureUnit: string;
  private pressureUnit: string;

  public depthView = [
    { label: 'TVD', value: 'tvd' },
    { label: 'MD', value: 'md' }
  ];

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.pressTempStore = this._storeService.createStore(this.componentId, new PressTempUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public pressTempStore: Store;

  constructor(
    private _loadCaseService: LoadCaseService,
    private _signalRService: SignalRService,
    private _udtService: UdtService,
    private _storeService: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    let units = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = units.longLengths;
    this.temperatureUnit = units.temperature;
    this.pressureUnit = units.pressure;

    this.getData();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS ||
      data.action === PeriforOnChangeMessages.REFRESH_PACKERS ||
      data.action == PeriforOnChangeMessages.REFRESH_LOAD_CASE_INPUTS) {
      this.isLoading = true;
      this.getData();
    }
  }

  resultsViewToggle(e) {
    this.pressTempStore.update(state => ({ ...state, selectedResultsView: e.value }));
    if (this.pressTempStore.state.selectedResultsView == 'temperaturePoints') {
      this.pressTempStore.update(state => ({ ...state, selectedDepthView: 'tvd' }));
    }
    this.plotData();
  }

  private getData() {
    const sources: Observable<any>[] = [
      this._loadCaseService.getAllPressuresAndTemperatures() as Observable<any>,
      this._udtService.getUndisturbedTemperaturePlot() as Observable<any>,
    ];

    forkJoin(sources).pipe(
      map(([pressTemp, udt]) => {
        this.data = pressTemp;
        this.udtData = udt;
        this.plotData();
      }),
      catchError(err => {
        return err;
      })).subscribe();
  }

  plotData() {
    this.plot.data = [];

    for (let i = 0; i < this.data.length; i++) {
      const numPoints = this.data[i]?.[this.pressTempStore.state.selectedResultsView].length ?? null;
      const xArray = new Array(numPoints);

      if (this.data[i] === null) {
        const defaultPlot = {
          x: 0,
          y: 0
        }

        this.plot.data.push(defaultPlot);

      } else {
        const plotData = this.data[i]?.[this.pressTempStore.state.selectedResultsView];

        const yArray = new Array(numPoints);

        for (let i = 0; i < numPoints; i++) {
          const value = this.pressTempStore.state.selectedResultsView == 'temperaturePoints' ? plotData[i].temperature : plotData[i].pressure;
          xArray[i] = (Math.trunc(value * 100) / 100);
          let depth = this.pressTempStore.state.selectedDepthView == 'md' ? plotData[i].measuredDepth : plotData[i].trueVerticalDepth;
          yArray[i] = (Math.trunc(depth * 100) / 100);
        }

        const trace = {
          name: this.data[i].name,
          x: xArray,
          y: yArray
        }

        this.plot.data.push(trace);

        if (this.pressTempStore.state.selectedResultsView === 'temperaturePoints' && i == this.data.length - 1) {
          const udt = {
            name: 'UDT',
            x: this.udtData.x,
            y: this.udtData.y,
            line: {
              dash: 'dot',
              color: '#FF0000',
              width: 1.5
            }
          }

          this.plot.data.push(udt);
        }
      }
    }

    this.yAxisTitle = this.pressTempStore.state.selectedDepthView === 'md' ? `MD (${this.depthUnit})` : `TVD (${this.depthUnit})`
    this.xAxisTitle = this.pressTempStore.state.selectedResultsView === 'temperaturePoints' ? `Temperature (${Units.lib[this.temperatureUnit].symbol})` : `Pressure (${this.pressureUnit})`;

    this.isLoading = false;
  }

  public depthViewToggle(e) {
    this.pressTempStore.update(state => ({ ...state, selectedDepthView: e.value }));
    this.plotData();
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }

}
