<div class="catalog">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form [formGroup]="catalogForm" novalidate>
      <ng-container formArrayName="items">
        <p-table
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="itemsCatalog.controls"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="componentId"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                pResizableColumn
                class="align-content-center"
              >
                {{ col.header }}
              </th>
              <th class="align-content-center">
                <p-button
                  [disabled]="itemsCatalog.invalid"
                  label="Add"
                  class="p-button-success p-button-sm addButton"
                  (click)="onAddItem()"
                  title="Add"
                ></p-button>
                <p-button
                  [disabled]="itemsCatalog.invalid"
                  label="Export to Excel"
                  class="p-button-success p-button-sm addButton"
                  (click)="exportExcel()"
                  title="Export to Excel"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-itemFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <div class="p-inputgroup">
                    <input
                      type="text"
                      pInputText
                      formControlName="name"
                      title="Name"
                      class="w-full"
                      autocomplete="off"
                    />
                    <validation-error
                      [isValid]="isControlInvalid(itemFg, 'name')"
                      [errorMsg]="getControlErrors(itemFg, 'name')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="density"
                      [minFractionDigits]="1"
                      title="Density ({{ solidsDensityUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(itemFg, 'density')"
                      [errorMsg]="getControlErrors(itemFg, 'density')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="specificHeatCapacity"
                      [minFractionDigits]="1"
                      title="Specific Heat Capacity ({{
                        specificHeatCapacityUnit
                      }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="
                        isControlInvalid(itemFg, 'specificHeatCapacity')
                      "
                      [errorMsg]="
                        getControlErrors(itemFg, 'specificHeatCapacity')
                      "
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="thermalConductivity"
                      [minFractionDigits]="2"
                      title="Thermal Conductivity ({{ thermalCondUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="
                        isControlInvalid(itemFg, 'thermalConductivity')
                      "
                      [errorMsg]="
                        getControlErrors(itemFg, 'thermalConductivity')
                      "
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="youngsModulus"
                      [minFractionDigits]="0"
                      title="Young's Modulus ({{ youngsModulusUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(itemFg, 'youngsModulus')"
                      [errorMsg]="getControlErrors(itemFg, 'youngsModulus')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="poissonsRatio"
                      [minFractionDigits]="1"
                      title="Poisson's Ratio"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(itemFg, 'poissonsRatio')"
                      [errorMsg]="getControlErrors(itemFg, 'poissonsRatio')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="thermalExpansionCoefficient"
                      [minFractionDigits]="1"
                      title="Thermal Expansion Coeff ({{
                        thermalExpCoeffUnit
                      }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="
                        isControlInvalid(itemFg, 'thermalExpansionCoefficient')
                      "
                      [errorMsg]="
                        getControlErrors(itemFg, 'thermalExpansionCoefficient')
                      "
                    ></validation-error>
                  </div>
                </td>
                <td style="width: 90px">
                  <button
                    pButton
                    class="p-button-text p-button-sm p-button-danger delete-btn"
                    title="Delete"
                    icon="pi pi-trash"
                    (click)="onDeleteItem(rIdx)"
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
