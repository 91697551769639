import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GradeCatalog } from '../../models/grade-catalog.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/gradesCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GradesService {

  constructor(private http: HttpClient) { }

  getGrades(): Observable<Array<GradeCatalog>> {
    return this.http.get<Array<GradeCatalog>>(`${PeriforApiEndpoint}`).pipe(
      map(data => data.map(data => new GradeCatalog(data)))
    );
  }

  addGrade(model: GradeCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint}`, model);
  }

  updateGrade(model: GradeCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteGrade(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
