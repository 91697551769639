export class Perforation {
  public constructor(init?: Partial<Perforation>) {
    Object.assign(this, init);
  }

  id: string;
  designId: string;
  name: string;
  measuredDepth: number;
  temperature?: number;
}
