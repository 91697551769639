<form [formGroup]="form.formGroup" bg-dark>
  <div class="field">
    <label>Depth</label>
    <div class="p-inputgroup">
      <p-inputNumber
        [disabled]="true"
        placeholder="{{ packerDepth }}"
      ></p-inputNumber>
      <span class="p-inputgroup-addon">{{ userUnits.longLengths }}</span>
    </div>
  </div>

  <div class="field">
    <label>Seal Contact Diameter</label>
    <div class="p-inputgroup">
      <input
        type="number"
        pInputText
        [formControlName]="form.formControlNames.sealBoreContactDiameter"
      />
      <!-- <p-inputNumber [minFractionDigits]="3" [disabled]="hasExpansionJoint" [formControlName]="formControlNames.sealBoreContactDiameter"></p-inputNumber> -->
      <span class="p-inputgroup-addon">{{ userUnits.shortLengths }}</span>
    </div>
  </div>

  <div class="field">
    <div class="p-inputgroup">
      <div class="flex align-content-center">
        <input
          [formControlName]="form.formControlNames.isSheared"
          label="Sheared"
          id="isSheared"
          binary="true"
          type="checkbox"
          [checked]="true"
        />
        <label style="font-size: 11px; padding-left: 5px" class="isShearedLabel">Is Sheared</label>
      </div>
    </div>
  </div>

  <div class="field">
    <label [ngClass]="grayoutUpwardLimit ? '' : 'grayout'">Upward Limit</label>
    <span>
      <i
        title="NOTE: when space-out is specified via No-Go limits for hydraulic-set packers, the space-out shall include length-change effects during the packer setting process.  This will be accurate if space-out is set in the yard prior to deployment to the wellsite."
        class="pi pi-info-circle nogoClass"
      ></i>
    </span>
    <div class="p-inputgroup">
      <input
        class="nogoCheckboxClass"
        style="height: 13px; width: 15px"
        type="checkbox"
        binary="true"
        [checked]="upwardLimitChecked"
        (change)="toggleUpwardLimit($event)"
        [disabled]="disableNogoCheckboxes"
      />
      <input
        pInputText
        [formControlName]="form.formControlNames.upwardLimit"
      />
      <span [ngClass]="grayoutUpwardLimit ? 'p-inputgroup-addon' : 'p-inputgroup-addon grayout'">{{ userUnits.longLengths }}</span>
    </div>
  </div>

  <div class="field">
    <label [ngClass]="grayoutDownwardLimit ? '' : 'grayout'">Downward Limit</label>
    <span>
      <i
        title="NOTE: when space-out is specified via No-Go limits for hydraulic-set packers, the space-out shall include length-change effects during the packer setting process.  This will be accurate if space-out is set in the yard prior to deployment to the wellsite."
        class="pi pi-info-circle nogoClass"
      ></i>
    </span>
    <div class="p-inputgroup">
      <input
        class="nogoCheckboxClass"
        style="height: 13px; width: 15px"
        type="checkbox"
        binary="true"
        [checked]="downwardLimitChecked"
        (change)="toggleDownwardLimit($event)"
        [disabled]="disableNogoCheckboxes"
      />
      <input
        pInputText
        [formControlName]="form.formControlNames.downwardLimit"
      />
      <span [ngClass]="grayoutDownwardLimit ? 'p-inputgroup-addon' : 'p-inputgroup-addon grayout'">{{ userUnits.longLengths }}</span>
    </div>
  </div>
</form>