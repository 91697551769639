import { UntypedFormControl } from "@angular/forms";

export function wellheadDepthAboveMudline(control: UntypedFormControl) {
    let wellheadDepth = control.parent && control.parent.controls['wellheadDepth'].value;
    let waterDepth = control.parent && control.parent.controls['waterDepth'].value;
    if (wellheadDepth > waterDepth) {
       return {error: "Must be above or at the mudline"};
    }
    return null;
  }
