<form [formGroup]="form.formGroup">
  <div class="grid grid-nogutter">
    <div class="col-6">
      <div *ngIf="!isAnnulus" class="field">
        <label for="pressure">Pressure Location</label>
        <div class="p-inputgroup">
          <p-dropdown
            [disabled]="isReadOnly"
            [options]="pressureLocations"
            [formControlName]="form.formControlNames.pressureLocation"
            optionLabel="name"
            optionValue="value"
            appendTo="body"
          ></p-dropdown>
          <input
            type="number"
            pInputText
            [formControlName]="form.formControlNames.wellheadPressure"
          />
          <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
        </div>
        <p
          *ngIf="
            form.formGroup.controls.wellheadPressure.value &&
            form.formGroup.controls.wellheadPressure.invalid
          "
          class="error"
        >
          Allowed range: {{ pressureValidation.min }} -
          {{ pressureValidation.max }} {{ pressureUnit }}
      </p>
      </div>
    </div>
  </div>
  <br>
</form>
