import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AppNotificationService } from './app-notification.service';
import { Subject } from 'rxjs';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

const PeriforHostUrl = environment.PeriforEndpoint;

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnDestroy {
  static ON_PFB_CHANGE: string = "OnPeriforBackendChange";
  static ON_APP_INFO: string = "OnApplicationInfo";

  private _changeHub: signalR.HubConnection;
  private _appInfoHub: signalR.HubConnection;
  private _destroy$: Subject<void> = new Subject();

  constructor(
    private _notification: AppNotificationService,
    private _storeService: StoreService
  ) {}

  public getConnectionToNotificationHub(): signalR.HubConnection {
    if (this._changeHub) {
      return this._changeHub;
    }
    const appNoticeHubUrl = `${PeriforHostUrl}/perifor-hub`;
    this._changeHub = this.initializeHubConnection(appNoticeHubUrl);
    return this._changeHub;
  }

  public getConnectionToApplicationInfoMessageHub(): signalR.HubConnection {
    if (this._appInfoHub) {
      return this._appInfoHub;
    }
    const appInfHubUrl = `${PeriforHostUrl}/app-message-hub`;
    this._appInfoHub = this.initializeHubConnection(appInfHubUrl);
    return this._appInfoHub;
  }

  public async subscribeToEventFilteredByDesignId(hub: signalR.HubConnection, eventName: string, callback: (data: any) => void): Promise<void> {
    const designId = (await this._storeService.get<any>(StorageKeys.DESIGN)).id;
    const filteredCallback = (data: any) => {
      if (!data.designId || data.designId === designId) {
        callback(data);
      }
    };
    hub.on(eventName, filteredCallback);
    this._destroy$.subscribe(() => hub.off(eventName, filteredCallback));
  }

  private initializeHubConnection(url: string): signalR.HubConnection {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    hubConnection.serverTimeoutInMilliseconds = 60000;

    hubConnection.start();

    hubConnection.onclose(() => {
      this._notification.showWarning(`Signal-R connection to ${url} closed.`);
    });

    return hubConnection;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    if (this._changeHub) {
      this._changeHub.stop();
    }
    if (this._appInfoHub) {
      this._appInfoHub.stop();
    }
  }
}


export abstract class PeriforOnChangeMessages {
  static readonly APB_CALCULATIONS_FINISHED: string = "FinishApbCalculations";
  static readonly THERMAL_CALCULATIONS_FINISHED: string = "FinishThermalCalculations";
  static readonly STRESS_CALCULATIONS_FINISHED: string = "FinishStressCalculations";
  static readonly REFRESH_PIPES_CATALOG: string = "RefreshPipesCatalog";
  static readonly REFRESH_CONNECTIONS_CATALOG: string = "RefreshConnectionsCatalog";
  static readonly REFRESH_GRADES_CATALOG: string = "RefreshGradesCatalog";
  static readonly REFRESH_TEMP_DERATION_CATALOG: string = "RefreshTemperatureDerationCatalog";
  static readonly REFRESH_SCHEMATIC: string = "RefreshSchematic";
  static readonly REFRESH_TRAJECTORY: string = "RefreshTrajectory";
  static readonly REFRESH_TRAJECTORY_PLOT: string = "RefreshTrajectoryPlots";
  static readonly REFRESH_RESULTS: string = "RefreshResults";
  static readonly REFRESH_STRING_INPUTS: string = "RefreshStringSpecificInputs";
  static readonly REFRESH_TUBULARS_LIST: string = "RefreshTubularsList";
  static readonly REFRESH_WELL_CONFIG: string = "RefreshWellConfig";
  static readonly REFRESH_WELL_EXPLORER: string = "RefreshWellExplorer";
  static readonly REFRESH_LOAD_CASE_INPUTS: string = "RefreshLoadCaseInputPlots";
  static readonly REFRESH_DESIGN_LIMITS_PLOT: string = "RefreshDesignLimitsPlot";
  static readonly REFRESH_UDT_PLOT: string = "RefreshUdtPlot";
  static readonly REFRESH_UDT_INPUTS: string = "RefreshUdtInputs";
  static readonly REFRESH_PORE_PRESSURE_INPUTS: string = "RefreshPorePressureInputs";
  static readonly REFRESH_THERMAL_RESULTS: string = "RefreshThermalResults";
  static readonly REFRESH_FLUIDS: string = "RefreshFluids";
  static readonly REFRESH_APB: string = "RefreshApb";
  static readonly REFRESH_APB_RESULTS: string = "RefreshApbResults";
  static readonly REFRESH_FORMATIONS_CATALOG: string = "RefreshFormationsCatalog";
  static readonly REFRESH_CEMENT_CATALOG: string = "RefreshCementCatalog";
  static readonly REFRESH_DRILL_STRING: string = "RefreshDrillstring";
  static readonly REFRESH_PERFORATIONS: string = "RefreshPerforations";
  static readonly REFRESH_PACKERS: string = "RefreshPackers";
  static readonly REFRESH_PHASE_ENVELOPE: string = "FinishGergCalculations";
  static readonly REFRESH_CHOKES: string = "RefreshChokes";
}
