import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { ExternalPressureProfileType, GeneralEPP, MudAndWetCementMixWaterEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

interface MudAndWetCementMixWaterEPPForm {
  type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER;
  generalEpp: GeneralEPP;
}

@Component({
  selector: 'app-mud-and-cement-mix-water-epp',
  templateUrl: './mud-and-cement-mix-water-epp.component.html',
  providers: subformComponentProviders(MudAndCementMixWaterEppComponent)
})
export class MudAndCementMixWaterEppComponent {

  @Input() currentString: any;
  @Input() cement: CementingLanding;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;
  public mixWaterDensity: number;

  public form = createForm<MudAndWetCementMixWaterEPP, MudAndWetCementMixWaterEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.MUDANDCEMENTMIXWATER),
      generalEpp: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: MudAndWetCementMixWaterEPP): MudAndWetCementMixWaterEPPForm => {
      return {
        type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER,
        generalEpp: obj
      };
    },
    fromFormGroup: (formValue: MudAndWetCementMixWaterEPPForm): MudAndWetCementMixWaterEPP => {
      return {
        type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity
      };
    }
  });

  constructor() { }

  ngOnInit(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
    this.mixWaterDensity = GetValueFromPpg(8.33, this.densityLabel);
  }
}
