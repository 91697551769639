import { AfterContentInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { InputNumber } from 'primeng/inputnumber';
import { mapControlErrors } from 'src/app/shared/services/validation-helpers';

export interface UomQuantityInput {
  control: number;
}

@Component({
  selector: 'uom-quantity-input',
  templateUrl: './uom-quantity-input.component.html',
  styleUrls: ['./uom-quantity-input.component.scss'],
  providers: subformComponentProviders(UomQuantityInputComponent)
})
export class UomQuantityInputComponent implements OnInit, OnChanges, AfterContentInit {

  @Input()
  public label: string;

  @Input()
  public unitLabel: string;

  @Input()
  public minDecimals?: number = 2;

  @Input()
  public maxDecimals?: number = 4;

  @Input()
  public min?: number;

  @Input()
  public max?: number;

  @Input()
  public title?: string;

  @Input()
  public default?: number;

  @Input()
  public inputDisabled?: boolean;

  @Input()
  public control: string;

  @Input()
  public validators: Array<any>;

  @ViewChild('pnInput')
  public pnInput: ElementRef;

  public form = createForm<UomQuantityInput, any>(this, {
    formType: FormType.SUB,
    formControls: {
      control: new UntypedFormControl(null)
    },
    toFormGroup: (obj: any | null): any | null => {
      if (!obj) {
        return null;
      }

      return {
        control: obj.control
      };
    },
    fromFormGroup: (formValue: any): any | null => {
      const { control, ...commonValues } = formValue;
      return {
        control
      };
    }
  });

  constructor() { }

  ngOnInit(): void {
    if (this.validators) {
      this.form.formGroup.controls.control.addValidators(this.validators);
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.default && (!this.form.formGroup.controls.control.value && this.form.formGroup.controls.control.value !== 0)) {
        this.form.formGroup.controls.control.setValue(this.default);
        let pInput: InputNumber = ((this.pnInput as unknown) as InputNumber);
        pInput.updateInput(this.default, "", "update", this.default.toString());
      }
    }, 0)
    
    this.enableDisableInput();
  }

  ngOnChanges(): void {
    this.enableDisableInput();
  }

  private enableDisableInput() {
    if(this.inputDisabled === true) {
      this.form.formGroup.controls.control.disable();
    } else {
      this.form.formGroup.controls.control.enable();
    }
  }

  isControlInvalid(): boolean {
    let isValid = this.form.formGroup.controls.control.touched && this.form.formGroup.controls.control.invalid; // Skipping dirty check here. This allows errors to show without the form field being dirty.
    return isValid && this.form.formGroup.controls.control.errors != null;
  }

  getControlErrors(): string {
    let formCtrlErrors = this.form.formGroup.controls.control.errors;
    return formCtrlErrors ? mapControlErrors(formCtrlErrors) : null;
  }

  onInputFocus($event) {
    $event.target.select();
  }
}
