import { AfterContentInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { WellType } from 'src/app/shared/models/wellType.model';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { CustomTempProfile, TemperaturePoint, TemperatureProfileType } from '../../models/temperature-profile.model';
import { validateCustomProfile } from '../../../../../shared/validators/custom-profile.validator';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { UdtService } from 'src/app/shared/services/udt.service';

@Component({
  selector: 'app-custom-temp-profile',
  template: `
  <form [formGroup]="form.formGroup">
    <label
      class="error"
      *ngIf="form.formGroup.controls.temperaturePoints.errors?.lastPointAboveShoe"
      >Must be specified from hanger: {{ currentString?.hangerMd.toFixed(2) }} {{ userUnits.longLengths }} and to or
      below shoe: {{ currentString?.shoeMd.toFixed(2) }} {{ userUnits.longLengths }}, but above well TD: {{ wellTd.toFixed(2) }} {{ userUnits.longLengths }}</label
    >
    <ng-table-grid-cmp
      tableName="Custom Temperatures"
      tableHeight="400px"
      [tableData]="customTemperatures"
      [columnDefinitions]="columnDefinitions"
      [newRowFormGroup]="newDataRow"
      [inputFields]="inputFields"
      (dataChange)="onCustomTemperaturesChange($event)"
      [isDynamicLoaded]="true"
    ></ng-table-grid-cmp>
  </form>
  `,
  styles: [`
    .error {
      color: red;
      font-size: 11px;
      height: 0px;
      margin-top: 0px;
      width: 169px;
    }
  `],
  providers: subformComponentProviders(CustomTempProfileComponent)
})
export class CustomTempProfileComponent implements AfterContentInit, OnChanges {

  @Input() wellType: WellType;
  @Input() currentString: any;
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;

  public lastPointAboveShoe: boolean;
  public customTemperatures: Array<TemperaturePoint>;
  
  private udtData: UndisturbedTemperature;

  public form = createForm<CustomTempProfile>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(TemperatureProfileType.CUSTOMTEMP),
      temperaturePoints: new UntypedFormControl(null)
    },
    toFormGroup: (obj: CustomTempProfile | null): CustomTempProfile | null => {
      return {
        type: obj.type,
        temperaturePoints: obj.temperaturePoints
      }
    }
  });

  public get columnDefinitions(): Array<{ field: string, header: string }> {
    return [
      { header: 'MD (' + this.userUnits.longLengths + ')', field: 'measuredDepth' },
      { header: 'Temperature (' + this.userUnits.temperature + ')', field: 'temperature' }
    ];
  }

  public newDataRow(): UntypedFormGroup {
    return new UntypedFormGroup({
      measuredDepth: new UntypedFormControl("", [Validators.required]),
      temperature: new UntypedFormControl("", [Validators.required])
    });
  }

  public inputFields = [{
    name: 'measuredDepth',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  },
  {
    name: 'temperature',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  }];

  constructor(private _udtService: UdtService) { }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.temperaturePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.temperaturePoints?.value) {
      this.customTemperatures = this.form.formGroup.controls.temperaturePoints.value;
    } else {
      this.form.formGroup.controls.temperaturePoints.setValue(this.CreateInitialCustomTemperaturePoints());
      this.customTemperatures = this.form.formGroup.controls.temperaturePoints.value;
    }
  }

  ngOnChanges(): void {
    this.form.formGroup.controls.temperaturePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.temperaturePoints?.value) {
      this.customTemperatures = this.form.formGroup.controls.temperaturePoints.value;
    } else {
      this._udtService.getUndisturbedTemperature().subscribe(data => {
        this.udtData = data;
        
      if (this.form.formGroup.controls.temperaturePoints?.value) {
        this.customTemperatures = this.form.formGroup.controls.temperaturePoints.value;
      } else {
        this.form.formGroup.controls.temperaturePoints.setValue(this.CreateInitialCustomTemperaturePoints());
        this.customTemperatures = this.form.formGroup.controls.temperaturePoints.value;
      }
      });
    }
  }

  onCustomTemperaturesChange(v: { dataRows: Array<any>, reload: boolean, triggeredBy: any }) {
    if (v.triggeredBy.type == 'reset') {
      this.customTemperatures = this.CreateInitialCustomTemperaturePoints();
      this.form.formGroup.controls.temperaturePoints.setValue(this.customTemperatures);
    } else if (v.dataRows) {
      this.form.formGroup.controls.temperaturePoints.setValue(v.dataRows);
    }
  }

  getTempAtTd(surfaceTemp: number, gradient: number, shoeDepth: number, mudlineTemp: number): number {
    const topTemperature = this.wellType.type !== 'Land' ? mudlineTemp : surfaceTemp;
    const topDepth = this.wellType.type !== 'Land' ? this.wellType.mudlineDepth : this.currentString.hangerMd;
    const temp = gradient * (shoeDepth - topDepth) / (this.userUnits.temperature == '°F' ? 100 : 30) + topTemperature;
    return Math.trunc(temp * 100) / 100;
  }

  private CreateInitialCustomTemperaturePoints() {
    const surfaceTemp = this.udtData.constantGradient.surfaceAmbientTemperature;
    const gradient = this.udtData.constantGradient.temperatureGradient;
    const mudlineTemp = this.udtData.constantGradient.mudlineTemperature || this.udtData.constantGradient.surfaceAmbientTemperature;

    let defaultTemp = [
      {
        measuredDepth: this.wellType?.wellheadDepthFromDrillFloor || 0,
        temperature: mudlineTemp,
      },
      {
        measuredDepth: this.currentString.shoeMd,
        temperature: this.getTempAtTd(surfaceTemp, gradient, this.shoeDepth, mudlineTemp)
      }
    ];

    if (this.wellType.type !== 'Land') {
      defaultTemp.splice(1, 0, {
        measuredDepth: this.wellType.mudlineDepth,
        temperature: mudlineTemp
      });
    }

    return defaultTemp;
  }
}
