<div class="connections-catalog">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form [formGroup]="connectionsForm" novalidate>
      <div class="col">
        <div class="field">
          <label for="fluids">Pipe OD Filter ({{ diameterUnit }})</label>
          <p-dropdown
            (onChange)="filterByOd($event)"
            [options]="filterPipeOds"
            appendTo="body"
            title="Pipe OD ({{ diameterUnit }})"
          ></p-dropdown>
        </div>
      </div>

      <ng-container formArrayName="connections">
        <p-table
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="connectionsCatalog.controls"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="componentId"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                pResizableColumn
                class="align-content-center"
              >
                {{ col.header }}
              </th>
              <th class="align-content-center">
                <p-button
                  [disabled]="connectionsCatalog.invalid"
                  label="Add"
                  class="p-button-success p-button-sm addButton"
                  (click)="onAddConnection()"
                  title="Add Connection"
                ></p-button>
                <p-button
                  [disabled]="connectionsCatalog.invalid"
                  label="Export to Excel"
                  class="p-button-success p-button-sm addButton"
                  (click)="exportExcel()"
                  title="Export to Excel"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-connFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <div class="p-inputgroup">
                    <input
                      type="text"
                      pInputText
                      formControlName="name"
                      title="Name"
                      class="w-full"
                      autocomplete="off"
                    />
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'name')"
                      [errorMsg]="getControlErrors(connFg, 'name')"
                    ></validation-error>
                  </div>
                </td>
                <td class="dropdownClass">
                  <p-dropdown
                    [disabled]="userRoles.readOnly"
                    formControlName="pipeOd"
                    [options]="pipeOds"
                    appendTo="body"
                    title="Pipe OD ({{ diameterUnit }})"
                  ></p-dropdown>
                </td>
                <td class="dropdownClass">
                  <p-dropdown
                    [disabled]="userRoles.readOnly"
                    [title]="showJson(connFg.get('tubularPipe').value)"
                    [placeholder]="
                      getPlaceholderText(
                        connFg.get('tubularPipe').value,
                        'outsideDiameter'
                      )
                    "
                    formControlName="tubularPipe"
                    [options]="getSortedPipeOptions(connFg, 'weightPerFoot')"
                    appendTo="body"
                  >
                  </p-dropdown>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="outerDiameter"
                      [minFractionDigits]="3"
                      title="Connection OD ({{ diameterUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'outerDiameter')"
                      [errorMsg]="getControlErrors(connFg, 'outerDiameter')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="innerDiameter"
                      [minFractionDigits]="3"
                      title="Connection ID ({{ diameterUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'innerDiameter')"
                      [errorMsg]="getControlErrors(connFg, 'innerDiameter')"
                    ></validation-error>
                  </div>
                </td>
                <td class="dropdownClass">
                  <p-dropdown
                    [disabled]="userRoles.readOnly"
                    [title]="showJson(connFg.get('grade').value)"
                    [placeholder]="
                      getPlaceholderText(connFg.get('grade').value, 'name')
                    "
                    formControlName="grade"
                    [options]="grades"
                    appendTo="body"
                  ></p-dropdown>
                  <validation-error
                    [isValid]="isControlInvalid(connFg, 'grade')"
                    [errorMsg]="getControlErrors(connFg, 'grade')"
                  ></validation-error>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="burstRating"
                      title="Burst Rating ({{ pressureUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'burstRating')"
                      [errorMsg]="getControlErrors(connFg, 'burstRating')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="collapseRating"
                      title="Collapse Rating ({{ pressureUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'collapseRating')"
                      [errorMsg]="getControlErrors(connFg, 'collapseRating')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="tensionRating"
                      title="Tension Rating ({{ forceUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'tensionRating')"
                      [errorMsg]="getControlErrors(connFg, 'tensionRating')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="compressionRating"
                      title="Compression Rating ({{ forceUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(connFg, 'compressionRating')"
                      [errorMsg]="getControlErrors(connFg, 'compressionRating')"
                    ></validation-error>
                  </div>
                </td>
                <td class="w-7rem">
                  <button
                    pButton
                    class="p-button-text p-button-sm p-button-danger delete-btn"
                    title="Delete Connection"
                    icon="pi pi-trash"
                    (click)="onDeleteConnection(rIdx)"
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
<label>* NOTE: External pressure rating is based on leakage from outside</label>
