import { NgModule } from '@angular/core';

import {AvatarModule} from 'primeng/avatar';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuModule } from 'primeng/menu';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { InplaceModule } from 'primeng/inplace';
import { ListboxModule } from 'primeng/listbox';
import { BlockUIModule} from 'primeng/blockui';
import { InputSwitchModule } from 'primeng/inputswitch';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BadgeModule} from 'primeng/badge';
import {OrderListModule} from 'primeng/orderlist';
import {SplitterModule} from 'primeng/splitter';
import {TreeSelectModule} from 'primeng/treeselect';
import { ChipModule } from 'primeng/chip';
import { PasswordModule } from 'primeng/password';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenubarModule } from 'primeng/menubar';
import { DockModule } from 'primeng/dock';

@NgModule({
  exports: [
    AvatarModule,
    CheckboxModule,
    InplaceModule,
    InputNumberModule,
    InputTextModule,
    CardModule,
    ConfirmDialogModule,
    DropdownModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    MenuModule,
    TabMenuModule,
    ToolbarModule,
    ButtonModule,
    PanelModule,
    TableModule,
    TabViewModule,
    DialogModule,
    DynamicDialogModule,
    RadioButtonModule,
    ProgressSpinnerModule,
    PanelMenuModule,
    TreeTableModule,
    TreeModule,
    TooltipModule,
    DynamicDialogModule,
    ContextMenuModule,
    SplitButtonModule,
    SelectButtonModule,
    BreadcrumbModule,
    FieldsetModule,
    DividerModule,
    ListboxModule,
    BlockUIModule,
    InputSwitchModule,
    MultiSelectModule,
    OverlayPanelModule,
    OrderListModule,
    BadgeModule,
    SplitterModule,
    TreeSelectModule,
    ChipModule,
    PasswordModule,
    ScrollPanelModule,
    MenubarModule,
    DockModule
  ],
  providers: [
    MessageService
  ]
})

export class PrimeNGModule {}
