export function GetValueFromPpg(density: number, unit: string): number {
    switch (unit) {
      case 'ppg':
        return density;
      case 'kg/m³':
      case 'g/L':
        return +(density * 119.8264).toFixed(2);
      case 'g/cm³':
      case 'kg/l':
        return +(density * 0.1198264).toFixed(2);
    }
  }

  export function GetPpgFromValue(density: number, unit: string): number {
    switch (unit) {
      case 'ppg':
        return density;
      case 'kg/m³':
      case 'g/L':
        return +(density / 119.8264).toFixed(2);
      case 'g/cm³':
      case 'kg/l':
        return +(density / 0.1198264).toFixed(2);
    }
  }

  export function GetValueFromPsi(pressure: number, unit: string): number {
    switch (unit) {
      case 'psi':
        return +pressure.toFixed(2);
    case 'bar':
        return +(pressure * 0.068947572932).toFixed(2);
    case 'KPa':
        return +(pressure * 6.8947572932).toFixed(2);
    case 'atm':
        return +(pressure / 14.696).toFixed(2);
    }
  }

  export function GetSolidsDensityFromLbmFt(density: number, unit: string): number {
    switch (unit) {
      case 'lbm/ft³':
        return density;
      case 'kg/m³':
        return +(density * 16.0185).toFixed(2);
      case 'g/cm³':
        return +(density * 0.0160185).toFixed(2);
    }
  }

  export function GetValueFromFahrenheit(temperature: number, unit: string): number {
    switch (unit) {
      case '°F':
        return temperature;
      case '°C':
        return +((temperature - 32) / 1.8).toFixed(2);
      case 'K':
        return +((temperature - 32) / 1.8 + 273.15).toFixed(2);
    }
  }

  export function GetValueFromFeet(length: number, unit: string): number {
    switch (unit) {
      case 'ft':
        return length;
      case 'm':
        return +(length * 0.3048).toFixed(2);
    }
  }

  export function GetShortLengthValueFromInches(length: number, unit: string): number {
    switch (unit) {
      case 'in':
        return length;
      case 'cm':
        return +(length * 2.54).toFixed(2);
      case 'mm':
        return +(length * 25.4).toFixed(2);
    }
  }

  export function GetForceValueFromLbf(force: number, unit: string): number {
    switch (unit) {
      case 'lbf':
        return force;
      case 'tonne':
        return +(force * 0.0005).toFixed(2);
      case 'kgf':
        return +(force * 0.453592).toFixed(2);
    }
  }