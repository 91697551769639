import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="loading-container" [style.position]="positionUnset ? 'unset' : 'relative'" [style.height]="positionUnset ? '100%' : 'unset'">
      <div class="loading-indicator" *ngIf='isLoading == true'>
        <div class="loading-indicator__centerer">
          <img class="img" src="../../assets/images/Perifor-with-Well-Circle.png" />
          <p-progressSpinner class="spinner" strokeWidth="4"></p-progressSpinner>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  @Input()
  isLoading: boolean = false;

  @Input()
  positionUnset? : boolean = false;
  
  constructor() { }
}
