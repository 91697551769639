<p-overlayPanel #notificationHub (onShow)="markRead()">
  <ng-template pTemplate>
    <div class="flex flex-row header" style="width: 100%">
      <div class="align-items-start col-9 label">
        <h2>Notifications</h2>
      </div>
      <div class="p-toolbar-group-right">
          <p-dropdown
            [(ngModel)]="selectedSeverity"
            [options]="messageTypes"
            (onChange)="filterSeverity($event.value)"
          ></p-dropdown>
          <button
            pButton
            pRipple
            type="button"
            class="p-button-outlined p-button-warning"
            (click)="clearNotifications()"
            [disabled]="messages.length === 0"
          >
            Clear All
          </button>
      </div>
    </div>

    <div class="hub-container">
      <div *ngFor="let msg of messages" class="message-box-container" [ngClass]="msg.severity">
        <span class="msg-severity">
          <i *ngIf="msg.severity == 'success'" class="pi pi-check"></i>
          <i *ngIf="msg.severity == 'info'" class="pi pi-exclamation-circle"></i>
          <i *ngIf="msg.severity == 'warn'" class="pi pi-exclamation-triangle"></i>
          <i *ngIf="msg.severity == 'error'" class="pi pi-times-circle"></i>
        </span>

        <span class="msg-summary">{{ msg.summary }}</span>

        <div class="msg-detail">
          {{ msg.detail }}
          <div class="msg-time">{{ msg.data.time }}</div>
        </div>

        <div class="buttons">
          <button
            pButton
            pRipple
            type="button"
            class="p-button-text p-button-rounded p-button-warning p-button-small"
            title="Clear Notification"
            (click)="removeNotification($event, msg)"
            icon="pi pi-times"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            class="p-button-text p-button-rounded p-button-info p-button-small"
            title="Copy Message Text"
            (click)="copyNotification(msg)"
            icon="pi pi-clone"
          ></button>
        </div>
      </div>

      <div *ngIf="messages.length === 0" class="flex justify-content-center noNotification">
        <br><br>
        No notifications to display.
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<button
  pButton
  pRipple
  type="button"
  class="p-button-rounded p-button-text p-text-secondary notification-button"
  icon="pi pi-bell"
  title="Notification Hub"
  (click)="notificationHub.toggle($event)"
  pBadge
  [value]="messagesUnread"
  severity="danger"
  *ngIf="messagesUnread > 0"
></button>

<button
  pButton
  pRipple
  type="button"
  class="p-button-rounded p-button-text p-text-secondary notification-button"
  icon="pi pi-bell"
  title="Notification Hub"
  (click)="notificationHub.toggle($event)"
  *ngIf="messagesUnread === 0"
></button>
