import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DrillstringModel } from '../components/drillstring/drillstring.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class DrillstringService {

  constructor(private http: HttpClient) { }

  getDrillstringForTubular(): Observable<DrillstringModel> {
    return this.http.get<DrillstringModel>(`${PeriforApiEndpoint}/drillstring`).pipe(
      map(data => new DrillstringModel(data)));
  }

  updateDrillstringForTubular(model: DrillstringModel): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/drillstring`, model);
  }

  setDrillstring(drillstring: DrillstringModel): Observable<DrillstringModel> {
    return this.http.post<DrillstringModel>(`${PeriforApiEndpoint}/drillstring`, drillstring);
  }
}
