<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="grid">
    <div class="col-5">
      <p-listbox
        [options]="apbData?.apbAnnuli"
        [(ngModel)]="apbInputsStore.state.selectedAnnulus"
        (onChange)="onAnnulusSelected($event)"
        optionLabel="name"
      >
      </p-listbox>
    </div>
    <div class="col-7">
      <label for="isVented">Vented Annulus</label>
      <p-inputSwitch
        id="isVented"
        [(ngModel)]="apbInputsStore.state.selectedAnnulus.isVented"
        (onChange)="updateAnnulus(apbInputsStore.state.selectedAnnulus)"
        [disabled]="!apbInputsStore.state?.selectedAnnulus"
      ></p-inputSwitch>
      <br class="breakLine">
      <!-- <p-fieldset legend="Initial Conditions">
        <label for="initialTemp">Initial Temperature</label>
        <p-dropdown
          id="initialTemp"
          [options]="initialTemps"
          [(ngModel)]="initialTemp"
          dataKey="id"
          appendTo="body"
          optionLabel="name"
        >
        </p-dropdown>
      </p-fieldset> -->
      <p-fieldset legend="Initial Conditions">
        <label for="initialTemp">Initial Temperature</label>
        <p-dropdown
          id="initialTemp"
          dataKey="id"
          appendTo="body"
          optionLabel="name"
          [disabled]="true"
          placeholder="UDT"
        >
        </p-dropdown>
      </p-fieldset>
    </div>
    <div class="col-12">
      <p-fieldset legend="Final Conditions">
        <div class="grid">
          <div class="col">
            <div class="field">
              <label for="thermalOperation">Thermal Operation</label>
              <p-dropdown
                id="thermalOperation"
                [options]="thermalOperations"
                [ngModel]="apbData?.finalOperation"
                (onChange)="updateFinalConditions($event.value)"
                dataKey="id"
                appendTo="body"
                optionLabel="name"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
</p-scrollPanel>
