import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
export interface BaseOilsForm {
  name: string;
  type: string;
  a0: number;
  a1: number;
  a2: number;
  b0: number;
  b1: number;
  b2: number;
}
@Component({
  selector: 'app-base-oils',
  templateUrl: './base-oils.component.html',
  styleUrls: ['./base-oils.component.scss'],
  providers: subformComponentProviders(BaseOilsComponent)
})
export class BaseOilsComponent {

  constructor() {}

  public form = createForm<BaseOilsForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      a0: new UntypedFormControl(null, [Validators.required]),
      a1: new UntypedFormControl(null, [Validators.required]),
      a2: new UntypedFormControl(null, [Validators.required]),
      b0: new UntypedFormControl(null, [Validators.required]),
      b1: new UntypedFormControl(null, [Validators.required]),
      b2: new UntypedFormControl(null, [Validators.required])
    }
  });

  public applyDefaults(): void {
    this.form.formGroup.controls.a0.setValue(-3.6058);
    this.form.formGroup.controls.a1.setValue(0.464);
    this.form.formGroup.controls.a2.setValue(-1.6843);

    this.form.formGroup.controls.b0.setValue(8.7071);
    this.form.formGroup.controls.b1.setValue(3.6031);
    this.form.formGroup.controls.b2.setValue(-72.465);
  }
}
