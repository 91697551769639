import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReservoirPerformance, ReservoirPerformanceResult } from '../models/reservoirPerformance';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class ReservoirService {

  constructor(private http: HttpClient) { }

  calculateReservoirPerformance(reservoirPerformance: ReservoirPerformance): Observable<ReservoirPerformanceResult> {
    return this.http.post<ReservoirPerformanceResult>(`${PeriforApiEndpoint}/thermalOperations/reservoirPerformance`, reservoirPerformance);
  }
}
