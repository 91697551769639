export class TrajectoryModel {
    public measuredDepth: number;
    public inclination: number;
    public azimuth: number;
    public doglegSeverity :number;
    public trueVerticalDepth: number;
    public northing: number;
    public easting: number;
}

export class TotalDepthResult {
    public measuredDepth: number;
    public trueVerticalDepth: number;
}

export class TrajectoryPlotUi {
  public constructor(init?: Partial<TrajectoryPlotUi>) {
    this.selectedPlotView = init && init.selectedPlotView || '3d';
    this.isRotating = init && init.isRotating || false;
    this.isShowingProjections = init && init.isShowingProjections || true;
  }
  selectedPlotView: string;
  isRotating: boolean;
  isShowingProjections: boolean;
}
