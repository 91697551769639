import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Subject } from 'rxjs/internal/Subject';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { GreenCementTest, Overpull, PreInstallLoadType, RunInHole, SinglePreInstallLoad } from 'src/app/perivis/models/load-case.model';

export interface SinglePreinstallLoadForm {
  type: PreInstallLoadType,
  overpull: Overpull,
  runInHole: RunInHole
  greenCementTest: GreenCementTest
}

@Component({
  selector: 'app-single-pre-install-load',
  templateUrl: './single-pre-install-load.component.html',
  styles: [`
    label {
      font-size: .70em;
      height: 10px;
      margin-bottom: 0px;
      user-select: none;
    }

    ::ng-deep .p-dropdown {
      height: 25px;
      width: 100%;
    }

    ::ng-deep .p-dropdown .p-dropdown-label {
      padding-top: 4px !important;
    }

    ::ng-deep .p-inputgroup-addon {
      font-size: .70em;
      height: 25px;
      user-select: none;
      color: white;
    }
  `],
  providers: subformComponentProviders(SinglePreInstallLoadComponent)
})
export class SinglePreInstallLoadComponent implements OnChanges {

  @Input() tabType: string;
  // tslint:disable-next-line: no-output-rename
  @Output('typeChanged') typeChangeEmitter: EventEmitter<any> = new EventEmitter<any>();

  private input$: Subject<SinglePreInstallLoad | null | undefined> = new Subject();
  @Input() set preInstallLoad(preInstallLoad: SinglePreInstallLoad | undefined) {
    this.input$.next(preInstallLoad);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Input() userUnits: UserUnitsModel;
  @Input() isCemented: boolean;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  public loadTypes: any[];
  public LoadType: typeof PreInstallLoadType = PreInstallLoadType;

  constructor() { }

  @Output() singlePreInstallLoadUpdate: EventEmitter<SinglePreInstallLoad> = new EventEmitter();

  ngOnChanges(): void {
    this.loadTypes = [
      { name: 'Overpull', value: 'Overpull' },
      { name: 'Run In Hole', value: 'RunInHole' }
    ];

    if (this.isCemented) {
      this.loadTypes.push({ name: 'Green Cement Test', value: 'GreenCementTest' });
    } else {
      this.loadTypes.push({ name: 'Pre-Packer Pressure Test', value: 'GreenCementTest' });
    }
  }

  public form = createForm<SinglePreInstallLoad, SinglePreinstallLoadForm>(this, {
    formType: FormType.SUB,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.singlePreInstallLoadUpdate,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      overpull: new UntypedFormControl(null),
      runInHole: new UntypedFormControl(null),
      greenCementTest: new UntypedFormControl(null)
    },
    toFormGroup: (obj: SinglePreInstallLoad | null): SinglePreinstallLoadForm => {
      if (!obj) {
        return null;
      }

      return {
        overpull: obj.type == PreInstallLoadType.OVERPULL ? obj : null,
        runInHole: obj.type == PreInstallLoadType.RUNINHOLE ? obj : null,
        greenCementTest: obj.type == PreInstallLoadType.GREENCEMENTTEST ? obj : null,
        type: obj.type
      }
    },
    fromFormGroup: (formValue: SinglePreinstallLoadForm | null): SinglePreInstallLoad => {
      const { overpull, runInHole, greenCementTest, type, ...commonValues } = formValue;

      switch (type) {
        case PreInstallLoadType.OVERPULL:
          return overpull ? { type, ...overpull } : null;
        case PreInstallLoadType.RUNINHOLE:
          return runInHole ? { type, ...runInHole } : null;
        case PreInstallLoadType.GREENCEMENTTEST:
          return greenCementTest ? { type, ...greenCementTest } : null;
        case null:
          return null;
        default:
      }
    }
  })
}