<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="loads">
    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>
    <div class="grid">
      <div class="col-5">
        <p-listbox
          #listbox
          [options]="loadCases"
          [(ngModel)]="selectedLoad"
          optionLabel="name"
          (onChange)="onLoadSelect($event)"
        >
          <ng-template pTemplate="header">
            <p-button
              class="p-button-success p-button-sm addLoad"
              label="Add Load"
              (click)="addLoad()"
            ></p-button>
          </ng-template>
          <ng-template let-load pTemplate="item">
            <div class="grid grid-nogutter load-item align-items-center">
              <div class="col-8">{{ load.name }}</div>
              <div class="col buttonsClass flex justify-content-end">
                <i
                class="
                  pi pi-copy
                  p-button p-button-text p-button-sm p-button-info
                  cloneBtn"
                pTooltip="Clone Load"
                (click)="cloneLoad(load)"
              ></i>
                <button
                  class="pi pi-trash p-button p-button-text p-button-sm p-button-danger deleteBtn"
                  (click)="deleteLoad(load)"
                ></button>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
      <div class="col-7">
        <div class="flex-1">
          <app-tool-loader [positionUnset]="true">
            <app-pre-install-data
              [disabled]="loadCases?.length === 0"
              [preInstallLoad]="selectedLoadDetails$ | async"
              (preInstallLoadUpdated)="updateOrCreateLoad($event)"
              [userUnits]="userUnits"
              [isCemented]="isCemented"
              [shoeMd]="shoeDepth"
              [hangerMd]="hangerMd"
              [annularFLuidDensity]="annularFLuidDensity"
            ></app-pre-install-data>
          </app-tool-loader>
        </div>
      </div>
    </div>
  </div>
</p-scrollPanel>