<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <div class="reservoirPerf">
            <p-dialog modal="true" [(visible)]="calculating" [draggable]="false">
                <i class="pi pi-exclamation-circle"></i> Please wait while Reservoir Performance is being calculated <br />
                <br />
            </p-dialog>

            <div class="grid">
                <div class="col">
                    <form *ngIf="reservoirPerformanceForm && userUnits" [formGroup]="reservoirPerformanceForm" bg-dark>
                        <p-fieldset legend="Input Configuration">
                            <div class="field">
                                <label for="type">Operation Type</label>
                                <p-dropdown
                                  [options]="operationTypes"
                                  formControlName="operationType"
                                  optionLabel="name"
                                  appendTo="body"
                                  optionValue="value"
                                  (onChange)="selectedOperationChange($event)"
                                  [autoDisplayFirst]="false"
                                  placeholder="Select Operation Type"
                                ></p-dropdown>
                              </div>

                              <div class="field">
                                <label>Fluid</label>
                                <p-dropdown
                                  [options]="fluids"
                                  optionLabel="state.name"
                                  appendTo="body"
                                  (onChange)="selectedFluidChange($event)"
                                ></p-dropdown>
                              </div>

                              <div class="field">
                                <label>Gas Flow Rate</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="gasFlowRate"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ flowRateUnit }}</span>
                                </div>
                                <p
                                  *ngIf="
                                    reservoirPerformanceForm?.controls.gasFlowRate.value &&
                                    reservoirPerformanceForm?.controls.gasFlowRate.invalid
                                  "
                                  class="error"
                                >
                                  Allowed range: {{ gasFlowRateValidation.min }} -
                                  {{ gasFlowRateValidation.max }}
                                </p>
                              </div>

                              <div *ngIf="selectedOperationType == 'injection'" class="field">
                                <label>Bottomhole Temperature</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="bottomholeTemperature"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                                </div>
                                <p
                                  *ngIf="
                                    reservoirPerformanceForm?.controls.bottomholeTemperaturevalue &&
                                    reservoirPerformanceForm?.controls.bottomholeTemperatureinvalid
                                  "
                                  class="error"
                                >
                                  Allowed range: {{ temperatureValidation.min }} -
                                  {{ temperatureValidation.max }}
                                </p>
                              </div>

                              <div *ngIf="selectedOperationType == 'production'" class="field">
                                <label>Reservoir Temperature</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="reservoirTemperature"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                                </div>
                                <p
                                  *ngIf="
                                    reservoirPerformanceForm?.controls.bottomholeTemperaturevalue &&
                                    reservoirPerformanceForm?.controls.bottomholeTemperatureinvalid
                                  "
                                  class="error"
                                >
                                  Allowed range: {{ temperatureValidation.min }} -
                                  {{ temperatureValidation.max }}
                                </p>
                              </div>

                              <div class="field">
                                <label>Bottomhole Diameter</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="bottomholeDiameter"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ userUnits?.shortLengths }}</span>
                                </div>
                              </div>
                        </p-fieldset>
                        <br>
                        <p-fieldset legend="Reservoir">
                            <div class="field">
                                <label>Pressure</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="reservoirPressure"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ userUnits?.pressure }}</span>
                                </div>
                                <p
                                  *ngIf="
                                    reservoirPerformanceForm?.controls.reservoirPressure.value &&
                                    reservoirPerformanceForm?.controls.reservoirPressure.invalid
                                  "
                                  class="error"
                                >
                                  Allowed range: {{ pressureValidation.min }} -
                                  {{ pressureValidation.max }}
                                </p>
                              </div>

                              <div class="field">
                                <label>Permeability</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="permeability"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">mD</span>
                                </div>
                              </div>

                              <div class="field">
                                <label>Height</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="height"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ userUnits?.longLengths }}</span>
                                </div>
                              </div>

                              <div class="field">
                                <label>Radial Extent</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="radialExtent"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ userUnits?.longLengths }}</span>
                                </div>
                              </div>

                              <div class="field">
                                <label>Skin Factor</label>
                                <div class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    formControlName="skinFactor"
                                  ></p-inputNumber>
                                </div>
                              </div>

                        </p-fieldset>
                        
                        <br>
                        <p-button
                          [disabled]="reservoirPerformanceForm?.invalid"
                          class="calculateButton w-full flex justify-content-center"
                          styleClass="p-button-sm p-button-warning"
                          label="Calculate"
                          (onClick)="calculateReservoirPerformance()"
                          ></p-button>
                    </form>
                </div>

                <div class="col">
                    <p-fieldset legend="Performance Output">
                        <div>
                            <div class="field">
                                <label>Bottomhole Pressure</label>
                                <div disabled="true" class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    [disabled]="true"
                                    placeholder="{{ reservoirPerformanceResult?.bottomholePressure }}"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">{{ userUnits?.pressure }}</span>
                                </div>
                              </div>
                        </div>

                        <div>
                            <div class="field">
                                <label>Performance Index (J)</label>
                                <div disabled="true" class="p-inputgroup">
                                  <p-inputNumber
                                    [minFractionDigits]="1"
                                    [disabled]="true"
                                    placeholder="{{ reservoirPerformanceResult?.performanceIndex }}"
                                  ></p-inputNumber>
                                  <span class="p-inputgroup-addon">ft³/hr/psi</span>
                                </div>
                              </div>
                        </div>

                        <div class="field">
                            <label>Effective Mass Flow Rate</label>
                            <i
                              title="Production (+) / Injection (-)"
                              class="pi pi-info-circle tooltip-icon"
                              style="padding-left: 5px;"
                            ></i>
                            <div class="p-inputgroup">
                              <p-inputNumber
                                [minFractionDigits]="1"
                                [disabled]="true"
                                placeholder="{{
                                  reservoirPerformanceResult?.effectiveMassFlowRate
                                }}"
                              ></p-inputNumber>
                              <span class="p-inputgroup-addon">{{ massFlowUnit }}</span>
                            </div>
                          </div>

                            <div class="field">
                              <label>Reservoir Phase</label>
                              <div class="p-inputgroup">
                                <p-inputNumber
                                  [minFractionDigits]="1"
                                  [disabled]="true"
                                  placeholder="{{ reservoirPerformanceResult?.reservoirPhase }}"
                                ></p-inputNumber>
                              </div>
                            </div>

                            <div class="field">
                              <label>Reservoir Vapor Quality</label>
                              <div class="p-inputgroup">
                                <p-inputNumber
                                  [minFractionDigits]="1"
                                  [disabled]="true"
                                  placeholder="{{ reservoirPerformanceResult?.reservoirVaporQuality }}"
                                ></p-inputNumber>
                              </div>
                            </div>
                    </p-fieldset>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</app-tool-loader>