<div class="formatAxis">
  <p-button
    title="Format Axis"
    class="p-button-success p-button-sm"
    (click)="expandFormatAxis()"
    icon="pi pi-chart-bar"
  ></p-button>
</div>

<p-toolbar *ngIf="showFormatAxis">
  <div *ngIf="!legendBelowPlot" class="col">
    <p-fieldset legend="X Axis Options">
      <div class="grid">
        <div class="col">
          <label>Min</label>
          <div class="p-inputgroup">
            <input [ngModel]="xAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="xAxisRangePlaceholder[0]" (change)="changeAxis($event, 'xAxisRange', 0)"/>
          </div>

          <label>Max</label>
          <div class="p-inputgroup">
            <input [ngModel]="xAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="xAxisRangePlaceholder[1]" (change)="changeAxis($event, 'xAxisRange', 1)"/>
          </div>
        </div>

        <div class="col">
          <label>Tick Spacing</label>
          <div class="p-inputgroup">
            <input [ngModel]="xAxisTicksPlaceholder" pInputText [type]="'number'" (change)="changeTicks($event, 'xAxis')"/>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>
  
  <div class="col">
    <p-fieldset legend="Y Axis Options">
      <div class="grid">
        <div class="col">
          <label>Min</label>
          <div class="p-inputgroup">
            <input *ngIf="yAxisRangePlaceholder[0] > yAxisRangePlaceholder[1]" [ngModel]="yAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="yAxisRangePlaceholder[1]" (change)="changeAxis($event, 'yAxisRange', 1)"/>
            <input *ngIf="yAxisRangePlaceholder[0] < yAxisRangePlaceholder[1]" [ngModel]="yAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="yAxisRangePlaceholder[0]" (change)="changeAxis($event, 'yAxisRange', 0)"/>
          </div>

          <label>Max</label>
          <div class="p-inputgroup">
            <input *ngIf="yAxisRangePlaceholder[0] > yAxisRangePlaceholder[1]" [ngModel]="yAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="yAxisRangePlaceholder[0]" (change)="changeAxis($event, 'yAxisRange', 0)"/>
            <input *ngIf="yAxisRangePlaceholder[0] < yAxisRangePlaceholder[1]" [ngModel]="yAxisRangePlaceholder" pInputText [type]="'number'" [placeholder]="yAxisRangePlaceholder[1]" (change)="changeAxis($event, 'yAxisRange', 1)"/>
          </div>
        </div>

        <div class="col">
          <label>Tick Spacing</label>
          <div class="p-inputgroup">
            <input [ngModel]="yAxisTicksPlaceholder" pInputText [type]="'number'" (change)="changeTicks($event, 'yAxis')"/>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>

  <!-- <div class="p-inputgroup"> -->
    <p-button label="Reset" class="p-button-success p-button-sm" (click)="resetFormatAxis()" icon="pi pi-refresh"></p-button>
  <!-- </div> -->
</p-toolbar>

<div (wheel)="onScroll()" [style]="{position: 'relative', height: plotHeight, width: '100%'}" #plotElement class="plot">
  <plotly-plot
    (doubleClick)="resetFormatAxis()"
    [divId]="plotName"
    [data]="plot.data"
    [layout]="plot.layout"
    [config]="plot.config"
    [updateOnDataChange]="true"
    [useResizeHandler]="true"
    [style]="{ position: 'relative', width: '100%', height: '100%' }"
  ></plotly-plot>
</div>

<p-dialog
  [modal]="true"
  class="container"
  [draggable]="false"
  [(visible)]="showScrollOverlay"
  [closable]="false"
>
  <i class="pi pi-exclamation-circle"></i> Use Shift+Scroll to zoom in <br />
  <br />
</p-dialog>