import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { SingleLoadResultsUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';
import {isEqual} from "lodash";

@Component({
  selector: 'app-single-load-collapse',
  templateUrl: './single-load-collapse.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }

    label {
      padding-left: 5px;
    }
  `]
})
export class SingleLoadCollapseComponent implements OnInit, OnDestroy {

  private _collapseResults: any[];
  private _subscriptions: Subscription;

  public loadCases: any[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' }
  ];
  public errorMsg: string;
  public results: any[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle: string = '';
  public xAxisTitle: string = '';
  public tableName: string;
  public userUnits: UserUnitsModel;
  public plotName: string = 'barlowCollapsePlot';
  public signalRChange: boolean;
  public longLengthUnit: string;

  public columnDefs: Array<{ header: string, field: string }>;
  public columnDefinitions: Array<any>;

  public tableHeight: string;
  public componentHeight: number;

  @ViewChild('toolbarDiv', {static: false}) toolbarDiv: ElementRef;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.collapseResultsStore = this._storeService.createStore(this.componentId, new SingleLoadResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public collapseResultsStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("API Collapse Results")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnit = this.userUnits.longLengths;
    this.xAxisTitle = `Collapse Pressure (${this.userUnits.pressure})`;
    this.yAxisTitle = `MD (${this.longLengthUnit})`;

    this.refreshResults(true, false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.columnDefs = [
      { header: 'Collapse Load', field: 'collapseLoad' },
      { header: 'API Collapse Rating', field: 'apiCollapseRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Collapse Load (${this.userUnits.pressure})`, field: 'collapseLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `API Collapse (${this.userUnits.pressure})`, field: 'apiCollapseRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true, true);
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode?: boolean): Promise<void> {
    this.isLoading = isLoading;

    this.xAxisTitle = `Collapse Pressure (${this.userUnits?.pressure})`;

    if ((!this._collapseResults || calledFromSignalR) && !calledFromMode) {
      try {
        this._collapseResults = await lastValueFrom(this._stressResultsService.getCollapseResults());
        this.signalRChange = true;
      } catch (ex) {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._collapseResults && (this._collapseResults.length === 0 || this._collapseResults[0].loadCaseName === '')) {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    if (this.collapseResultsStore.state.selectedMode == "single") {
      this.handleSingleCollapseLoad(this._collapseResults);
    } else {
      this.handleMultiCollapseLoad(this._collapseResults)
    }

    this.isLoading = false;
  }

  onModeChange(e): void {
    this.collapseResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (this.collapseResultsStore.state.selectedMode == "multiple") {
      this.collapseResultsStore.update(state => ({ ...state, selectedLoadCase: { label: 'Collapse Load', value: 'collapseLoad' } }));
    } else {
      // this.xAxisTitle = `Collapse Pressure (psi)`;
    }
    this.refreshResults(true, false, true);
  }

  onSelectedResultDisplayUpdated(e): void {
    this.collapseResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  setLoadCase(e): void {
    if (this.collapseResultsStore.state.mode === "single") {
      this.collapseResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.results = e.value.value.collapseResults;
    } else {
      this.collapseResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.refreshResults(true, false, false);
    }
  }

  public depthViewToggle(e) {
    this.collapseResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private handleSingleCollapseLoad(collapseResults: Array<any>) {
    this.columnDefs = [
      { header: 'Collapse Load', field: 'collapseLoad' },
      { header: 'API Collapse Rating', field: 'apiCollapseRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Collapse Load (${this.userUnits?.pressure})`, field: 'collapseLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `API Collapse (${this.userUnits?.pressure})`, field: 'apiCollapseRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];

    this.results = [];

    this.loadCases = collapseResults?.map(lc => {
      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (!this.collapseResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.collapseResultsStore.state.selectedLoadCase))) {
      this.collapseResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.results = this.collapseResultsStore.state.selectedLoadCase?.value.collapseResults || [];
  }

  private handleMultiCollapseLoad(collapseResults: Array<any>) {
    this.columnDefinitions.splice(2, this.columnDefinitions.length);
    this.columnDefs = [];
    this.results = [];

    this.loadCases = [
      { label: 'Collapse Load', value: 'collapseLoad' },
      { label: 'API Collapse Rating', value: 'apiCollapseRating' }
    ]

    this.xAxisTitle = this.collapseResultsStore.state.selectedLoadCase.value === 'collapseLoad' ? `Collapse Load (${this.userUnits.pressure})` : `API Collapse Rating (${this.userUnits.pressure})`;

    collapseResults.forEach(load => {
      const loadName: string = load.loadCaseName + ` (${this.userUnits.pressure})`;
      const loadNameCamel = toCamelCaseString(load.loadCaseName);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName,
        field: loadNameCamel,
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      });

      let backupArray = [];

      load.collapseResults.forEach(loadResult => {
        let result = {
          [loadNameCamel]: loadResult[this.collapseResultsStore.state.selectedLoadCase.value],
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (collapseResults[0].loadCaseName === load.loadCaseName) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        let otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });
    });
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
