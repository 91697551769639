<form [formGroup]="form.formGroup" bg-dark>
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Operation Details </ng-template>
      <div class="grid">
        <div class="col-6">
          <div class="field">
            <label for="fluids">Fluid</label>
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="fluids"
              [formControlName]="form.formControlNames.fluid"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.fluid?.state.name }}
              </ng-template>
              <ng-template let-fluid pTemplate="item">
                {{ fluid.state.name }}
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field">
            <label for="perforation">Perforation</label>
            <p-dropdown
            [disabled]="isReadOnly"
            [options]="perforations"
              [formControlName]="form.formControlNames.perforation"
              optionLabel="name"
              dataKey="id"
              appendTo="body"
              (onChange)="perfChange($event)"
            ></p-dropdown>
          </div>
          <div class="field">
            <label for="pressure">Pressure Location</label>
            <div class="p-inputgroup">
              <p-dropdown
              [disabled]="isReadOnly"
              [options]="pressureLocations"
                [formControlName]="form.formControlNames.pressureLocation"
                optionLabel="name"
                optionValue="value"
                appendTo="body"
                class="inputGroupDropdown"
              ></p-dropdown>
              <input
                id="pressure"
                pInputText
                [formControlName]="form.formControlNames.perforationPressure"
                required
              />
              <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.perforationPressure.value &&
                form.formGroup.controls.perforationPressure.invalid
              "
              class="error"
            >
              Allowed range: {{ pressureValidation.min }} -
              {{ pressureValidation.max }} {{ pressureUnit }}
            </p>
          </div>
          <div class="field">
            <label for="tempAtPerfs">Temperature at Perfs</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.tempAtPerfs"
              />
              <span class="p-inputgroup-addon">{{ tempUnit }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.tempAtPerfs.value &&
                form.formGroup.controls.tempAtPerfs.invalid
              "
              class="error"
            >
              Allowed range: {{ temperatureValidation.min }} -
              {{ temperatureValidation.max }} {{ tempUnit }}
            </p>
          </div>
          <br />
          <!-- <p-fieldset *ngIf="false" class="fieldset">
            <ng-template pTemplate="header">
              <input
                style="height: 13px"
                type="checkbox"
                binary="true"
                (change)="isShutinChange($event)"
                [checked]="isShutInCheck"
              />
              <label
                class="shutinLabel"
                style="font-size: 11px; padding-left: 5px"
                >Shut-In After Production</label
              >
            </ng-template>

            <div class="field">
              <label for="time">Shut-in Time</label>
              <div class="p-inputgroup">
                <input
                  type="number"
                  pInputText
                  [formControlName]="form.formControlNames.time"
                />
                <span class="p-inputgroup-addon">hr</span>
              </div>
            </div>
            <div class="field">
              <label for="pressure">Pressure Location</label>
              <div class="p-inputgroup">
                <p-dropdown
          [disabled]="shutinTimeDisabled || isReadOnly"
                  [options]="pressureLocations"
                  [formControlName]="form.formControlNames.location"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body"
                  class="inputGroupDropdown"
                ></p-dropdown>
                <input
                  type="number"
                  pInputText
                  [formControlName]="form.formControlNames.pressure"
                />
                <span class="p-inputgroup-addon">psi</span>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.pressure.value &&
                  form.formGroup.controls.pressure.invalid
                "
                class="error"
              >
                Allowed range: 0 - 100000 psi
              </p>
            </div>
          </p-fieldset> -->
          <!-- <br /> -->
        </div>
        <div class="col-6">
          <div [ngSwitch]="true">
            <app-standard-fluid
              *ngSwitchCase="
                form.formGroup.value.fluid?.state.type === FluidType.BRINE ||
                form.formGroup.value.fluid?.state.type ===
                  FluidType.STANDARDMUD ||
                form.formGroup.value.fluid?.state.type === FluidType.ADVANCEDMUD
              "
              [formControlName]="form.formControlNames.standardFluidFlow"
              [userUnits]="userUnits"
            ></app-standard-fluid>
            <app-hydrocarbon-fluid
            [userUnits]="userUnits" [isReadOnly]="isReadOnly"
              *ngSwitchCase="
                form.formGroup.value.fluid?.state.type === FluidType.BLACKOIL ||
                form.formGroup.value.fluid?.state.type ===
                  FluidType.HYDROCARBON ||
                form.formGroup.value.fluid?.state.type === FluidType.VLE ||
                form.formGroup.value.fluid?.state.type === FluidType.CO2FLUID
              "
              [formControlName]="form.formControlNames.hydrocarbonFlow"
              [selectedFluid]="form.formGroup.value.fluid"
            ></app-hydrocarbon-fluid>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</form>
