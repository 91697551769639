import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function measuredDepthMustBeGreaterThanPrevious(control: UntypedFormArray) {
   let currentArray = control.controls as Array<UntypedFormGroup>;
   let errorMsg = "";
   if (currentArray.length > 1) {
      var isAscending = a => a.slice(1)
                        .map((e,i) => e > a[i])
                        .every(x => x);

      let btMds = currentArray.map(c=> c.controls['bottomMeasuredDepth'].value);
      let mdIsAscending =  isAscending(btMds);
      if (!mdIsAscending) {
         errorMsg += `Bottom depths must be in increasing order \n`;
      }
      return errorMsg ? { error: errorMsg } : null;
   }
   return null;
}
