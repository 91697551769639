import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { WellType } from 'src/app/shared/models/wellType.model';
import { map } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
    providedIn: 'root'
})
export class WellTypeService {

    constructor(private _http: HttpClient) { }

    getWellType(skipUnits: boolean = false): Observable<WellType> {
      return this._http.get<WellType>(`${PeriforApiEndpoint}/wellType?skipUnits=${skipUnits}`).pipe(map(data => new WellType(data)));
    }

    updateWellType(wellType: WellType) {
      return this._http.put(`${PeriforApiEndpoint}/wellType`, wellType);
    }
}
