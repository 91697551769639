import { GradeCatalog } from "./grade-catalog.model";
import { PipesCatalog } from "./pipes-catalog.model";

export class ConnectionCatalog {
    public constructor(init?: Partial<ConnectionCatalog>) {
      Object.assign(this, init);
      this.grade = init && init.grade ? new GradeCatalog(init.grade) : null;
      this.tubularPipe = init && init.tubularPipe ? new PipesCatalog(init.tubularPipe) : null;
    }

    id: string;
    name: string;
    tubularPipe: PipesCatalog;
    grade: GradeCatalog;
    outerDiameter: number;
    innerDiameter: number;
    burstRating: number;
    collapseRating: number;
    tensionRating: number;
    compressionRating: number;

    tubularPipeLookup: Array<PipesCatalog>;
  }
