<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="grid grid-nogutter">
    <form *ngIf="stressOptionsForm" [formGroup]="stressOptionsForm" bg-dark class="w-full">
      <div class="field col-6 verticalSpacingClass">
        <label for="maximumGridSpacing">Vertical Grid Spacing</label>
        <div class="p-inputgroup">
          <input
            onfocus="this.select()"
            type="number"
            required
            pInputText
            formControlName="maximumGridSpacing"
            id="maximumGridSpacing"
          />
          <span class="p-inputgroup-addon">{{ verticalGridUnit }}</span>
        </div>
        <p
          *ngIf="stressOptionsForm.controls.maximumGridSpacing.invalid"
          class="error"
        >
          Allowed range: {{ this.gridSpacingValidation.min }} -
          {{ this.gridSpacingValidation.max }}
        </p>
      </div>

      <div class="field col-12">
        <label for="maximumGridSpacing">API TR 5C3 Collapse Formula:</label>
        <p-dropdown
          formControlName="apiCollapseType"
          id="apiCollapseType"
          [options]="apiCollapse"
          appendTo="body"
        ></p-dropdown>
      </div>

      <p-fieldset class="bucklingClass" legend="Buckling Parameters">
        <div class="field col-12">
          <label for="helicalFactorType"
            >Critical Ratio for Transition to Helical Buckling:</label
          >
          <div class="p-inputgroup">
            <p-dropdown
              formControlName="helicalFactorType"
              id="helicalFactorType"
              [options]="helicalFactorTypes"
              appendTo="body"
              class="w-full"
            ></p-dropdown>
            <input
              onfocus="this.select()"
              [attr.disabled]="true"
              type="number"
              required
              pInputText
              formControlName="helicalBucklingFactor"
              id="helicalBucklingFactor"
            />
          </div>
          <p
            *ngIf="stressOptionsForm.controls.helicalBucklingFactor.invalid"
            class="error"
          >
            Allowed range: 1 - 3
          </p>
        </div>
        <div class="kyllingstadClass">
          <p-checkbox
            label=" He-Kyllingstad Buckling Dogleg Correlation"
            formControlName="useHeKyllingstad"
            id="useHeKyllingstad"
            binary="true"
          ></p-checkbox>
        </div>
      </p-fieldset>

      <br />

      <p-fieldset legend="Dynamic Friction Model" class="dfm">
        <div class="field col-6">
          <label for="frictionFactor">Friction Factor</label>
          <input
            onfocus="this.select()"
            type="number"
            required
            pInputText
            formControlName="frictionFactor"
            id="frictionFactor"
          />
        </div>

        <p-fieldset legend="Damping Type">
          <div class="field-radiobutton">
            <p-radioButton
              label="Artificial Damping"
              name="dampingType"
              formControlName="dampingType"
              value="artificialDamping"
            ></p-radioButton>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              label="Fluid Damping"
              name="dampingType"
              formControlName="dampingType"
              value="fluidDamping"
            ></p-radioButton>
          </div>
        </p-fieldset>
      </p-fieldset>
    </form>
  </div>
</app-tool-loader>
