<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader
    class="wellType"
    [isLoading]="isLoading"
    positionUnset="true"
  >
    <form [formGroup]="wellTypeForm" bg-dark>
      <div class="grid grid-nogutter">
        <div class="col-12">
          <p-dropdown
            formControlName="type"
            [options]="wellTypes"
          ></p-dropdown>
          <br>
          <br>
          <p-fieldset legend="Elevation">
            <div class="field">
              <label for="drillFloorElevation">
                Drill Floor Elevation {{ depthRelativeTo }}
              </label>
              <p-inputNumber
                id="drillFloorElevation"
                formControlName="drillFloorElevation"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
            </div>

            <div
              *ngIf="wellTypeForm?.get('type').value != 'Subsea'"
              class="field"
            >
              <label for="wellheadElevation">
                {{ wellheadType }} {{ wellheadElevationRelativeTo }}
              </label>
              <p-inputNumber
                id="wellheadElevation"
                formControlName="wellheadElevation"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
            </div>

            <div
              *ngIf="wellTypeForm.get('type').value == 'Subsea'"
              class="field"
            >
              <label for="wellheadDepth">
                {{ wellheadType }} {{ wellheadElevationRelativeTo }}
              </label>
              <p-inputNumber
                id="wellheadDepth"
                formControlName="wellheadDepth"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
            </div>
          </p-fieldset>
          <br>
          <p-fieldset
            class="offshoreFieldset"
            *ngIf="waterDepthVisible"
            legend="Offshore"
          >
            <div class="field">
              <label for="waterDepth">
                Water Depth ({{ lengthUnit }})
              </label>
              <p-inputNumber
                id="waterDepth"
                formControlName="waterDepth"
                [minFractionDigits]="1"
                class="w-full"
              ></p-inputNumber>
              <label>
                Mudline: {{ mudlineDepth }} ({{ lengthUnit }})
              </label>
            </div>
          </p-fieldset>
        </div>
      </div>
    </form>
  </app-tool-loader>
</p-scrollPanel>
