import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
  selector: 'app-single-load-grid',
  template: `
    <ng-table-results-cmp
      [tableName]="tableName"
      [tableData]="rowData"
      [designFactors]="designFactors"
      [columnDefinitions]="columnDefinitions"
      [tableHeight]="tableHeight"
      [selectedOperation]="selectedLoad"
      [apiCollapseType]="apiCollapseType"
      [hangerLiftoffForce]="hangerLiftoffForce"
      [virtualScroll]="true"
      [mode]="mode"
    ></ng-table-results-cmp>
  `
})
export class SingleLoadGridComponent implements OnDestroy {

  @Input()
  public gridName: string;

  @Input()
  public tableName:string;

  @Input()
  public rowData: Array<any>;

  @Input()
  public designFactors: any;

  @Input()
  public apiCollapseType: string;

  @Input()
  public hangerLiftoffForce: number;

  @Input()
  public selectedLoad: string;

  @Input()
  public mode: string;

  @Input()
  public columnDefinitions: Array<{ field: string, header: string, valueFormatter(params: any): string }>;

  @Input()
  public tableHeight: string;

  private _subscriptions: Subscription;

  constructor(private _messenger: MediatorService) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == this.gridName) {
        this.tableHeight = (e.itemHeight - 100) + 'px';
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}
