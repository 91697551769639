import { SelectItem } from "primeng/api";

export class PressTempUi {
  public constructor(init?: Partial<PressTempUi>) {
    this.selectedResultsView = init && init.selectedResultsView || 'internalPressurePoints';
    this.selectedDepthView = init && init.selectedDepthView || 'tvd';
  }
  selectedResultsView: string;
  selectedDepthView: string;
}

export class DesignLimitsPlotUi {
  public constructor(init?: Partial<DesignLimitsPlotUi>) {
    this.selectedStringSection = init && init.selectedStringSection || null;
  }
  selectedStringSection: any;
}

export class LoadPressureResultsUi {
  public constructor(init?: Partial<LoadPressureResultsUi>) {
    this.selectedResultsView = init && init.selectedResultsView || 'internalPressure';
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'plot';
    this.selectedDepthView = init && init.selectedDepthView || 'tvd';
  }
  selectedResultsView: string;
  selectedResultDisplay: string;
  selectedDepthView: string;
}

export class SingleLoadResultsUi {
  public constructor(init?: Partial<SingleLoadResultsUi>) {
    this.selectedMode = init && init.selectedMode || 'single';
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'plot';
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.selectedLoadCase = init && init.selectedLoadCase || null;
  }
  selectedMode: string;
  selectedResultDisplay: string;
  selectedDepthView: string;
  selectedLoadCase: SelectItem;
}

export class DisplacementResultsUi {
  public constructor(init?: Partial<SingleLoadResultsUi>) {
    this.selectedMode = init && init.selectedMode || 'single';
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'grid';
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.selectedLoadCase = init && init.selectedLoadCase || null;
  }
  selectedMode: string;
  selectedResultDisplay: string;
  selectedDepthView: string;
  selectedLoadCase: SelectItem;
}

export class SafetyFactorResultsUi {
  public constructor(init?: Partial<SafetyFactorResultsUi>) {
    this.selectedMode = init && init.selectedMode || 'single';
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'plot';
    this.includeCharacters = init && init.includeCharacters || true;
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.selectedLoadCase = init && init.selectedLoadCase || null;
  }
  selectedMode: string;
  selectedResultDisplay: string;
  includeCharacters: boolean;
  selectedDepthView: string;
  selectedLoadCase: SelectItem;
}

export class ConnectionSafetyFactorResultsUi {
  public constructor(init?: Partial<ConnectionSafetyFactorResultsUi>) {
    this.selectedMode = init && init.selectedMode || 'single';
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'plot';
    this.includeCharacters = init && init.includeCharacters || true;
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.selectedLoadCase = init && init.selectedLoadCase || null;
  }
  selectedMode: string;
  selectedResultDisplay: string;
  includeCharacters: boolean;
  selectedDepthView: string;
  selectedLoadCase: SelectItem;
}

export class LiftOffResultsUi {
  public constructor(init?: Partial<LiftOffResultsUi>) {
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'plot';
  }
  selectedResultDisplay: string;
}
