<div class="pipes-catalog">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form [formGroup]="pipesForm" novalidate>
      <ng-container formArrayName="pipes">
        <div class="grid">
          <div class="col">
            <div class="field">
              <label for="fluids">Type</label>
              <p-dropdown
                (onChange)="getPipes($event.value)"
                [options]="pipeTypes"
                [(ngModel)]="pipesCatalogStore.state.selectedType"
                appendTo="body"
                [ngModelOptions]="{standalone: true}"
              ></p-dropdown>
            </div>
          </div>
          <div class="col">
            <div class="field">
              <label for="fluids">Pipe OD Filter ({{ diameterUnit }})</label>
              <p-dropdown
                (onChange)="filterByOd($event)"
                [options]="pipeOds"
                [(ngModel)]="pipesCatalogStore.state.selectedOd"
                appendTo="body"
                title="Pipe OD ({{ diameterUnit }})"
                [ngModelOptions]="{standalone: true}"
              ></p-dropdown>
            </div>
          </div>
        </div>

        <p-table
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="pTableData"
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          [virtualScroll]="true"
          [virtualScrollItemSize]="34"
          minBufferPx="800"
          maxBufferPx="1800"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="'pipesCatalogTable@' + componentId"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn>
                {{ col.header }}
              </th>
              <th>
                <p-button
                  [disabled]="pipesCatalog.invalid"
                  label="Add"
                  class="p-button-success p-button-sm addButton"
                  (click)="onAddPipe()"
                  title="Add Pipe"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-pipeFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr style="height: 34px">
                <td>
                  <p-inputNumber
                    formControlName="outsideDiameter"
                    [minFractionDigits]="1"
                    title="Outside Diameter"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(pipeFg, 'outsideDiameter')"
                    [errorMsg]="getControlErrors(pipeFg, 'outsideDiameter')"
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="insideDiameter"
                    [minFractionDigits]="1"
                    title="Inside Diameter"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(pipeFg, 'insideDiameter')"
                    [errorMsg]="getControlErrors(pipeFg, 'insideDiameter')"
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="weightPerFoot"
                    [minFractionDigits]="1"
                    title="Weight Per Foot"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(pipeFg, 'weightPerFoot')"
                    [errorMsg]="getControlErrors(pipeFg, 'weightPerFoot')"
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="driftDiameter"
                    [minFractionDigits]="2"
                    title="Drift"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(pipeFg, 'driftDiameter')"
                    [errorMsg]="getControlErrors(pipeFg, 'driftDiameter')"
                  ></validation-error>
                </td>
                <td>
                  <p-inputNumber
                    formControlName="wallThickness"
                    [minFractionDigits]="1"
                    title="Wall Thickness"
                  ></p-inputNumber>
                </td>
                <td style="width: 90px">
                  <button
                    pButton
                    class="p-button-text p-button-sm p-button-danger delete-btn"
                    title="Delete Pipe"
                    icon="pi pi-trash"
                    (click)="onDeletePipe(rIdx)"
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
