import { Component, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GridsterComponentInterface, GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashboardWorkspaceModel, UiComponentModel } from '../models/dashboard.model';
import { Subject, Subscription } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage, OpenSearchComponentDropdown } from 'src/app/shared/models/mediator-messages.model';
import { debounceTime } from 'rxjs/operators';
import { UserRoles } from '../../user-admin-page/user-model';
import { MenuItem } from 'primeng/api';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

/*
  Holds the Grid Workspace that contains UI Components.
*/
@Component({
  selector: 'workspace-cmp',
  templateUrl: './workspace-component.html',
  styleUrls: ['./workspace-component.scss']
})
export class DashboardWorkspaceComponent implements OnInit, OnChanges, OnDestroy {

  private _subscriptions: Subscription;
  private _gridster: GridsterComponentInterface;
  private _gridsterColOpts = { min: 50, max: 50 };
  private _uiComponentUpdatedDebouncer: Subject<Array<UiComponentModel>>;

  public gridOptions: GridsterConfig;
  public userRoles: UserRoles;
  public contextMenuItems: Array<MenuItem>;

  @HostListener('window:resize', ['$event'])
  onResize($event: Event) {
    if ($event.isTrusted) { // User invoked
      this.computeGridColWidth(null);
    }
  }

  @Input()
  public workspace: DashboardWorkspaceModel;

  @Output()
  public onUiComponentsRemoved: EventEmitter<Array<UiComponentModel>>;

  @Output()
  public onUiComponentXyUpdated: EventEmitter<Array<UiComponentModel>>;

  constructor(
    private _messenger: MediatorService,
    private _storeService : StoreService
  ) {
    this._subscriptions = new Subscription();
    this._uiComponentUpdatedDebouncer = new Subject<Array<UiComponentModel>>();

    this.onUiComponentsRemoved = new EventEmitter();
    this.onUiComponentXyUpdated = new EventEmitter();

    this.initializeGridOptions();
  }

  async ngOnInit() : Promise<void> {
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);

    this._uiComponentUpdatedDebouncer
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.onUiComponentXyUpdated.emit(value);
      });

    this.contextMenuItems = [
      { label: 'Clear Workspace', icon: 'pi pi-fw pi-times', command: () => this.removeAllComponents() },
      { label: 'Component Search', icon: 'pi pi-fw pi-search', command: () => this._messenger.publish(new OpenSearchComponentDropdown()) }
    ];

    // this.initializeGridOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workspace && changes.workspace.currentValue) {
      this.computeGridColWidth(null);
    }
  }

  public removeUiComponent(uiComponents: UiComponentModel) {
    this.removeUiComponents([uiComponents]);
  }

  public removeUiComponents(uiComponents: Array<UiComponentModel>) {
    //Removes state key from local storage when component is removed from workspace
    uiComponents.forEach(async uiComponent => {
      await this._storeService.remove(uiComponent.id);
    });
    this.onUiComponentsRemoved.emit(uiComponents);
  }

  private async removeAllComponents() {
    const uiComponents = this.workspace.uiComponents.slice(); // Create a shallow copy to avoid mutating the array while iterating

    this.removeUiComponents(uiComponents);
    
    this.workspace.uiComponents = []; // Clear the components array after removal
  }

  private initializeGridOptions(): void {
    this.gridOptions = {
      initCallback: (ic: GridsterComponentInterface) => this.computeGridColWidth(ic),
      gridType: 'scrollVertical',
      enableEmptyCellDrop: true,
      enableOccupiedCellDrop: true,
      pushItems: true,
      swap: true,
      compactType: 'compactLeft&Up',
      pushDirections: { north: true, east: true, south: true, west: true },
      resizable: { enabled: true },
      itemChangeCallback: (e: GridsterItem, ic: GridsterItemComponentInterface) => this.onGridItemChange(e, ic),
      draggable: {
        enabled: true,
        ignoreContent: true,
        dropOverItems: true,
        dragHandleClass: 'drag-handler',
        ignoreContentClass: 'no-drag',
      },
      displayGrid: 'none',
      minCols: 50,
      maxCols: 50,
      margin: 5,
      disableWarnings: true,
      disableAutoPositionOnConflict: false,
      itemResizeCallback: (e: GridsterItem, ic: GridsterItemComponentInterface) => { this.gridItemResized(e, ic); }
    };
  }

  private onGridItemChange(e: GridsterItem, ic: GridsterItemComponentInterface): void {
    if (e && ic) {
      this.gridItemResized(e, ic)
    }
    // Debounced as item change fires lots of events as the grid items reflow.
    this._uiComponentUpdatedDebouncer.next(this.workspace.uiComponents);
  }

  private computeGridColWidth(ic: GridsterComponentInterface = null) {
    if (ic) {
      this._gridster = ic;
    }

    const width = this._gridster.el.clientWidth;
    const columns = Math.floor(width / this._gridsterColOpts.min);

    if (columns !== this._gridsterColOpts.max) {
      this.gridOptions.maxCols = columns;
      this.gridOptions.minCols = columns;
      this.gridOptions.api.optionsChanged();
    }
  }

  private gridItemResized(gi: GridsterItem, gic: GridsterItemComponentInterface) {
    this._subscriptions.add(this._messenger.publish(new GridItemResizedMessage(gi.name, gic.height, gic.width)));
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }

}
