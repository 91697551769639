import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import zipObject from 'lodash/zipObject';
import { SelectItem } from 'primeng/api/selectitem';
import { Subscription } from 'rxjs';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { BaseOperation } from '../../models/thermal-operation.model';
import { AnnularResultsUi, DetailedAnnularResultsPhase } from '../../models/thermal-results.model';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { forkJoin } from 'rxjs';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';

@Component({
  selector: 'app-detailed-annular-results',
  templateUrl: './detailed-annular-results.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }

    ::ng-deep .p-dropdown {
      height: 25px;
      width: 100%;
    }

    ::ng-deep .p-dropdown .p-dropdown-label {
      padding-top: 4px !important;
    }

    ::ng-deep .p-toolbar {
      padding: 10px;
    }
  `]
})
export class DetailedAnnularResultsComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;
  public missingOperations: boolean = false;
  public needToCalculate: boolean;
  public tableHeight: string;
  public results: any[];
  public allOperationsResults: any[];
  public calculationError: string = "Please calculate Perical to see results.";
  public resultTypesDropdown: Array<{ header: string, field: number, valueFormatter: any, value: number }> = [];
  public multipleResultHeaders: Array<{ header: string, field: number, valueFormatter: any, value: number }> = [];
  public xAxisTitle: string = '';
  public numDecimalPoints: number;
  public plotName = 'detailedAnnularResultsPlot'
  public componentHeight: number;

  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  // Multiple
  public columnDefinitions: Array<any> = [];
  public multipleResultsFull: any[] = [];
  public annulusNames: Array<{ header: string, value: string }> = [];

  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];

  public fluidGasDisplay: SelectItem[] = [
    { label: 'Fluid/Mixture', value: 'fluid' },
    { label: 'Liquid & Gas Fractions', value: 'gas' }
  ];

  public singleMultiple: SelectItem[] = [
    { label: 'Single Result', value: 'singleAnnulus' },
    { label: 'Single Annulus', value: 'multipleAnnuli' }
  ];

  public operations: Partial<BaseOperation>[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public resultsGrid: any[];
  public cols: any[];
  public phases: DetailedAnnularResultsPhase[];
  public temperatureUnit: string;
  public depthUnit: string;
  public pressureUnit: string;
  public densityUnit: string;
  public gasGradientUnit: string;
  public speedUnit: string;
  public specificHeatCapacityUnit: string;
  public enthalpyUnit: string;
  public heatUnit: string;

  private _subscriptions: Subscription;

  // State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.annularResultsStore = this._storeService.createStore(this.componentId, new AnnularResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public annularResultsStore: Store;

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _messenger: MediatorService,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Annular Results")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));
  }

 async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.temperatureUnit = uu.temperature;
    this.depthUnit = uu.longLengths;
    this.pressureUnit = uu.pressure;
    this.densityUnit = Units.lib[uu.density].symbol;
    this.gasGradientUnit = uu.gasGradient;
    this.speedUnit = uu.speed;
    this.specificHeatCapacityUnit = Units.lib[uu.specificHeatCapacity].symbol;
    this.enthalpyUnit = uu.enthalpy;
    this.heatUnit = uu.heat;

    this.getResultTypesDropdown();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getData();
  }

  private signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_THERMAL_RESULTS || data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.isLoading = true;
      this.getData();
      if (!this.annularResultsStore.state.selectedResultType) {
        this.annularResultsStore.update(state => ({ ...state, selectedResultType: this.resultTypesDropdown[0].field.toString() }));
      }
    }
  }

  public getData() {
    const sources = [
      this._thermalOperationsService.getThermalOperations(),
      this._thermalOperationsService.getDetailedAnnularResults()
    ];

    forkJoin(sources).subscribe({
      next: ([operations, allResults]) => {
        this.operations = operations;
        if (!this.annularResultsStore.state.selectedOperation) {
          this.annularResultsStore.update(state => ({...state, selectedOperation: this.operations[0]}));
        }

        this.allOperationsResults = allResults;

        if (!this.annularResultsStore.state.selectedResultType) {
          this.annularResultsStore.update(state => ({ ...state, selectedResultType: this.resultTypesDropdown[0].value }));
        }

        if (this.allOperationsResults.length > 0) {
          let selectedOperation = this.allOperationsResults.find(x => x.operationId === this.annularResultsStore.state.selectedOperation.id);
          this.annularResultsStore.update(state => ({...state, selectedPhase: selectedOperation?.phases[selectedOperation.phases.length - 1]}));
          if (this.annularResultsStore.state.selectedSingleMultiple == 'singleAnnulus') {
            if (!this.annularResultsStore.state.selectedResultsDisplay) {
              this.annularResultsStore.update(state => ({ ...state, selectedResultsDisplay: 'plot' }));
            }
            this.remapResults();
          } else if (this.annularResultsStore.state.selectedSingleMultiple == 'multipleAnnuli') {
            this.annularResultsStore.update(state => ({ ...state, selectedResultsDisplay: 'grid' }));
            this.remapMultipleResults();
          }
        } else {
          this.results = [];
          this.cols = [];
          this.resultsGrid = [];
          this.multipleResultsFull = [];
          this.needToCalculate = true;
        }

        this.operations.length === 0 ? this.missingOperations = true : this.missingOperations = false;
        this.missingOperations && (this.results = []);

        this.isLoading = false;
      },
      error: () => {
        this.missingOperations = true;
        this.isLoading = false;
      }
    });
  }

  public dropdownChange(e) {
    if (this.annularResultsStore.state.selectedSingleMultiple === 'singleAnnulus') {
      this.annularResultsStore.update(state => ({ ...state, selectedResultType: e.value }));
      this.remapResults();
    } else {
      this.annularResultsStore.update(state => ({ ...state, selectedAnnulus: e.value }));
      this.remapMultipleResults();
    }
  }

  public modeToggle(e) {
    this.annularResultsStore.update(state => ({ ...state, selectedSingleMultiple: e.value }));
    if (e.value == 'singleAnnulus') {
      this.annularResultsStore.update(state => ({ ...state, selectedResultsDisplay: 'plot' }));
      this.remapResults();
    } else if (e.value == 'multipleAnnuli') {
      this.annularResultsStore.update(state => ({ ...state, selectedResultsDisplay: 'grid', selectedAnnulus: e.value }));
      this.remapMultipleResults();
    }
  }

  public onSelectedResultsDisplayUpdated(e): void {
    this.annularResultsStore.update(state => ({...state, selectedResultsDisplay: e.value}));
    this.remapResults();
  }

  public onSelectFluidGas(e): void {
    this.annularResultsStore.update(state => ({...state, selectedFluidGas: e.value}));
    this.remapMultipleResults();
  }

  private remapMultipleResults(): void {
    let selectedOperationResults = this.allOperationsResults.find(o => o.operationId === this.annularResultsStore.state.selectedOperation.id);
    this.needToCalculate = !selectedOperationResults;
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (!this.annularResultsStore.state.selectedPhase) {
      this.annularResultsStore.update(state => ({...state, selectedPhase: this.phases ? this.phases[this.phases.length - 1] : null}));
    }

    const annResultsTables = this.annularResultsStore.state.selectedPhase.annulusResultsTables;
    this.annulusNames = annResultsTables[0].headers.slice(2).map(h => {
      return {
        value: toCamelCaseString(h),
        header: h
      };
    });

    this.multipleResultsFull = [];
    const start = this.annularResultsStore.state.selectedFluidGas === 'fluid' ? 0 : 24;
    const end = this.annularResultsStore.state.selectedFluidGas === 'fluid' ? 26 : annResultsTables['length'];
    const selectedPhaseLength = annResultsTables[0].rows.length;
    for (let l = 0; l < selectedPhaseLength; l++) {
      let multipleResults = [];
      for (let i = start; i < end; i++) {
        if (i === start) {
          multipleResults.push(annResultsTables[i].rows[l][0]);
          multipleResults.push(annResultsTables[i].rows[l][1]);
        }
        let annulusColumnIndex = annResultsTables[i].headers.findIndex(x => toCamelCaseString(x) == this.annularResultsStore.state.selectedAnnulus.value);
        if (annulusColumnIndex < 0) {
          annulusColumnIndex = 2;
        }
        multipleResults.push(annResultsTables[i].rows[l][annulusColumnIndex]);
      }

      this.multipleResultsFull.push(multipleResults);
    }

    this.multipleResultHeaders = this.getResultTypes();

    if (this.annularResultsStore.state.selectedFluidGas === 'gas') {
      this.multipleResultHeaders.splice(2, 24);
    } else {
      this.multipleResultHeaders.splice(26, end);
    }

    this.resultsGrid = this.multipleResultsFull.map(r => zipObject(this.multipleResultHeaders.map(c => c.field), r));
  }

  private remapResults(): void {
    if (this.resultTypesDropdown[0].header.includes('MD') || this.resultTypesDropdown[0].header.includes('TVD')) {
      this.resultTypesDropdown.splice(0, 2);
    }

    let selectedOperationResults = this.allOperationsResults.find(o => o.operationId === this.annularResultsStore.state.selectedOperation.id);
    this.needToCalculate = !selectedOperationResults;
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (!this.annularResultsStore.state.selectedPhase) {
      this.annularResultsStore.update(state => ({ ...state, selectedPhase: this.phases ? this.phases[this.phases.length - 1] : null }));
    }

    if (this.annularResultsStore.state.selectedResultType === 5) {
      this.annularResultsStore.update(state => ({ ...state, selectedResultsDisplay: 'grid' }));
    }

    if (this.annularResultsStore.state.selectedPhase) {
      this.results = this.annularResultsStore.state.selectedPhase.annulusResultsTables[this.annularResultsStore.state.selectedResultType].rows;
      if (isNaN(this.results[0][3]) && !this.results[0][3]) {
        this.results = [];
        this.cols = [];
        this.resultsGrid = [];
        this.needToCalculate = true;
        // this.calculationError = selectedOperationResults['errors'][1] ?? "Perical engine error while calculating operation.";
        this.calculationError = "Perical engine error while calculating operation.";
        return;
      }

      this.xAxisTitle = this.resultTypesDropdown[this.annularResultsStore.state.selectedResultType].header;

      this.numDecimalPoints = 2;
      switch (this.annularResultsStore.state.selectedResultType + 2) {
        case 5:
          this.numDecimalPoints = 5;
          break;
        case 8:
        case 9:
        case 10:
        case 13:
          this.numDecimalPoints = 3;
          break;
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 32:
        case 33:
        case 34:
        case 35:
        case 37:
        case 36:
          this.numDecimalPoints = 4;
          break;
        default:
          this.numDecimalPoints = 2;
          break;
      }

      this.cols = this.annularResultsStore.state.selectedPhase.annulusResultsTables[this.annularResultsStore.state.selectedResultType].headers.map(h => {
        return {
          field: toCamelCaseString((h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})`) ? h.split(' ').shift() : h),
          header: h,
          valueFormatter: (h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})`) ? (params: any) => this.formatDecimal(params, 100) : this.annularResultsStore.state.selectedResultType === 5 ? (params: any) => params : (params: any) => this.formatDecimalNew(params, this.numDecimalPoints)
        };
      });

      this.resultsGrid = this.results.map(r => zipObject(this.cols.map(c => c.field), r));

    } else {
      // this.calculationError = selectedOperationResults['errors'][1] ?? (!this.needToCalculate ? "Perical engine error while calculating operation." : "Please calculate Perical to see results.");
      this.calculationError = "Perical engine error while calculating operation.";
      this.needToCalculate = true;
      this.results = [];
      this.cols = [];
      this.resultsGrid = [];
    }
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private formatDecimalNew(value: any, numDecimalPoints: number): string {
    return !isNaN(value) ? (parseFloat(value).toFixed(numDecimalPoints)) : value;
  }

  public depthViewToggle(e) {
    this.annularResultsStore.update(state => ({...state, selectedDepthView: e.value}));
  }

  get isDialogVisible(): boolean {
    return !this.missingOperations && this.needToCalculate;
  }

  public onOperationSelected(e): void {
    this.annularResultsStore.update(state => ({...state, selectedOperation: e.value}));
    if (this.allOperationsResults.length > 0) {
      if (this.annularResultsStore.state.selectedSingleMultiple === 'singleAnnulus') {
        let firstOperation = this.allOperationsResults.find(x => x.operationId === e.value.id);
        this.annularResultsStore.update(state => ({...state, selectedPhase: firstOperation?.phases[firstOperation.phases.length - 1]}));
        this.remapResults();
      } else {
        this.annularResultsStore.update(state => ({ ...state, selectedSingleMultiple: 'multipleAnnuli' }));
        this.remapMultipleResults();
      }
    } else {
      this.needToCalculate = true;
    }
  }

  public onPhaseSelected(results: any[]) {
    if (this.allOperationsResults.length > 0) {
      if (this.annularResultsStore.state.selectedSingleMultiple === 'singleAnnulus') {
        this.annularResultsStore.update(state => ({...state, selectedPhase: results['value']}));
        this.remapResults();
      } else {
        this.annularResultsStore.update(state => ({ ...state, selectedSingleMultiple: 'multipleAnnuli' }));
        this.remapMultipleResults();
      }
    } else {
      this.needToCalculate = true;
    }
  }

  private getResultTypes() {
    return [
      { header: `MD (${this.depthUnit})`, field: 0, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 999 },
      { header: `TVD (${this.depthUnit})`, field: 1, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 999 },
      { header: `Pressure (${this.pressureUnit})`, field: 2, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 0 },
      { header: `Velocity (${this.speedUnit})`, field: 3, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 1 },
      { header: `Density (${this.densityUnit})`, field: 4, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 2 },
      { header: 'Viscosity (cP)', field: 5, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 3 },
      { header: 'Yield Point (lbf/100ft2)', field: 6, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 4 },
      { header: 'Flow Regime', field: 7, valueFormatter: (params: any) => params, value: 5 },
      { header: 'Quality', field: 8, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 6 },
      { header: 'Holdup', field: 9, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 7 },
      { header: `Vaporization Enthalpy (${this.enthalpyUnit})`, field: 10, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 8 },
      { header: `Heat Generation (${this.heatUnit})`, field: 11, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 9 },
      { header: `Latent Heat Term (${this.heatUnit})`, field: 12, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 10 },
      { header: `Pressure Loss (${this.gasGradientUnit})`, field: 13, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 11 },
      { header: 'Reynolds Number', field: 14, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 12 },
      { header: 'Fanning FF', field: 15, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 13 },
      { header: 'Prandtl Number', field: 16, valueFormatter: (params: any) => this.formatDecimalNew(params, 3), value: 14 },
      { header: 'Convection Coeff (BTU/ft2-hr-°F)', field: 17, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 15 },
      { header: 'Natural Convection Coeff (BTU/ft2-hr-°F)', field: 18, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 16 },
      { header: 'Radiation Coeff (BTU/ft2-hr-°F)', field: 19, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 17 },
      { header: 'Nusselt Number', field: 20, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 18 },
      { header: 'Grashof Number', field: 21, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 19 },
      { header: `Specific Heat Cp (${this.specificHeatCapacityUnit})`, field: 22, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 20 },
      { header: `Specific Heat Cv (${this.specificHeatCapacityUnit})`, field: 23, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 21 },
      { header: 'Fluid Effective Conductivity (BTU/hr-ft-°F)', field: 24, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 22 },
      { header: 'Fluid Expansivity (1/R)', field: 25, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 23 },
      { header: `Liquid Velocity (${this.speedUnit})`, field: 26, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 24 },
      { header: `Liquid Density (${this.densityUnit})`, field: 27, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 25 },
      { header: 'Liquid Viscosity (cP)', field: 28, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 26 },
      { header: `Liquid Specific Heat Cp (${this.specificHeatCapacityUnit})`, field: 29, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 27 },
      { header: `Liquid Specific Heat Cv (${this.specificHeatCapacityUnit})`, field: 30, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 28 },
      { header: 'Liquid Expansion Coeff (1/R)', field: 31, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 29 },
      { header: 'Liquid Dissolved GOR (SCF/bbl)', field: 32, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 30 },
      { header: 'Liquid Water Holdup (%)', field: 33, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 31 },
      { header: `Liquid Water Fraction Density (${this.densityUnit})`, field: 31, valueFormatter: (params: any) => this.formatDecimal(params, 100), value: 32 },
      { header: `Gas Velocity (${this.speedUnit})`, field: 34, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 33 },
      { header: `Gas Density (${this.densityUnit})`, field: 35, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 34 },
      { header: 'Gas Viscosity (cP)', field: 36, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 35 },
      { header: `Gas Specific Heat Cp (${this.specificHeatCapacityUnit})`, field: 37, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 36 },
      { header: `Gas Specific Heat Cv (${this.specificHeatCapacityUnit})`, field: 38, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 37 },
      { header: 'Gas Expansion Coeff (1/R)', field: 39, valueFormatter: (params: any) => this.formatDecimalNew(params, 4), value: 38 },
    ];
  }

  private getResultTypesDropdown(): void {
    this.resultTypesDropdown = this.getResultTypes();
    this.resultTypesDropdown.splice(0, 2);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
