import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tool-loader',
  template: `
  <div class="loading-container" [style.position]="positionUnset ? 'unset' : 'relative'" [style.height]="positionUnset ? '100%' : 'unset'">
    <div class="loading-indicator" *ngIf='isLoading == true'>
        <div class="loading-indicator__centerer">
            <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem !important"></i>
        </div>
    </div>
    <ng-content></ng-content>
  </div>
  `,
  styles: [`
    .loader-container {
        position: relative;
    }

    .loading-indicator {
      position: absolute;
      height: 100%;
      width: 100%;
      color: #FFF;
      background: #1e1e1e;
      z-index: 50;
      opacity: 60%;
    }

    .loading-indicator__centerer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  `]
})
export class ToolLoaderComponent {
  @Input()
  isLoading: boolean = false;

  @Input()
  positionUnset? : boolean = false;

  constructor() { }
}
