export class NonApiPipeModel {
    constructor(init?: Partial<NonApiPipeModel>) {
        this.tension = init && init.tension || 0;
        this.compression = init && init.compression || 0;
        this.burst = init && init.burst || 0;
        this.collapse = init && init.collapse || 0;
        this.wallToleranceHoop = init && init.wallToleranceHoop || 0;
        this.wallToleranceTriaxialAxial = init && init.wallToleranceTriaxialAxial || 0;
        this.wallToleranceAxial = init && init.wallToleranceAxial || 0;
        this.wallToleranceBurst = init && init.wallToleranceBurst || 0;
        this.wallToleranceCollapse = init && init.wallToleranceCollapse || 0;
        this.tensionEnabled = init && init.tensionEnabled || false;
        this.compressionEnabled = init && init.compressionEnabled || false;
        this.burstEnabled = init && init.burstEnabled || false;
        this.collapseEnabled = init && init.collapseEnabled || false;
    }
    public tension: number;
    public compression: number;
    public burst: number;
    public collapse: number;
    public wallToleranceHoop: number;
    public wallToleranceTriaxialAxial: number;
    public wallToleranceAxial: number;
    public wallToleranceBurst: number;
    public wallToleranceCollapse: number;
    public tensionEnabled: boolean;
    public compressionEnabled: boolean;
    public burstEnabled: boolean;
    public collapseEnabled: boolean;
}