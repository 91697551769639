<app-tool-loader class="operationEnvelopeLoader" [isLoading]="isLoading" positionUnset="true">
    <div #toolbarDiv class="p-toolbar-group-left">
        <div class="mr-2 singleMultiple">
            <p-dropdown
                [options]="singleMultiple"
                appendTo="body"
                (onChange)="modeToggle($event.value)"
                [(ngModel)]="operationEnvelopeStore.state.selectedSingleMultiple"
            ></p-dropdown>
            </div>

            <div *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'multipleOperation'" class="mr-2">
            <p-dropdown
                [options]="phaseEnvelopes"
                (onChange)="onPhaseEnvelopeSelected($event)"
                [(ngModel)]="operationEnvelopeStore.state.selectedPhaseEnvelope"
                optionLabel="fluid.state.name"
                appendTo="body">
            </p-dropdown>
        </div>

        <div *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'" class="mr-2">
            <p-dropdown
                [options]="operations"
                (onChange)="onOperationSelected($event)"
                [(ngModel)]="operationEnvelopeStore.state.selectedOperation"
                optionLabel="name"
                appendTo="body">
            </p-dropdown>
        </div>

        <p-button *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'" [disabled]="moveUpDisabled" (onClick)="onUpDownButtonClick('up')" icon="pi pi-arrow-up" class="upDownButtons"></p-button>
        <p-button *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'" [disabled]="moveDownDisabled" (onClick)="onUpDownButtonClick('down')" icon="pi pi-arrow-down" class="upDownButtons"></p-button>

        <div *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'" class="mr-2 selectButtons">
            <p-selectButton
                [options]="resultsDisplay"
                [(ngModel)]="operationEnvelopeStore.state.selectedResultsDisplay"
                (onChange)="onSelectedResultsDisplayUpdated($event)"
                [allowEmpty]="false"
            ></p-selectButton>
        </div>

        <div *ngIf="operationEnvelopeStore.state?.selectedResultsDisplay == 'grid'" class="mr-2">
            <p-selectButton
                [options]="envelopeOperation"
                [(ngModel)]="operationEnvelopeStore.state.selectedEnvelopeOperation"
                (onChange)="selectedTableChange($event)"
                [allowEmpty]="false"
            ></p-selectButton>
        </div>
    </div>

    <div *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'" [style.display]="operationEnvelopeStore.state?.selectedResultsDisplay != 'plot' ? 'block' : 'none'">
        <br>
        <ng-table-results-cmp
            tableName="{{ tableName }}"
            [tableData]="resultsGrid"
            [columnDefinitions]="cols"
            [tableHeight]="tableHeight"
        ></ng-table-results-cmp>
    </div>

    <div class="plot" *ngIf="operationEnvelopeStore.state?.selectedResultsDisplay == 'plot'">
        <app-xy-line-plot
            [plotData]="plot.data"
            [xAxisTitle]="xAxisTitle"
            [yAxisTitle]="yAxisTitle"
            [plotName]="plotName"
            [downloadPlotName]="downloadPlotName"
            [reverseAutoRange]="true"
            [plotTitle]="tableName"
            [legendGroupClick]="'toggle'"
            [plotOrientation]="''"
            [componentHeight]="componentHeight"
        ></app-xy-line-plot>
    </div>
    <p-dialog [draggable]="false" [(visible)]="needToCalculate" [closable]="false">
        <i class="pi pi-exclamation-circle"></i> {{ calculationError }}
        <br> <br>
    </p-dialog>
</app-tool-loader>
