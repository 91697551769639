<app-tool-loader class="dls" [isLoading]="isLoading" positionUnset="true">
  <div class="toggle-btns">
    <div class="mr-2">
      <p-selectButton
        [options]="tablePlot"
        [(ngModel)]="dlsOverridesStore.state.tablePlotSelected"
        (onChange)="tablePlotToggle($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
  </div>
  <br *ngIf="dlsOverridesStore.state?.tablePlotSelected === 'table'">
  <ng-table-grid-cmp
    *ngIf="dlsOverridesStore.state?.tablePlotSelected === 'table'"
    #dlsOverridesTable
    tableName="Additional Doglegs"
    [tableHeight]="tableHeight"
    [tableData]="dlsOverrides"
    [columnDefinitions]="columnDefinitions"
    [newRowFormGroup]="newDataRow"
    [inputFields]="inputFields"
    (dataChange)="onDlsOverrideChanged($event)"
  ></ng-table-grid-cmp>

  <div class="plot">
    <app-xy-line-plot
      *ngIf="dlsOverridesStore.state?.tablePlotSelected === 'plot'"
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="'Additional Doglegs'"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="'Additional Doglegs'"
      [componentHeight]="componentHeight"
    ></app-xy-line-plot>
  </div>
</app-tool-loader>
