import { AfterContentInit, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { TemperatureProfileType, UndisturbedTempProfile } from '../../models/temperature-profile.model';

@Component({
  selector: 'app-undisturbed-temp-profile',
  template: ``,
  providers: subformComponentProviders(UndisturbedTempProfileComponent)
})
export class UndisturbedTempProfileComponent implements AfterContentInit {

  public form = createForm<UndisturbedTempProfile>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(TemperatureProfileType.UNDISTURBED)
    }
  });

  constructor() { }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(TemperatureProfileType.UNDISTURBED);
  }
}
