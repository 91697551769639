<form [formGroup]="form.formGroup" bg-dark>
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Operation Details </ng-template>
      <div class="grid">
        <div class="col-4">
          <div [hidden]="true" class="field">
            <label for="pressure"> Time since last operation</label>
            <div class="p-inputgroup">
              <p-inputSwitch
                [formControlName]="form.formControlNames.isRunInHole"
                (onChange)="rihChange($event, true)"
                binary="true"
                [disabled]="isReadOnly"
              >
              </p-inputSwitch>
              <p-inputNumber
                [disabled]="rihDisabled"
                [formControlName]="form.formControlNames.runInHoleTime"
              ></p-inputNumber>
              <span class="p-inputgroup-addon">hr</span>

              <p
                *ngIf="form.formGroup.controls.runInHoleTime.invalid"
                class="error"
              >
                Allowed range: 0 - 100000 hrs
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <br> -->

      <!-- <p-fieldset legend="Drill & Circ" class="drilling"> -->
      <div class="grid">
        <div class="col-6">
          <!-- <div class="p-field">
            <label for="flowRate">Flow Rate</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="formControlNames.flowRate"
              />
              <span class="p-inputgroup-addon">gpm</span>
            </div>
          </div> -->

          <app-standard-fluid
            [formControlName]="form.formControlNames.standardFluidFlow"
            [operationType]="operationType"
            [userUnits]="userUnits"
          ></app-standard-fluid>

          <div class="field">
            <label for="startDrillingMd">Start Drilling Depth</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.startDepth"
                (change)="onDepthRangeChange($event, 'startDepth')"
              />
              <span class="p-inputgroup-addon"
                >{{ userUnits.longLengths }} MD</span
              >
            </div>
            <p *ngIf="form.formGroup.controls.startDepth.invalid" class="error">
              Allowed range: {{ startDrillingMd }} - {{ endDrillingMd }}
              {{ userUnits.longLengths }}
            </p>
          </div>

          <div class="field">
            <label for="endDrillingMd">End Drilling Depth</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.endDepth"
                (change)="endDepthChanged($event)"
              />
              <span class="p-inputgroup-addon"
                >{{ userUnits.longLengths }} MD</span
              >
            </div>
            <p *ngIf="form.formGroup.controls.endDepth.invalid" class="error">
              Allowed range: {{ startDrillingMd }} - {{ endDrillingMd }}
              {{ userUnits.longLengths }}
            </p>
          </div>

          <!-- <p-fieldset legend="Drill Period" class="drilling"> -->
          <!-- <div class="field">
              <label for="rateOfPenetration">Rate of Penetration</label>
              <div class="p-inputgroup">
                <input
                  pInputText
                  [formControlName]="formControlNames.rateOfPenetration"
                />
                <span class="p-inputgroup-addon">{{ userUnits.longLengths }}/hr</span>
              </div>
            </div> -->

          <!-- <div class="field">
              <label for="ropTime">Time</label>
              <div class="p-inputgroup">
                <input
                  pInputText
                  [formControlName]="formControlNames.ropTime"
                />
                <span class="p-inputgroup-addon">hr</span>
              </div>
            </div> -->
          <!-- </p-fieldset> -->
        </div>

        <div class="col-6">
          <div class="field">
            <label for="fluids">Fluid</label>
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="fluids"
              [formControlName]="form.formControlNames.fluid"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.fluid?.state.name }}
              </ng-template>
              <ng-template let-fluid pTemplate="item">
                {{ fluid.state.name }}
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field">
            <label for="inletTemperature">Inlet Temperature</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.inletTemperature"
              />
              <span class="p-inputgroup-addon">{{ tempUnit }}</span>
            </div>
            <p
              *ngIf="form.formGroup.controls.inletTemperature.invalid"
              class="error"
            >
              Allowed range: {{ temperatureValidation.min }} -
              {{ temperatureValidation.max }} {{ tempUnit }}
            </p>
          </div>

          <br />

          <div class="grid grid-nogutter">
            <div class="col-6">
              <div class="field">
                <label for="pressure">Circ on Bottom? / Time</label>
                <div class="p-inputgroup">
                  <p-inputSwitch
                    [formControlName]="form.formControlNames.isConditionHole"
                    (onChange)="circOnBottomChange($event, true)"
                    binary="true"
                    [disabled]="isReadOnly"
                  ></p-inputSwitch>
                  <p-inputNumber
                    [minFractionDigits]="2"
                    [disabled]="conditionHoleDisabled"
                    [formControlName]="form.formControlNames.conditionHoleTime"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">hr</span>
                  <p
                    *ngIf="form.formGroup.controls.conditionHoleTime.invalid"
                    class="error"
                  >
                    Allowed range: 0 - 100000 hrs
                  </p>
                </div>
              </div>

              <br />

              <div class="field">
                <label for="pressure">POOH? / Time</label>
                <div class="p-inputgroup">
                  <p-inputSwitch
                    [formControlName]="form.formControlNames.isPoolOutOfHole"
                    id="isPoolOutOfHole"
                    (onChange)="poohChange($event, true)"
                    binary="true"
                    [disabled]="isReadOnly"
                  ></p-inputSwitch>
                  <p-inputNumber
                    [minFractionDigits]="2"
                    [disabled]="poohDisabled"
                    [formControlName]="form.formControlNames.poolOutOfHoleTime"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon checkboxAddon">hr</span>
                  <p
                    *ngIf="form.formGroup.controls.poolOutOfHoleTime.invalid"
                    class="error"
                  >
                    Allowed range: 0 - 100000 hrs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        Drill String
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="drillpipeMissing"
          pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </ng-template>

      <label class="{{ drillpipeMissing ? 'error' : '' }}">{{
        selectedDrillPipe
      }}</label>

      <br *ngIf="selectedHwdp && hasHwdp" />
      <label *ngIf="selectedHwdp && hasHwdp">{{ selectedHwdp }}</label>

      <br *ngIf="selectedCollar && hasCollar" />
      <label *ngIf="selectedCollar && hasCollar">{{ selectedCollar }}</label>
      <br />
      <br />

      <div class="field">
        <label for="totalFlowArea">Nozzle Flow Area</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.totalFlowArea"
          />
          <div class="p-inputgroup-addon">
            <label>{{ areaUnit }}</label>
          </div>
        </div>
        <p class="error" *ngIf="form.formGroup.controls.totalFlowArea.invalid">
          Allowed range: {{ totalFlowAreaValidation.min }} -
          {{ totalFlowAreaValidation.max }} {{ areaUnit }}
        </p>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="showRiser">
      <ng-template pTemplate="header">
        Riser
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="
            form.formGroup.controls?.insideDiameter.invalid ||
            form.formGroup.controls?.outsideDiameter.invalid
          "
          pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </ng-template>

      <p-fieldset legend="Riser" class="cementing">
        <div class="field">
          <label for="outsideDiameter">Outside Diameter</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.outsideDiameter"
            />
            <span class="p-inputgroup-addon">{{ userUnits.shortLengths }}</span>
          </div>
        </div>
        <div class="field">
          <label for="outsideDiameter">Inside Diameter</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.insideDiameter"
            />
            <label class="p-inputgroup-addon">{{
              userUnits.shortLengths
            }}</label>
          </div>
        </div>
      </p-fieldset>
    </p-tabPanel>
  </p-tabView>
</form>
<!-- <pre>{{ formGroupErrors | json }}</pre> -->
