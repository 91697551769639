import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { CreateTreeLevelDialogComponent } from '../create-tree-level-dialog/create-tree-level-dialog.component';
import { RenameTreeLevelDialogComponent } from '../rename-tree-level-dialog/rename-tree-level-dialog.component';
import { DeleteTreeLevelDialogComponent } from '../delete-tree-level-dialog/delete-tree-level-dialog.component';
import { CreateDesignDialogComponent } from '../create-design-dialog/create-design-dialog.component';
import { getChildLevel } from '../well-explorer-helpers';

@Component({
  selector: 'well-explorer-item',
  templateUrl: './well-explorer-item.component.html',
  styleUrls: ['./well-explorer-item.component.scss'],
  providers: [DialogService]
})
export class WellExplorerItemComponent implements OnDestroy {

  readonly companyLevel: string = "companies";
  readonly designLevel: string = "designs";

  private _subscriptions: Subscription;
  private titleCasePipe = new TitleCasePipe();

  public hover: boolean;
  public hoverNode: TreeNode;
  public getChildLevel : Function;

  @Input()
  public isAdmin: boolean;

  @Input()
  public treeNode: any;

  @Input()
  public selectedNode: TreeNode;

  @Input()
  public columns: Array<any>;

  @Input()
  public rowData : Array<any>;

  @Input()
  public treeItems: TreeNode[];

  @Output()
  public onContextMenuUpdated : EventEmitter<Array<MenuItem>>;

  @Output()
  public onDeleteNode: EventEmitter<TreeNode>;

  @Output()
  public onRenameItem: EventEmitter<{ name: string, treeNode: TreeNode }>;

  @Output()
  public onCloneDesign: EventEmitter<TreeNode>;

  @Output()
  public onExportDesign: EventEmitter<TreeNode>;

  @Output()
  public onShowImportDesignDialog: EventEmitter<TreeNode>;

  @Output()
  public onAddChildNode: EventEmitter<{levelData: {id:string, name: string}, level: string, treeNode: TreeNode}>;

  constructor(
    private _dialogService: DialogService
  ) {
    this._subscriptions = new Subscription();
    this.onDeleteNode = new EventEmitter();
    this.onRenameItem = new EventEmitter();
    this.onCloneDesign = new EventEmitter();
    this.onAddChildNode = new EventEmitter();
    this.onContextMenuUpdated = new EventEmitter();
    this.onExportDesign = new EventEmitter();
    this.onShowImportDesignDialog = new EventEmitter();
    this.getChildLevel = getChildLevel;
  }

  public addChildNode(treeNode: TreeNode): void {

    const level = getChildLevel(treeNode.data.level);

    let dialogReferenceData = {
      data: {
        level: level,
        treeItems: this.treeItems
      },
      header: 'Create a New ' + this.titleCasePipe.transform(level.slice(0, -1)),
      width: '500px'
    };

    let dialogReference: DynamicDialogRef = this._dialogService
      .open(level === this.designLevel ? CreateDesignDialogComponent : CreateTreeLevelDialogComponent, dialogReferenceData);

    dialogReference.onClose.subscribe((levelData: { id: string, name: string }) => {
      if(levelData){
        this.onAddChildNode.emit({ levelData : levelData, treeNode, level: level});
      }
    });
  }

  public deleteItem(treeNode: TreeNode): void {

    let level = treeNode.data.level;
    let levelName = treeNode?.data.label;

    const deleteDialogRef = this._dialogService.open(DeleteTreeLevelDialogComponent, {
      data: {
        levelName: levelName,
        parentName: treeNode?.parent?.data?.label ?? "",
        level: level
      },
      header: level == this.companyLevel ? 'Delete Company' : 'Delete ' + this.titleCasePipe.transform(level.slice(0, -1)),
      width: '60%'
    });

    this._subscriptions.add(deleteDialogRef.onClose.subscribe(async res => {
      if (res === 'confirmed') {
        this.onDeleteNode.emit(treeNode);
      }
    }));
  }

  public cloneDesign(treeNode: TreeNode): void {
    this.onCloneDesign.emit(treeNode);
  }

  public exportDesign(treeNode: TreeNode) : void {
    this.onExportDesign.emit(treeNode);
  }

  public renameItem(treeNode: TreeNode): void {

    let level = treeNode.data.level;

    let singularLevel = level === this.companyLevel
      ? 'Company'
      : this.titleCasePipe.transform(treeNode.data.level.slice(0, -1));

    const ref = this._dialogService.open(RenameTreeLevelDialogComponent, {
      data: {
        level: level,
        currentLevelName: treeNode.data.label,
        singularLevel: singularLevel
      },
      header: 'Rename ' + singularLevel,
      width: '400px'
    });

    this._subscriptions.add(ref.onClose.subscribe(async (levelName: string) => {
      if (levelName) {
        this.onRenameItem.emit({ name: levelName, treeNode });
      }
    }));
  }

  public onMouseOver(treeNode: TreeNode): void {
    this.hover = true;
    this.hoverNode = treeNode;
  }

  public filterContextMenu(level: string) {
    let actions = [
      { label: 'Delete', icon: 'pi pi-trash', command: (e) => { this.deleteItem(this.selectedNode); }},
      { label: 'Rename', icon: 'pi pi-pencil', command: (e) => { this.renameItem(this.selectedNode); }} 
    ];

    if ((level == 'companies' || level === 'projects' || level === 'sites') && !this.isAdmin){
      actions = [];
    }

    if (level === 'designs') {
      actions.push({ label: 'Clone', icon: 'pi pi-copy', command: (event) => { this.cloneDesign(this.selectedNode); } });
      actions.push({ label: 'Export', icon: 'pi pi-download', command: (event) => { this.exportDesign(this.selectedNode); } });
    }

    if (level === 'wellbores') {
      actions.push({ label: 'Import', icon: 'pi pi-upload', command: (event) => { this.onShowImportDesignDialog.emit(this.selectedNode); } });
    }
    this.onContextMenuUpdated.emit(actions);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}
