  <p-toolbar>
    <ng-template pTemplate="left">
      <p-dropdown
        resetFilterOnHide=true
        [placeholder]="'Search'"
        [options]="allDesigns"
        filterBy="name"
        optionLabel="name"
        [filter]="true"
        appendTo="body"
        [(ngModel)]="selectedDesign"
        (onChange)="updateDesign($event)">
      </p-dropdown>
    </ng-template>

    <ng-template pTemplate="right">
      <p-dropdown
        class="recentDropdown"
        [placeholder]="'Recent'"
        [options]="designs"
        optionLabel="name"
        appendTo="body"
        [(ngModel)]="selectedDesign"
        (onChange)="updateDesign($event)">
      </p-dropdown>

      <button
        *ngIf="isAdmin"
        pButton
        type="button"
        icon="pi pi-plus"
        class="p-button-sm p-button-text p-button-success"
        pTooltip="Create New Company"
        (click)="addCompany()">
      </button>
      <p-button class="collapseButton" icon="pi pi-angle-double-left" (onClick)="collapseSidenav()"></p-button>
    </ng-template>
  </p-toolbar>
