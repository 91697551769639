<form [formGroup]="form.formGroup">
  <div class="formgrid grid grid-nogutter">
    <div class="field grid-nogutter col-1">
      <label for="isOil">Oil</label>
      <p-inputSwitch
              [disabled]="isReadOnly"
              [formControlName]="form.formControlNames.isOil"
        id="isOil"
        (onChange)="isOilChange($event)"
      ></p-inputSwitch>
    </div>
    <div
      class="field grid-nogutter col-11"
      [hidden]="!form.formGroup.controls.isOil.value"
    >
      <label for="oilApiGravity">API Gravity</label>
      <p-inputNumber
        id="oilApiGravity"
        [formControlName]="form.formControlNames.oilApiGravity"
        required
        [minFractionDigits]="1"
        class="w-full"
      ></p-inputNumber>
      <p *ngIf="form.formGroup.controls.oilApiGravity.invalid" class="error">
        Allowed range: 0 - 200
      </p>
    </div>
  </div>
  <div class="field grid-nogutter">
    <label for="isGas">Gas</label>
    <p-inputSwitch
          [disabled]="isReadOnly"
          [formControlName]="form.formControlNames.isGas"
      id="isGas"
      (onChange)="isGasChange($event)"
    ></p-inputSwitch>
  </div>
  <div
    class="field"
    [hidden]="!form.formGroup.controls.isGas.value"
  >
    <app-gas-composition
      [formControlName]="form.formControlNames.gasComposition"
      required
    ></app-gas-composition>
  </div>
</form>
