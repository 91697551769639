import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { VleHeavyWeightHydrocarbonComponent } from 'src/app/perical/models/fluid.model';

export interface HeaviesForm {
  vleHeavyWeightHydrocarbonComponents: VleHeavyWeightHydrocarbonComponent[];
}

@Component({
  selector: 'app-heavies',
  templateUrl: './heavies.component.html',
  styleUrls: ['./heavies.component.scss'],
  providers: subformComponentProviders(HeaviesComponent)
})
export class HeaviesComponent {

  constructor() { }

  public form = createForm<VleHeavyWeightHydrocarbonComponent[], HeaviesForm>(this, {
    formType: FormType.SUB,
    formControls: {
      vleHeavyWeightHydrocarbonComponents: new UntypedFormArray([])
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'vleHeavyWeightHydrocarbonComponents':
          return new UntypedFormControl(value, [Validators.required]);
        default:
          return new UntypedFormControl(value);
      }
    },
    toFormGroup: (obj: VleHeavyWeightHydrocarbonComponent[] | null): HeaviesForm | null => {
      return {
        vleHeavyWeightHydrocarbonComponents: !obj ? [] : obj,
      };
    },
    fromFormGroup: (formValue: HeaviesForm): VleHeavyWeightHydrocarbonComponent[] | null => {
      return formValue.vleHeavyWeightHydrocarbonComponents;
    }
  });

  public removeHeavy(index: number): void {
    this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.removeAt(index);
  }

  public addHeavy(): void {
    this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.push(
      this.form.createFormArrayControl('vleHeavyWeightHydrocarbonComponents', {
        name: null,
        fraction: null,
        molecularWeight: null,
        specificGravity: null
      }),
    );
  }
}
