import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { PreInstallLoad, PreInstallLoadForm } from 'src/app/perivis/models/load-case.model';

@Component({
  selector: 'app-pre-install-data',
  template: `
    <form [formGroup]="form.formGroup">
      <div class="field nameClass">
        <label for="name">Name</label>
        <input
          id="name"
          type="text"
          pInputText
          [formControlName]="form.formControlNames.name"
        />
      </div>
      <app-single-pre-install-load
        [formControlName]="form.formControlNames.data"
        [userUnits]="userUnits"
        [isCemented]="isCemented"
        [shoeMd]="shoeMd"
        [hangerMd]="hangerMd"
        [annularFLuidDensity]="annularFLuidDensity"
      ></app-single-pre-install-load>
    </form>
  `,
  styles: [`
    label {
      font-size: 12px;
      height: 20px;
      margin-bottom: 0px;
      padding-left: 8px;
      user-select: none;
    }

    input {
      font-size: .70em;
      height: 25px;
      width: 100%;
    }

    .field {
      margin-bottom: 8px;
    }

    .field > .input {
      display: block;
    }
  `]
})
export class PreInstallDataComponent {

  constructor() {}

  private input$: Subject<PreInstallLoad | null | undefined> = new Subject();
  @Input() set preInstallLoad(preInstallLoad: PreInstallLoad | undefined) {
    this.input$.next(preInstallLoad);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }
  
  @Input() userUnits: UserUnitsModel;
  @Input() isCemented: boolean;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  @Output() preInstallLoadUpdated: EventEmitter<PreInstallLoad> = new EventEmitter();

  public form = createForm<PreInstallLoad, PreInstallLoadForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.preInstallLoadUpdated,
    formControls: {
      name: new UntypedFormControl('New Load', [Validators.required]),
      data: new UntypedFormControl(null, [Validators.required])
    },
    toFormGroup: (obj: PreInstallLoad | null): PreInstallLoadForm => {
      if (!obj) {
        return null;
      }

      const { data, ...commonValues } = obj;

      return {
        data: data,
        ...commonValues
      }
    },
    fromFormGroup: (formValue: PreInstallLoadForm): PreInstallLoad => {
      const { data, ...commonValues } = formValue;

      return {
        data: data,
        ...commonValues
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  })
}
