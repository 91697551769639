<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <p-dialog [draggable]="false" [(visible)]="hideGerg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> Functionality only available for CO2 based fluids
    <br> <br>
  </p-dialog>

  <div *ngIf="!isLoading" class="chokes">
    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>

    <div class="grid">
      <div class="col-4">
        <p-listbox
          [options]="chokeData?.chokes"
          [(ngModel)]="selectedChoke"
          optionLabel="name"
          (onChange)="onChokeSelect($event)"
        >
          <ng-template pTemplate="header">
            <p-button
              class="p-button-success p-button-sm addChoke"
              label="Add Choke"
              (click)="onAddChoke()"
              [disabled]="hideGerg"
            ></p-button>
          </ng-template>
          <ng-template let-choke pTemplate="item">
            <div class="grid grid-nogutter choke-item align-items-center">
              <div class="col-8">{{ choke.name }}</div>
              <div class="col-4" [style]="{ 'text-align': 'right' }">
                <button
                  class="pi pi-trash p-button p-button-text p-button-sm p-button-danger deleteBtn"
                  pTooltip="Delete Choke"
                  (click)="onDeleteChoke(choke)"
                ></button>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
      <div class="col-8">
        <app-choke-data
          [choke]="selectedChokeDetails$ | async"
          (chokeUpdate)="saveChoke($event)"
          [disabled]="chokeData?.chokes?.length < 1"
          [chokesComponentHeight]="componentHeight"
          [chokesStore]="chokesStore"
        ></app-choke-data>
      </div>
    </div>
  </div>
</p-scrollPanel>
