<p-fieldset legend="Gas Composition %">
  <form [formGroup]="form.formGroup">
    <div class="defaultButton">
      <img
        (click)="applyDefaults()"
        title="Apply Defaults"
        src="../../assets/icons/default-icon-white.png"
      />
    </div>
    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="co2">CO</label><label class="sub">2</label>
          <p-inputNumber
            id="co2"
            [formControlName]="form.formControlNames.co2"
            required
            (onInput)="validatePercentage('co2', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.co2.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="h2S">H</label><label class="sub">2</label><label>S</label>
          <p-inputNumber
            id="h2S"
            [formControlName]="form.formControlNames.h2S"
            required
            (onInput)="validatePercentage('h2S', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.h2S.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="n2">N</label><label class="sub">2</label>
          <p-inputNumber
            id="n2"
            [formControlName]="form.formControlNames.n2"
            required
            (onInput)="validatePercentage('n2', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.n2.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="c1">C</label><label class="sub">1</label>
          <p-inputNumber
            id="c1"
            [formControlName]="form.formControlNames.c1"
            required
            (onInput)="validatePercentage('c1', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c1.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <div class="col">
        <div class="field">
          <label for="c2">C</label><label class="sub">2</label>
          <p-inputNumber
            id="c2"
            [formControlName]="form.formControlNames.c2"
            required
            (onInput)="validatePercentage('c2', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c2.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <div class="col">
        <div class="field">
          <label for="c3">C</label><label class="sub">3</label>
          <p-inputNumber
            id="c3"
            [formControlName]="form.formControlNames.c3"
            required
            (onInput)="validatePercentage('c3', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c3.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="nc4">NC</label><label class="sub">4</label>
          <p-inputNumber
            id="nc4"
            [formControlName]="form.formControlNames.nC4"
            required
            (onInput)="validatePercentage('nC4', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.nC4.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="iC4">i-C</label><label class="sub">4</label>
          <p-inputNumber
            id="iC4"
            [formControlName]="form.formControlNames.iC4"
            required
            (onInput)="validatePercentage('iC4', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.iC4.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="nC5">NC</label><label class="sub">5</label>
          <p-inputNumber
            id="nC5"
            [formControlName]="form.formControlNames.nC5"
            required
            (onInput)="validatePercentage('nC5', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.nC5.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="iC5">IC</label><label class="sub">5</label>
          <p-inputNumber
            id="iC5"
            [formControlName]="form.formControlNames.iC5"
            required
            (onInput)="validatePercentage('iC5', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.iC5.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <div class="col">
        <div class="field">
          <label for="nC6">NC</label><label class="sub">6</label>
          <p-inputNumber
            id="nC6"
            [formControlName]="form.formControlNames.nC6"
            required
            (onInput)="validatePercentage('nC6', $event)"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p *ngIf="form.formGroup.controls.nC6.invalid" class="error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <div class="col">
        <div class="field">
          <label for="c7C10">C</label><label class="sub">7</label
          ><label>&nbsp;to C</label><label class="sub">10</label>
          <p-inputNumber
            id="c7C10"
            [formControlName]="form.formControlNames.c7C10"
            [disabled]="true"
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
      </div>
    </div>
    <p
      *ngIf="form.formGroup.controls.c7C10.invalid"
      class="error totalGasError"
    >
      Total Gas Composition % must be equal to 100%
    </p>
    <div class="field grid grid-nogutter">
      <label for="averageMolWeightC7ToC10">Avg. Mol Mass of C</label>
        <label class="sub">7</label><label>&nbsp;to C</label
        ><label class="sub">10</label>
      <div class="col-12">
        <p-inputNumber
          id="averageMolWeightC7ToC10"
          [formControlName]="form.formControlNames.averageMolWeightC7ToC10"
          required
          [minFractionDigits]="2"
          (onInput)="calculateGasGravity('averageMolWeightC7ToC10', $event)"
          class="w-full"
        ></p-inputNumber>
      </div>
      <br>
      <br>
      <label for="gasGravity" class="col-12">Gas Gravity</label>
      <div class="col-12">
        <input
          id="gasGravity"
          pInputText
          [disabled]="true"
          value="{{ totalMolWeight }}"
          class="w-full"
        />
      </div>
    </div>
  </form>
</p-fieldset>
<!-- <pre>{{ formGroupValues | json }}</pre> -->
