import { Component, Input, Output } from "@angular/core";
import { User } from "./user-model";
import { Subject } from "rxjs";
import { ConfirmationService } from "primeng/api";
@Component({
  selector: 'users-table',
  template: `
        <p-confirmDialog
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
        ></p-confirmDialog>
      <p-table selectionMode="single" [value]="users">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="profile.email">Email</th>
                <th pSortableColumn="profile.firstName">First Name</th>
                <th pSortableColumn="profile.lastName">Last Name</th>
                <th pSortableColumn="profile.jobTitle">Job Title</th>
                <th pSortableColumn="profile.isLicensed">Licensed</th>
                <th class="editControlsThClass"></th>
            </tr>
        </ng-template>
        <ng-template let-people pTemplate="body" let-user>
            <tr (dblclick)="editUser(user)" [pSelectableRow]="user">
                <td>{{ user.profile.email }}</td>
                <td>{{ user.profile.firstName }}</td>
                <td>{{ user.profile.lastName }}</td>
                <td>{{ user.profile.jobTitle }}</td>
                <td>{{ user.isLicensed }}</td>
                <td>
                    <p-splitButton (onDropdownClick)="setUser(user)" (onClick)="editUser(user)"
                      [model]="editOptions" icon="pi pi-pencil" styleClass="p-button-sm mr-2 mb-2" appendTo="body"></p-splitButton>
                </td>
            </tr>
        </ng-template>
    </p-table>
`,
  styles: [`
    :host ::ng-deep .p-splitbutton {
        width: 110px
    }

    tr td {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    tr:nth-child(odd) {
        background-color: #262729;
    }

    tr th {
        padding-left: 5px;
    }

    .editControlsThClass {
        width: 50px;
    }
`],
  providers: [ConfirmationService]
})
export class UsersTableComponent {

  showEditUserForm: boolean;

  @Input() users: Array<User>;

  @Output() onEditUser: Subject<User> = new Subject();
  @Output() onRemoveUser: Subject<User> = new Subject();
  @Output() onEditPermissions: Subject<User> = new Subject();
  @Output() onToggleLicense: Subject<User> = new Subject();
  @Output() onSendInvite: Subject<User> = new Subject();

  constructor(private _confirmationService: ConfirmationService) { }

  editOptions: any[] = [
    { label: 'Remove', icon: 'pi pi-trash', command: () => this.removeUser() },
    { label: 'Toggle License', icon: 'pi pi-refresh', command: () => this.toggleLicense() },
    { label: 'Permissions', icon: 'pi pi-lock', command: () => this.editPermissions() },
    { label: 'Send Invite Email', icon: 'pi pi-envelope', command: () => this.sendInvite() }
  ];

  selectedUser: User;

  setUser(user: User) {
    this.selectedUser = user;
  }

  editUser(user: User) {
    this.setUser(user);
    this.onEditUser.next(user);
  }

  removeUser() {
    this._confirmationService.confirm({
      message: 'Are you sure that you want to remove this user?',
      accept: () => {
        this.onRemoveUser.next(this.selectedUser);
      }
    });
  }

  editPermissions() {
    this.onEditPermissions.next(this.selectedUser);
  }

  toggleLicense() {
    this.onToggleLicense.next(this.selectedUser);
  }

  sendInvite() {
    this.onSendInvite.next(this.selectedUser);
  }
}
