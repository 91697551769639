import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Store } from '@ngneat/elf';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-single-load-results',
  template: `
  <div class="grid">
    <div class="col">
    <p-dropdown
      [options]="loadCases"
      [(ngModel)]="stateStore.state.selectedLoadCase"
      optionLabel="label"
      (onChange)="onLoadCaseSelected($event)"
      appendTo="body"
    ></p-dropdown>
    </div>
    <div class="col">
    <span *ngIf="isAxialForce" style="padding-left: 10px;">
      <i title="Forces with bending. Results for forces without bending are accessible in Forces/Displacements results" class="pi pi-info-circle"></i>
    </span>
    </div>
  </div>

  `,
  styles: [`
    :host ::ng-deep .p-dropdown {
      height: 25px;
    }

    :host ::ng-deep .p-dropdown .p-dropdown-label {
      padding-top: 4px;
    }

    .grid {
      align-items: center;
    }

    .col {
      padding-bottom: 0px;
    }
  `]
})
export class SingleLoadResultsComponent implements OnChanges {
  constructor() { }

  @Input() isAxialForce: boolean;
  @Input() loadCases: SelectItem[] = [];
  @Input() signalRChange: boolean;
  @Input() stateStore: Store;

  @Output() loadCaseSelected = new EventEmitter<Event>();

  ngOnChanges(): void {
    if (!this.signalRChange && !this.stateStore.state?.selectedLoadCase) {
      this.stateStore.update(state => ({...state, selectedLoadCase: this.loadCases[0]}));
    }
  }

  onLoadCaseSelected(event: Event): void {
    this.loadCaseSelected.emit(event);
  }
}
