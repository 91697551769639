import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BhctTempProfile, TemperatureProfileType } from '../../models/temperature-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

interface BhctTempProfileForm {
  type: TemperatureProfileType.BHCT;
  bottomMd: UomQuantityInput;
}

@Component({
  selector: 'app-bhct-temp-profile',
  template: `
    <form [formGroup]="form.formGroup">
      <div class="field col-12">
        <uom-quantity-input
          label="Bottom hole circulation depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.bottomMd"
          [control]="'bottomMd'"
          [default]="currentString?.shoeMd"
          [validators]="validators?.bottomMd"
          [inputDisabled]="loadName === 'Initial Condition'"
          minFractionDigits="1"
          (onFocus)="onInputFocus($event)"
        ></uom-quantity-input>
      </div>
    </form>
  `,
  providers: subformComponentProviders(BhctTempProfileComponent)
})
export class BhctTempProfileComponent implements OnChanges {

  @Input() currentString: any;
  @Input() loadName: string;
  @Input() userUnits: UserUnitsModel;

  public validators: any;

  public form = createForm<BhctTempProfile, BhctTempProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(TemperatureProfileType.BHCT),
      bottomMd: new UntypedFormControl(null)
    },
    toFormGroup: (obj: BhctTempProfile | null): BhctTempProfileForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        bottomMd: { control: obj.bottomMd }
      }
    },
    fromFormGroup: (formValue: BhctTempProfileForm): BhctTempProfile | null => {
      const { bottomMd, ...commonValues } = formValue;
      return {
        bottomMd: +bottomMd?.control,
        ...commonValues
      }
    }
  });

  constructor() { }

  ngOnChanges() {
    this.validators = {
      bottomMd: [Validators.required, Validators.min(this.currentString.shoeMd)]
    }
  }

  onInputFocus($event){
    $event.target.select();
  }
}
