import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BarlowBurst } from '../models/barlow-burst.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class StressResultsService {

  constructor(private http: HttpClient) { }

  getDisplacementResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/displacements`);
  }

  getBarlowBurstResults(): Observable<BarlowBurst[]> {
    return this.http.get<BarlowBurst[]>(`${PeriforApiEndpoint}/stressResults/burst`);
  }

  getCollapseResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/collapse`);
  }

  getAxialResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/axial`);
  }

  getSafetyFactorResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/safetyFactors`);
  }

  getConnectionSafetyFactorResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/connectionSafetyFactors`);
  }

  getDesignLimitsPlots(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/designLimitsPlot`);
  }

  getLiftoffResults(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/stressResults/liftoff`);
  }

  getPackerLoads(): Observable<any> {
    return this.http.get<any>(`${PeriforApiEndpoint}/stressResults/packerLoads`);
  }

}
