export function LinearInterpSingle(tvd, pressures, units): number {
  const ppfg = pressures;
  let xLowerIndex = ppfg.findIndex(x => x.trueVerticalDepth >= tvd) - 1;
  if (xLowerIndex == -1) {
    xLowerIndex = 0;
  }

  if (xLowerIndex == -2) {
    xLowerIndex = ppfg.length - 2;
  }

  const xUpper = ppfg[xLowerIndex + 1].trueVerticalDepth;
  const xLower = ppfg[xLowerIndex].trueVerticalDepth;

  let yUpper;
  let yLower;
  if (units.density == 'ppg (U.S.)') {
    yUpper = ppfg[xLowerIndex + 1].equivalentMudWeight * 0.052 * tvd;
    yLower = ppfg[xLowerIndex].equivalentMudWeight * 0.052 * tvd;
  } else {
    yUpper = ppfg[xLowerIndex + 1].equivalentMudWeight * 9.81 * tvd / 100000;
    yLower = ppfg[xLowerIndex].equivalentMudWeight * 9.81 * tvd / 100000;
  }

  const pressure = (yLower + (yUpper - yLower) / (xUpper - xLower) * (tvd - xLower));

  let emw;
  if (units.density == 'ppg (U.S.)') {
    emw = pressure / (tvd * 7.48051948051947 / 144);
  } else {
    emw = pressure / (9.81 * tvd / 100000);
  }

  return emw;
}

export function LinearInterpSingleVal(xTarget: number, xValues: number[], yValues: number[]) {
  let xLowerIndex = xValues.findIndex(xValue => xValue >= xTarget) - 1;
  if (xLowerIndex < 0) xLowerIndex = 0;

  let xLower = xValues[xLowerIndex];

  if (Math.abs(xLower - xTarget) < 0.00001)
  {
      return yValues[xLowerIndex];
  }

  let xUpper = xValues[xLowerIndex + 1];
  let yUpper = yValues[xLowerIndex + 1];
  let yLower = yValues[xLowerIndex];

  return yLower + (yUpper - yLower) / (xUpper - xLower) * (xTarget - xLower);
}
