import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType } from 'ngx-sub-form';
import { MudPit, OperationType } from 'src/app/perical/models/thermal-operation.model';
import { UdtService } from 'src/app/shared/services/udt.service';

export interface MudPitsForm {
  type: OperationType;
  isNewMudTemperature: boolean;
  newMudTemperature: number;
  // isStartingFromPreviousPutConditions: boolean;
  airTemperature: number;
  airVelocity: number;
  numberOfTanks: number;
  nominalVolume: number;
  activeVolumeFraction: number;
  exposedSurfaceArea: number;
  agitatorPower: number;
  annulusIndex: number;
}

@Component({
  selector: 'app-mud-pit-details',
  templateUrl: './mud-pit-details.component.html',
  styleUrls: ['./mud-pit-details.component.scss']
})
export class MudPitDetailsComponent implements OnInit, AfterViewInit {

  constructor(
    private _udtService: UdtService) {
  }

  public startingConditions = [
    { name: 'New Mud Temp', value: 'newMudTemperature' },
    { name: 'Start From Previous Pit', value: 'isStartingFromPreviousPutConditions' }
  ];

  public isLoading: boolean;
  public totalMudVolumeBbls: number;
  public totalMudVolumeCubicFeat: number;
  public startWithNewMudPit: boolean;

  public form = createForm<MudPit, MudPitsForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.MUDPIT),
      isNewMudTemperature: new UntypedFormControl(false, [Validators.required]),
      newMudTemperature: new UntypedFormControl(null, [Validators.required]),
      airTemperature: new UntypedFormControl(null, [Validators.required]),
      airVelocity: new UntypedFormControl(null, [Validators.required]),
      numberOfTanks: new UntypedFormControl(3, [Validators.min(1), Validators.required]),
      nominalVolume: new UntypedFormControl(500, [Validators.required]),
      activeVolumeFraction: new UntypedFormControl(70, [Validators.required]),
      exposedSurfaceArea: new UntypedFormControl(null, [Validators.required]),
      agitatorPower: new UntypedFormControl(null, [Validators.required]),
      annulusIndex: new UntypedFormControl(null)
    },
    toFormGroup: (obj: MudPit | null): MudPitsForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        isNewMudTemperature: obj.isNewMudTemperature,
        newMudTemperature: obj.newMudTemperature,
        airTemperature: obj.airTemperature,
        airVelocity: obj.airVelocity,
        numberOfTanks: obj.numberOfTanks,
        nominalVolume: obj.nominalVolume,
        activeVolumeFraction: obj.activeVolumeFraction,
        exposedSurfaceArea: obj.exposedSurfaceArea,
        agitatorPower: obj.agitatorPower,
        annulusIndex: obj.annulusIndex
      }
    },
    fromFormGroup: (formValue: MudPitsForm): MudPit | null => {
      const { type, ...commonValues } = formValue;

      return {
        type: OperationType.MUDPIT,
        ...commonValues
      };
    }
  });

  public getTotalMudVolume(updatedInput, e) {
    let data: Array<{ activeVolumeFraction: number, nominalVolume: number, numberOfTanks: number }> = [];

    Object.entries(this.form.formGroup.controls).forEach(control => {
      if (control[0] === 'activeVolumeFraction' || control[0] === 'nominalVolume' || control[0] === 'numberOfTanks') {
        if (control[0] !== updatedInput) {
          data[control[0]] = +control[1].value;
        } else {
          data[updatedInput] = e.currentTarget.value;
        }
      }
    });
    this.totalMudVolumeBbls = data['activeVolumeFraction'] * data['nominalVolume'] * 0.01 * data['numberOfTanks'];
    this.totalMudVolumeCubicFeat = +(this.totalMudVolumeBbls * 5.6145835124493).toFixed(2);
  }

  ngOnInit(): void {
    this.isLoading = true;

    this._udtService.getUndisturbedTemperaturePoints().subscribe(res => {
      this.form.formGroup.controls.airTemperature
        .setValue((this.form.formGroup.value.airTemperature ?? res[0].temperature), { emitEvent: false });
      this.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    this.getTotalMudVolume(null, null);
  }

  public disableConfiguration(e) {
    this.startWithNewMudPit = e.value === 'newMudTemperature' ? null : true;
  }
}
