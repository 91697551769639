import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-tree-level-dialog',
  templateUrl: './create-tree-level-dialog.component.html',
  styles: [`
    .error {
      color: red;
      font-size: 11.5px;
      height: 0px;
      margin-top: 0px;
      width: 100%;
    }

    input {
      font-size: 12px;
      height: 35px;
      width: 100%;
    }
  `]
})
export class CreateTreeLevelDialogComponent {

  public createTreeLevelForm: UntypedFormGroup;
  public levelName: string;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _ref: DynamicDialogRef,
    private _config: DynamicDialogConfig
  ) {
    this.createTreeLevelForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]]
    });

    const level = this._config.data.level;
    this.levelName = level !== 'companies' ? level.slice(0, -1) : 'company';
  }

  handleKeyUp(e: KeyboardEvent){
    if(e.code === "Enter"){
      this.onSave();
   }
  }

  onSave() {
    let formNameValue =this.createTreeLevelForm.get('name').value;
    this._ref.close({name: formNameValue});
  }

  onCancel() {
    this._ref.close();
  }
}
