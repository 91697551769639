import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadCaseService } from '../../services/load-case.service';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Subscription, debounceTime, lastValueFrom } from 'rxjs';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-wellhead-slump',
  templateUrl: './wellhead-slump.component.html',
  styleUrls: ['./wellhead-slump.component.scss']
})
export class WellheadSlumpComponent implements OnInit, OnDestroy {
  public wellheadSlumpForm: UntypedFormGroup;
  public formationDisplacements: Array<{ measuredDepth: number, formationDisplacement: number }> = [];
  public units: { long: string, short: string };
  public columnDefinitions: Array<{ header: string, field: string }>;
  public isLoading: boolean;
  public tableHeight: string;
  private _subscriptions: Subscription;
  public slumpValidation: { min: number, max: number };

  public newDataRow(): FormGroup {
    return new FormGroup({
      measuredDepth: new FormControl("", [Validators.required]),
      formationDisplacement: new FormControl("", [Validators.required])
    });
  }

  public inputFields = [{
    name: 'measuredDepth',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 2
  },
  {
    name: 'formationDisplacement',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 2
  }];

  @Input()
  private componentId: string;

  constructor(
    private _loadCaseService: LoadCaseService,
    private _formBuilder: UntypedFormBuilder,
    private _storeService: StoreService,
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.units = {
      long: uu.longLengths,
      short: uu.shortLengths
    };

    if (this.units.short == 'in') {
      this.slumpValidation = { min: -100, max: 100 };
    } else if (this.units.short == 'cm') {
      this.slumpValidation = { min: -254, max: 254 };
    } else {
      this.slumpValidation = { min: -2540, max: 2540 };
    }

    this.columnDefinitions = [
      { header: `Measured Depth (${this.units.long})`, field: 'measuredDepth' },
      { header: `Displacement (${uu.shortLengths})`, field: 'formationDisplacement' },
    ];

    this.createReactiveForm();

    var wellheadSlump = await lastValueFrom(this._loadCaseService.getWellheadSlump());
    this.wellheadSlumpForm.patchValue(wellheadSlump, { emitEvent: false });

    this.wellheadSlumpForm.controls.applyDisplacementToPostInstallLoads.disable({ emitEvent: false });
    this.wellheadSlumpForm.controls.applyDisplacementToPostInstallLoads.setValue(true, { emitEvent: false });

    this.formationDisplacements = [
      { measuredDepth: 10000, formationDisplacement: 0 }
    ];
  }

  private createReactiveForm(): void {
    this.wellheadSlumpForm = this._formBuilder.group({
      id: new UntypedFormControl(''),
      applyDisplacementToPostInstallLoads: new UntypedFormControl(true),
      wellheadSlump: new UntypedFormControl(0, [Validators.min(this.slumpValidation.min), Validators.max(this.slumpValidation.max)]),
      // formationDisplacements: new FormControl(this.formationDisplacements, [Validators.required, Validators.minLength(1)]),
    });

    this._subscriptions.add(this.wellheadSlumpForm.valueChanges.pipe(debounceTime(500)).subscribe(async (e) => {
      if (this.wellheadSlumpForm.valid) {
        await lastValueFrom(this._loadCaseService.updateWellheadSlump(this.wellheadSlumpForm.getRawValue()));
      }
    }));
  }

  public formationChange(event: any) {
    this.formationDisplacements = event.dataRows;
    this.wellheadSlumpForm.controls.formationDisplacements.patchValue(this.formationDisplacements);
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
