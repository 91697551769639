import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, lastValueFrom, takeUntil } from 'rxjs';
import { DashboardModel, UiComponentModel } from './models/dashboard.model';
import { ComponentLauncher } from './services/component-launcher';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { DashboardApiService } from './services/dashboard-api.service';
import { DashboardItemAddedMessage } from 'src/app/shared/models/mediator-messages.model';
import { UsersService } from '../../services/users-service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { StorageKeys, StoreService } from '../../services/store.service';

@Component({
  selector: 'app-dashboard',
  template: `
      <p-confirmDialog
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
      ></p-confirmDialog>

    <div class="dashboard">
      <workspace-cmp
        [workspace]="dashboard?.activeWorkspace"
        (onUiComponentsRemoved)="handleUiComponentsRemoved($event)"
        (onUiComponentXyUpdated)="handleUiComponentXyUpdated()"
      ></workspace-cmp>
      <workspace-tabs-cmp
        [workspaces]="dashboard?.workspaces"
        [selectedTabIndex]="dashboard?.activeWorkspace?.index ?? 1"
        (onWorkspaceInsert)="handleWorkspaceInsert($event)"
        (onWorkspaceIdx)="handleWorkspaceIndex($event)"
        (onWorkspaceDelete)="handleWorkspaceDelete($event)"
        (onWorkspaceRename)="handleWorkspaceRename($event)"
        (onWorkspaceSelect)="handleWorkspaceSelect($event)"
        (onWorkspacesCloned)="handleWorkspacesCloned()"
      ></workspace-tabs-cmp>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
    .dashboard {
      height: calc(100vh - 13px);
      width: 100%;
    }
  `],
  providers: [ComponentLauncher, DialogService, ConfirmationService]
})
export class DashboardContainerComponent implements OnInit, OnDestroy {

  private _designId : string;
  private _destroy$ = new Subject<void>();
  private _subscriptions = new Subscription();
  private _previousComponent: string;
  private _addingComponent: boolean = false;
  public dashboard: DashboardModel;

  constructor(
    private _componentLauncher: ComponentLauncher,
    private _dashboardApi: DashboardApiService,
    private _usersService: UsersService,
    private _mediator: MediatorService,
    private _storeService: StoreService,
    private _confirmationService: ConfirmationService
  ) { }

  async ngOnInit() {
    this._subscriptions.add(
      this._storeService
        .getStore(StorageKeys.DESIGN)
        .pipe(takeUntil(this._destroy$))
        .subscribe((d) => {
          if(this._designId != d.id){
            this._designId = d?.id;
            this.fetchDashboardInitComponents();
          }
        })
    );

    this._subscriptions.add(
      this._mediator.of(DashboardItemAddedMessage)
        .pipe(takeUntil(this._destroy$))
        .subscribe(message => {
          this.handleUiComponentAdded(message.widgetIdentifier);
        })
    );

  }

  async handleWorkspacesCloned() {
    this.fetchDashboardInitComponents();
  }

  async handleWorkspaceInsert(e: { idx: number }) {
    const inserted = await lastValueFrom(this._dashboardApi.insertWorkspace({ workspaceIndex: e.idx }));
    if (inserted) {
      this.fetchDashboardInitComponents();
    }
  }

  async handleWorkspaceDelete(idx: number) {
    const deleted = await lastValueFrom(this._dashboardApi.deleteWorkspace(idx));
    if (deleted) {
      this.fetchDashboardInitComponents();
    }
  }

  async handleWorkspaceRename(e: { idx: number, name: string }) {
    await lastValueFrom(this._dashboardApi.renameWorkspace(e.idx, e.name));
  }

  async handleWorkspaceSelect(idx: number) {
    const setWs = await lastValueFrom(this._usersService.updateCurrentAppIds({ dashboardId: this.dashboard.id, dashboardWorkspaceIndex: idx }));
    if (setWs) {
      this.fetchDashboardInitComponents();
    }
  }

  async handleUiComponentsRemoved(uiComponents: Array<UiComponentModel>) {
    this.dashboard.activeWorkspace.uiComponents = this._componentLauncher.removeComponents(uiComponents, this.dashboard.activeWorkspace.uiComponents);
    await lastValueFrom(this._dashboardApi.updateUiComponents(this.dashboard.activeWorkspace.uiComponents));
  }

  async handleUiComponentAdded(uiComponentName: string) {
    if (this._previousComponent === uiComponentName) {
      return;
    }

    this._addingComponent = true;
    this._previousComponent = uiComponentName;
    setTimeout(() => this._previousComponent = null, 1000);

    const activeComponents = this.dashboard.activeWorkspace.uiComponents;
    const allComponents = this._componentLauncher.getComponentRegistry();
    const uiComponentNameKey = allComponents.find(c => c.key === uiComponentName)?.name;
    const componentIndex = activeComponents.findIndex(c => c.name === uiComponentNameKey);

    if (componentIndex > -1) {
      this._confirmationService.confirm({
        message: `Component ${activeComponents[componentIndex].name.bold()} already exists in the workspace. Do you want to add another?`,
        accept: async () => {
          await this.addComponent(uiComponentName, allComponents);
        }
      });
    } else {
      await this.addComponent(uiComponentName, allComponents);
    }

    this._addingComponent = false;
  }

  async handleUiComponentXyUpdated() {
    if (!this._addingComponent) {
      await lastValueFrom(this._dashboardApi.updateUiComponents(this.dashboard.activeWorkspace.uiComponents));
    }
  }

  async handleWorkspaceIndex(e: { fromIdx: number, toIdx: number }) {
    const indexed = await lastValueFrom(this._dashboardApi.reIndexWorkspace(e.fromIdx, e.toIdx));
    if (indexed) {
      await lastValueFrom(this._usersService.updateCurrentAppIds({ dashboardId: this.dashboard.id, dashboardWorkspaceIndex: e.toIdx }));
      this.fetchDashboardInitComponents();
    }
  }

  private async addComponent(uiComponentName: string, allComponents: any[]): Promise<void> {
    const uiComponents = this.dashboard.activeWorkspace.uiComponents;
    const uiComponent = this._componentLauncher.generateComponent(uiComponentName);
    const uiComponentId = await lastValueFrom(this._dashboardApi.updateUiComponents([...uiComponents, uiComponent]));

    uiComponent.id = uiComponentId;
    this._componentLauncher.addComponentToUi(uiComponent, uiComponents);
  }

  private async fetchDashboardInitComponents() {
    this.dashboard = await lastValueFrom(this._dashboardApi.getDashboard());
    this._componentLauncher.loadComponentInstances(this.dashboard?.activeWorkspace?.uiComponents);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
