<form [formGroup]="form.formGroup">
  <div class="field">
    <label for="type">Type</label>
    <p-dropdown
      [options]="loadTypes"
      [formControlName]="form.formControlNames.type"
      optionLabel="name"
      appendTo="body"
      optionValue="value"
      [autoDisplayFirst]="false"
    ></p-dropdown>
  </div>

  <div [ngSwitch]="form.formGroup.value.type">
      <app-run-in-hole-details
        *ngSwitchCase="LoadType.RUNINHOLE"
        [formControlName]="form.formControlNames.runInHole"
        [userUnits]="userUnits"
        [shoeMd]="shoeMd.toFixed(2)"
        [hangerMd]="hangerMd"
        [annularFLuidDensity]="annularFLuidDensity.toFixed(2)"
      ></app-run-in-hole-details>
      <app-overpull-details
        *ngSwitchCase="LoadType.OVERPULL"
        [formControlName]="form.formControlNames.overpull"
        [annularFLuidDensity]="annularFLuidDensity.toFixed(2)"
        [userUnits]="userUnits"
        [shoeMd]="shoeMd.toFixed(2)"
        [hangerMd]="hangerMd"
      ></app-overpull-details>
      <app-green-cement-test-details
        *ngSwitchCase="LoadType.GREENCEMENTTEST"
        [formControlName]="form.formControlNames.greenCementTest"
        [userUnits]="userUnits"
      ></app-green-cement-test-details>
  </div>
</form>