import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './shared.module';
import { ThermalOperationsComponent } from '../perical/components/thermal-operations/thermal-operations.component';
import { ProductionOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/production-operation-details/production-operation-details.component';
import { InjectionOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/injection-operation-details/injection-operation-details.component';
import { OperationDataComponent } from '../perical/components/thermal-operations/operation-data/operation-data.component';
import { SingleOperationComponent } from '../perical/components/thermal-operations/operation-data/single-operation/single-operation.component';
import { StandardFluidComponent } from '../perical/components/thermal-operations/operation-data/single-operation/standard-fluid/standard-fluid.component';
import { HydrocarbonFluidComponent } from '../perical/components/thermal-operations/operation-data/single-operation/hydrocarbon-fluid/hydrocarbon-fluid.component';
import { ThermalTemperatureResultsComponent } from '../perical/components/thermal-temperature-results/thermal-temperature-results.component';
import { ThermalResultsPlotComponent } from '../perical/shared/components/thermal-results-plot/thermal-results-plot.component';
import { ThermalPressureResultsComponent } from '../perical/components/thermal-pressure-results/thermal-pressure-results.component';
import { ShutInOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/shut-in-operation-details/shut-in-operation-details.component';
import { CirculationOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/circulation-operation-details/circulation-operation-details.component';
import { GasLiftDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/gas-lift-details/gas-lift-details.component';
import { FluidsComponent } from '../perical/components/fluids/fluids.component';
import { StandardMudsComponent } from '../perical/components/fluids/standard-muds/standard-muds.component';
import { FluidDataComponent } from '../perical/components/fluids/fluid-data/fluid-data.component';
import { BrinesComponent } from '../perical/components/fluids/brines/brines.component';
import { BlackOilComponent } from '../perical/components/fluids/black-oil/black-oil.component';
import { GasCompositionComponent } from '../perical/components/fluids/black-oil/gas-composition/gas-composition.component';
import { BaseOilsComponent } from '../perical/components/fluids/advanced-muds/base-oils/base-oils.component';
import { CementSlurriesComponent } from '../perical/components/fluids/cement-slurries/cement-slurries.component';
import { VleComponent } from '../perical/components/fluids/vle/vle.component';
import { AdvancedMudsComponent } from '../perical/components/fluids/advanced-muds/advanced-muds/advanced-muds.component';
import { HeaviesComponent } from '../perical/components/fluids/vle/heavies/heavies.component';
import { VleTuningParametersComponent } from '../perical/components/fluids/vle/vle-tuning-parameters/vle-tuning-parameters.component';
import { HeavyComponent } from '../perical/components/fluids/vle/heavies/heavy/heavy.component';
import { DrillingOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/drilling-operation-details/drilling-operation-details.component';
import { MudPitDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/mud-pit-details/mud-pit-details.component';
import { CementingOperationDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/cementing-operation-details/cementing-operation-details.component';
import { Co2FluidComponent } from '../perical/components/fluids/co2-fluid/co2-fluid.component';
import { DetailedAnnularResultsComponent } from '../perical/components/detailed-annular-results/detailed-annular-results.component';
import { RunCasingAndCirculateDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/run-casing-and-circulate-details/run-casing-and-circulate-details.component';
import { TripPipeAndCirculateDetailsComponent } from '../perical/components/thermal-operations/operation-data/single-operation/trip-pipe-and-circulate-details/trip-pipe-and-circulate-details.component';
import { OperationEnvelopeComponent } from '../perical/components/operation-envelope/operation-envelope.component';
import { PericalOptionsComponent } from '../perical/components/perical-options/perical-options.component';
import { HeatmapComponent } from '../perical/components/thermal-temperature-results/heatmap.component';
import { ChokePerformanceUtilityComponent } from '../perical/components/chokes/choke-performance-utility/choke-performance-utility.component';
import { ReservoirPerformanceUtilityComponent } from '../perical/components/reservoir-performance-utility/reservoir-performance-utility.component';

@NgModule({
  declarations: [
    HeatmapComponent,
    ThermalOperationsComponent,
    ProductionOperationDetailsComponent,
    InjectionOperationDetailsComponent,
    OperationDataComponent,
    SingleOperationComponent,
    StandardFluidComponent,
    HydrocarbonFluidComponent,
    ThermalTemperatureResultsComponent,
    ThermalResultsPlotComponent,
    ThermalPressureResultsComponent,
    ShutInOperationDetailsComponent,
    GasLiftDetailsComponent,
    DrillingOperationDetailsComponent,
    CementingOperationDetailsComponent,
    RunCasingAndCirculateDetailsComponent,
    TripPipeAndCirculateDetailsComponent,
    CirculationOperationDetailsComponent,
    MudPitDetailsComponent,
    FluidsComponent,
    StandardMudsComponent,
    FluidDataComponent,
    BrinesComponent,
    BlackOilComponent,
    GasCompositionComponent,
    BaseOilsComponent,
    CementSlurriesComponent,
    VleComponent,
    AdvancedMudsComponent,
    HeaviesComponent,
    VleTuningParametersComponent,
    HeavyComponent,
    Co2FluidComponent,
    DetailedAnnularResultsComponent,
    OperationEnvelopeComponent,
    PericalOptionsComponent,
    ChokePerformanceUtilityComponent,
    ReservoirPerformanceUtilityComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  ]
})
export class PericalModule { }
