export class FormationCementCatalog {
  public constructor(init?: Partial<FormationCementCatalog>) {
    Object.assign(this, init);
  }

  id: string;
  name: string;
  density: number;
  specificHeatCapacity: number;
  thermalConductivity: number;
  youngsModulus: number;
  poissonsRatio: number;
  thermalExpansionCoefficient: number;
  discriminator: string;
}
