<div class="col-12">
  <label for="designTemplate">Apply workspace from another design</label>
  <br />
  <label for="designTemplate" class="text-xs"
    >(All tabs and components)</label
  >
  <p-treeSelect
    [options]="treeItems"
    appendTo="body"
    id="designTemplate"
    placeholder="Select a design"
    filter="true"
    filterBy="data.label"
    showClear="true"
    (onNodeSelect)="onTemplateSelect($event.node?.data.id)"
    (onClear)="onTemplateClear()"
    class="col-12"
  >
    <ng-template pTemplate="default" let-node>
      <img
        *ngIf="node?.data.level != 'designs'"
        class="level-icon"
        [src]="node?.data.icon"
      />
      {{ node?.data.label }}
    </ng-template>
    <ng-template pTemplate="value" let-node>
      <div *ngIf="!node">Select a design</div>
      {{ node?.data?.label }}
    </ng-template>
  </p-treeSelect>
  <div *ngIf="templateWorkspaces">
    <br>
    <p-fieldset legend="Tabs in Selected Workspace:">
      <div
        *ngFor="let tab of templateWorkspaces.workspaces"
        class="inline-flex"
      >
        <p-chip
          [pTooltip]="showComponentList(tab.uiComponents)"
          [escape]="false"
          >{{ tab.name }}</p-chip
        >
      </div>
    </p-fieldset>
    <p class="error">WARNING: All tabs and components in the current design will be overwritten! Design data will not be affected.</p>
  </div>

  <br>
  <br>
  <div style="float: right">
    <button
      pButton
      type="button"
      icon="pi pi-times"
      class="p-button-text p-button-danger"
      label="Cancel"
      (click)="onCancel()"
    ></button>
    <button
      pButton
      type="button"
      icon="pi pi-check"
      class="p-button-text p-button-success"
      label="Save"
      (click)="onSave()"
      [disabled]="!workspaceToClone"
    ></button>
  </div>
</div>
