import { GradeReference, PipeReference } from "src/app/wellbore-inputs/components/well-configuration/models";

export class CustomConnectionModel {

    constructor(init?: Partial<CustomConnectionModel>) {
        this.id = init && init.id || "";
        this.name = init && init.name || "";
        this.tubularPipe = init && init.tubularPipe ? new PipeReference(init.tubularPipe) : null;
        this.grade = init && init.grade ? new GradeReference(init.grade) : null;
        this.innerDiameter = init && init.innerDiameter || 0;
        this.outerDiameter = init && init.outerDiameter || 0;
        this.burstRating = init && init.burstRating || 0;
        this.collapseRating = init && init.collapseRating || 0;
        this.tensionRating = init && init.tensionRating || 0;
        this.compressionRating = init && init.compressionRating || 0;
    }

    public  id : string;
    public name : string;
    public tubularPipe : PipeReference;
    public grade : GradeReference;
    public innerDiameter : number;
    public outerDiameter : number;
    public burstRating : number;
    public collapseRating : number;
    public tensionRating : number;
    public compressionRating : number;
    
  }
  