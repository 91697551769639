<div (keydown.enter)="saveLoadCase()">
  <form [formGroup]="newLoadForm" bg-dark>
    <div class="p-field p-col-12 p-md-12">
      <br>
      <span class="p-float-label">
        <input
          type="string"
          pInputText
          autofocus
          formControlName="name"
          required
          id="name"
          style="width: 250px"
        />
        <label for="name">Name</label>
      </span>
    </div>
  </form>
  <div style="float: right">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text p-button-danger"
      (click)="closeDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      [disabled]="newLoadForm.invalid"
      icon="pi pi-check"
      class="p-button-text p-button-success"
      (click)="saveLoadCase()"
    ></button>
  </div>
</div>
