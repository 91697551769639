import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TrajectoryXyzModel } from '../models/trajectory-xyz.model';
import { TotalDepthResult, TrajectoryModel } from '../models/trajectory.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class TrajectoryService {

  constructor(private http: HttpClient) { }

  getTrajectoryPoints(skipUnits: boolean = false): Observable<Array<TrajectoryModel>> {
    return this.http.get<Array<TrajectoryModel>>(`${PeriforApiEndpoint}/trajectory/points?skipUnits=${skipUnits}`);
  }

  getTrajectoryXyz(skipUnits: boolean = false): Observable<TrajectoryXyzModel> {
    return this.http.get<TrajectoryXyzModel>(`${PeriforApiEndpoint}/trajectory/points-xyz?skipUnits=${skipUnits}`);
  }

  getTvdFromMd(measuredDepth: number, calledFromPPFG: boolean = false): Observable<number> {
    return this.http.get<number>(`${PeriforApiEndpoint}/trajectory/md-to-tvd?md=${measuredDepth}&calledFromPPFG=${calledFromPPFG}`);
  }

  updateTrajectory(trajectory: Array<TrajectoryModel>): Observable<Array<TrajectoryModel>> {
    return this.http.put<Array<TrajectoryModel>>(`${PeriforApiEndpoint}/trajectory`, trajectory);
  }

  getTotalDepth(skipUnits: boolean = false): Observable<TotalDepthResult> {
    return this.http.get<TotalDepthResult>(`${PeriforApiEndpoint}/trajectory/total-depth?skipUnits=${skipUnits}`);
  }
}
