export class Utilities {

 /** Generate a Unique Id on the client. This Guid is shorter than a normal Guid. 
  *  Typical use is for tracking Id.
  */
  public static generateFakeGuid(): string {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
  }

}