import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FormationCementCatalog } from "../../models/formations-cement-catalog.model";

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/formationCementCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FormationsService {

  constructor(private http: HttpClient) { }

  getFormationCement(): Observable<Array<FormationCementCatalog>> {
    return this.http.get<Array<FormationCementCatalog>>(`${PeriforApiEndpoint}`).pipe(
      map(data => data.map(data => new FormationCementCatalog(data)))
    );
  }

  addItem(model: FormationCementCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint}`, model);
  }

  updateItem(model: FormationCementCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteItem(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
