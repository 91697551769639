import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message, SelectItem } from 'primeng/api';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SignalRService } from 'src/app/shared/services/signal-r.service';
import isEqual from 'lodash/isEqual';
import { StorageKeys, StoreService } from '../../services/store.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notification-hub',
  templateUrl: './notification-hub.component.html',
  styleUrls: ['./notification-hub.component.scss']
})
export class NotificationHubComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();
  private _designId : string;
  public messages: Message[] = [];
  public messagesBackup: Message[] = [];
  public live: boolean = true;
  public messagesUnread: number = 0;
  public selectedSeverity: any;

  public messageTypes: SelectItem[] = [
    { label: 'All', value: 'all' },
    { label: 'Success', value: 'success' },
    { label: 'Info', value: 'info' },
    { label: 'Error', value: 'error' },
    { label: 'Warning', value: 'warn' }
  ];

  constructor(
    public appMessageNoticeService: AppNotificationService,
    private _clipboard: Clipboard,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    const designStore = this._storeService.getStore(StorageKeys.DESIGN);
    
    designStore.pipe(takeUntil(this._destroy$)).subscribe(d => {
      this._designId = d.id;
    });
    
    this.loadMessages();
    this.setupSignalR();
  }

  private loadMessages(): void {
    this.appMessageNoticeService.getMessages().subscribe(res => {
      res.forEach(m => this.processMessage(m));
      this.messages = res;
      this.messagesBackup = res;
      this.messagesUnread = this.calculateUnreadMessages(res);
    });
  }

  private processMessage(m: Message): void {
    if (!m.detail && m.summary?.includes('Thermal operation is linked')) {
      m.detail = m.summary.slice(m.summary.indexOf('Thermal operation is linked'));
      m.summary = 'Perical';
    }

    if (!m.detail) {
      m.detail = m.summary;
      m.summary = this.capitalizeFirstLetter(m.severity || 'info');
    }
  }

  private setupSignalR(): void {
    const signalRHub = this._signalRService.getConnectionToApplicationInfoMessageHub();
    signalRHub.on(SignalRService.ON_APP_INFO, (data: any) => {
      if (this._designId === data.designId) {
        const msg = { severity: data.level, summary: data.summary, detail: data.message, data: `${data.designId}` };
        this.appMessageNoticeService.showMessage(msg);
      }
    });
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  private calculateUnreadMessages(messages: Message[]): number {
    return messages.filter(m => m.data.read === false).length;
  }

  filterSeverity(severity: string): void {
    this.messages = severity === 'all' ? this.messagesBackup : this.messagesBackup.filter(x => x.severity === severity);
  }

  clearNotifications(): void {
    this.appMessageNoticeService.clearMessages();
    this.messages = [];
  }

  removeNotification(event: Event, message: Message): void {
    event.stopPropagation();
    this.appMessageNoticeService.removeMessage(message);
    this.messages = this.messagesBackup.filter(f => !isEqual(f, message));
    this.messagesBackup = this.messages;
    this.selectedSeverity = this.messages.some(x => x.severity === this.selectedSeverity) ? this.selectedSeverity : { label: 'All', value: 'all' };
    this.filterSeverity(this.selectedSeverity.value);
  }

  copyNotification(message: Message): void {
    this._clipboard.copy(message.detail || message.summary);
  }

  markRead(): void {
    this.messages = this.messagesBackup.map(m => ({ ...m, data: { ...m.data, read: true } }));
    this.messagesBackup = this.messages;
    this.appMessageNoticeService.markRead();
    this.messagesUnread = this.calculateUnreadMessages(this.messages);
    this.selectedSeverity = { label: 'All', value: 'all' };
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
