<form [formGroup]="form.formGroup">
  <div
    *ngIf="
      operationType != 'runCasingAndCirculate' &&
      operationType != 'drilling' &&
      operationType != 'tripPipeAndCirculate'
    "
    class="p-field"
  >
    <div class="field">
      <label for="pipeRoughness">Pipe Roughness</label>
      <div class="p-inputgroup">
        <input
          id="pipeRoughness"
          pInputText
          [formControlName]="form.formControlNames.pipeRoughness"
        />
        <label class="p-inputgroup-addon">{{ userUnits.shortLengths }}</label>
      </div>
      <p class="error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
        Allowed range: {{ pipeRoughnessValidation.min }} -
        {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths }}
      </p>
    </div>
  </div>

  <div class="field">
    <label
      *ngIf="
        operationType != 'runCasingAndCirculate' &&
        operationType != 'tripPipeAndCirculate'
      "
      for="waterMudFlowRate"
      >Flow Rate</label
    >
    <label
      *ngIf="
        operationType == 'runCasingAndCirculate' ||
        operationType == 'tripPipeAndCirculate'
      "
      for="waterMudFlowRate"
      >Circulation Rate</label
    >
    <div class="p-inputgroup">
      <input pInputText [formControlName]="form.formControlNames.flowRate" />
      <span class="p-inputgroup-addon">{{ flowUnit }}</span>
    </div>
    <p
      *ngIf="
        form.formGroup.controls.flowRate.value &&
        form.formGroup.controls.flowRate.invalid
      "
      class="error"
    >
      Allowed range: {{ flowRateValidation.min }} -
      {{ flowRateValidation.max }} {{ flowUnit }}
    </p>
  </div>
</form>
