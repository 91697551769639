import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { PoreFrac, PpfgPlotUi } from '../../models/poreFrac.model';
import { PoreAndFracService } from '../../services/pore-and-frac.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { FluidsService } from '../../../perical/services/fluids.service';
import { FormationTopService } from 'src/app/wellbore-inputs/services/formation-top.service';
import { FormationTop } from 'src/app/perical/models/formation.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-ppfg-plot',
  template: `
  <div class="pore-frac-cmp">
      <div class="p-toolbar-group-center">
        <div class="mr-2">
          <p-selectButton
            [options]="pressureEmw"
            [(ngModel)]="ppfgPlotStore.state.pressureEmwSelected"
            (onChange)="tablePlotToggle($event)"
            [allowEmpty]="false"
          ></p-selectButton>
        </div>

        <div class="mr-2">
          <p-checkbox [(ngModel)]="ppfgPlotStore.state.plotFormationTops" label=" Show Formation Tops" (onChange)="toggleFormationTops($event)" binary="true"></p-checkbox>&nbsp;
        </div>
      </div>

    <app-tool-loader [isLoading]="isLoading" positionUnset="true">
      <app-xy-line-plot
        [plotData]="plot.data"
        [xAxisTitle]="xAxisTitle"
        [yAxisTitle]="yAxisTitle"
        [plotName]="plotName"
        [downloadPlotName]="downloadPlotName"
        [plotTitle]="'PPFG Plot'"
      ></app-xy-line-plot>
    </app-tool-loader>
  </div>
  `,
  styles: [`
    .pore-frac-cmp {
      height: calc(100% - 10px);
      position: relative;
      user-select: none;
    }
  `]
})
export class PpfgPlotComponent implements OnInit, OnDestroy {
  private _userUnits: UserUnitsModel;

  public casingArray: Array<{ hanger: number, shoe: number, name: string, seqNumber: number }> = Array();
  public casingArrayMudWeight: Array<{ top: number, bottom: number, mudWeight: number, seqNumber: number }> = Array();
  public isLoading = false;
  public columnDefsPlot: Array<any> = [
    { header: 'EMW', field: 'equivalentMudWeight' }
  ];
  public pressureEmw: SelectItem[] = [
    { label: 'EMW', value: 'emw' },
    { label: 'Pressure', value: 'pressure' }
  ];
  public porePressures: Array<PoreFrac>;
  public fracturePressures: Array<PoreFrac>;
  private _formationTops: Array<FormationTop>
  public mudlineDepth: number;
  public plot = {
    data: []
  };

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'poreFracPressurePlot';
  public downloadPlotName = 'pore_pressure_plot';

    //State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.ppfgPlotStore = this._storeService.createStore(this.componentId, new PpfgPlotUi);
    }
    get componentId(): string {
      return this._componentId;
    }
    public ppfgPlotStore: Store;

  constructor(
    private _poreAndFracService: PoreAndFracService,
    private _wellTypeService: WellTypeService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService,
    private _trajectoryService: TrajectoryService,
    private _fluidsService: FluidsService,
    private _formationTopService: FormationTopService,
    private _storeService: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    this._userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getTubularStrings();
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_PORE_PRESSURE_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.casingArrayMudWeight = [];
      this.getTubularStrings();
    }
  }

  getTubularStrings() {
    this.isLoading = true;
    const sources = [
      this._poreAndFracService.getPorePressures() as Observable<any>,
      this._poreAndFracService.getFractureGradient() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>,
      this._wellConfigService.getTubulars(),
      this._formationTopService.getAllFormationTops()
    ];

    forkJoin(sources).pipe(
      map(([porePressures, fractureGradient, wellType, tubulars, formationTops]) => {
        this.porePressures = porePressures;
        this.fracturePressures = fractureGradient;
        this.mudlineDepth = +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(2);
        this._formationTops = formationTops;

        this.casingArray = [];
        for (let i = 0; i < tubulars.length; i++) {
          if (tubulars[i].type === 'Tubing') {
            return;
          }

          const topPoint = wellType.type != 'Land' ? Math.max(this.mudlineDepth, tubulars[i].hangerMd).toFixed() : tubulars[i].hangerMd;
          const top = i == 0 ? topPoint : tubulars[i - 1].shoeMd;
          const bottomDepth = tubulars[i].shoeMd;
          this.getData(+top, bottomDepth, tubulars[i]);
        }
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  getData(previousShoe: number, bottomDepth: number, tubular: any) {
    const sources = [
      this._trajectoryService.getTvdFromMd(bottomDepth) as Observable<any>,
      this._trajectoryService.getTvdFromMd(previousShoe) as Observable<any>,
      this._fluidsService.getFluidById(tubular.annularFluidId) as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([bottom, top, annularFluid]) => {
        let density = annularFluid.state.nominalDensity > 0 ? annularFluid.state.nominalDensity : undefined;
        this.casingArrayMudWeight.push({ top: top, bottom: bottom, mudWeight: density, seqNumber: tubular.sequenceNumber });
        this.casingArrayMudWeight.sort((a, b) => (a.seqNumber > b.seqNumber ? 1 : -1));

        this.casingArray.push({ hanger: top, shoe: bottom, name: tubular.name, seqNumber: tubular.sequenceNumber });
        this.casingArray.sort((a, b) => (a.seqNumber > b.seqNumber ? 1 : -1));

        this.plotData();
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  tablePlotToggle(e): void {
    this.ppfgPlotStore.update(state => ({...state, pressureEmwSelected: e.value}));
    this.plotData();
  }

  plotData() {
    let poreData = this.porePressures;
    let fracData = this.fracturePressures;
    if (poreData) {
      this.parseData(poreData, fracData);
    }
  }

  parseData(poreData: any[], fracData: any[]) {
    if (poreData === undefined) { return; }
    let minX = 50;
    for(var i = 0; i < 2; i++) {
    const data = i == 0 ? poreData : fracData;
    const traceArray = [];
    const trueVerticalDepths = data.map(r => r.trueVerticalDepth);
    const pressures = data.map(r => r.pressure);
    const mudlineIndex = trueVerticalDepths.findIndex(r => r >= this.mudlineDepth);
    trueVerticalDepths.splice(0, mudlineIndex);
    pressures.splice(0, mudlineIndex);
    if (trueVerticalDepths[0] > this.mudlineDepth) {
      trueVerticalDepths.unshift(this.mudlineDepth);
      pressures.unshift(0);
    }
    const emws = data.map(r => r.equivalentMudWeight);

    const minXCheck = Math.min(...emws);
    if (minXCheck < minX) {
      minX = minXCheck;
    }

    this.columnDefsPlot.forEach((element => {
      traceArray.push(
        {
          name: element.header,
          x: this.ppfgPlotStore.state.pressureEmwSelected === 'emw' ? emws : pressures,
          y: trueVerticalDepths,
        });
    }));

    this.plot.data[i] = traceArray;
  }

  const mudWeightLine = [];
  const csgArrayLength = this.casingArrayMudWeight.length;
  let casing: any;
  for (let i = 0; i < csgArrayLength; i++) {
    const top = this.casingArrayMudWeight[i].top;
    const bottom = this.casingArrayMudWeight[i].bottom;
    const mudWeight = this.casingArrayMudWeight[i].mudWeight;

    if (i == 0) {
      casing = {
        name: 'Mud Weight',
        x: [mudWeight, mudWeight],
        y: [top, bottom],
      }
    } else {
      casing.x.push(mudWeight);
      casing.y.push(this.casingArrayMudWeight[i - 1].bottom);

      casing.x.push(mudWeight);
      casing.y.push(bottom);
    }
    mudWeightLine.push(casing);
  }

  this.plotPorePressure(mudWeightLine, casing, minX);
  this.isLoading = false;
  }

  plotPorePressure(mudWeightLine, casing, minX) {
    const plot = [];

    let data;
    for(var i = 0; i < 2; i++) {
    data = this.plot.data[i];

    const pressureData = {
      name: i == 0 ? 'Pore Pressure ' : 'Fracture Pressure',
      x: data[0].x,
      y: data[0].y
    }

    plot.push(pressureData);
  }

  let maxX = Math.max(...data[0].x);

  if (this.ppfgPlotStore.state.plotFormationTops) {
    let formationColors: string[] = [
      '#743E07',
      '#9C560F',
      '#D27617',
      '#8E5D2A',
      '#6D4F31',
      '#A36A2F',
      '#EC8316',
      '#A96F34'
    ];

    let x = 0;
    this._formationTops.forEach(formTop => {
      const maxMudWeight = Math.max(...casing.x);
      if (maxX < maxMudWeight) {
        maxX = maxMudWeight;
      }

      const top = {
        name: formTop.formation['name'] + ' Top',
        y: [formTop.formationTop, formTop.formationTop],
        x: [minX - 0.5, maxX + 0.5],
        mode: 'lines',
        hoverinfo: 'none',
        line: {
          width: 1.5,
          dash: 'dot',
          color: formationColors[x]
        },
      }
      x++;
      if (x >= formationColors.length) { x = 0; }
      plot.push(top);
    });
  }

  const maxMudWeight = Math.max(...casing.x);
  if (maxX < maxMudWeight) {
    maxX = maxMudWeight;
  }

  const mudline = {
    name: "Mudline",
    y: [this.mudlineDepth, this.mudlineDepth],
    x: [minX - 0.5, maxX + 0.5],
    line: {
      color: '#783F04'
    },
    mode: 'lines',
    hoverinfo: 'none'
  }

  if (mudline.y[0]) {
    plot.push(mudline);
  }

  let casingX;

  if (this.ppfgPlotStore.state.pressureEmwSelected == 'emw') {
    plot.push(mudWeightLine[0]);
  } else {
    const lastElement = plot[0].x.length - 1;
    if (!plot[0].x[lastElement]) {
      plot[0].x.pop();
      plot[0].y.pop();
    }
    const largestPointExt = Math.max(...plot[0].x);
    casingX = largestPointExt + 500;

    const csgArrayLength = this.casingArray.length;
    let distance = 25;
    for (let i = 0; i < csgArrayLength; i++) {
      const hanger = this.casingArray[i].hanger;
      const shoe = this.casingArray[i].shoe;
      const name = this.casingArray[i].name;

      const casing = {
        name: name,
        x: [casingX + distance, casingX + distance],
        y: [hanger, shoe],
        marker: {
          size: 10,
          symbol: ["line-ew", "triangle-up-dot"]
        },
        line: {
          color: '#999999'
        }
        // hoverinfo: 'none'
      }
      distance += 100;

      plot.push(casing);
    }
  }

    this.plot.data = plot;
    this.xAxisTitle = this.ppfgPlotStore.state.pressureEmwSelected === 'emw' ? `EMW (${Units.lib[this._userUnits.density].symbol})` : `Pressure (${this._userUnits.pressure})`;
    this.yAxisTitle = `TVD (${this._userUnits.longLengths})`;
  }

  public toggleFormationTops(e) {
    this.ppfgPlotStore.update(state => ({...state, plotFormationTops: e.checked}));
    this.plotData();
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }
}
