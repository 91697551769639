import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomPoint, UndisturbedTemperature, UndisturbedTemperaturePlot } from '../../wellbore-inputs/models/undisturbed-temperature.model';
import { map } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UdtService {

  constructor(private http: HttpClient) { }

  getUndisturbedTemperature(skipUnits: boolean = false): Observable<UndisturbedTemperature> {
    const url = `${PeriforApiEndpoint}/UndisturbedTemperature?skipUnits=${skipUnits}`;
    return this.http.get(url).pipe(
      map(data => new UndisturbedTemperature(data))
    );
  }

  updateUndisturbedTemperature(undisturbedTemperature: UndisturbedTemperature) {
    return this.http.put(`${PeriforApiEndpoint}/UndisturbedTemperature`, undisturbedTemperature, httpOptions);
  }

  getUndisturbedTemperaturePlot(): Observable<UndisturbedTemperaturePlot> {
    return this.http.get(`${PeriforApiEndpoint}/UndisturbedTemperature/plot`).pipe(
      map(data => new UndisturbedTemperaturePlot(data))
    );
  }

  getUndisturbedTemperaturePoints(): Observable<CustomPoint[]> {
    return this.http.get<CustomPoint[]>(`${PeriforApiEndpoint}/UndisturbedTemperature/points`).pipe(
      map(data => data.map(data => new CustomPoint(data)))
    );
  }
}
