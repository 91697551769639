import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WellSchematicConsolidated } from '../models/well-schematic.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class WellSchematicService {

  constructor(private http: HttpClient) { }

  getWellSchematic(designId: string): Observable<WellSchematicConsolidated> {
    return this.http.get<WellSchematicConsolidated>(`${PeriforApiEndpoint}/WellSchematic?designId=${designId}`);
  }
}
