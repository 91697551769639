<form [formGroup]="form.formGroup">
  <div class="field col-12">
    <uom-quantity-input
      label="Fracture Depth"
      [unitLabel]="userUnits.longLengths + ' MD'"
      [formControlName]="form.formControlNames.fractureDepth"
      [control]="'fractureDepth'"
      [default]="currentString.shoeMd"
      [validators]="validators.fractureDepth"
      title="Allowed range: {{ currentString.hangerMd }} - {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
      (focusout)="getEmwDefaultFrac($event.target.value)"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Fracture Gradient"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fractureGradient"
      [control]="'fractureGradient'"
      [default]="defaultFracGradient"
      [validators]="validators.fractureGradient"
      title="Allowed range: {{ fractureGradientValidation.min }} - {{ fractureGradientValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [default]="currentString.annularFluid?.state.nominalDensity"
      [validators]="validators.fluidDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Fracture Margin"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fractureMargin"
      [control]="'fractureMargin'"
      [default]="'0'"
      [validators]="validators.fractureMargin"
      title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
</form>
