import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DashboardItemAddedMessage, GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
  selector: 'sidebar-menu-group',
  templateUrl: './sidebar-menu-group.component.html',
  styleUrls: ['./sidebar-menu-group.component.scss']
})
export class SidebarMenuGroupComponent {

  constructor(private _mediator: MediatorService) {
    this.onClose = new EventEmitter();
    this._mediator.of(GridItemResizedMessage).subscribe((e) => {
      this.scrollHeight = (window.innerHeight - 85) + "px";
    });
  }
  
  public scrollHeight: string;

  @Input()
  public solidColorLabel: string;

  @Input()
  public suffixLabel : string;

  @Input()
  public labelColor : string;

  @Input()
  public menuItems: Array<TreeNode>;

  @Output()
  public onClose : EventEmitter<string>;

  public collapseSubMenu() : void{
    this.onClose.emit("close");
  }

  public onClick(uiComponentName: string) : void {
    this._mediator.publish(new DashboardItemAddedMessage(uiComponentName));
  }
}
