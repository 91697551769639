<form [formGroup]="form.formGroup">
  <div class="field grid grid-nogutter">
    <div *ngIf="isAltus" class="idClass">
      <label for="name">Name</label>
      <button
        class="pi pi-copy p-button p-button-text p-button-sm p-button-info buttons"
        pTooltip="Copy Fluid ID"
        (click)="copyFluidId()"
      ></button>
    </div>
    <div class="col-12">
      <input
        id="name"
        type="text"
        pInputText
        [formControlName]="form.formControlNames.name"
      />
    </div>

  </div>
  <div [ngSwitch]="form.formGroup.value.type">
    <div style="padding: 8px;">
      <app-standard-muds
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.STANDARDMUD"
        [formControlName]="form.formControlNames.standardMud"
        [userUnits]="userUnits"
      ></app-standard-muds>
      <app-brines
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.BRINE"
        [formControlName]="form.formControlNames.brine"
        [userUnits]="userUnits"
        [selectedBrineType]="form.formGroup.controls.brine.value.brineType"
      ></app-brines>
      <app-black-oil
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.BLACKOIL"
        [formControlName]="form.formControlNames.blackOil"
      ></app-black-oil>
      <app-base-oils
        *ngSwitchCase="FluidType.BASEOIL"
        [formControlName]="form.formControlNames.baseOil"
      ></app-base-oils>
      <app-cement-slurries
        *ngSwitchCase="FluidType.CEMENTSLURRY"
        [formControlName]="form.formControlNames.cementSlurry"
        [userUnits]="userUnits"
      ></app-cement-slurries>
      <app-vle
        *ngSwitchCase="FluidType.VLE"
        [formControlName]="form.formControlNames.vle"
      ></app-vle>
      <app-advanced-muds
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.ADVANCEDMUD"
        [formControlName]="form.formControlNames.advancedMud"
        [fluids]="fluids"
        [userUnits]="userUnits"
      ></app-advanced-muds>
      <app-co2-fluid
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.CO2FLUID"
        [formControlName]="form.formControlNames.co2Fluid"
        [isPureCo2]="isPureCo2"
        [co2ChangeTrigger]="co2ChangeTrigger"
        [phaseEnvelopeCalculated]="phaseEnvelopeCalculated"
        [userUnits]="userUnits"
        [fluidsComponentHeight]="fluidsComponentHeight"
      ></app-co2-fluid>
    </div>
  </div>
</form>

<!-- <pre>{{ formGroupErrors | json }}</pre> -->
