import { PipesCatalog } from "src/app/catalogs/models/pipes-catalog.model";

export class DrillstringModel {
  public constructor(init?: Partial<DrillstringModel>) {
    Object.assign(this, init);
  }

  id: string;
  tubularStringId: string;
  drillpipe: PipesCatalog;
  heavyWeightDrillPipe: PipesCatalog;
  collar: PipesCatalog;
  hwdpLength: number;
  collarLength: number;
  designId?: string;
}