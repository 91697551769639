export enum FlowType {
  STANDARD = 'standardFluidFlow',
  HYDROCARBON = 'hydrocarbonFlow'
}

export interface StandardFluidFlow {
  readonly type: FlowType.STANDARD;
  flowRate: number;
  pipeRoughness: number;
}

export interface HydrocarbonFlow {
  readonly type: FlowType.HYDROCARBON;
  oilFlowRate: number;
  gasFlowRate: number;
  waterFlowRate: number;
  multiphaseCorrelation: string;
  gasModel: string;
  pipeRoughness: number;
  co2EosOption: string;
}

export type Flow = StandardFluidFlow | HydrocarbonFlow;
