import { Component, Input, Output } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { EditUserAccount } from "./user-model";

@Component({
  selector: 'edit-user-form',
  template: `
      <form [formGroup]="form.formGroup">
        <div class="form-container">

          <div class="field">
            <label>Companies</label>
            <p-multiSelect appendTo="body" [placeholder]="companiesPlaceholder" [options]="companies" [formControlName]="form.formControlNames.companies" optionLabel="name" dataKey="id"></p-multiSelect>
          </div>

          <user-profile-form 
            [showAdminInputs]="showAdminInputs"
            [formControlName]="form.formControlNames.profile"
           ></user-profile-form>

          <button pButton type="button" (click)="save()" class="p-button-success">Save</button>
        </div>

      </form>
    `,
  styles: [`
      form {
        width: 350px;
      }
      :host ::ng-deep .p-multiselect {
        width: 100%;
      }
      :host ::ng-deep label {
        font-size: .70em;
        height: 10px;
        user-select: none;
      }
    `]
})
export class EditUserFormComponent {

  private input$: Subject<EditUserAccount | undefined> = new Subject();
  //This is a fix for the companies dropdown not binding. https://github.com/primefaces/primeng/issues/9673
  public companiesPlaceholder: string = "";

  constructor() {
    this.companiesPlaceholder = "Select Company";
  }

  @Input() companies: Array<any>;

  @Input() set userProfile(userProfile: EditUserAccount | undefined) {
    this.input$.next(userProfile);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  private _showAdminInputs : boolean;
  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  @Output() userUpdated: Subject<EditUserAccount> = new Subject();

  manualSave$: Subject<void> = new Subject();

  public form = createForm<EditUserAccount, EditUserAccount>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      id: new UntypedFormControl(),
      companies: new FormControl<{ id: string, name: string }[] | null>(null),
      profile: new UntypedFormControl(null)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  save() {
    this.manualSave$.next();
  }

}
