import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface BrineForm {
  name: string;
  type: string;
  brineType?: string;
  nominalDensity?: number;
  plasticViscosity?: number;
  viscosityTemperature?: number;
}

@Component({
  selector: 'app-brines',
  templateUrl: './brines.component.html',
  styleUrls: ['./brines.component.scss'],
  providers: subformComponentProviders(BrinesComponent)
})
export class BrinesComponent implements OnInit, OnChanges {

  constructor() {}
  
  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  @Input()
  public selectedBrineType: string;
  
  public densityUnits: string;
  public densityValidation: { min: number, max: number };

  private _densityDefault: number;
  private _minDensityPpg: number;
  private _maxDensityPpg: number;

  public brineTypes = [
    { label: 'Seawater', value: 'seawater' },
    { label: 'NaCl', value: 'nacl' },
    { label: 'KCl', value: 'kcl' },
    { label: 'CaCl2', value: 'cacl2' },
    { label: 'CaBr2', value: 'cabr2' },
    { label: 'ZnBr2', value: 'znbr2' },
    { label: 'KFo', value: 'kfo' },
    { label: 'CsFo', value: 'csfo' },
    { label: 'CsKFo', value: 'cskfo' },
    { label: 'CaCl2-NaCl', value: 'cacl2nacl' }
  ];

  ngOnInit(): void {
    this.densityUnits = Units.lib[this.userUnits.density].symbol;

    this.getDefaultDensities();

    let minDensity = GetValueFromPpg(this._minDensityPpg, this.densityUnits);
    let maxDensity = GetValueFromPpg(this._maxDensityPpg, this.densityUnits);

    if (this.selectedBrineType === 'seawater') {
      this.form.formGroup.controls.nominalDensity.patchValue(minDensity);
      this.form.formGroup.controls.nominalDensity.disable({ emitEvent: false})
      return;
    }

    this.form.formGroup.controls.nominalDensity.clearValidators();
    this._densityDefault = GetValueFromPpg(this._minDensityPpg, this.densityUnits);

    this.densityValidation = { min: minDensity, max: maxDensity};
    this.form.formGroup.controls.nominalDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
  }

  ngOnChanges(e: any): void {
    this.getDefaultDensities();

    let minDensity = GetValueFromPpg(this._minDensityPpg, this.densityUnits);
    let maxDensity = GetValueFromPpg(this._maxDensityPpg, this.densityUnits);

    this.form.formGroup.controls.nominalDensity.clearValidators();
    this._densityDefault = GetValueFromPpg(this._minDensityPpg, this.densityUnits);

    if (e.selectedBrineType) {
      if (this.selectedBrineType === 'seawater') {
        this.form.formGroup.controls.nominalDensity.patchValue(this._densityDefault);
        this.form.formGroup.controls.nominalDensity.disable({ emitEvent: false});
      } else {
        this.form.formGroup.controls.nominalDensity.patchValue(this._densityDefault);
        this.form.formGroup.controls.nominalDensity.enable();
      }
    }

    this.densityValidation = { min: minDensity, max: maxDensity};
    this.form.formGroup.controls.nominalDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
  }

  private getDefaultDensities(): void {
    switch(this.selectedBrineType) {
      case 'seawater': {
        this._minDensityPpg = 8.6;
        this._maxDensityPpg = 8.6;
        break;
      }
      case 'nacl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 10;
        break;
      }
      case 'kcl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 10;
        break;
      }
      case 'cacl2': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 12;
        break;
      }
      case 'cabr2': {
        this._minDensityPpg = 11;
        this._maxDensityPpg = 15.5;
        break;
      }
      case 'znbr2': {
        this._minDensityPpg = 14.5;
        this._maxDensityPpg = 19.2;
        break;
      }
      case 'kfo': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 14.2;
        break;
      }
      case 'csfo': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 20.8;
        break;
      }
      case 'cskfo': {
        this._minDensityPpg = 13.1;
        this._maxDensityPpg = 18.4;
        break;
      }
      case 'cacl2nacl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 11.2;
        break;
      }
    }
  }

  public form = createForm<BrineForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      brineType: new UntypedFormControl(null, [Validators.required]),
      nominalDensity: new UntypedFormControl(null),
      plasticViscosity: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)]),
      viscosityTemperature: new UntypedFormControl(null, [Validators.required, Validators.min(32), Validators.max(500)])
    }
  });

  public applyDefaults(): void {
    this.form.formGroup.controls.nominalDensity.setValue(this._densityDefault);
    // this.formGroupControls.plasticViscosity.setValue(1);
    // this.formGroupControls.viscosityTemperature.setValue(70);
  }
}
