import { UntypedFormControl } from "@angular/forms";

export function topOfCementMdRequired(control: UntypedFormControl) {
    let topOfCementMd = control.value;
    let hasCement = control.parent && control.parent.controls['hasCement'].value;
    let hangerMd = control.parent && control.parent.controls['hangerMd'].value;
    let shoeMd = control.parent && control.parent.controls['shoeMd'].value;
    if(hasCement && topOfCementMd < hangerMd){
       return {error: "Top of cement must be set at or below the hanger"};
    } else if(hasCement && topOfCementMd > shoeMd) {
      return {error: "Top of cement must be set at or above the shoe"};
    }
    return null;
  }
  