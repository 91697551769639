
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './modules/app-routing.module';

import { CoreModule } from './modules/core.module';
import { WellboreInputsModule } from './modules/wellbore-inputs.module';
import { SharedModule } from './modules/shared.module';
import { PerivisModule } from './modules/perivis.module';
import { PericalModule } from './modules/perical.module';
import { CatalogsModule } from './modules/catalogs.module';
import { HttpErrorInterceptor } from './shared/services/http-error.interceptor';
import { PerinixModule } from './modules/perinix.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [
          'http://localhost:4200/*',
          `${environment.PeriforEndpoint}/*`,
        ],
      },
    }),
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    WellboreInputsModule,
    PerivisModule,
    PericalModule,
    PerinixModule,
    CatalogsModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
