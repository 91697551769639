import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'validation-error',
    template: `
    <span class="validation-error" *ngIf="isValid" pTooltip="{{errorMsg}}" tooltipPosition="top" tooltipStyleClass="toolTip" tooltipZIndex=150000>
        <i class="p-error pi pi-exclamation-circle"></i>
    </span> 
    `,
    styleUrls: ['./validation-error.component.scss']
  })
  export class ValidationErrorComponent implements OnInit {

    @Input()
    public isValid : boolean = true;

    @Input()
    public errorMsg : string = "";

    ngOnInit(): void {    }
    
  }
