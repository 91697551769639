<div class="grid-container">
  <div class="toolbar">
    <app-toolbar [smallerScreen]="smallerScreen" [user]="user" *ngIf="this.designId"></app-toolbar>

    <app-dashboard *ngIf="this.designId"></app-dashboard>
    <app-no-current-design *ngIf="!this.designId"></app-no-current-design>
  </div>
  <div class="sidebar">
    <app-sidebar-menu></app-sidebar-menu>
  </div>
  <!-- <div class="dashboard">

  </div> -->
  <div *ngIf="showWellSchematic" class="{{ smallerScreen ? 'smallerSchematic' : 'schematic' }}">
    <div class="grabber" resizer (onResizeDone)="requestSchematicRedraw()" minWidth="150" maxWidth="650"></div>
    <div class="pad">
      <app-well-schematic2 #schematic isSidebarComp="true"></app-well-schematic2>
    </div>
  </div>
</div>