<form [formGroup]="form.formGroup">
  <label
    class="error"
    *ngIf="form.formGroup.controls.pressurePoints.errors?.lastPointAboveShoe"
    >Must be specified from hanger: {{ currentString?.hangerMd.toFixed(2) }} {{ userUnits.longLengths }}, to or
    below shoe: {{ currentString?.shoeMd.toFixed(2) }} {{ userUnits.longLengths }}, but above well TD: {{ wellTd.toFixed(2) }} {{ userUnits.longLengths }}</label
  >
  <ng-table-grid-cmp
    tableName="Custom External Pressures"
    tableHeight="400px"
    [tableData]="customPressures"
    [columnDefinitions]="columnDefinitions"
    [newRowFormGroup]="newDataRow"
    [inputFields]="inputFields"
    (dataChange)="onCustomPressuresChange($event)"
    [isDynamicLoaded]="true"
  >
  </ng-table-grid-cmp>
</form>
