<div *ngIf="tubular" class="infoBox">

    <button pButton type="button" icon="pi pi-times" class="closeBtn p-button-rounded p-button-danger p-button-text" (click)="handleClose()"></button>

    <dl class="table-display">
        <dt>Type:</dt> <dd>{{tubular?.type}}</dd>
        <dt  *ngIf="tubular?.holeSize > 0">Hole Size ({{ units.short }}):</dt> <dd *ngIf="tubular?.holeSize > 0">{{tubular?.holeSize  | number:'1.0-4'}}</dd>
        <dt>Hanger Depth ({{ units.long }}):</dt> <dd>{{tubular?.hangerMd  | number:'1.0-4'}}</dd>
        <dt>Shoe Depth ({{ units.long }}):</dt> <dd>{{tubular?.shoeMd  | number:'1.0-4'}}</dd>
        <dt>TOC ({{ units.long }}):</dt> <dd>{{tubular?.topOfCementMd  | number:'1.0-4'}}</dd>
        <dt>Sections:</dt><dd>{{tubular?.stringSections.length}}</dd>

        <table class="pipeTable">
            <tr>
                <th>Btm</th>
                <th>OD </th>
                <th>ID</th>
                <th>Grade</th>
                <th>Connection</th>
            </tr>
            <tbody>
                <tr *ngFor="let t of tubular.stringSections">
                    <td>{{t.bottomMeasuredDepth | number:'1.0-4' }}</td>
                    <td>{{t.pipe.outsideDiameter | number:'1.0-4'}}</td>
                    <td>{{t.pipe.insideDiameter | number:'1.0-4'}}</td>
                    <td>{{t.grade.name}}</td>
                    <td>{{t.connection?.name}}</td>
                </tr>
            </tbody>
        </table>

    </dl>

</div>
