<p-scrollPanel [style]="{ width: '100%', height: '101%' }">
  <div *ngIf="!isLoading" class="thermal-operations">
    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>

    <p-button
      pTooltip="{{ drawerOpen ? 'Collapse List' : 'Expand List' }}"
      class="collapseButton"
      icon="{{drawerOpen ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right'}}"
      (onClick)="drawerToggle()"
    ></p-button>
    <p-dropdown
      *ngIf="!drawerOpen && operations"
      id="thermalOperation"
      [options]="operations"
      [ngModel]="selectedOperation[0]"
      dataKey="id"
      appendTo="body"
      optionLabel="name"
      (onChange)="onOperationSelect($event, false, true)"
    ></p-dropdown>

    <div class="grid">
      <div class="flex">
        <div class="drawer" *ngIf="drawerOpen">
          <div class="col-3">
            <p-orderList
              #orderlist
              [value]="operations"
              [(selection)]="selectedOperation"
              optionLabel="name"
              [dragdrop]="selectedOperation[0]?.id !== '0000-0000-0000-0000'"
              (onSelectionChange)="onOperationSelect($event, false, false)"
              (onReorder)="onReorder()"
              appendTo="body"
            >
              <ng-template pTemplate="header">
                <p-button
                  class="p-button-success p-button-sm addOperation"
                  label="Add Operation"
                  (click)="addOperation()"
                ></p-button>
              </ng-template>
              <ng-template let-operation pTemplate="item">
                <div class="grid-nogutter grid align-items-center">
                  <div class="col-9 nameClass">{{ operation.name }}</div>
                  <div class="col buttonsClass flex justify-content-end">
                    <i class="pi pi-copy p-button p-button-text p-button-sm p-button-info"
                      pTooltip="Clone Operation"
                      (click)="cloneOperation(operation)"
                    ></i>
                    <i class="pi pi-trash p-button p-button-text p-button-sm p-button-danger"
                      pTooltip="Delete Operation"
                      (click)="deleteOperation(operation)"
                    ></i>
                  </div>
                </div>
              </ng-template>
            </p-orderList>
          </div>
        </div>
        <div class="content">
          <div class="col">
            <div class="fluid">
              <app-tool-loader [isLoading]="isLoading" [positionUnset]="true">
                <app-operation-data
                  [isReadOnly]="userRoles?.readOnly"
                  [disabled]="operations?.length === 0"
                  [operation]="selectedOperationDetail$ | async"
                  (operationUpdate)="updateOrCreateOperation($event)"
                  [previousOperations]="previousOperations"
                  [user]="user"
                ></app-operation-data>
              </app-tool-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-scrollPanel>