import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-fracture-pressure',
  template: `
    <app-tool-loader [isLoading]="false" positionUnset="true">
      <app-pore-and-frac [pressureType]="'fracPressure'" [componentId]="componentId"></app-pore-and-frac>
    </app-tool-loader>
  `
})
export class FracturePressureComponent {
  constructor() { }

  @Input()
  public componentId: string;
}
