<form [formGroup]="form.formGroup">
  <p-tabView #tabGroup>
    <p-tabPanel header="Kick Parameters">
      <div class="grid">
        <p-fieldset legend="Kick Parameters" class="col-6 leftFieldset">
          <div class="field col-12">
            <uom-quantity-input
              label="Influx Depth"
              [unitLabel]="userUnits.longLengths + ' MD'"
              [formControlName]="form.formControlNames.influxDepth"
              [control]="'influxDepth'"
              [default]="influxDepthDefault"
              [validators]="validators.influxDepth"
              title="Must be at or below shoe: {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Liquid Density"
              [unitLabel]="densityLabel"
              [formControlName]="form.formControlNames.liquidDensity"
              [control]="'liquidDensity'"
              [default]="liquidDensityDefault"
              [validators]="validators.liquidDensity"
              title="Allowed range: {{ liquidDensityValidation.min }} - {{ liquidDensityValidation.max }} {{ volumeLabel }}"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Kick Volume"
              [unitLabel]="volumeLabel"
              [formControlName]="form.formControlNames.kickVolume"
              [control]="'kickVolume'"
              [default]="kickVolumeDefault"
              [validators]="validators.kickVolume"
              title="Allowed range: {{ kickVolumeValidation.min }} - {{ kickVolumeValidation.max }} {{ volumeLabel }}"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Kick Intensity"
              [unitLabel]="densityLabel"
              [formControlName]="form.formControlNames.kickIntensity"
              [control]="'kickIntensity'"
              [default]="kickIntensityDefault"
              [validators]="validators.kickIntensity"
              title="Allowed range: {{ kickIntensityValidation.min }} - {{ kickIntensityValidation.max }} {{ densityLabel }}"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Max Mud Weight"
              [unitLabel]="densityLabel"
              [formControlName]="form.formControlNames.maxMudWeight"
              [control]="'maxMudWeight'"
              [default]="nextStringMudWeight"
              [validators]="validators.maxMudWeight"
              title="Allowed range: {{ maxMudWeightValidation.min }} - {{ maxMudWeightValidation.max }} {{ densityLabel }}"
            ></uom-quantity-input>
            <!-- (focusout)="getDefaultFluidDensity('nextStringMudWeightLiquidKick')" -->
          </div>
        </p-fieldset>

        <p-fieldset legend="Fracture Pressure" class="col">
          <div class="field col-12">
            <uom-quantity-input
              label="Fracture Depth"
              [unitLabel]="userUnits.longLengths + ' MD'"
              [formControlName]="form.formControlNames.fractureDepth"
              [control]="'fractureDepth'"
              [default]="shoeDepth + 1"
              [validators]="validators.fractureDepth"
              title="Allowed range: {{ shoeDepth }} - {{ nextShoeDepth }} {{ userUnits.longLengths }}"
              (focusout)="getEmwDefaultFrac($event.target.value)"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Fracture Gradient"
              [unitLabel]="densityLabel"
              [formControlName]="form.formControlNames.fractureGradient"
              [control]="'fractureGradient'"
              [default]="defaultFracGradient"
              [validators]="validators.fractureGradient"
              title="Allowed range: {{ fractureGradientValidation.min }} - {{ fractureGradientValidation.max }} {{ densityLabel }}"
            ></uom-quantity-input>
          </div>
          <div class="field col-12">
            <uom-quantity-input
              label="Margin Of Error"
              [unitLabel]="densityLabel"
              [formControlName]="form.formControlNames.fractureMarginOfError"
              [control]="'fractureMarginOfError'"
              [default]="'0'"
              [validators]="validators.fractureMarginOfError"
              title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}"
            ></uom-quantity-input>
          </div>
        </p-fieldset>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Drill String">
      <div class="field col-10">
        <uom-quantity-input
          label="Drill Pipe OD"
          [unitLabel]="userUnits.shortLengths"
          [formControlName]="form.formControlNames.drillPipeOd"
          [control]="'drillPipeOd'"
          [default]="defaultDrillPipeOd"
          title="Allowed range: 0 - {{ smallestPipeId }}"
        ></uom-quantity-input>
      </div>
    </p-tabPanel>
  </p-tabView>
</form>
