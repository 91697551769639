<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div #toolbarDiv>
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="mr-2">
          <p-selectButton
            [options]="singleMultiple"
            [(ngModel)]="annularResultsStore.state.selectedSingleMultiple"
            (onChange)="modeToggle($event)"
            [allowEmpty]="false"
          ></p-selectButton>
        </div>
        <div class="mr-2" *ngIf="annularResultsStore.state.selectedSingleMultiple === 'singleAnnulus'">
          <p-dropdown
            [options]="resultTypesDropdown"
            (onChange)="dropdownChange($event)"
            [(ngModel)]="annularResultsStore.state.selectedResultType"
            optionLabel="header"
            optionValue="value"
            appendTo="body"
          ></p-dropdown>
        </div>
        <div class="mr-2" *ngIf="annularResultsStore.state.selectedSingleMultiple === 'multipleAnnuli'">
          <p-dropdown
            [options]="annulusNames"
            (onChange)="dropdownChange($event)"
            [(ngModel)]="annularResultsStore.state.selectedAnnulus"
            optionLabel="header"
            appendTo="body"
          ></p-dropdown>
        </div>
        <div class="mr-2">
          <p-selectButton
            [options]="fluidGasDisplay"
            [(ngModel)]="annularResultsStore.state.selectedFluidGas"
            (onChange)="onSelectFluidGas($event)"
            *ngIf="
              annularResultsStore.state?.selectedSingleMultiple ==
              'multipleAnnuli'
            "
            [allowEmpty]="false"
          ></p-selectButton>
        </div>
        <div class="mr-2">
          <p-selectButton
            [options]="resultsDisplay"
            [(ngModel)]="annularResultsStore.state.selectedResultsDisplay"
            (onChange)="onSelectedResultsDisplayUpdated($event)"
            *ngIf="
              annularResultsStore.state?.selectedSingleMultiple ==
              'singleAnnulus'
            "
            [allowEmpty]="false"
          ></p-selectButton>
        </div>
        <div
          class="flex grid grid-nogutter"
          *ngIf="annularResultsStore.state?.selectedResultsDisplay === 'plot'"
        >
          <div class="mr-2">
            <p-selectButton
              [options]="depthView"
              [(ngModel)]="annularResultsStore.state.selectedDepthView"
              (onChange)="depthViewToggle($event)"
              [allowEmpty]="false"
            ></p-selectButton>
          </div>
        </div>
      </div>

      <div class="p-toolbar-group-center">
        <p-dropdown
          [options]="operations"
          [(ngModel)]="annularResultsStore.state.selectedOperation"
          (onChange)="onOperationSelected($event)"
          optionLabel="name"
          appendTo="body"
        ></p-dropdown>

        <p-dropdown
          [options]="phases"
          [ngModel]="annularResultsStore.state.selectedPhase"
          (onChange)="onPhaseSelected($event)"
          optionLabel="phaseNumber"
          [hidden]="phases?.length <= 1"
          appendTo="body"
        >
          <ng-template pTemplate="selectedItem">
            Phase {{ annularResultsStore.state?.selectedPhase?.phaseNumber }}
          </ng-template>
          <ng-template let-phase pTemplate="item">
            Phase {{ phase.phaseNumber }}
          </ng-template>
        </p-dropdown>
      </div>
      <div class="mr-2"></div>
    </p-toolbar>
  </div>

  <br *ngIf="annularResultsStore.state?.selectedResultsDisplay == 'grid'" />
  <ng-table-results-cmp
    tableName="Annular Results"
    [tableData]="resultsGrid"
    [columnDefinitions]="
      annularResultsStore.state?.selectedSingleMultiple === 'singleAnnulus'
        ? cols
        : multipleResultHeaders
    "
    [tableHeight]="tableHeight"
    [selectedOperation]="annularResultsStore.state?.selectedOperation"
    (onChange)="onOperationSelected($event)"
    *ngIf="annularResultsStore.state?.selectedResultsDisplay == 'grid'"
    [virtualScroll]="true"
  >
  </ng-table-results-cmp>

  <app-thermal-results-plot
    *ngIf="annularResultsStore.state?.selectedResultsDisplay === 'plot'"
    xAxisTitle="{{ xAxisTitle }}"
    depthUnit="{{ depthUnit }}"
    plotTitle="Annular Results"
    [plotData]="results"
    [columnDefinitions]="cols"
    [selectedDepthDisplay]="annularResultsStore.state?.selectedDepthDisplay"
    [isAnnularResults]="true"
    [xAxisTickFormatNumber]="numDecimalPoints"
    [plotName]="plotName"
    [componentHeight]="componentHeight"
    [selectedDepthDisplay]="annularResultsStore.state.selectedDepthView"
  ></app-thermal-results-plot>

  <p-dialog
    [draggable]="false"
    [(visible)]="missingOperations"
    [closable]="false"
  >
    <i class="pi pi-exclamation-circle"></i> No Operations Exist: Please specify
    at least one operation to view results. <br />
    <br />
  </p-dialog>
  <p-dialog
    [draggable]="false"
    [visible]="isDialogVisible"
    [closable]="false"
  >
    <i class="pi pi-exclamation-circle"></i> {{ calculationError }} <br />
    <br />
  </p-dialog>
</app-tool-loader>
