<div class="container">
  <div class="sidebarMenu">
    <div class="dockClass">
      <p-dock position="left" [model]="navbarItems">
        <ng-template pTemplate="item" let-item let-i="index">
          <a class="p-menuitem-link flex grid-nogutter justify-content-center align-items-center">
            <div class="col">
              <!-- <i class="{{ item.icon }} sidebarIcon p-mx-auto"></i> -->
              <img [style]="item.color" class="sidebarIcon mx-auto" src="{{ item.icon }}" />
              <p class="sidebarLabel mx-auto">{{ item.label }}</p>
            </div>
          </a>
        </ng-template>
      </p-dock>
    </div>

    <p-button *ngIf="menuSelected" class="collapseButton" icon="pi pi-angle-double-left" (onClick)="handleClose()"></p-button>
  </div>

  <div class="{{ menuSelected === '' ? '' : menuSelected == 'wellExplorer' ? 'sidebarSubMenuWellExplorer' : 'sidebarSubMenu' }}">
    <app-well-explorer
      [hidden]="!(this.menuSelected === 'wellExplorer')"
      (onClose)="handleClose()"
    ></app-well-explorer>

    <sidebar-menu-group
      [hidden]="!(menuSelected === 'wellboreInputs')"
      suffixLabel="FOR"
      labelColor="Red"
      [menuItems]="wellboreInputsSubMenu"
      (onClose)="handleClose()"
    >
    </sidebar-menu-group>

    <sidebar-menu-group
      [hidden]="!(menuSelected === 'thermal')"
      suffixLabel="CAL"
      [menuItems]="thermalSubMenu"
      labelColor="#FF5722"
      (onClose)="handleClose()"
    >
    </sidebar-menu-group>

    <sidebar-menu-group
      [hidden]="!(menuSelected === 'apb')"
      suffixLabel="NIX"
      labelColor="#4CAF50"
      [menuItems]="apbSubMenu"
      (onClose)="handleClose()"
    >
    </sidebar-menu-group>

    <sidebar-menu-group
      [hidden]="!(menuSelected === 'stress')"
      suffixLabel="VIS"
      [menuItems]="stressSubMenu"
      labelColor="#00e5ff"
      (onClose)="handleClose()"
    >
    </sidebar-menu-group>

    <sidebar-menu-group
      [hidden]="!(menuSelected === 'database')"
      solidColorLabel="Catalogs"
      labelColor="White"
      [menuItems]="catalogSubMenu"
      (onClose)="handleClose()"
    >
    </sidebar-menu-group>
  </div>
</div>

