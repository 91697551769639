<app-tool-loader [isLoading]="isLoading">
  <form [formGroup]="form.formGroup">
    <p-panel>
      <ng-template pTemplate="header">
        <p>Internal Pressure: &nbsp; </p>
        <p-dropdown
          [disabled]="isReadOnly"
          appendTo="body"
          [options]="ipps"
          [formControlName]="form.formControlNames.type"
        ></p-dropdown>
      </ng-template>
      <ng-template pTemplate="body">
        <div [ngSwitch]="form.formGroup.value.type">
          <app-perical-link-ipp
            *ngSwitchCase="InternalPressureProfileType.PERICALLINK"
            [formControlName]="form.formControlNames.pericalLinkIpp"
            [thermalOperations]="thermalOperations"
          ></app-perical-link-ipp>
          <app-displacement-fluid-ipp
            *ngSwitchCase="InternalPressureProfileType.DISPLACEMENTFLUID"
            [formControlName]="form.formControlNames.displacementFluidIpp"
            [cement]="cement"
            [currentString]="currentString"
            [userUnits]="userUnits"
          ></app-displacement-fluid-ipp>
          <app-pressure-test-ipp
            *ngSwitchCase="InternalPressureProfileType.PRESSURETEST"
            [formControlName]="form.formControlNames.pressureTestIpp"
            [currentString]="currentString"
            [shoeDepth]="shoeDepth"
            [userUnits]="userUnits"
          ></app-pressure-test-ipp>
          <app-sssv-ipp
            *ngSwitchCase="InternalPressureProfileType.SSSV"
            [formControlName]="form.formControlNames.sssvIpp"
            [currentString]="currentString"
            [shoeDepth]="shoeDepth"
            [userUnits]="userUnits"
          ></app-sssv-ipp>
          <app-general-fluid-ipp
            *ngSwitchCase="InternalPressureProfileType.GENERALFLUID"
            [formControlName]="form.formControlNames.generalFluidIpp"
            [currentString]="currentString"
            [wellType]="wellType"
            [userUnits]="userUnits"
          ></app-general-fluid-ipp>
          <app-full-evac-ipp
            *ngSwitchCase="InternalPressureProfileType.FULLEVAC"
            [formControlName]="form.formControlNames.fullEvacIpp"
          ></app-full-evac-ipp>
          <app-partial-evac-by-md-ipp
            *ngSwitchCase="InternalPressureProfileType.PARTIALEVAC"
            [formControlName]="form.formControlNames.partialEvacByMdIpp"
            [currentString]="currentString"
            [userUnits]="userUnits"
          ></app-partial-evac-by-md-ipp>
          <app-wcd-ipp
            *ngSwitchCase="InternalPressureProfileType.WCDIPP"
            [formControlName]="form.formControlNames.wcdIpp"
            [userUnits]="userUnits"
          ></app-wcd-ipp>
          <app-fluid-drop-ipp
            *ngSwitchCase="InternalPressureProfileType.FLUIDDROP"
            [formControlName]="form.formControlNames.lostReturnsFluidDropIpp"
            [currentString]="currentString"
            [nextShoeDepth]="nextShoeDepth"
            [nextStringMudWeight]="nextStringMudWeight"
            [porePressures]="porePressures"
            [userUnits]="userUnits"
          ></app-fluid-drop-ipp>
          <app-lost-returns-water-ipp
            *ngSwitchCase="InternalPressureProfileType.LOSTRETURNSWATER"
            [formControlName]="form.formControlNames.lostReturnsWaterIpp"
            [currentString]="currentString"
            [fracPressures]="fracPressures"
            [userUnits]="userUnits"
          ></app-lost-returns-water-ipp>
          <app-circulated-kick-gas-ipp
            *ngSwitchCase="InternalPressureProfileType.GASKICK"
            [formControlName]="form.formControlNames.circulatedKickGasIpp"
            [currentString]="currentString"
            [shoeDepth]="shoeDepth"
            [nextShoeDepth]="nextShoeDepth"
            [influxDepthDefault]="influxDepthDefault"
            [nextStringMudWeight]="nextStringMudWeight"
            [fracPressures]="fracPressures"
            [smallestPipeId]="smallestPipeId"
            [userUnits]="userUnits"
          ></app-circulated-kick-gas-ipp>
          <app-circulated-kick-liquid-ipp
            *ngSwitchCase="InternalPressureProfileType.LIQUIDKICK"
            [formControlName]="form.formControlNames.circulatedKickLiquidIpp"
            [currentString]="currentString"
            [shoeDepth]="shoeDepth"
            [nextShoeDepth]="nextShoeDepth"
            [influxDepthDefault]="influxDepthDefault"
            [nextStringMudWeight]="nextStringMudWeight"
            [fracPressures]="fracPressures"
            [smallestPipeId]="smallestPipeId"
            [userUnits]="userUnits"
          ></app-circulated-kick-liquid-ipp>
          <app-custom-ipp
            *ngSwitchCase="InternalPressureProfileType.CUSTOMIPP"
            [formControlName]="form.formControlNames.customIpp"
            [currentString]="currentString"
            [userUnits]="userUnits"
            [wellTd]="wellTd"
          ></app-custom-ipp>
          <app-casing-full-of-gas-ipp
            *ngSwitchCase="InternalPressureProfileType.CFOG"
            [formControlName]="form.formControlNames.cfogIpp"
            [userUnits]="userUnits"
            [shoeDepth]="shoeDepth"
            [nextShoeDepth]="nextShoeDepth"
            [currentString]="currentString"
            [fracPressures]="fracPressures"
            [porePressures]="porePressures"
          ></app-casing-full-of-gas-ipp>
        </div>
      </ng-template>
    </p-panel>
  </form>
</app-tool-loader>
