
<label [style]="{position: 'absolute', top:'75px', zIndex: '1000'}">
  <p-checkbox [(ngModel)]="trajectoryPlotStore.state.isRotating" (onChange)="onRotationCheckChange($event)" binary="true"/>
  Rotate
</label>
<label [style]="{position: 'absolute', top:'95px', zIndex: '1000'}">
  <p-checkbox [(ngModel)]="trajectoryPlotStore.state.isShowingProjections" (onChange)="onProjectionsCheckChange($event)" binary="true"/>
  Projections
</label>
<plotly-plot
  *ngIf="plotlyData && layout"
  [data]="plotlyData"
  [layout]="layout"
  [config]="config"
  [updateOnDataChange]="true"
  [updateOnLayoutChange]="true"
  [useResizeHandler]="true"
  [style]="{ position: 'relative', width: '100%', height: '96%' }"
></plotly-plot>
