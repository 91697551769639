<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div class="p-toolbar-group-left">
        <div class="mr-2">
            <p-dropdown
              [(ngModel)]="apbResultsGridStore.state.selectedRadiusType"
              (onChange)="onRadiusTypeChange($event)"
              optionValue="value"
              optionLabel="label"
              [options]="radiusTypes"
              appendTo="body"
            ></p-dropdown>
          </div>
    </div>

    <br>

    <ng-table-results-cmp
        tableName="APB Results Grid"
        [tableData]="resultsGrid"
        [columnDefinitions]="cols"
        [tableHeight]="tableHeight"
        [selectedOperation]="apbResultsGridStore.state?.selectedRadiusType"
        [virtualScroll]="true"
    ></ng-table-results-cmp>

    <p-dialog
    [draggable]="false"
    [(visible)]="needToCalculate"
    [closable]="false"
  >
    <i class="pi pi-exclamation-circle"></i> Please calculate Perinix to view results <br />
    <br />
  </p-dialog>
</app-tool-loader>
