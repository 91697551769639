export module Units {
    export let lib = {
        ...longLengths(),
        ...shortLengths(),
        ...doglegSeverity(),
        ...temperatureGradient(),
        ...linearDensity(),
        ...temperature(),
        ...pressure(),
        ...stressPressure(),
        ...force(),
        ...speed(),
        ...density(),
        ...solidsDensity(),
        ...standardFluidFlow(),
        ...hydrocarbonFlow(),
        ...volume(),
        ...area(),
        ...thermalConductivity(),
        ...coefficientOfThermalExpansion(),
        ...gasInjectionRate(),
        ...massFlow(),
        ...specificHeatCapacity(),
        ...slurryFlow(),
        ...gasGradient(),
        ...enthalpy(),
        ...heat()
    };

    export function longLengths(): any {
        return {
          ft: { symbol: "ft", label: "API (Feet)" },
          m: { symbol: "m", label: "SI (Meters)" }
        };
      }

      export function shortLengths(): any {
        return {
          'in': { symbol: "m", label: "API (Inches)" },
          'cm': { symbol: "cm", label: "Centimeters" },
          'mm': { symbol: "mm", label: "Millimeters" }
        };
      }

      export function doglegSeverity(): any {
        return {
          'd-ft': { symbol: "°/100ft", label: "API (°/100ft)" },
          'd-m': { symbol: "°/30m", label: "SI (°/30m)" },
          'd-100m': { symbol: "°/100m", label: "°/100m" }
        };
      }

      export function temperatureGradient(): any {
        return {
          '∆°F/ft': { symbol: "°F/100ft", label: "API (°F/100ft)" },
          '∆°C/m': { symbol: "°C/30m", label: "SI (°C/30m)" },
          '∆°C/km': { symbol: "°C/100m", label: "°C/100m" }
        };
      }

      export function linearDensity(): any {
        return {
          'lb/ft': { symbol: "ppf", label: "API (ppf)" },
          'kg/m': { symbol: "kg/m", label: "SI (kg/m)" },
          'lb/in': { symbol: "lb/in", label: "lb/in" },
          'g/m': { symbol: "g/m", label: "g/m" },
          'g/cm': { symbol: "g/cm", label: "g/cm" }
        };
      }

      export function temperature(): any {
        return {
          '°F': { symbol: "°F", label: "API (°F)" },
          '°C': { symbol: "°C", label: "SI (°C)" },
          'K': { symbol: "K", label: "K" }
        };
      }

      export function pressure(): any {
        return {
          'psi': { symbol: "psi", label: "API (psi)" },
          'bar': { symbol: "bar", label: "SI (bar)" },
          'KPa': { symbol: "KPa", label: "KPa" },
          'atm': { symbol: "atm", label: "atm" }
        };
      }

      export function stressPressure(): any {
        return {
          'psi': { symbol: "psi", label: "API (psi)" },
          'KPa': { symbol: "KPa", label: "SI (KPa)" },
        };
      }
      
      export function force(): any {
        return {
          'lbf': { symbol: "lbf", label: "API (lbf)" },
          'tf': { symbol: "tonne", label: "SI (tonne)" },
          'kgf': { symbol: "kgf", label: "kgf" }
        };
      }

      export function speed(): any {
        return {
          'ft/s': { symbol: "ft/s", label: "API (ft/s)" },
          'm/s': { symbol: "m/s", label: "SI (m/s)" }
        };
      }

      export function density(): any {
        return {
          'ppg (U.S.)': { symbol: "ppg", label: "API (ppg (U.S.))" },
          'kg/m³': { symbol: "kg/m³", label: "SI (kg/m³)" },
          'g/cm³': { symbol: "g/cm³", label: "g/cm³" },
          'g/L': { symbol: "g/L", label: "g/l" },
          'kg/l': { symbol: "kg/l", label: "kg/l" }
        };
      }

      export function solidsDensity(): any {
        return {
          'lbm/ft³': { symbol: "lbm/ft³", label: "API (lbm/ft³)" },
          'kg/m³': { symbol: "kg/m³", label: "SI (kg/m³)" },
          'g/cm³': { symbol: "g/cm³", label: "g/cm³" }
        };
      }

      export function standardFluidFlow(): any {
        return {
          'GPM': { symbol: "gpm", label: "API (gpm)" },
          'm³/min': { symbol: "m³/min", label: "SI (m³/min)" }
        };
      }

      export function hydrocarbonFlow(): any {
        return {
          'bbl/d': { symbol: "bpd", label: "API (bpd)" },
          'm³/d': { symbol: "m³/d", label: "SI (m³/d)" }
        };
      }

      export function slurryFlow(): any {
        return {
          'bbl/min': { symbol: "bbl/min", label: "API (bbl/min)" },
          'm³/d': { symbol: "m³/d", label: "SI (m³/d)" }
        };
      }

      export function volume(): any {
        return {
          'bbl': { symbol: "bbl", label: "API (bbl)" },
          'm³': { symbol: "m³", label: "SI (m³)" },
          'l': { symbol: "l", label: "Liter" }
        };
      }

      export function area(): any {
        return {
          'in²': { symbol: "in²", label: "API (in²)" },
          'cm²': { symbol: "cm²", label: "SI (cm²)" }
        };
      }

      export function thermalConductivity(): any {
        return {
          'BTU/h·ft·°F': { symbol: "BTU/h·ft·°F", label: "API (BTU/h·ft·°F)" },
          'W/m·K': { symbol: "W/m·K", label: "SI (W/m·K)" }
        };
      }

      export function coefficientOfThermalExpansion(): any {
        return {
          '1/°F': { symbol: "1E-6/°F", label: "API (1E-6/°F)" },
          '1/°C': { symbol: "1E-6/°C", label: "SI (1E-6/°C)" }
        };
      }

      export function gasInjectionRate(): any {
        return {
          'scfh': { symbol: "MMscf/day", label: "API (MMscf/day)" },
          'Sm³/h': { symbol: "MMm³/d", label: "SI (MMm³/d)" }
        };
      }

      export function massFlow(): any {
        return {
          'lb/h': { symbol: "lbm/h", label: "API (lbm/h)" },
          't/d': { symbol: "MTPA", label: "SI (MTPA)" },
          'kg/s': { symbol: "kg/s", label: "kg/s" },
        };
      }

      export function specificHeatCapacity(): any {
        return {
          'BTU/ft³·°F': { symbol: "BTU/lbm-°F", label: "API (BTU/lbm-°F)" },
          'J/m³·°C': { symbol: "J/kg-K", label: "SI (J/kg-K)" }
        };
      }

      export function gasGradient(): any {
        return {
          'psi/ft': { symbol: "psi/ft", label: "API (psi/ft)" },
          'kPa/m': { symbol: "kPa/m", label: "SI (kPa/m)" },
          'sg': { symbol: "sg", label: "sg" },
          'bar/m': { symbol: "bar/m", label: "bar/m" }
        };
      }

      export function enthalpy(): any {
        return {
          'BTU/lbm': { symbol: "BTU/lbm", label: "API (BTU/lbm)" },
          'J/kg': { symbol: "J/kg", label: "SI (J/kg)" }
        };
      }

      export function heat(): any {
        return {
          'BTU': { symbol: "BTU", label: "API (BTU)" },
          'J': { symbol: "J", label: "SI (J)" }
        };
      }

      export function getSelectList(units: any): Array<{ label: string, key: string }> {
        return Object.keys(units).map(key => {
          return { label: units[key].label, key };
        });
      }
  };