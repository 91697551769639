<form [formGroup]="form.formGroup">
  <div class="grid">
    <p-fieldset legend="Lower Stage (Bottom Plug)" class="col-6 leftFieldset">
      <div class="field col-12">
        <uom-quantity-input
          label="Surface Pressure"
          [unitLabel]="userUnits.pressure"
          [formControlName]="form.formControlNames.lowerStageSurfacePressure"
          [control]="'lowerStageSurfacePressure'"
          [default]="'0'"
          [validators]="validators.lowerStageSurfacePressure"
          title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
        ></uom-quantity-input>
      </div>
      <div class="field col-12">
        <uom-quantity-input
          label="Fluid Density"
          [unitLabel]="densityLabel"
          [formControlName]="form.formControlNames.lowerStageFluidDensity"
          [control]="'lowerStageFluidDensity'"
          [default]="currentString.annularFluid.state?.nominalDensity"
          [validators]="validators.lowerStageFluidDensity"
          title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
        ></uom-quantity-input>
      </div>
      <div class="field col-12">

        <input
          style="height: 13px; width: 20px;"
          type="checkbox"
          binary="true"
          [formControlName]="form.formControlNames.lowerStageHasPlug"
          (change)="lowerStagePlugChange()"
        >
        <label style="font-size: 14px; padding-left: 5px;">Has Plug</label>
      </div>
      <div class="field col-12">
        <uom-quantity-input
          label="Plug Depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.lowerStagePlugMd"
          [control]="'lowerStagePlugMd'"
          [default]="null"
          [validators]="validators.lowerStagePlugMd"
          [inputDisabled]="lowerStagePlugDepthDisabled"
        ></uom-quantity-input>
      </div>
    </p-fieldset>
    <p-fieldset legend="Upper Stage (Top Plug)" class="col">
      <div class="field col-12">
        <input
          style="height: 13px; width: 20px;"
          type="checkbox"
          binary="true"
          [formControlName]="form.formControlNames.upperStageEvacAbovePlug"
          (change)="upperStagePlugChange()"
        >
        <label style="font-size: 12px; padding-left: 5px;">Evacuated above plug</label>
      </div>
      <div class="field col-12">
        <uom-quantity-input
          label="Surface Pressure"
          [unitLabel]="userUnits.pressure"
          [formControlName]="form.formControlNames.upperStageSurfacePressure"
          [control]="'upperStageSurfacePressure'"
          [default]="'0'"
          [validators]="validators.upperStageSurfacePressure"
          [inputDisabled]="upperStageEvacAbovePlug"
          title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
        ></uom-quantity-input>
      </div>
      <div class="field col-12">
        <uom-quantity-input
          label="Fluid Density"
          [unitLabel]="densityLabel"
          [formControlName]="form.formControlNames.upperStageFluidDensity"
          [control]="'upperStageFluidDensity'"
          [default]="currentString.annularFluid.state?.nominalDensity"
          [validators]="validators.upperStageFluidDensity"
          [inputDisabled]="upperStageEvacAbovePlug"
          title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
        ></uom-quantity-input>
      </div>
      <div class="field col-12">
        <uom-quantity-input
          label="Plug/Valve Depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.upperStagePlugMd"
          [control]="'upperStagePlugMd'"
          [default]="currentString.hangerMd + 100"
          [validators]="validators.upperStagePlugMd"
        ></uom-quantity-input>
      </div>
    </p-fieldset>
  </div>
</form>
