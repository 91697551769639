<div bg-dark>
  <form [formGroup]="form.formGroup" bg-dark>
    <div class="grid">
      <div class="field col-12">
        <label class="pressureLabel">{{ topPressureLabel }}</label>
        <validation-error
          [isValid]="isControlInvalid(form.formGroup, 'hangerPressure')"
          [errorMsg]="getControlErrors(form.formGroup, 'hangerPressure')"
        ></validation-error>
        <div class="p-inputgroup">
          <p-inputNumber
            [formControlName]="form.formControlNames.hangerPressure"
          [maxFractionDigits]="2"
            title="Allowed range: {{ pressureValidation.min }} - {{
              pressureValidation.max
            }} {{ pressureLabel }}"
            (onFocus)="onInputFocus($event)"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ userUnits.pressure }}</span>
        </div>
      </div>

      <div class="field col-12">
        <label class="densityLabel">Fluid Density</label>
        <validation-error
          [isValid]="isControlInvalid(form.formGroup, 'fluidDensity')"
          [errorMsg]="getControlErrors(form.formGroup, 'fluidDensity')"
        ></validation-error>
        <div class="p-inputgroup">
          <p-inputNumber
            [formControlName]="form.formControlNames.fluidDensity"
          [maxFractionDigits]="2"
            title="Allowed range: {{ densityValidation.min }} - {{
              densityValidation.max
            }} {{ densityLabel }}"
            (onFocus)="onInputFocus($event)"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ densityLabel }}</span>
        </div>
      </div>
    </div>
  </form>
</div>
