import { UntypedFormGroup } from "@angular/forms";
import { WellComponent } from "../models";

export function hangerAbovePreviousShoe(control: UntypedFormGroup) {
    let wellConfiguration = control?.parent?.parent?.getRawValue() as Array<WellComponent>; //Using RawValue so we can get the disabled inputs.
    let hangerMd :number = control?.value;
    if(!wellConfiguration || !hangerMd || control.pristine){
        return null;
    }
    let tubularString = control?.parent.getRawValue() as WellComponent;
    let idx = wellConfiguration.findIndex(wc => wc.id == tubularString.id);
    if(idx == 0){
        return null;
    }
    let previousTubular = wellConfiguration[idx -1];
    let previousCasingShoe = previousTubular.shoeMd;
    if( hangerMd > previousCasingShoe){
       return { error: `Hanger must be set above previous shoe: ${previousCasingShoe}ft \n` };
    }
    return null;
 }
