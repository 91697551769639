  <app-tool-loader
    class="performanceInput"
    [isLoading]="isLoading"
    positionUnset="true"
  >
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="chokes">
      <p-dialog
        modal="true"
        [(visible)]="chokePerformanceCalculating"
        [draggable]="false"
      >
        <i class="pi pi-exclamation-circle"></i> Please wait while Choke Performance
        is being calculated <br />
        <br />
      </p-dialog>

      <div class="grid">
        <div class="col">
          <form *ngIf="chokePerformanceForm" [formGroup]="chokePerformanceForm" bg-dark>
            <p-fieldset legend="Input Configuration">
              <div [hidden]="false">
                <div class="field">
                  <label for="hardCement">Choke</label>
                  <p-dropdown
                    [options]="chokes"
                    formControlName="choke"
                    optionLabel="name"
                    dataKey="id"
                    appendTo="body"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Opening / Travel</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="openingTravel"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <p
                    *ngIf="
                      chokePerformanceForm?.controls.openingTravel.value &&
                      chokePerformanceForm?.controls.openingTravel.invalid
                    "
                    class="error"
                  >
                    Allowed range: 0% - 100%
                  </p>
                </div>

                <div class="field">
                  <label for="pressure">Fluid</label>
                  <p-dropdown
                    [options]="fluids"
                    optionLabel="state.name"
                    appendTo="body"
                    (onChange)="selectedFluidChange($event)"
                  >
                  </p-dropdown>
                </div>

                <div class="field">
                  <label>Gas Flow Rate</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="gasFlowRate"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ flowRateUnit }}</span>
                  </div>
                  <p
                    *ngIf="
                      chokePerformanceForm?.controls.gasFlowRate.value &&
                      chokePerformanceForm?.controls.gasFlowRate.invalid
                    "
                    class="error"
                  >
                    Allowed range: {{ gasFlowRateValidation.min }} -
                    {{ gasFlowRateValidation.max }}
                  </p>
                </div>

                <div class="field">
                  <label>Upstream Pressure</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="upstreamPressure"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
                  </div>
                  <p
                    *ngIf="
                      chokePerformanceForm?.controls.upstreamPressure.value &&
                      chokePerformanceForm?.controls.upstreamPressure.invalid
                    "
                    class="error"
                  >
                    Allowed range: {{ pressureValidation.min }} -
                    {{ pressureValidation.max }}
                  </p>
                </div>

                <div class="field">
                  <label>Upstream Temperature</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      formControlName="upstreamTemperature"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                  </div>
                  <p
                    *ngIf="
                      chokePerformanceForm?.controls.upstreamTemperature.value &&
                      chokePerformanceForm?.controls.upstreamTemperature.invalid
                    "
                    class="error"
                  >
                    Allowed range: {{ temperatureValidation.min }} -
                    {{ temperatureValidation.max }}
                  </p>
                </div>
                <p-button
                  [disabled]="chokePerformanceForm?.invalid"
                  class="calculateButton w-full flex justify-content-center"
                  styleClass="p-button-sm p-button-warning"
                  label="Calculate"
                  (onClick)="calculateChokePerformance()"
                >
                </p-button>
              </div>
            </p-fieldset>
          </form>
        </div>

        <div class="col">
          <p-fieldset legend="Performance Output">
            <div [hidden]="false">
              <div class="field">
                <label>Downstream Pressure</label>
                <div disabled="true" class="p-inputgroup">
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{ chokePerformanceResult?.downstreamPressure }}"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
                </div>
              </div>

              <div class="field">
                <label>Downstream Temperature</label>
                <div class="p-inputgroup">
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{
                      chokePerformanceResult?.downstreamTemperature
                    }}"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                </div>
              </div>

              <div class="field">
                <label>Effective Cv</label>
                <div class="p-inputgroup">
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{ chokePerformanceResult?.effectiveCv }}"
                  ></p-inputNumber>
                </div>
              </div>

              <div class="field">
                <label>Effective Flow Rate</label>
                <div class="p-inputgroup">
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{ chokePerformanceResult?.effectiveFlowRate }}"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">{{ flowRateUnit }}</span>
                </div>
              </div>

              <div class="field">
                <label>Effective Mass Flow Rate</label>
                <div class="p-inputgroup">
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{
                      chokePerformanceResult?.effectiveMassFlowRate
                    }}"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">{{ massFlowUnit }}</span>
                </div>
              </div>

              <div class="grid">
                <div class="col-6">
                  <div class="field">
                    <label>Upstream Phase</label>
                    <div class="p-inputgroup">
                      <p-inputNumber
                        [minFractionDigits]="1"
                        [disabled]="true"
                        placeholder="{{ chokePerformanceResult?.upstreamPhase }}"
                      ></p-inputNumber>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="field">
                    <label>Downstream Phase</label>
                    <div class="p-inputgroup">
                      <p-inputNumber
                        [minFractionDigits]="1"
                        [disabled]="true"
                        placeholder="{{ chokePerformanceResult?.downstreamPhase }}"
                      ></p-inputNumber>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  chokePerformanceResult?.isChoked &&
                  chokePerformanceResult?.downstreamPressure
                "
                class="field"
              >
                <br />
                <label class="warning"
                  >Warning: flow is choked and/or subject to flashing. Nominal
                  downstream performance without choking:
                </label>
                <br /><br />

                <div class="field">
                  <label>Downstream Pressure (Non-Choked)</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{
                        chokePerformanceResult?.downstreamPressureNonChoked
                      }}"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
                  </div>
                </div>

                <div class="field">
                  <label>Downstream Temperature (Non-Choked)</label>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{
                        chokePerformanceResult?.downstreamTemperatureNonChoked
                      }}"
                    ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>
    </div>
  </p-scrollPanel>
  </app-tool-loader>