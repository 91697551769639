import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';
import { Subscription, debounceTime } from 'rxjs';
import { setPlotImageColorsForExport, setPlotImageColorsForInterface } from 'src/app/shared/helpers/plot-export-colors.helper';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
  selector: 'app-xy-line-plot',
  templateUrl: './xy-line-plot.component.html',
  styleUrls: ['./xy-line-plot.component.scss']
})
export class XyLinePlotComponent implements OnChanges, OnDestroy {
  public plot = {
    data: [],
    layout: {
    },
    config: { }
  };

  @Input()
  public componentHeight: number;

  @ViewChild('plotElement', { read: ElementRef, static: false })
  public plotElement: ElementRef;

  @Input()
  public plotName: string;

  @Input()
  public plotTitle: string;

  @Input()
  public downloadPlotName: string;

  @Input()
  public plotData: any[];

  @Input()
  public xAxisTitle: string;

  @Input()
  public yAxisTitle: string;

  @Input()
  public reverseAutoRange: boolean = false;

  @Input()
  public legendBelowPlot: boolean = false;

  @Input()
  public xAxisRange: number[] = null;

  @Input()
  public yAxisRange: number[] = null;

  @Input()
  public annotations: any[] = [];

  @Input()
  public shapes: any[] = [];

  @Input()
  public plotOrientation: string = 'h';

  @Input()
  public legendGroupClick: string = 'toggleitem';

  public showFormatAxis: boolean = false;
  public plotHeightBackup: number;
  public plotHeight: string = '90%';
  public xAxisRangePlaceholder: number[] = [0, 0];
  public yAxisRangePlaceholder: number[] = [0, 0];
  public xAxisTicksPlaceholder: number;
  public yAxisTicksPlaceholder: number;
  public showScrollOverlay: boolean = false;

  private resizeSubscription: Subscription;
  private yAxisRangeBackup: number[] = [0, 0];
  private xAxisRangeBackup: number[] = [0, 0];
  private enableScroll: boolean = false;

  constructor(
    private plotlyService: PlotlyService,
    private _messenger: MediatorService) {
    this.resizeSubscription = new Subscription();
    addEventListener("keydown", (event) => {
      if (event.code == "Shift" || event.code == "ShiftLeft" || event.code == "ShiftRight") {
        if (!this.enableScroll) {
          const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);

          this.populateBackups(graphDiv);

          this.generateConfig();
        }
        this.enableScroll = true;
      }
    });

    addEventListener("keyup", (event) => {
    if (event.code == "Shift" || event.code == "ShiftLeft" || event.code == "ShiftRight") {
      this.enableScroll = false;

      const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
      let yAxisMin = graphDiv['layout']['yaxis'].range[0].toFixed(0);
      let yAxisMax = graphDiv['layout']['yaxis'].range[1].toFixed(0);
      let xAxisMin = graphDiv['layout']['xaxis'].range[0].toFixed(0);
      let xAxisMax = graphDiv['layout']['xaxis'].range[1].toFixed(0);

      this.changeAxis({ srcElement: { value: yAxisMin } }, 'yAxisRange', 0);
      this.changeAxis({ srcElement: { value: yAxisMax } }, 'yAxisRange', 1);
      this.changeAxis({ srcElement: { value: xAxisMin } }, 'xAxisRange', 0);
      this.changeAxis({ srcElement: { value: xAxisMax } }, 'xAxisRange', 1);
    }
    });

    this.resizeSubscription.add(this._messenger.of(GridItemResizedMessage).pipe(debounceTime(450)).subscribe(msg => {
      if (msg.name == this.plotTitle) {
        if (this.componentHeight) {
          this.plotHeight = this.showFormatAxis ? this.componentHeight - 155 + 'px' : this.componentHeight + 'px';
        } else {
          this.plotHeight = this.showFormatAxis ? msg.itemHeight - 290 + 'px' : '90%';
        }
      }
      this.generateConfig();
    }));
  }

  public async onScroll() {
    if (!this.enableScroll) {
      this.showScrollOverlay = true;

      setTimeout(() => {
        this.showScrollOverlay = false;
      }, 1300);
    }
  } 

  ngOnChanges() {
    if (this.componentHeight) {
      this.plotHeight = this.componentHeight + 'px';
    }
    this.generateConfig();
  }

  public resetFormatAxis() {
    this.yAxisRange = null;
    this.xAxisRange = null;
    this.xAxisTicksPlaceholder = null;
    this.yAxisTicksPlaceholder = null;
    this.generateConfig();
    this.yAxisRangePlaceholder = [...this.yAxisRangeBackup];
    this.yAxisRangePlaceholder = [...this.yAxisRangeBackup];

    this.xAxisRangePlaceholder = [...this.xAxisRangeBackup];
    this.xAxisRangePlaceholder = [...this.xAxisRangeBackup];
  }

  public expandFormatAxis() {
    this.showFormatAxis = !this.showFormatAxis;
    if (this.componentHeight) {
      this.plotHeight = this.showFormatAxis ? this.componentHeight - 154 + 'px' : this.componentHeight + 'px';
    } else {
      this.plotHeight = this.showFormatAxis ? this.plotElement.nativeElement.offsetHeight - 154 + 'px' : '90%';
    }

    this.generateConfig();
    this.populatePlaceholders();
  }

  private populatePlaceholders() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);

    this.populateBackups(graphDiv);

    this.xAxisRangePlaceholder[0] = graphDiv['layout']['xaxis'].range[0].toFixed(0);
    this.xAxisRangePlaceholder[1] = graphDiv['layout']['xaxis'].range[1].toFixed(0);

    this.yAxisRangePlaceholder[0] = graphDiv['layout']['yaxis'].range[0].toFixed(0);
    this.yAxisRangePlaceholder[1] = graphDiv['layout']['yaxis'].range[1].toFixed(0);
  }

  populateBackups(graphDiv: Plotly.PlotlyHTMLElement) {
    if (this.yAxisRangeBackup[0] == 0 && this.yAxisRangeBackup[1] == 0) {
      this.yAxisRangeBackup[0] = graphDiv['layout']['yaxis'].range[0].toFixed(0);
      this.yAxisRangeBackup[1] = graphDiv['layout']['yaxis'].range[1].toFixed(0);

      this.xAxisRangeBackup[0] = graphDiv['layout']['xaxis'].range[0].toFixed(0);
      this.xAxisRangeBackup[1] = graphDiv['layout']['xaxis'].range[1].toFixed(0);
    }
  }

  public changeAxis(e, axis, range) {
    let value = e.srcElement.value;
    if (value == '') {
      return;
    }
    this[axis] = this[axis + 'Placeholder'];
    this[axis][range] = value;
    this.generateConfig();
  }

  public changeTicks(e, axis) {
    let value = e.srcElement.value;
    if (value == '') {
      return;
    }
    this[axis + 'TicksPlaceholder'] = value;
    this.generateConfig();
  }

  private async generateConfig() {
    const Plotly = await this.plotlyService.getPlotly();
    this.plot.data = this.plotData;
    this.plot.layout = {
      autoexpand: 'true',
      autosize: 'true',
      margin: { t: (this.legendBelowPlot ? 50 : 90), r: 10, l: 80, b: 5 },
      offset: 0,
      type: 'scattergl',
      hovermode: 'closest',
      plot_bgcolor: '#1C1C1C',
      paper_bgcolor: '#1C1C1C',
      hoverlabel: { namelength: -1 },
      legend: {
        font: {
          color: 'white',
          size: 11
        },
        orientation: this.plotOrientation,
        groupclick: this.legendGroupClick,
      },
      annotations: this.annotations,
      shapes: this.shapes,
      xaxis: {
        range: this.xAxisRange,
        side: this.legendBelowPlot ? '' : 'top',
        linecolor: 'grey',
        mirror: true,
        dtick: this.xAxisTicksPlaceholder,
        tickfont: {
          color: 'white'
        },
        tickcolor: 'grey',
        gridcolor: '#454545',
        zerolinecolor: '#5C5c5c',
        zerolinewidth: 2,
        tickformat:"f",
        automargin: this.legendBelowPlot ? true : false,
        title: {
          text: this.xAxisTitle,
          font: {
            color: 'white'
          }
        },
        autorange: this.xAxisRange ? this.xAxisRange : true,
        hoverformat: '.2f'
      },
      yaxis: {
        range: this.yAxisRange,
        linecolor: 'grey',
        linewidth: 1,
        mirror: true,
        dtick: this.yAxisTicksPlaceholder,
        tickfont: {
          color: 'white'
        },
        tickcolor: 'grey',
        gridcolor: '#454545',
        zerolinecolor: '#5c5c5c',
        zerolinewidth: 2,
        tickformat:"f",
        title: {
          text: this.yAxisTitle,
          font: {
            color: 'white'
          }
        },
        autorange: this.yAxisRange ? this.xAxisRange : this.reverseAutoRange ? '' : 'reversed',
        hoverformat: '.2f'
      }
    };
    this.plot.config = {
      responsive: true,
      scrollZoom: this.enableScroll,
      displaylogo: false,
      useResizeHandler: true,
      modeBarButtons: [
        [
          {
            name: 'Format Axis',
            icon: Plotly.Icons.logomark,
            click: this.expandFormatAxis.bind(this)
          },
          {
          name: 'Download plot as png with white background',
          icon: Plotly.Icons.camera,
          click: this.downloadPlot.bind(this)
        }, 'toImage'],
        ['zoom2d', 'pan2d'],
        ['zoomIn2d', 'zoomOut2d', 'autoScale2d'], ['hoverClosestCartesian', 'hoverCompareCartesian']
      ]
    };
  }

  async downloadPlot() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
    const Plotly = await this.plotlyService.getPlotly();
    Plotly.downloadImage(graphDiv, {
      filename: this.downloadPlotName,
      width: this.plotElement.nativeElement.offsetWidth,
      height: this.plotElement.nativeElement.offsetHeight,
      format: 'png',
      setImageColors: setPlotImageColorsForExport(graphDiv)
    }).then(setPlotImageColorsForInterface(graphDiv));
  }

  ngOnDestroy() {
    this.resizeSubscription?.unsubscribe();
  }
}
