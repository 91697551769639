import { UntypedFormControl } from "@angular/forms";

export function wellheadElevationAboveDfe(control: UntypedFormControl) {
    let wellheadElevation = control.parent && control.parent.controls['wellheadElevation'].value;
    let drillFloorElevation = control.parent && control.parent.controls['drillFloorElevation'].value;
    if(wellheadElevation > drillFloorElevation){
       return {error: "Must be above DFE"};
    }
    return null;
  }
