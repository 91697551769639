// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'DEV',
  PeriforEndpoint: 'https://dev-api.perifor.com',
  auth: {
    domain: 'dev-n8g3nae8.eu.auth0.com',
    clientId : 'yGpsp0BKSPMwUylZjtR2AgmBkKNEtu3y',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience : 'https://periforbackend.azurewebsites.net',
      scope: ' profile email run:perivis run:perical run:perinix write:perifordata'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
