<form [formGroup]="form.formGroup" bg-dark>
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Operation Details </ng-template>
      <div class="grid">
        <div class="col-6">
          <div class="field">
            <label for="tripIntoHole">Trip Into Hole</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.tripIntoHole"
              />
              <span class="p-inputgroup-addon">hr</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.tripIntoHole.value &&
                form.formGroup.controls.tripIntoHole.invalid
              "
              class="error"
            >
              Allowed range: 0 - 100000 hrs
            </p>
          </div>

          <div class="field">
            <label for="circulationDepth">Circulation Depth</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.circulationDepth"
              />
              <span class="p-inputgroup-addon">{{
                userUnits.longLengths
              }}</span>
            </div>
            <p
              *ngIf="form.formGroup.controls.circulationDepth.invalid"
              class="error"
            >
              Max value: {{ currentShoe }}{{ userUnits.longLengths }}
            </p>
          </div>

          <app-standard-fluid
            [formControlName]="form.formControlNames.standardFluidFlow"
            [operationType]="operationType"
            [userUnits]="userUnits"
          ></app-standard-fluid>

          <div class="field">
            <label for="circulationTime">Circulation Time</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.circulationTime"
              />
              <span class="p-inputgroup-addon">hr</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.circulationTime.value &&
                form.formGroup.controls.circulationTime.invalid
              "
              class="error"
            >
              Allowed range: 0 - 100000 hrs
            </p>
          </div>

          <div class="field">
            <div class="grid">
              <div class="col-6">
                <label for="pressure">POOH? / Time</label>
                <div class="p-inputgroup">
                  <p-inputSwitch
                      [disabled]="isReadOnly"
                      [formControlName]="form.formControlNames.isPullOutOfHole"
                    id="isPoolOutOfHole"
                    (onChange)="poohChange($event, true)"
                    binary="true"
                  ></p-inputSwitch>
                  <p-inputNumber
                    [disabled]="poohDisabled"
                      [formControlName]="form.formControlNames.pullOutOfHoleTime"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">hr</span>
                </div>
                <p
                  *ngIf="form.formGroup.controls.pullOutOfHoleTime.invalid"
                  class="error"
                >
                  Allowed range: 0 - 100000 hrs
                </p>
              </div>
            </div>
          </div>

          <p-checkbox
            label=" Open hole operation (current casing/liner not run yet)"
            formControlName="isNextStringInPlace"
            id="isNextStringInPlace"
            binary="true"
          ></p-checkbox>
        </div>

        <div class="col-6">
          <div class="field">
            <label for="fluids">Fluid</label>
            <p-dropdown
                [disabled]="isReadOnly"
                [options]="fluids"
              [formControlName]="form.formControlNames.fluid"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.fluid?.state.name }}
              </ng-template>
              <ng-template let-fluid pTemplate="item">
                {{ fluid.state.name }}
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field">
            <label for="inletTemperature">Inlet Temperature</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.inletTemperature"
              />
              <span class="p-inputgroup-addon">{{ tempUnit }}</span>
            </div>
            <p
              *ngIf="form.formGroup.controls.inletTemperature.invalid"
                class="error" 
            >
              Allowed range: {{ temperatureValidation.min }} -
              {{ temperatureValidation.max }} {{ tempUnit }}
            </p>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        Drill String
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="drillStringMissing"
          pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </ng-template>

      <label class="{{ drillStringMissing ? 'error' : '' }}">{{
        selectedDrillPipe
      }}</label>

      <br *ngIf="selectedHwdp && hasHwdp" />
      <label *ngIf="selectedHwdp && hasHwdp">{{ selectedHwdp }}</label>

      <br *ngIf="selectedCollar && hasCollar" />
      <label *ngIf="selectedCollar && hasCollar">{{ selectedCollar }}</label>
      <br />
      <br />

      <div class="field">
        <label for="totalFlowArea">Nozzle Flow Area</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.totalFlowArea"
          />
          <div class="p-inputgroup-addon">
            <label>{{ areaUnit }}</label>
          </div>
        </div>
        <p class="error" *ngIf="form.formGroup.controls.totalFlowArea.invalid">
          Allowed range: {{ totalFlowAreaValidation.min }} -
          {{ totalFlowAreaValidation.max }} {{ areaUnit }}
        </p>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="showRiser">
      <ng-template pTemplate="header">
        Riser
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="
            form.formGroup.controls?.insideDiameter.invalid ||
            form.formGroup.controls?.outsideDiameter.invalid
          "
          pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </ng-template>

      <p-fieldset legend="Riser">
        <div class="field">
          <label for="outsideDiameter">Outside Diameter</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.outsideDiameter"
            />
            <span class="p-inputgroup-addon">{{ userUnits.shortLengths }}</span>
          </div>
        </div>
        <div class="field">
          <label for="outsideDiameter">Inside Diameter</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.insideDiameter"
            />
            <label class="p-inputgroup-addon">{{
              userUnits.shortLengths
            }}</label>
          </div>
        </div>
      </p-fieldset>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header"> Other </ng-template>

      <div class="field">
        <label for="pipeRoughness">Pipe Roughness</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.pipeRoughness"
          />
          <label class="p-inputgroup-addon">{{ pipeRoughnessUnit }}</label>
        </div>
        <p class="error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
          Allowed range: {{ pipeRoughnessValidation.min }} -
          {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths }}
        </p>
      </div>
      <br />
    </p-tabPanel>
    <br />
    <br />
  </p-tabView>
</form>
<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->
