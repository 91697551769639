<p-dialog
  [draggable]="false"
  [visible]="isDialogVisible"
  [closable]="false"
>
  <i class="pi pi-exclamation-circle"></i> {{ disabledMessage }} <br />
  <br />
</p-dialog>

<app-tool-loader
  class="drillstring"
  [isLoading]="isLoading"
  positionUnset="true"
>
  <div class="header">
    <label *ngIf="!isTubingOrTieback" class="stringLabel">{{
      currentStringName
    }}</label>
  </div>
  <hr *ngIf="!isTubingOrTieback" class="separator" />

  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div>
      <form [formGroup]="drillstringForm" bg-dark>
        <p-fieldset legend="Drill Pipe">
          <div class="grid">
            <div class="col">
              <div class="field">
                <label>Drill Pipe</label>
                <p-dropdown
                  placeholder="Select"
                  formControlName="drillPipe"
                  [options]="drillPipes"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-2">
              <div class="field">
                <label></label>
                <div class="p-inputgroup">
                  <p-button
                    class="resetButtons"
                    pTooltip="Reset Drill Pipe"
                    styleClass="p-button-sm p-button-warning"
                    label="Reset"
                    (onClick)="resetDrillPipe()"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </p-fieldset>

        <br />

        <p-fieldset legend="Heavy Weight Drill Pipe">
          <div class="grid">
            <div class="col-7">
              <div class="p-field">
                <label>HWDP</label>
                <p-dropdown
                  placeholder="Select"
                  formControlName="heavyWeightDrillPipe"
                  [options]="hwdps"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-3">
              <div class="field">
                <label class="lengthLabel">Length</label>
                <div class="p-inputgroup">
                  <input formControlName="hwdpLength" pInputText required />
                  <span class="p-inputgroup-addon">{{ longLengthUnit }}</span>
                </div>
              </div>
            </div>

            <div class="col-2">
              <div class="field">
                <label></label>
                <div class="p-inputgroup">
                  <p-button
                    class="resetButtons"
                    pTooltip="Reset HWDP Data"
                    styleClass="p-button-sm p-button-warning"
                    label="Reset"
                    (onClick)="resetHwdp()"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </p-fieldset>

        <br />

        <p-fieldset legend="Collars">
          <div class="grid">
            <div class="col-7">
              <div class="p-field">
                <label>Collar</label>
                <p-dropdown
                  placeholder="Select"
                  formControlName="collar"
                  [options]="collars"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-3">
              <div class="field">
                <label class="lengthLabel">Length</label>
                <div class="p-inputgroup">
                  <input formControlName="collarLength" pInputText required />
                  <span class="p-inputgroup-addon">{{ longLengthUnit }}</span>
                </div>
              </div>
            </div>

            <div class="col-2">
              <div class="field">
                <label></label>
                <div class="p-inputgroup">
                  <p-button
                    class="resetButtons"
                    pTooltip="Reset Collar Data"
                    styleClass="p-button-sm p-button-warning"
                    label="Reset"
                    (onClick)="resetCollar()"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </p-fieldset>
      </form>
    </div>
  </p-scrollPanel>
</app-tool-loader>
