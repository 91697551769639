import { TemperatureDerationCatalog } from "src/app/catalogs/models/temperature-deration-catalog.model";

//TODO: Looks like this is same as Grade Catalog...
export class GradeReference {
  
    constructor(init?: Partial<GradeReference>) {
      this.id = init && init.id || "";
      this.name = init && init.name || "";
      this.minimumApiYieldStrength = init && init.minimumApiYieldStrength || 0;
      this.poissonsRatio = init && init.poissonsRatio || 0;
      this.thermalExpansionCoefficient = init && init.thermalExpansionCoefficient || 0;
      this.density = init?.density || 0;
      this.thermalConductivity = init?.thermalConductivity || 0;
      this.specificHeatCapacity = init?.specificHeatCapacity || 0;
      this.ultimateTensileStrength = init && init.ultimateTensileStrength || 0;
      this.youngsModulus = init && init.youngsModulus || 0;
      this.temperatureDeration = init?.temperatureDeration || new TemperatureDerationCatalog();
      this.strengthCalculationOption = init?.strengthCalculationOption || "Api";
    }
  
    id: string;
    minimumApiYieldStrength: number;
    name: string;
    poissonsRatio: number;
    thermalExpansionCoefficient: number;
    density: number;
    specificHeatCapacity : number;
    thermalConductivity : number;
    ultimateTensileStrength : number;
    youngsModulus: number;
    temperatureDeration: TemperatureDerationCatalog
    strengthCalculationOption: string;
}
