<div class="header" *ngIf="wellSchematicStore?.state">
  <i
    class="pi pi-download p-button p-button-text p-button-sm export"
    id="exportPng"
    title="Download .png"
    (click)="onExportPng()"
  ></i>

  <div class="p-inputGroup">
    <label for="currentStageOnly">Current Stage &nbsp;</label>
    <p-inputSwitch
      id="currentStageOnly"
      [(ngModel)]="wellSchematicStore.state.currentStageOnly"
      (onChange)="onCurrentStageOnly($event)"
    ></p-inputSwitch>

    <label for="toggleLabels">No Labels &nbsp;</label>
    <p-inputSwitch
      id="toggleLabels"
      [(ngModel)]="wellSchematicStore.state.drawNoLabels"
      (onChange)="onLabelToggle($event)"
    ></p-inputSwitch>
  </div>
  <div *ngIf="data?.wellType?.type !== 'Land'" >
    <label for="toggleWater">MSL &nbsp;</label>
    <p-inputSwitch
      [(ngModel)]="wellSchematicStore.state.drawWater"
      id="toggleWater"
      (onChange)="onWaterToggle($event)"
    ></p-inputSwitch>
  </div>
</div>

<div class="infoBoxWrapper">
  <schematic-info [tubular]="selectedTubularString"></schematic-info>
</div>

<canvas
  #wellSchematic
  id="wellSchematic"
  pTooltip="Scroll to Zoom"
  (dblclick)="drawSchematic()"
  (mousewheel)="onMouseWheel($event)"
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp($event)"
  (mousemove)="onMouseMove($event)"
  (mouseover)="onMouseOver($event)"
  resize
></canvas>
