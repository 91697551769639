import { BaseOperation } from "./thermal-operation.model";

export interface ThermalResults {
  operationId: string;
  name: string;
  operationType: string;
}

export interface TemperatureResults extends ThermalResults {
  phases: TemperatureResultPhase[];
}

export interface PressureResults extends ThermalResults {
  phases: PressureResultPhase[];
}

export interface TemperatureResultPhase {
  phaseNumber: number;
  temperatureResultsTable: ResultsTable;
}

export interface PressureResultPhase {
  phaseNumber: number;
  pressureResultsTable: ResultsTable;
}

export interface ResultsTable {
  headers: string[];
  rows: [number[]];
}

export interface DetailedAnnularResultsPhase {
  phaseNumber: number;
  annulusResultsTables: ResultsTable;
}

export class TemperatureResultsUi {
  public constructor(init?: Partial<TemperatureResultsUi>) {
    this.selectedSingleMultiple = init && init.selectedSingleMultiple || 'singleOperation';
    this.selectedResultsDisplay = init && init.selectedResultsDisplay || 'plot';
    this.showHeatmap = init && init.showHeatmap || true;
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.syncThermalResults = init && init.syncThermalResults || true;
    this.selectedOperation = init && init.selectedOperation || null;
    this.selectedAnnulus = init && init.selectedAnnulus || 'innerfluid';
    this.selectedPhase = init && init.selectedPhase || null;
    this.showWellborePoints = init && init.showWellborePoints || true;
    this.showFormationPoints = init && init.showFormationPoints || false;
  }
  selectedSingleMultiple: string;
  selectedResultsDisplay: string;
  showHeatmap: boolean;
  selectedDepthView: string;
  syncThermalResults: boolean;
  selectedOperation: Partial<BaseOperation>;
  selectedAnnulus: string;
  selectedPhase: TemperatureResultPhase;
  showWellborePoints: boolean;
  showFormationPoints: boolean;
}

export class PressureResultsUi {
  public constructor(init?: Partial<PressureResultsUi>) {
    this.selectedSingleMultiple = init && init.selectedSingleMultiple || 'singleOperation';
    this.selectedResultsDisplay = init && init.selectedResultsDisplay || 'plot';
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.syncThermalResults = init && init.syncThermalResults || true;
    this.selectedOperation = init && init.selectedOperation || null;
    this.selectedAnnulus = init && init.selectedAnnulus || 'innerfluid';
    this.selectedPhase = init && init.selectedPhase || null;
  }
  selectedSingleMultiple: string;
  selectedResultsDisplay: string;
  selectedDepthView: string;
  syncThermalResults: boolean;
  selectedOperation: Partial<BaseOperation>;
  selectedAnnulus: string;
  selectedPhase: PressureResultPhase;
}

export class AnnularResultsUi {
  public constructor(init?: Partial<AnnularResultsUi>) {
    this.selectedSingleMultiple = init && init.selectedSingleMultiple || 'singleAnnulus';
    this.selectedResultType = init && init.selectedResultType || null;
    this.selectedResultsDisplay = init && init.selectedResultsDisplay || 'plot';
    this.selectedDepthView = init && init.selectedDepthView || 'md';
    this.selectedFluidGas = init && init.selectedFluidGas || 'fluid';
    this.selectedOperation = init && init.selectedOperation || null;
    this.selectedAnnulus = init && init.selectedAnnulus || 'innerfluid';
    this.selectedPhase = init && init.selectedPhase || null;
  }
  selectedSingleMultiple: string;
  selectedResultType: number;
  selectedResultsDisplay: string;
  selectedDepthView: string;
  selectedFluidGas: string;
  selectedOperation: Partial<BaseOperation>;
  selectedAnnulus: string;
  selectedPhase: DetailedAnnularResultsPhase;
}

export class OperationEnvelopeUi {
  public constructor(init?: Partial<OperationEnvelopeUi>) {
    this.selectedPhaseEnvelope = init && init.selectedPhaseEnvelope || null;
    this.selectedOperation = init && init.selectedOperation || null;
    this.selectedSingleMultiple = init && init.selectedSingleMultiple || 'singleOperation';
    this.selectedResultsDisplay = init && init.selectedResultsDisplay || 'plot';
    this.selectedEnvelopeOperation = init && init.selectedEnvelopeOperation || 'phaseEnvelope';
  }
  selectedPhaseEnvelope: any;
  selectedOperation: Partial<BaseOperation>;
  selectedSingleMultiple: string;
  selectedResultsDisplay: string;
  selectedEnvelopeOperation: string;
}
