<form [formGroup]="form.formGroup">
  <div class="field col-12">
    <uom-quantity-input
      label="Surface Pressure"
      [unitLabel]="userUnits.pressure"
      [formControlName]="form.formControlNames.surfacePressure"
      [control]="'surfacePressure'"
      [validators]="validators.surfacePressure"
      [default]="'0'"
      title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [validators]="validators.fluidDensity"
      [default]="currentString.annularFluid?.state.nominalDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>
  <div class="field col-12">

    <input
      style="height: 13px; width: 20px;"
      type="checkbox"
      binary="true"
      [formControlName]="form.formControlNames.hasPlug"
      (change)="pressureTestPlugChange()"
    >
    <label style="font-size: 14px; padding-left: 5px;">Has Plug</label>
    <!-- <label
      class="shutinLabel"
      style="font-size: 11px; width: 50px; padding-left: 5px"
      >Has Plug</label
    > -->
  </div>
  <div class="field col-12">
    <uom-quantity-input
      label="Plug Depth"
      [unitLabel]="userUnits.longLengths + ' MD'"
      [formControlName]="form.formControlNames.plugMd"
      [control]="'plugMd'"
      [validators]="validators.plugMd"
      [default]="null"
      [inputDisabled]="plugDepthDisabled"
    ></uom-quantity-input>
  </div>
</form>
