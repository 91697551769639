import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Apb, ApbAnnulus, ApbResult } from '../models/apb.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class ApbService {

  constructor(private http: HttpClient) { }

  getApb(): Observable<Apb> {
    return this.http.get<Apb>(`${PeriforApiEndpoint}/apb`);
  }

  changeFinalOperation(operationId: string): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/apb/finalOperation`, { 'id': operationId });
  }

  editAnnulus(annulus: ApbAnnulus): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/apb/annulus/${annulus.tubularStringId}`, annulus);
  }

  getApbResults(): Observable<ApbResult[]> {
    return this.http.get<ApbResult[]>(`${PeriforApiEndpoint}/apb/apbResults`);
  }

  getApbResultsGrid(): Observable<any[]> {
    return this.http.get<any[]>(`${PeriforApiEndpoint}/apb/apbResults/grid`);
  }
}
