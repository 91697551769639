import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { SingleLoadResultsUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';
import { isEqual } from "lodash"

@Component({
  selector: 'app-single-load-axial',
  templateUrl: './single-load-axial.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }

    label {
      padding-left: 5px;
    }
  `]
})
export class SingleLoadAxialComponent implements OnInit, OnDestroy {
  private _axialResults: any[];
  private _subscriptions: Subscription;
  private _forceUnit: string;

  public loadCases: any[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' }
  ];
  public errorMsg: string;
  public results: any[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle: string = '';
  public xAxisTitle: string = '';
  public tableName: string;
  public userUnits: UserUnitsModel;
  public plotName: string = 'axialForcesPlot';
  public signalRChange: boolean;
  public tableHeight: string;
  public componentHeight: number;
  public longLengthUnit: string;

  public columnDefs: Array<{ header: string, field: string }>;
  public columnDefinitions: Array<any>;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.axialResultsStore = this._storeService.createStore(this.componentId, new SingleLoadResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public axialResultsStore: Store;

  @ViewChild('toolbarDiv', {static: false}) toolbarDiv: ElementRef;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Axial Forces Results")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnit = this.userUnits.longLengths;
    this._forceUnit = Units.lib[this.userUnits?.force]?.symbol;
    this.xAxisTitle = `Axial Force (${this._forceUnit})`;
    this.yAxisTitle = `MD (${this.longLengthUnit})`;

    this.refreshResults(true, false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.columnDefs = [
      { header: 'Axial Load', field: 'axialLoad' },
      { header: 'Axial Rating', field: 'axialRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Axial Load (${this._forceUnit})`, field: 'axialLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Axial Rating (${this._forceUnit})`, field: 'axialRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true, true, false);
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode?: boolean): Promise<void> {
    this.isLoading = isLoading;

    this.xAxisTitle = `Axial Force (${this._forceUnit})`;

    if ((!this._axialResults || calledFromSignalR) && !calledFromMode) {
      try {
        this._axialResults = await lastValueFrom(this._stressResultsService.getAxialResults());
        this.signalRChange = true;
      } catch (ex) {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._axialResults && (this._axialResults.length === 0 || this._axialResults[0].loadCaseName === '')) {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    if (this.axialResultsStore.state.selectedMode == "single") {
      this.handleSingleAxialLoad(this._axialResults);
    } else {
      this.handleMultiAxialLoad(this._axialResults)
    }

    this.isLoading = false;
  }

  onModeChange(e): void {
    this.axialResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (this.axialResultsStore.state.selectedMode == "multiple") {
      this.axialResultsStore.update(state => ({ ...state, selectedLoadCase: {label: 'Axial Load', value: 'axialLoad'} }));
    } else {
      // this.xAxisTitle = `Axial Force (lbf)`;
    }
    this.refreshResults(true, false, true);
  }

  onSelectedResultDisplayUpdated(e): void {
    this.axialResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  public setLoadCase(e): void {
    if (this.axialResultsStore.state.selectedMode === "single") {
      this.axialResultsStore.update(state => ({...state, selectedLoadCase: e.value}));
      this.results = e.value.value.axialResults;
    } else {
      this.axialResultsStore.update(state => ({...state, selectedLoadCase: e.value}));
      this.refreshResults(true, false, false);
    }
  }

  public depthViewToggle(e) {
    this.axialResultsStore.update(state => ({...state, selectedDepthView: e.value}));
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private handleSingleAxialLoad(axialResults: Array<any>) {

    this.columnDefs = [
      { header: 'Axial Load', field: 'axialLoad' },
      { header: 'Axial Rating', field: 'axialRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Axial Load (${Units.lib[this.userUnits?.force].symbol})`, field: 'axialLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Axial Rating (${Units.lib[this.userUnits?.force].symbol})`, field: 'axialRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
    this.results = [];

    this.loadCases = axialResults?.map(lc => {
      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (!this.axialResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.axialResultsStore.state.selectedLoadCase))) {
      this.axialResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.results = this.axialResultsStore.state.selectedLoadCase?.value.axialResults || [];
  }

  private handleMultiAxialLoad(axialResults: Array<any>) {
    this.columnDefinitions.splice(2, this.columnDefinitions.length);
    this.columnDefs = [];
    this.results = [];

    this.loadCases = [
      { label: 'Axial Load', value: 'axialLoad' },
      { label: 'Axial Rating', value: 'axialRating' }
    ]

    this.xAxisTitle = this.axialResultsStore.state.selectedLoadCase.value === 'axialLoad' ? `Axial Load (${this._forceUnit})` : `Axial Rating (${this._forceUnit})`;

    axialResults.forEach(load => {
      const loadName: string = load.loadCaseName + ` (${this._forceUnit})`
      const loadNameCamel = toCamelCaseString(load.loadCaseName);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName,
        field: loadNameCamel,
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      });

      let backupArray = [];

      load.axialResults.forEach(loadResult => {
        let result = {
          [loadNameCamel]: loadResult[this.axialResultsStore.state.selectedLoadCase.value],
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (axialResults[0].loadCaseName === load.loadCaseName) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        let otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });
    });
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
