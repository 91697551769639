export class SimplePanAndZoom {

  changeZoom(oldZoom: number, delta: number) {
    let factor = 1.05;
    if (delta < 0) {
      return oldZoom * factor;
    }
    if (delta > 0) {
      return oldZoom / factor;
    }
    return oldZoom;
  }

  changeCenter(oldCenter, deltaX, deltaY, factor) {
    let offset = new paper.Point(deltaX, -deltaY);
    offset = offset.multiply(factor);
    return oldCenter.add(offset);
  }

}

export class StableZoom extends SimplePanAndZoom {

  changeZoomStable(oldZoom, delta, c, p) {
    let newZoom = super.changeZoom(oldZoom, delta);
    let beta = oldZoom / newZoom;
    let pc = p.subtract(c);
    let a = p.subtract(pc.multiply(beta)).subtract(c);
    return [newZoom, a];
  }

}