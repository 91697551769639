export class StressOptions {

  public constructor(init?: Partial<StressOptions>) {
    Object.assign(this, init);
    // Adds items in form, but not yet in API
    this.dampingType = init?.dampingType || "FluidDamping";
    this.useDynamicFriction = init?.useDynamicFriction || false;
    this.frictionFactor = init?.frictionFactor || 0.3;
  }

  id?: string;
  maximumGridSpacing: number;
  helicalFactorType: string;
  helicalBucklingFactor: number;
  useHeKyllingstad: boolean;
  useDynamicFriction: boolean;
  frictionFactor?: number;
  dampingType?: string;
}
