import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class CalculationsApiService {

  constructor(private http: HttpClient) {
  }

  calculateThermal(operationId?: string): Observable<boolean> {
    return this.http.post<boolean>(`${PeriforApiEndpoint}/thermalOperations/calculate`, operationId ? {operationId} : null);
  }

  calculateStress(): Observable<boolean>{
    return this.http.post<boolean>(`${environment.PeriforEndpoint}/api/loadCases/calculate`, null);
  }

  calculateApb(): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/apb/calculate`, null);
  }

}
