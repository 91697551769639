import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from '../components/user-admin-page/user-model';
import { CurrentAppIdsDto } from '../models/current-app-ids-dto';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private http: HttpClient) { }

    public getUser(): Observable<User> {
        return this.http.get<User>(`${PeriforApiEndpoint}/users/user`);
    }

    public resetPword(email: string): Observable<boolean> {
        return this.http.put<boolean>(`${PeriforApiEndpoint}/users/reset-pword`, 
            JSON.stringify(email),
            {headers:{'Content-Type': 'application/json'}
        });
    }

    public updateUserUnits(model: any): Observable<boolean> {
        return this.http.put<boolean>(`${PeriforApiEndpoint}/users/units`, model);
    }

    public updateCurrentAppIds(model :Partial<CurrentAppIdsDto>): Observable<boolean> {
        return this.http.put<boolean>(`${PeriforApiEndpoint}/users/app-ids`, model);
    }

}

