import { Component, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-post-install-load-dialog',
  templateUrl: './new-post-install-load-dialog.component.html',
  styles: [`
    label {
      user-select: none;
    }
  `]
})
export class NewPostInstallLoadDialogComponent {

  public newLoadForm: UntypedFormGroup;

  @Output() saveLoad$: Subject<string> = new Subject();
  @Output() cancelNewLoad$: Subject<boolean> = new Subject();

  constructor(private _formBuilder: UntypedFormBuilder) {
      this.newLoadForm = this._formBuilder.group({
      name: ''
    });
  }

  closeDialog(): void {
    this.cancelNewLoad$.next(true);
  }

  saveLoadCase(): void {
    this.saveLoad$.next(this.newLoadForm.value.name.trim());
  }
}
