import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { TrajectoryService } from '../../../shared/services/trajectory.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgTableGridComponent } from 'src/app/shared/components/ng-table-grid/ng-table-grid.component';
import { valuesIncreaseValidator } from 'src/app/shared/components/ng-table-grid/shared-grid.validators';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { TrajectoryModel } from 'src/app/shared/models/trajectory.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-trajectory-grid',
  template: `
    <app-tool-loader [isLoading]="isLoading" positionUnset="true">

      <ng-table-grid-cmp
        #trajectoryTable
        tableName="Trajectory"
        [tableHeight]="tableHeight"
        [tableData]="trajectories"
        [columnDefinitions]="columnDefinitions"
        [newRowFormGroup]="newDataRow"
        [inputFields]="inputFields"
        [calculatedFields]="calculatedFields"
        (dataChange)="onTrajectoryChanged($event)"
        [virtualScroll]="true"
        [componentId]="componentId"
      ></ng-table-grid-cmp>

    </app-tool-loader>
  `
})
export class TrajectoryGridComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription;

  //Used for component state
  @Input()
  public componentId: string;


  public isLoading: boolean;
  public columnDefinitions: Array<{ field: string, header: string }>;
  public newRowInputForm: UntypedFormGroup;
  public tableHeight: string;
  public trajectories: Array<TrajectoryModel>;
  public inputFields: Array<{ name: string, minFractions: number, maxFractions: number, formatDecimals: number }>;
  public calculatedFields: Array<{ name: string, formatDecimals: number }>;

  public newDataRow() : UntypedFormGroup {
    return new UntypedFormGroup({
      measuredDepth: new UntypedFormControl("0", [Validators.required, valuesIncreaseValidator("measuredDepth")]),
      inclination: new UntypedFormControl("0", [Validators.required]),
      azimuth: new UntypedFormControl("0", [Validators.required])
    });
  }

  @ViewChild("trajectoryTable")
  public trajectoryTable : NgTableGridComponent<TrajectoryModel>;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _storeService: StoreService,
    private _messenger: MediatorService
  ) {

    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.inputFields = [
      { name: 'measuredDepth', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
      { name: 'inclination', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
      { name: 'azimuth', minFractions: 2, maxFractions: 6, formatDecimals: 3 }
    ];

    this.calculatedFields = [
      { name: 'doglegSeverity', formatDecimals: 3 },
      { name: 'trueVerticalDepth', formatDecimals: 1 },
      { name: 'northing', formatDecimals: 1 },
      { name: 'easting', formatDecimals: 1 }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.trajectories = await lastValueFrom(this._trajectoryService.getTrajectoryPoints());
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.columnDefinitions = [
      { field: 'measuredDepth', header: `MD (${uu.longLengths})` },
      { field: 'inclination', header: 'Inclination (°)' },
      { field: 'azimuth', header: 'Azimuth (°)' },
      { field: 'doglegSeverity', header: `DLS (${Units.lib[uu.doglegSeverity].symbol})` },
      { field: 'trueVerticalDepth', header: `TVD (${uu.longLengths})` },
      { field: 'northing', header: `Northing (${uu.longLengths})` },
      { field: 'easting', header: `Easting (${uu.longLengths})` }
    ];

    this.isLoading = false;
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Trajectory Input") {
        this.tableHeight = (e.itemHeight - 42) + 'px';
      }
    }));
    //Handle disabeling of the first row.
    this._subscriptions.add(this.trajectoryTable.dataFormArray.valueChanges.subscribe(()=> {
      this.trajectoryTable.dataFormArray?.controls[0]?.disable({emitEvent: false});
    }));
  }

  public onTrajectoryChanged(v: { dataRows: Array<TrajectoryModel>, reload: boolean }) {
    // Reloads trajectory from API back into the child trajectory Table Component
    this._trajectoryService.updateTrajectory(v.dataRows).subscribe(async (r) => {
      if (v.reload) {
        this.trajectories = [...r];
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}

