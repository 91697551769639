import { UntypedFormArray } from "@angular/forms";

// Using this to handle cases where we need to re-render validation 
// on child string sections after value in parent well component changes.
// This component does no validation itself. Add tubular items that have validation
// relating to something in the parent controls
export function revalidateTubulars(control: UntypedFormArray) {
   control.controls['stringSections'].controls.forEach(c=>{
      c.controls['pipe'].controls['outsideDiameter'].updateValueAndValidity({onlySelf: true, emitEvent: false }); // Emit false so we don't throw change events
      c.controls['pipe'].updateValueAndValidity({onlySelf: true, emitEvent: false });
   });
   return null;
}
