export class LoadCaseDefinitionModel {
    index: number;
    name: string;
    externalPressureProfile: any;
    internalPressureProfile: any;
    temperatureProfile: any;
}

export class LoadCaseSummaryResult {
    public index: number;
    public name: string;
    public type?: string;
    public ippType: string;
    public ippDisplayName: string;
    public eppType: string;
    public eppDisplayName: string;
    public tpType: string;
    public tpDisplayName: string;
}

export interface Overpull {
    readonly type: PreInstallLoadType.OVERPULL,
    OverpullForce: number,
    IsFlotation: boolean,
    InternalFluidDensity: number,
    HasBottomCap: boolean,
    HasFlotationPlug: boolean,
    PlugDepth: number,
    FluidDensity: number,
}

export interface RunInHole {
    readonly type: PreInstallLoadType.RUNINHOLE,
    RunInHoleSpeed: number,
    IsFlotation: boolean,
    InternalFluidDensity: number,
    HasBottomCap: boolean,
    HasFlotationPlug: boolean,
    PlugDepth: number,
    FluidDensity: number,
}

export interface GreenCementTest {
    readonly type: PreInstallLoadType.GREENCEMENTTEST,
    PumpPressure: number
}

export interface PreInstallLoad {
    name: string;
    data: SinglePreInstallLoad;
}

export type SinglePreInstallLoad = Overpull | RunInHole | GreenCementTest | null;

export interface PreInstallLoadForm {
    name: string;
    data: SinglePreInstallLoad;
}

export enum PreInstallLoadType {
    OVERPULL = 'Overpull',
    RUNINHOLE = 'RunInHole',
    GREENCEMENTTEST = 'GreenCementTest'
}

export const EMPTY_GUID = '0000-0000-0000-0000';

export class PreInstallLoadUi {
  public constructor(init?: Partial<PreInstallLoadUi>) {
    this.selectedLoad = init && init.selectedLoad || null;
  }
  selectedLoad: Partial<PreInstallLoad>;
}