<form [formGroup]="form.formGroup">
  <div class="defaultButton">
    <img
      (click)="applyDefaults()"
      title="Apply Defaults"
      src="../../assets/icons/default-icon-white.png"
    />
  </div>
  <div class="field">
    <label for="brineType">Brine Type</label>
    <p-dropdown
      [disabled]="isReadOnly"
      [options]="brineTypes"
      [formControlName]="form.formControlNames.brineType"
      id="brineType"
      optionLabel="label"
      optionValue="value"
      appendTo="body"
      class="w-full"
    ></p-dropdown>
  </div>
  <div class="field">
    <label for="nominalDensity">Nominal Density</label>
    <div class="p-inputgroup">
      <p-inputNumber
        id="nominalDensity"
        [formControlName]="form.formControlNames.nominalDensity"
        required
        mode="decimal"
        [minFractionDigits]="1"
      ></p-inputNumber>
      <span class="p-inputgroup-addon">{{ densityUnits }}</span>
    </div>
    <p *ngIf="form.formGroup.controls.nominalDensity.invalid" class="error">
      Allowed range: {{ densityValidation.min }} -
      {{ densityValidation.max }} {{ densityUnits }}
    </p>
  </div>
  <!-- <div class="p-field">
          <label for="plasticViscosity">Plastic Viscosity</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="plasticViscosity"
              [formControlName]="formControlNames.plasticViscosity"
              required
              mode="decimal"
              [minFractionDigits]="1"
              ></p-inputNumber>
            <span class="p-inputgroup-addon">cP</span>
          </div>
          <p *ngIf="formGroupControls.plasticViscosity.invalid" class="error">Allowed range: 0 - 2000 cP</p>
        </div>
        <div class="p-field">
          <label for="rheologyTemperature">Viscosity Temperature</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="rheologyTemperature"
              [formControlName]="formControlNames.viscosityTemperature"
              required
              mode="decimal"
              [minFractionDigits]="1"
              ></p-inputNumber>
            <span class="p-inputgroup-addon">°F</span>
          </div>
          <p *ngIf="formGroupControls.viscosityTemperature.invalid" class="error">Allowed range: 32 - 500 °F</p>
        </div> -->
</form>
