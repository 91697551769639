import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Packers } from '../models/packers.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class PackerService {

  constructor(private http: HttpClient) { }

  getPackersForTubular(): Observable<Packers> {
    return this.http.get<Packers>(`${PeriforApiEndpoint}/packers`);
  }

  updatePackers(packer: any): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/packers`, packer);
  }

}
