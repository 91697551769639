import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConnectionCatalog } from '../../models/connection-catalog.model';

const PeriforApiEndpoint_CustomConnections = `${environment.PeriforEndpoint}/api/customConnections`;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ConnectionsService {

    constructor(private http: HttpClient) { }

    getConnections(): Observable<Array<ConnectionCatalog>> {
        return this.http.get<Array<ConnectionCatalog>>(`${PeriforApiEndpoint_CustomConnections}`).pipe(
            map(data => data.map(data => new ConnectionCatalog(data)))
        );
    }

    getConnectionsByPipeId(pipeId: string): Observable<Array<ConnectionCatalog>> {
        return this.http.get<Array<ConnectionCatalog>>(`${PeriforApiEndpoint_CustomConnections}/pipe/${pipeId}`).pipe(
            map(data => data.map(data => new ConnectionCatalog(data)))
        );
    }

    addConnection(model: ConnectionCatalog): Observable<{id: string}> {
        return this.http.post<{id: string}>(`${PeriforApiEndpoint_CustomConnections}`, model);
    }

    updateConnection(model: ConnectionCatalog) {
        return this.http.put<boolean>(`${PeriforApiEndpoint_CustomConnections}/${model.id}`, model, httpOptions);
    }

    deleteConnection(id: string): Observable<boolean> {
      return this.http.delete<boolean>(`${PeriforApiEndpoint_CustomConnections}/${id}`);
    }

}
