import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { UdtService } from '../../../shared/services/udt.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TrajectoryService } from '../../../shared/services/trajectory.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellTypeService } from '../../services/well-type-datums.service';
import { UndisturbedTemperature } from '../../models/undisturbed-temperature.model';
import { TotalDepthResult } from 'src/app/shared/models/trajectory.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { SelectItem } from 'primeng/api';
import { createStore, withProps } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Units } from 'src/app/core/services/unit-library';

interface UdtUi {
  interpolationDepth: number;
}

export function createUdtPlotStore(storeName) {
  return createStore(
    { name: storeName },
    withProps<UdtUi>({
      interpolationDepth: 0
    })
  );
}

@Component({
  selector: 'app-undisturbed-temperature',
  templateUrl: './undisturbed-temperature.component.html',
  styleUrls: ['./undisturbed-temperature.component.scss']
})
export class UndisturbedTemperatureComponent implements OnInit, AfterViewInit, OnDestroy {
  private _udt: UndisturbedTemperature;
  private _wellType: string;
  private _subscriptions: Subscription;
  private interpolationDepth: number;
  private _tempTemperatureAtTd: number;

  //Used for state management
  @Input()
  private componentId: string;

  public interpDepth: number = 0;
  public udtForm: UntypedFormGroup;
  public interpolatedTempForm: UntypedFormGroup;
  public tempAtTd: string;
  public totalDepth: TotalDepthResult;
  public interpolatedTemp: string;
  public mudlineDepth: number;
  public isOffshore: boolean;
  public isLoading: boolean;
  public depthTypes: any[];
  public selectedDepthType: string;
  public depthUnit: string;
  public gradientUnit: string;
  public tempUnit: string;
  public surfaceAndMudlineValidation: { min: number, max: number };
  public tempAtTdValidaton: { min: number, max: number };
  public customPoints: Array<{ trueVerticalDepth: number, temperature: number }> = [];
  public tableHeight: string;
  public columnDefinitions: Array<{ header: string, field: string }>;
  public isResetting: boolean;
  public udtTypeOptions: SelectItem[];

  public newDataRow(): FormGroup {
    return new FormGroup({
      trueVerticalDepth: new FormControl("", [Validators.required]),
      temperature: new FormControl("", [Validators.required])
    });
  }

  public get constGrad(): FormGroup {
    let constGrad = (this.udtForm.controls.constantGradient as FormGroup)
    return constGrad;
  }

  public inputFields = [{
    name: 'trueVerticalDepth',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 2
  },
  {
    name: 'temperature',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 2
  }];

  public get totalDepthLabel(): string {
    return `${(Math.trunc(this.totalDepth?.trueVerticalDepth * 100) / 100).toLocaleString('en-US')} ${this.depthUnit} TVD`;
  }

  constructor(
    private _udtService: UdtService,
    private formBuilder: UntypedFormBuilder,
    private _trajectoryService: TrajectoryService,
    private _signalRService: SignalRService,
    private _wellTypeService: WellTypeService,
    private _store: StoreService,
    private _messenger: MediatorService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.udtForm = this.formBuilder.group({
      id: new UntypedFormControl(''),
      isConstantGradient: new UntypedFormControl(false),
      temperatureAtTd: [0, [Validators.required]],
      constantGradient: this.formBuilder.group({
        surfaceAmbientTemperature: [0, [Validators.required]],
        mudlineTemperature: [0, [Validators.required]],
        temperatureGradient: [0, [Validators.required]]
      }),
      customPoints: new FormControl([])
    });

    this.interpolatedTempForm = this.formBuilder.group({
      interpolationDepth: new UntypedFormControl(),
      interpolationDepthType: new UntypedFormControl('tvd')
    });

    this._subscriptions.add(this.interpolatedTempForm.valueChanges.pipe(debounceTime(500)).subscribe((values: any) => {
      this.interpolationDepth = values.interpolationDepth;
      this.selectedDepthType = values.interpolationDepthType;

      this.interpolateMdTvd();
      // this.interpolateTemp(this._udt.surfaceAmbientTemperature, this._udt.mudlineTemperature, this.interpolationDepth, this.mudlineDepth, this.totalDepth);
    }));

    this._subscriptions.add(this.udtForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((formData) => {
      if (this.udtForm.invalid) {
        return;
      }
      this.checkChangesForCalculation(formData).then(() => {
        this._udtService.updateUndisturbedTemperature(this.udtForm.value).subscribe();
        this._udt = formData;
        if (!this._udt.constantGradient.temperatureGradient) {
          this.udtForm.controls.constantGradient['controls'].temperatureGradient.setValue(2.25);
        }
        this.interpolateMdTvd(formData['temperatureAtTd']);
      });
    }));
  }

  public customPointsChange(e: any) {
    if (e.triggeredBy.type == 'reset') {
      this.isResetting = true;
      this.isLoading = true;
      this.udtForm.controls.isConstantGradient.setValue(true, { emitEvent: false });
      this._udtService.updateUndisturbedTemperature(this.udtForm.value).subscribe(() => {
        this._udtService.getUndisturbedTemperaturePoints().subscribe(points => {
          this.customPoints = points;
          this.udtForm.controls.customPoints.patchValue(points);
          this.udtForm.controls.isConstantGradient.patchValue(false);
          this.isResetting = false;
          this.isLoading = false;
        });
      });
    } else {
      if (e.dataRows.length > 1) {
        this.udtForm.controls.customPoints.patchValue(e.dataRows);
      }
    }
  }

  checkChangesForCalculation(formData) {
    return new Promise(resolve => {
      let changed = {};
      for (let ctrl in formData) {
        // if (formData[ctrl] !== this._udt[ctrl]) {
        changed[ctrl] = formData[ctrl];
        // }
      }

      if (changed['temperatureAtTd'] != this._tempTemperatureAtTd) {
        this._tempTemperatureAtTd = changed['temperatureAtTd'];
        this.updateGradient();
      } else if (changed['constantGradient'].hasOwnProperty('temperatureGradient') || changed['constantGradient'].hasOwnProperty('surfaceAmbientTemperature') ||
        changed['constantGradient'].hasOwnProperty('mudlineTemperature')) {
        this.interpolateTempByWellType(changed['constantGradient']['temperatureGradient'], changed['constantGradient']['surfaceAmbientTemperature'], changed['constantGradient']['mudlineTemperature']);
        formData.temperatureAtTd = +this.tempAtTd;
      }

      resolve(formData);
    });
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = uu.longLengths;
    this.gradientUnit = Units.lib[uu.temperatureGradient].symbol;
    this.tempUnit = uu.temperature;

    this.columnDefinitions = [
      { header: `TVD (${uu.longLengths})`, field: 'trueVerticalDepth' },
      { header: `Temperature (${this.tempUnit})`, field: 'temperature' }
    ];

    this.udtTypeOptions = [
      { label: 'Gradient', value: true },
      { label: 'Custom', value: false }
    ];

    if (this.tempUnit == '°F') {
      this.surfaceAndMudlineValidation = { min: -500, max: 1000 };
      this.tempAtTdValidaton = { min: 0, max: 1000 };
      this.constGrad.controls.surfaceAmbientTemperature.setValidators([Validators.min(-500), Validators.max(1000), Validators.required]);
      this.constGrad.controls.mudlineTemperature.setValidators([Validators.min(-500), Validators.max(1000)]);
      this.udtForm.controls.temperatureAtTd.setValidators([Validators.min(0), Validators.max(1000), Validators.required]);
    } else if (this.tempUnit == '°C') {
      this.surfaceAndMudlineValidation = { min: -295.5, max: 537.7 };
      this.tempAtTdValidaton = { min: -17.7, max: 537.7 };
      this.constGrad.controls.surfaceAmbientTemperature.setValidators([Validators.min(-295.5), Validators.max(537.7), Validators.required]);
      this.constGrad.controls.mudlineTemperature.setValidators([Validators.min(-295.5), Validators.max(537.7)]);
      this.udtForm.controls.temperatureAtTd.setValidators([Validators.min(-17.7), Validators.max(537.7), Validators.required]);
    } else if (this.tempUnit == 'K') {
      this.surfaceAndMudlineValidation = { min: -22.4, max: 810.9 };
      this.tempAtTdValidaton = { min: 255.37, max: 810.9 };
      this.constGrad.controls.surfaceAmbientTemperature.setValidators([Validators.min(-22.4), Validators.max(810.9), Validators.required]);
      this.constGrad.controls.mudlineTemperature.setValidators([Validators.min(-22.4), Validators.max(810.9)]);
      this.udtForm.controls.temperatureAtTd.setValidators([Validators.min(255.37), Validators.max(810.9), Validators.required]);
    }

    this.depthTypes = [
      { name: `${this.depthUnit} TVD`, value: 'tvd' },
      { name: `${this.depthUnit} MD`, value: 'md' }
    ]

    this.selectedDepthType = 'tvd';

    this.setUdt();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: { action: string, designId: string }) {
    if (data.action == PeriforOnChangeMessages.REFRESH_UDT_INPUTS) {
      this.setUdt();
    }
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "UDT") {
        this.tableHeight = (e.itemHeight - 155) + 'px';
      }
    }));
  }

  setUdt() {
    this.isLoading = true;
    const sources: Observable<any>[] = [
      this._trajectoryService.getTotalDepth() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>,
      this._udtService.getUndisturbedTemperature() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([totalDepth, wellType, udt]) => {
        if (!totalDepth) {
          return;
        }

        this._udt = udt;
        if (!this._udt.constantGradient.mudlineTemperature) {
          this._udt.constantGradient.mudlineTemperature = 0;
        }

        this._wellType = wellType.type;

        this.totalDepth = totalDepth;
        this.mudlineDepth = +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(2);

        this.interpolateTempByWellType(null, null, null);
        udt.constantGradient.temperatureAtTd = +this.tempAtTd;

        this.customPoints = udt.customPoints;

        this.udtForm.patchValue(this._udt, { emitEvent: false });

        if (!this.udtForm.controls.constantGradient['controls'].mudlineTemperature.value && wellType.type != 'Land') {
          this.udtForm.controls.constantGradient['controls'].mudlineTemperature.addValidators([Validators.required]);
          let defaultValue = this.tempUnit == '°F' ? 40 : 5;
          this.udtForm.controls.constantGradient['controls'].mudlineTemperature.patchValue(defaultValue);
        }

        this.interpolatedTempForm.setValue({ interpolationDepth: null, interpolationDepthType: 'tvd' });

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public changeUdtType() {
    if (this.customPoints.length < 1) {
      this._udtService.getUndisturbedTemperaturePoints().subscribe(points => {
        this.customPoints = points;
        this.udtForm.controls.customPoints.patchValue(points);
      });
    }
  }

  public interpolateTempByWellType(updatedGradient, updatedSurface, updatedMudline): void {
    let { surfaceAmbientTemperature, mudlineTemperature } = this._udt.constantGradient;
    let defaultValue40 = this.tempUnit == '°F' ? 40 : 5;
    let defaultValue50 = this.tempUnit == '°F' ? 50 : 10;

    const gradient = updatedGradient ?? this._udt.constantGradient.temperatureGradient ?? 2;
    const surfaceTemp = updatedSurface ?? surfaceAmbientTemperature ?? defaultValue50;
    const mudlineTemp = updatedMudline ?? mudlineTemperature ?? defaultValue40;

    this.interpolateTemp(surfaceTemp, mudlineTemp, 0, this.mudlineDepth, this.totalDepth.trueVerticalDepth);

    this.isOffshore = true;
    switch (this._wellType) {
      case 'Land':
        this.isOffshore = false;
        this.setTempAtTd(surfaceTemp, gradient, this.mudlineDepth);
        break;
      case 'Platform':
        this.setTempAtTd(mudlineTemp, gradient, this.mudlineDepth);
        break;
      case 'Subsea':
        this.setTempAtTd(mudlineTemp, gradient, this.mudlineDepth);
        break;
    }
  }

  private interpolateMdTvd(tempAtTd?: number) {
    this.interpDepth = this.interpolatedTempForm.controls.interpolationDepth.value ?? this.totalDepth.trueVerticalDepth;
    if (this.selectedDepthType === 'md') {
      if (this.interpDepth > this.totalDepth.measuredDepth) {
        this.interpDepth = this.totalDepth.measuredDepth;
        this.interpolatedTempForm.controls.interpolationDepth.patchValue(this.totalDepth.measuredDepth, { emitEvent: false });
      }
      this._trajectoryService.getTvdFromMd(this.interpDepth).subscribe(mdInterpolationDepth => {
        this.interpolateTemp(this._udt.constantGradient.surfaceAmbientTemperature, this._udt.constantGradient.mudlineTemperature, mdInterpolationDepth, this.mudlineDepth, this.totalDepth.trueVerticalDepth);
      });
    } else {
      if (this.interpDepth > this.totalDepth.trueVerticalDepth) {
        this.interpDepth = this.totalDepth.trueVerticalDepth;
        this.interpolatedTempForm.controls.interpolationDepth.patchValue(this.totalDepth, { emitEvent: false });
      }
      this.interpolateTemp(this._udt.constantGradient.surfaceAmbientTemperature, this._udt.constantGradient.mudlineTemperature, this.interpDepth, this.mudlineDepth, this.totalDepth.trueVerticalDepth, tempAtTd);
    }
  }

  public updateInterpDepth(e) {
    this.selectedDepthType = e.value;

    this.interpolateMdTvd();
  }

  private setTempAtTd(temperature: number, gradient: number, depth: number): void {
    let temp = gradient * (this.totalDepth.trueVerticalDepth - depth) / (this.tempUnit == '°F' ? 100 : 30) + temperature;
    let maxValue = this.tempUnit == '°F' ? 1000 : 500;
    temp = temp > maxValue ? maxValue : temp;
    this.tempAtTd = (Math.trunc(temp * 100) / 100).toLocaleString('en-US');
    this._tempTemperatureAtTd = +this.tempAtTd;
    this.udtForm.controls.temperatureAtTd.patchValue(+this.tempAtTd, { emitEvent: false });
  }

  updateGradient(): void {
    let { surfaceAmbientTemperature, mudlineTemperature } = this._udt.constantGradient;

    let defaultValue40 = this.tempUnit == '°F' ? 40 : 5;
    let defaultValue50 = this.tempUnit == '°F' ? 50 : 10;
    switch (this._wellType) {
      case 'Land':
        this.calculateGradient(this.udtForm.controls.temperatureAtTd.value ?? defaultValue40, this.totalDepth.trueVerticalDepth, 0, surfaceAmbientTemperature ?? defaultValue50);
        break;
      case 'Platform':
        this.calculateGradient(this.udtForm.controls.temperatureAtTd.value ?? defaultValue40, this.totalDepth.trueVerticalDepth, this.mudlineDepth, mudlineTemperature ?? defaultValue40);
        break;
      case 'Subsea':
        this.calculateGradient(this.udtForm.controls.temperatureAtTd.value ?? defaultValue40, this.totalDepth.trueVerticalDepth, this.mudlineDepth, mudlineTemperature ?? defaultValue40);
        break;
    }
  }

  private calculateGradient(tempAtTd: number, totalDepthTvd: number, topGradientDepthTvd: number, topTemperature: number) {
    const gradient = ((tempAtTd - topTemperature) / (totalDepthTvd - topGradientDepthTvd) * (this.tempUnit == '°F' ? 100 : 30));
    this.udtForm.controls.constantGradient['controls'].temperatureGradient.patchValue(gradient.toFixed(2), { emitEvent: false });
  }

  private interpolateTemp(surfaceTemp: number, mudlineTemp: number, interpDepth: number, mudlineDepth: number, td: number, tempAtTd?: number): void {
    const tvdArray = [];
    const tempArray = [];

    tvdArray.push(0);
    tempArray.push(surfaceTemp);

    if (mudlineDepth > 0) {
      tvdArray.push(mudlineDepth);
      tempArray.push(mudlineTemp);
    }

    tvdArray.push(td);
    tempArray.push(tempAtTd ?? Number(this.tempAtTd));

    const i = tvdArray.findIndex(x => interpDepth <= x);

    let temp = tempArray[i - 1] + (interpDepth - tvdArray[i - 1]) * (tempArray[i] - tempArray[i - 1]) / (tvdArray[i] - tvdArray[i - 1]);
    if (!temp) {
      temp = surfaceTemp;
    }
    this.interpolatedTemp = (Math.trunc(temp * 100) / 100).toLocaleString('en-US');
  }

  copyTemperature() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.interpolatedTemp));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
