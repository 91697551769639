import { UserUnitsModel } from "src/app/core/components/user-units/user-units.model";
import { GetPpgFromValue, GetValueFromPsi } from "./units.helper";
import { Units } from "src/app/core/services/unit-library";

export function GetMudHydrostaticPressure(tvd: number, density: number, units: UserUnitsModel) {
    let depthInFeet = 0;
    switch(units.longLengths) {
        case 'ft':
            depthInFeet = tvd;
            break;
        case 'm':
            depthInFeet = tvd * 3.28084;
            break;
    }

    let densityInPpg = GetPpgFromValue(density, Units.lib[units.density].symbol);

    let pressureInPsi = depthInFeet * densityInPpg * 7.48051948051947 / 144;

    return GetValueFromPsi(pressureInPsi, units.pressure);
}