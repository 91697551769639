<div class="flex workspaceTabs">
  <div class="mr" #workspaceSelect>
    <p-tabMenu [model]="workspaceTabs" [activeItem]="activeWorkspaceTab">
      <ng-template pTemplate="workspace" let-workspaceTab let-i="index">
        <a class="p-menuitem-link flex justify-content-between align-items-center p-2">
          <div
            (contextmenu)="onContextMenu($event, workspaceTab)"
            *ngIf="workspaceTab?.id !== selectedEditTab?.id"
            (dblclick)="editWorkspace(workspaceTab, false)"
          >
            {{ workspaceTab.label }}
          </div>
          <input
            (keydown)="handleInput($event)"
            #editNameBox
            type="text"
            pInputText
            class="p-inputtext-sm"
            [(ngModel)]="workspaceTab.label"
            *ngIf="workspaceTab?.id === selectedEditTab?.id"
            (blur)="renameWorkspace(workspaceTab?.id, workspaceTab?.label)"
            (keyup.enter)="renameWorkspace(workspaceTab?.id, workspaceTab?.label)"
            [ngClass]="{ menuItem: workspaceTab?.id === selectedEditTab.id }"
          />
        </a>
      </ng-template>
    </p-tabMenu>
  </div>
  <div class="mr addWorkspace">
    <p-menubar [model]="workspaceOptions">
      <ng-template pTemplate="item" let-item let-i="index">
          <i
            [class]="item.icon"
            [pTooltip]="item.tooltip"
            class="p-tabmenu p-tabmenu-nav p-tabmenuitem p-menuitem-link menuItem"
          ></i>
      </ng-template>
    </p-menubar>
  </div>
</div>
<p-contextMenu
  [target]="workspaceSelect"
  [model]="workspaceContextMenu"
  appendTo="body"
></p-contextMenu>
