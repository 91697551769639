<form [formGroup]="form.formGroup">
  <span>
    <i
      class="pi pi-info-circle pickupSlackoffClass"
      title=" In the case of cement interval inside the shoe of the previous casing,&#13;this EPP applies the mud/fluid hydrostatic to TOC of the current casing and then &#13;interpolates linearly from TOC to Pore Pressure at the shoe of the previous casing"
    ></i>
  </span>
  <app-general-epp
    [formControlName]="form.formControlNames.generalEpp"
    [currentString]="currentString"
    [userUnits]="userUnits"
  ></app-general-epp>
  <label
    class="error field col-12"
    *ngIf="form.formGroup.hasError('porePressureNotSpecified')"
    >Pore Pressure profile not specified.</label
  >
  <label
    class="error field col-12"
    *ngIf="
      form.formGroup.hasError('porePressureMustBeToShoe') &&
      !form.formGroup.hasError('porePressureNotSpecified')
    "
    >Pore Pressure profile must be extended to the shoe of the string.</label
  >
  <br
    *ngIf="
      form.formGroup.hasError('porePressureMustBeToShoe') ||
      form.formGroup.hasError('porePressureNotSpecified')
    "
  />
  <label class="field col-12"
    >Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}</label
  >
</form>
