import { UntypedFormControl } from "@angular/forms";

export function innerSmallerThanOuterDiameter(control: UntypedFormControl) {
    let innerDiameter = control.value;
    let outerDiameter = control.parent && (control.parent.controls['outsideDiameter']?.value ?? control.parent.controls['outerDiameter']?.value);
    if(outerDiameter <= innerDiameter){
       return {error: "Inside Diameter must be smaller than Outside Diameter."};
    } else if (innerDiameter <= 0) {
      return {error: "Value must be greater than 0"};
    }
    return null;
  }
