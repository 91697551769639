<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader [isLoading]="isLoading" [positionUnset]="true">
  <div class="well-config">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <div class="infoClass">
      <span class="info-text">
        Total Well Depth: {{ totalDepth?.measuredDepth | number: '1.0-0' }} {{ units?.longLengths }} MD
      </span>
      <span class="infoSpan">
        <i title="Note: Pipe, Grade and Connections will be copied into the well configuration from catalogs.&#13;If a Pipe, Grade or Connection is removed from the catalog, it will remain in the design.&#13;The design is valid and can be used without issue. It will be shown as (local only) in the Well Configuration." class="pi pi-info-circle"></i>
      </span>
    </div>

    <div class="well-config-strings">

        <form [formGroup]="wellConfigForm" novalidate>
          <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
          <ng-container formArrayName="wellComponents">
            <p-table
            styleClass="p-datatable-gridlines"
            [columns]="cols"
            [resizableColumns]="true"
            [value]="wellComponents?.controls"
            dataKey="value.id"
            rowExpandMode="single"
            [contextMenu]="cm"
            stateStorage="local"
            [stateKey]="componentId"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th [ngStyle]="{'width':'85px'}">
                    Strings
                  </th>
                  <th *ngFor="let col of columns" pResizableColumn>
                    {{ col.header }}
                  </th>
                  <th>
                    <p-button [disabled]="this.wellComponents.invalid" label="Add String" class="p-button-success p-button-sm addButton" (click)="onAddWellComponent()"></p-button>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-wellCmpFg let-wIdx="rowIndex" let-expanded="expanded">
                <ng-container [formGroupName]="wIdx">
                  <tr [pContextMenuRow] (mouseover)="getRowIndex(wIdx, wellCmpFg)" (dblclick)="stringSectionsExpand(expanded, wIdx)" class="stringRowClass" [ngStyle]="{'background-color': wIdx === selectedStringId ? '#474646' : '#2e3236'}">
                    <td [title]="getStringSectionsTextHintHover(wellCmpFg)">
                      <!-- <button [disabled]="this.wellComponents.invalid" pButton class="p-button-text p-button-sm p-button-secondary" title="Insert string above" icon="pi pi-plus" (click)="onAddWellComponent(wIdx)"></button> -->
                      <button *ngIf="!userRoles.readOnly" [disabled]="isControlInvalid(wellCmpFg, 'holeSize')" pButton pRipple type="button" [pRowToggler]="wellCmpFg" (click)="stringSectionsExpand(expanded, wIdx)"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'"></button>
                      <i
                      *ngIf="userRoles.readOnly"
                      [pRowToggler]="wellCmpFg"
                      (click)="stringSectionsExpand(expanded, wIdx)"
                      class="enabled-button"
                      tabindex="0"
                      [class]="expanded ? 'pi pi-chevron-circle-down enabledChevron' : 'pi pi-chevron-right enabledChevron'"
                    >
                    </i>
                      <validation-error class="stringError" [isValid]="wellCmpFg.invalid" [errorMsg]="getControlErrors(wellCmpFg)" title="Expand for Errors"></validation-error>
                      <!-- <span style="padding-left: 20px;"></span> -->
                      <label *ngIf="!wellCmpFg.invalid">{{ getStringSectionsTextHint(wellCmpFg) }}</label>
                    </td>
                    <td class="stringTypeClass">
                      <p-dropdown [disabled]="userRoles.readOnly" [options]="stringTypes" formControlName="type" appendTo="body" title="Type"></p-dropdown>
                    </td>
                    <td>
                      <p-inputNumber formControlName="hangerMd" [minFractionDigits]="1" title="Hanger MD"></p-inputNumber>
                      <validation-error [isValid]="isControlInvalid(wellCmpFg, 'hangerMd')" [errorMsg]="getControlErrors(wellCmpFg,'hangerMd')"></validation-error>
                    </td>
                    <td>
                      <p-inputNumber formControlName="shoeMd" [minFractionDigits]="1" title="Shoe MD"></p-inputNumber>
                      <validation-error [isValid]="isControlInvalid(wellCmpFg, 'shoeMd')" [errorMsg]="getControlErrors(wellCmpFg,'shoeMd')"></validation-error>
                    </td>
                    <td>
                      <p-inputNumber formControlName="holeSize" [minFractionDigits]="2" title="Hole Size"></p-inputNumber>
                      <validation-error [isValid]="isControlInvalid(wellCmpFg, 'holeSize')" [errorMsg]="getControlErrors(wellCmpFg,'holeSize')"></validation-error>
                    </td>
                    <td class="hasCementClass">
                      <input
                        title="Has Cement"
                        style="height: 13px; width: 20px;"
                        type="checkbox"
                        binary="true"
                        formControlName="hasCement"
                      >
                      <p-inputNumber class="form-control" [minFractionDigits]="1" formControlName="topOfCementMd" title="Top of Cement MD"></p-inputNumber>
                      <validation-error [isValid]="isControlInvalid(wellCmpFg, 'topOfCementMd')" [errorMsg]="getControlErrors(wellCmpFg,'topOfCementMd')"></validation-error>
                    </td>
                    <td style="width: 20%;" class="annularFluidClass">
                        <p-dropdown
                        [disabled]="userRoles.readOnly"
                        title="Annular Fluid"
                        placeholder="Select"
                        [options]="fluids"
                        formControlName="annularFluidId"
                        appendTo="body">
                      </p-dropdown>
                      <validation-error [isValid]="isControlInvalid(wellCmpFg, 'annularFluidId')" [errorMsg]="getControlErrors(wellCmpFg,'annularFluidId')"></validation-error>
                    </td>
                    <td style="padding-right: 0px;">
                      <button pButton class="p-button-text p-button-sm p-button-danger delete-btn" title="Remove String" icon="pi pi-trash" (click)="onDeleteWellComponent(wIdx, wellCmpFg)"></button>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>

              <ng-template pTemplate="rowexpansion" let-wellCmpFg>
                <tr class="tubulars">
                  <td class="stringSections" colspan="9">
                  <div class="ui-g ui-fluid">
                    <p-table
                    styleClass="p-datatable-gridlines"
                    [value]="getWellComponentTubulars(wellCmpFg).controls"
                    [resizableColumns]="true"
                    [columns]="tubularCols"
                    dataKey="id"
                    >
                      <ng-container [formGroup]="wellCmpFg">
                        <ng-container formArrayName="stringSections">
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th>
                              Sections
                              <validation-error style="padding-left: 5px;" *ngIf="isControlInvalid(wellCmpFg, 'stringSections')" [isValid]="isControlInvalid(wellCmpFg, 'stringSections')" [errorMsg]="getControlErrors(wellCmpFg,'stringSections')"></validation-error>
                          </th>
                            <th *ngFor="let col of columns" pResizableColumn>
                              {{ col.header }}
                            </th>
                            <th pResizableColumn [ngStyle]="{'width':'50px'}">
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-tIdx="rowIndex" let-rowData>

                          <tr [formGroupName]="tIdx">
                            <td>
                              <button style="height: 1px;" pButton class="p-button-text p-button-sm p-button-secondary" title="Insert section above" icon="pi pi-angle-up" (click)="onAddTubular(wellCmpFg, tIdx, false)"></button>
                              <button style="height: 1px;" pButton class="p-button-text p-button-sm p-button-secondary" title="Insert section below" icon="pi pi-angle-down" (click)="onAddTubular(wellCmpFg, tIdx, true)"></button>
                            </td>
                            <td>
                              <p-inputNumber [minFractionDigits]="1" formControlName="bottomMeasuredDepth"></p-inputNumber>
                              <validation-error [isValid]="isControlInvalid(rowData, 'bottomMeasuredDepth')" [errorMsg]="getControlErrors(rowData,'bottomMeasuredDepth')"></validation-error>
                            </td>
                              <ng-container formGroupName="pipe">
                                <td>
                                  <p-dropdown
                                    [disabled]="userRoles.readOnly"
                                    [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'outsideDiameter', rowData.get('bottomMeasuredDepth'), 'pipe', false)"
                                    [options]="rowData.get('odOptions').value"
                                    formControlName="outsideDiameter"
                                    appendTo="body">
                                  </p-dropdown>
                                  <validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'outsideDiameter')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'outsideDiameter')"></validation-error>
                                </td>

                                <td>
                                  <p-dropdown
                                    [disabled]="userRoles.readOnly"
                                    [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'weightPerFoot', rowData.get('bottomMeasuredDepth'), 'pipe', false)"
                                    [options]="getSortedPipeOptions(rowData, 'weightPerFoot')"
                                    optionLabel="weightPerFoot"
                                    optionValue="id"
                                    formControlName="id"
                                    appendTo="body">
                                  </p-dropdown>
                                  <validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'weightPerFoot')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'weightPerFoot')"></validation-error>
                                </td>
                                <td>
                                <p-dropdown
                                  [disabled]="userRoles.readOnly"
                                  [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'insideDiameter', rowData.get('bottomMeasuredDepth'), 'pipe', false)"
                                  [options]="getSortedPipeOptions(rowData, 'insideDiameter')"
                                  optionLabel="insideDiameter"
                                  optionValue="id"
                                  formControlName="id"
                                  appendTo="body">
                                </p-dropdown>
                                <validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'insideDiameter')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'insideDiameter')"></validation-error>
                              </td>
                              <td>
                                <p-dropdown
                                  [disabled]="userRoles.readOnly"
                                  [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'wallThickness', rowData.get('bottomMeasuredDepth'), 'pipe', false)"
                                  [options]="getSortedPipeOptions(rowData, 'wallThickness')"
                                  optionLabel="wallThickness"
                                  optionValue="id"
                                  formControlName="id"
                                  appendTo="body">
                                </p-dropdown>
                                <validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'wallThickness')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'wallThickness')"></validation-error>
                              </td>
                            </ng-container>
                              <td>
                                <p-dropdown
                                  [disabled]="userRoles.readOnly"
                                  [title]="showJson(rowData.get('grade').value)"
                                  [placeholder]="getPlaceholderText(rowData.get('grade').value, 'name', rowData.get('bottomMeasuredDepth'), 'grade', false)"
                                  [options]="grades"
                                  formControlName="grade"
                                  appendTo="body">
                                </p-dropdown>
                                <validation-error [isValid]="isControlInvalid(rowData, 'grade')" [errorMsg]="getControlErrors(rowData, 'grade')"></validation-error>
                              </td>
                              <td class="wallPerformanceClass">
                                <div *ngIf="checkStrengthCalculation(rowData.get('grade'))">
                                </div>

                                <div class="nonApiButton" style="text-align: center;">
                                  <p-button *ngIf="!userRoles.readOnly" [icon]="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx) ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'" (click)="expandNonApiPipeProperties(wellCmpFg, tIdx)" styleClass="p-button-sm p-button-warning" label="{{ highCollapsePipeLabel }}"></p-button>
                                  <i
                                  *ngIf="userRoles.readOnly"
                                  (click)="expandNonApiPipeProperties(wellCmpFg, tIdx)"
                                  [class]="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx) ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'"
                                >{{ highCollapsePipeLabel }}
                                </i>
                                </div>
                              </td>
                              <td class="connectionClass">
                                <p-dropdown
                                  [disabled]="userRoles.readOnly"
                                  [title]="showJson(rowData.get('connection').value)"
                                  [placeholder]="getPlaceholderText(rowData.get('connection').value, 'name', rowData.get('bottomMeasuredDepth'), 'connection', rowData.get('connection').value)"
                                  [options]="rowData.get('connectionOptions').value"
                                  formControlName="connection"
                                  appendTo="body">
                                </p-dropdown>

                                <button style="bottom: 3px; padding-left: 6px;" pButton title="Remove Connection" class="p-button-text p-button-sm p-button-danger reset-conn-btn" icon="pi pi-refresh" (click)="resetConnection(rowData.get('bottomMeasuredDepth'))" ></button>
                            </td>
                            <td style="padding-right: 0px; text-align: center;">
                                <button pButton class="p-button-text p-button-sm p-button-danger delete-btn" icon="pi pi-trash" title="Remove String Section" (click)="onDeleteTubular(wellCmpFg, tIdx)" [disabled]='rowData.parent.length == 1'></button>
                            </td>
                          </tr>

                          <tr class="nonApiPipeTr" *ngIf="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx)" [formGroupName]="tIdx">
                            <td class="nonApiPipeTdClass" colspan="10">
                              <div class="ui-g ui-fluid">
                                <ng-container formGroupName="nonApiPipe">
                                  <tr>
                                    <th *ngFor="let col of nonApiPipeInputs" pResizableColumn>
                                      {{ col.header }}
                                    </th>
                                  </tr>
                                  <tr *ngIf="checkStrengthCalculation(rowData.get('grade'))">
                                    <td>
                                      <div class="inputSwitch">
                                        <label>Edit</label>
                                        <p-inputSwitch formControlName="tensionEnabled" binary="true"></p-inputSwitch>
                                      </div>
                                    </td>

                                    <td>
                                      <div class="inputSwitch">
                                        <label>Edit</label>
                                        <p-inputSwitch formControlName="compressionEnabled" binary="true"></p-inputSwitch>
                                      </div>
                                    </td>

                                    <td>
                                      <div class="inputSwitch">
                                        <label>Edit</label>
                                        <p-inputSwitch formControlName="burstEnabled" binary="true"></p-inputSwitch>
                                      </div>
                                    </td>

                                    <td>
                                      <div class="inputSwitch">
                                        <label>Edit</label>
                                        <p-inputSwitch formControlName="collapseEnabled" binary="true"></p-inputSwitch>
                                      </div>
                                    </td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'tensionEnabled', rowData.get('grade'), 'tension')" [minFractionDigits]="1" formControlName="tension"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'compressionEnabled', rowData.get('grade'), 'compression')" required [minFractionDigits]="1" formControlName="compression"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'burstEnabled', rowData.get('grade'), 'burst')" required [minFractionDigits]="1" formControlName="burst"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'collapseEnabled', rowData.get('grade'), 'collapse')" required [minFractionDigits]="1" formControlName="collapse"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceHoop"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceTriaxialAxial"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceAxial"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceBurst"></p-inputNumber>
                                    </td>
                                    <td>
                                      <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceCollapse"></p-inputNumber>
                                    </td>
                                  </tr>
                                </ng-container>
                              </div>
                            </td>
                          </tr>
                          <!-- <tr style="height: 10px;" *ngIf="tIdx === wellCmpFg.controls.stringSections.controls.length - 1">
                            <td style="height: 1px; background-color: '#474646';" colspan="9"></td>
                          </tr> -->
                        </ng-template>
                        </ng-container>
                      </ng-container>
                    </p-table>
                  </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </form>
      </div>
    </div>
    <br>
  </app-tool-loader>
</p-scrollPanel>
