<div class="adminContainer">

    <div class="header">
        <h1>Users</h1>
    </div>


    <div class="field addUserButton" >
        <p-button (click)="showAddUser()" icon="pi pi-user" label="Add User"></p-button>
    </div>
    <div class="card flex justify-content-center">
        <p-dialog modal="true" style="max-width: 300px;" header="Add User" [(visible)]="showAddUserForm">
            <add-user-form 
                [showAdminInputs]="user?.profile?.isGlobalAdmin"
                [companies]="companies" 
                [(userProfile)]="addUserAccount"
                (userUpdated)="onUserAdd($event)"
                (emailChanged)="onUserEmailChanged($event)"
                [showPassword]="userNotInAuth0"
            ></add-user-form>
        </p-dialog>

        <p-dialog modal="true" header="Edit User" [(visible)]="showEditUserForm">
            <edit-user-form 
                [showAdminInputs]="user?.profile?.isGlobalAdmin"
                [companies]="companies" 
                [userProfile]="editUserAccount" 
                (userUpdated)="onUserEdit($event)">
            </edit-user-form>
        </p-dialog>

        <p-dialog modal="true" header="Edit Permissions" [(visible)]="showEditPermissionsForm">
            <edit-permissions-form 
                [userPermissions]="userPermissions"
                (permissionsUpdated)="onUserPermissionsUpdated($event)">
            </edit-permissions-form>
        </p-dialog>
    </div>

    
    <app-tool-loader [isLoading]="isLoading">
        <div class="grid grid-nogutter users-table">
            <div class="col">
                <users-table 
                    [users]="users" 
                    (onEditUser)="showEditUser($event)" 
                    (onRemoveUser)="removeUser($event)"
                    (onEditPermissions)="showEditPermissions($event)" 
                    (onToggleLicense)="toggleLicense($event)" 
                    (onSendInvite)="sendInvite($event)">
                </users-table>
            </div>
        </div>
    </app-tool-loader>
</div>
