import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { isNumber } from 'lodash';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Flow } from 'src/app/perical/models/flow.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { CirculationOperation, OperationType } from 'src/app/perical/models/thermal-operation.model';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';

export interface CirculationOperationForm {
  type: OperationType;
  fluid: Fluid;
  circulationDirection: string;
  pressure: number;
  pressureLocation: string;
  circulationMd: number;
  inletTemperature: number;
  standardFluidFlow: Flow;
  annulusIndex: number;
}

@Component({
  selector: 'app-circulation-operation-details',
  templateUrl: './circulation-operation-details.component.html',
  styleUrls: ['./circulation-operation-details.component.scss'],
  providers: subformComponentProviders(CirculationOperationDetailsComponent)
})
export class CirculationOperationDetailsComponent implements OnInit, AfterViewInit {

  constructor( ) { }

  @Input()
  public isReadOnly: boolean;

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public udt: UndisturbedTemperature;

  @Input()
  public tubingShoe: number;

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.BRINE ||
      f.state.type === FluidType.STANDARDMUD ||
      f.state.type === FluidType.ADVANCEDMUD)
      .sort((a, b) => a.state.name.localeCompare(b.state.name));
  }
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;

  public circulationDirections = [
    { name: 'Forward', value: 'forward' },
    { name: 'Reverse', value: 'reverse' }
  ];
  public pressureLocations = [
    { name: 'Choke', value: 'choke' },
    { name: 'Pump', value: 'pump' }
  ];

  public depthUnit: string;
  public tempUnit: string;
  public pressureUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pressureValidation: { min: number, max: number };

  public form = createForm<CirculationOperation, CirculationOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.CIRCULATION),
      fluid: new UntypedFormControl(null, [Validators.required]),
      circulationDirection: new UntypedFormControl('forward', [Validators.required]),
      pressure: new UntypedFormControl(null),
      pressureLocation: new UntypedFormControl('choke', [Validators.required]),
      circulationMd: new UntypedFormControl(null),
      inletTemperature: new UntypedFormControl(null),
      standardFluidFlow: new UntypedFormControl(null),
      annulusIndex: new UntypedFormControl(null),
    },
    toFormGroup: (obj: CirculationOperation | null): CirculationOperationForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        fluid: this.fluids?.find(f => f.id === obj.fluid.id),
        standardFluidFlow: obj.flow,
        circulationDirection: obj.circulationParameters.circulationDirection,
        circulationMd: obj.circulationParameters.circulationMd ?? this.tubingShoe,
        inletTemperature: obj.circulationParameters.inletTemperature ?? this.udt[0].temperature,
        pressure: obj.circulationParameters.pressure,
        pressureLocation: obj.circulationParameters.pressureLocation,
        annulusIndex: obj.annulusIndex
      };
    },
    fromFormGroup: (formValue: CirculationOperationForm): CirculationOperation | null => {
      const { fluid, standardFluidFlow, circulationDirection, circulationMd, inletTemperature, pressure, pressureLocation, annulusIndex } = formValue;
        return standardFluidFlow ? {
          type: OperationType.CIRCULATION,
          fluid,
          flow: standardFluidFlow,
          circulationParameters: {
            circulationDirection,
            circulationMd: +circulationMd,
            inletTemperature: +inletTemperature,
            pressure: +pressure,
            pressureLocation
          },
          annulusIndex
        } : null;
      }
    });

  ngOnInit(): void {
    this.isLoading = true;
    this.tempUnit = this.userUnits.temperature;
    this.pressureUnit = this.userUnits.pressure;
    this.depthUnit = this.userUnits.longLengths;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.78, max: 537.77 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 810.92 };
        break;
    }
    this.form.formGroup.controls.inletTemperature.setValidators([Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max), Validators.required]);

    switch (this.pressureUnit) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 100000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 6894 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 689475 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 6804 };
        break;
    }
    this.form.formGroup.controls.pressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required]);
    this.form.formGroup.controls.circulationMd.setValidators([Validators.max(this.tubingShoe)]);
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    let controls = this.form.formGroup.controls;
    if (!controls.fluid.value) {
      controls.fluid.setValue(this.fluids ? this.fluids[0] : {
        id: "0000-0000-0000-0000",
        isInUse: false,
        state: {
          brineType: "seawater",
          nominalDensity: 10,
          plasticViscosity: 1,
          viscosityTemperature: 120,
          name: "10ppg SW",
          type: FluidType.BRINE
        }
      });
    }
    if (!isNumber(controls.circulationMd.value)) { this.form.formGroup.controls.circulationMd.setValue(this.tubingShoe); }
    if (!isNumber(controls.inletTemperature.value)) { this.form.formGroup.controls.inletTemperature.setValue(this.udt[0].temperature); }
  }
}
