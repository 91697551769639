import { Component, OnInit } from '@angular/core';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { lastValueFrom } from 'rxjs';
import { AddUserAccount, EditUserAccount, EditUserPermissions, User, UserProfile } from './user-model';
import { UsersAdminService } from '../../services/users-admin.service';
import { UsersService } from '../../services/users-service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
})
export class UserAdminComponent implements OnInit {

  public showAddUserForm: boolean;
  public showEditUserForm: boolean;
  public showEditPermissionsForm: boolean;
  public userNotInAuth0: boolean;

  public users: Array<User> = [];
  public addUserAccount: AddUserAccount;
  public editUserAccount: EditUserAccount;
  public userPermissions: EditUserPermissions;

  public companies: Array<any> = [];
  public isLoading: boolean;
  public user: User;

  constructor(
    private _usersAdminService: UsersAdminService,
    private _usersService: UsersService,
    private _toaster: AppNotificationService
  ) { }

  async ngOnInit() {
    await this.loadData();
  }

  showAddUser() {
    this.addUserAccount = new AddUserAccount();
    this.showAddUserForm = true;
  }

  async showEditUser(user: User) {
    this.isLoading = true;
    let auth0UserData = await lastValueFrom(this._usersAdminService.getAuth0User(user.profile.email));
    user.profile.isGlobalAdmin = auth0UserData?.app_metadata?.isGlobalAdmin ?? false;
    user.profile.database = auth0UserData?.app_metadata.current_db || "";
    
    this.editUserAccount = new EditUserAccount({ ...user });
    this.showEditUserForm = true;
    this.isLoading = false;
  }

  showEditPermissions(user: User) {
    this.userPermissions = new EditUserPermissions({ ...user });
    this.showEditPermissionsForm = true;
  }

  async onUserAdd(userAct: AddUserAccount) {
    this.showAddUserForm = false;
    try {
      const response = await lastValueFrom(this._usersAdminService.addUser(userAct));
      if (response) {
        this._toaster.showSuccess("User added");
        await this.loadData();
      }
    } catch (error) {
      this._toaster.showError("Failed to add user");
    }
  }

  async onUserEdit(userAct: EditUserAccount) {
    this.showEditUserForm = false;
    try {
      const response = await lastValueFrom(this._usersAdminService.updateUser(userAct.id, userAct));
      if (response) {
        this._toaster.showSuccess("User edited");
        await this.loadData();
      }
    } catch (error) {
      this._toaster.showError("Failed to edit user");
    }
  }

  async onUserPermissionsUpdated(userAct: EditUserPermissions) {
    this.showEditPermissionsForm = false;
    try {
      const response = await lastValueFrom(this._usersAdminService.updateRoles(userAct.id, userAct.roles));
      if (response) {
        this._toaster.showSuccess("User permissions updated");
        await this.loadData();
      }
    } catch (error) {
      this._toaster.showError("Failed to update user permissions");
    }
  }

  async onUserEmailChanged(email: string) {
    try {
      const response = await lastValueFrom(this._usersAdminService.getAuth0User(email));
      if (response) {
        this._toaster.showSuccess("Auth0 User Found");
        this.addUserAccount = new AddUserAccount({ profile: new UserProfile({ email: email, firstName: response.given_name, lastName: response.family_name }) });
        this.userNotInAuth0 = false;
      } else {
        this.userNotInAuth0 = true;
      }
    } catch (error) {
      this._toaster.showError("Failed to fetch Auth0 user");
    }
  }

  async removeUser(user: User) {
    try {
      await lastValueFrom(this._usersAdminService.deleteUser(user.id));
      await this.loadData();
    } catch (error) {
      this._toaster.showError("Failed to remove user");
    }
  }

  async toggleLicense(user: User) {
    try {
      await lastValueFrom(this._usersAdminService.toggleLicense(user.id));
      await this.loadData();
    } catch (error) {
      this._toaster.showError("Failed to toggle license");
    }
  }

  async sendInvite(user: User) {
    try {
      await lastValueFrom(this._usersAdminService.sendInvite(user.id));
      this._toaster.showSuccess("Invite sent");
    } catch (error) {
      this._toaster.showError("Failed to send invite");
    }
  }

  // Using arrow function to ensure this context is preserved
  loadData = async () => {
    try {
      this.isLoading = true;
      this.user = await lastValueFrom(this._usersService.getUser());
      this.users = await lastValueFrom(this._usersAdminService.getAllUsers());
      this.companies = await lastValueFrom(this._usersAdminService.getAllCompanies());
    } catch (error) {
      this._toaster.showError("Failed to load data");
      this.isLoading = false;
    }
    this.isLoading = false;
  }
  
}
