import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { SelectItem } from 'primeng/api';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { PoreAndFracService } from 'src/app/perivis/services/pore-and-frac.service';
import { WellType } from 'src/app/shared/models/wellType.model';
import { WellComponent } from 'src/app/wellbore-inputs/components/well-configuration/models';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { WellConfigService } from '../../../../../shared/services/well-config.service';
import { CasingFullOfGasIPP, CirculatedKickGasIPP, CirculatedKickLiquidIPP, CustomIPP, DisplacementFluidIPP,
   FullEvacIPP, GeneralFluidIPP, InternalPressureProfile, InternalPressureProfileType, LostReturnsFluidDropIPP,
   LostReturnsWaterIPP, PartialEvacByMdIPP, PericalLinkIPP, PressureTestIPP, SssvIPP, WCDIPP } from '../models/internal-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

export interface InternalPressureProfileForm {
  type: InternalPressureProfileType;
  customIpp: CustomIPP;
  pericalLinkIpp: PericalLinkIPP;
  displacementFluidIpp: DisplacementFluidIPP;
  pressureTestIpp: PressureTestIPP;
  sssvIpp: SssvIPP;
  generalFluidIpp: GeneralFluidIPP;
  fullEvacIpp: FullEvacIPP;
  partialEvacByMdIpp: PartialEvacByMdIPP;
  wcdIpp: WCDIPP;
  lostReturnsFluidDropIpp: LostReturnsFluidDropIPP;
  lostReturnsWaterIpp: LostReturnsWaterIPP;
  circulatedKickGasIpp: CirculatedKickGasIPP;
  circulatedKickLiquidIpp: CirculatedKickLiquidIPP;
  cfogIpp: CasingFullOfGasIPP;
}

@Component({
  selector: 'app-internal-pressure-profiles',
  templateUrl: './internal-pressure-profiles.component.html',
  styleUrls: ['./internal-pressure-profiles.component.scss'],
  providers: subformComponentProviders(InternalPressureProfilesComponent)
})
export class InternalPressureProfilesComponent implements OnInit {

  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() cement: CementingLanding;
  @Input() currentString: any;
  @Input() wellType: WellType;
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;
  @Input() isReadOnly: boolean;

  public form = createForm<InternalPressureProfile, InternalPressureProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      customIpp: new UntypedFormControl(null),
      pericalLinkIpp: new UntypedFormControl(null),
      displacementFluidIpp: new UntypedFormControl(null),
      pressureTestIpp: new UntypedFormControl(null),
      sssvIpp: new UntypedFormControl(null),
      generalFluidIpp: new UntypedFormControl(null),
      fullEvacIpp: new UntypedFormControl(null),
      partialEvacByMdIpp: new UntypedFormControl(null),
      wcdIpp: new UntypedFormControl(null),
      lostReturnsFluidDropIpp: new UntypedFormControl(null),
      lostReturnsWaterIpp: new UntypedFormControl(null),
      circulatedKickGasIpp: new UntypedFormControl(null),
      circulatedKickLiquidIpp: new UntypedFormControl(null),
      cfogIpp: new UntypedFormControl(null)
    },
    toFormGroup: (obj: InternalPressureProfile | null): InternalPressureProfileForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        customIpp: obj.type === InternalPressureProfileType.CUSTOMIPP ? obj : null,
        pericalLinkIpp: obj.type === InternalPressureProfileType.PERICALLINK ? obj : null,
        displacementFluidIpp: obj.type === InternalPressureProfileType.DISPLACEMENTFLUID ? obj : null,
        pressureTestIpp: obj.type === InternalPressureProfileType.PRESSURETEST ? obj : null,
        sssvIpp: obj.type === InternalPressureProfileType.SSSV ? obj : null,
        generalFluidIpp: obj.type === InternalPressureProfileType.GENERALFLUID ? obj : null,
        fullEvacIpp: obj.type === InternalPressureProfileType.FULLEVAC ? obj : null,
        partialEvacByMdIpp: obj.type === InternalPressureProfileType.PARTIALEVAC ? obj : null,
        wcdIpp: obj.type === InternalPressureProfileType.WCDIPP ? obj : null,
        lostReturnsFluidDropIpp: obj.type === InternalPressureProfileType.FLUIDDROP ? obj : null,
        lostReturnsWaterIpp: obj.type === InternalPressureProfileType.LOSTRETURNSWATER ? obj : null,
        circulatedKickGasIpp: obj.type === InternalPressureProfileType.GASKICK ? obj : null,
        circulatedKickLiquidIpp: obj.type === InternalPressureProfileType.LIQUIDKICK ? obj : null,
        cfogIpp: obj.type === InternalPressureProfileType.CFOG ? obj : null
      };
    },
    fromFormGroup: (formValue: InternalPressureProfileForm): InternalPressureProfile | null => {
      const { type, customIpp, pericalLinkIpp, displacementFluidIpp, pressureTestIpp, sssvIpp, generalFluidIpp,
         fullEvacIpp, partialEvacByMdIpp, wcdIpp, lostReturnsFluidDropIpp, lostReturnsWaterIpp, circulatedKickGasIpp,
         circulatedKickLiquidIpp, cfogIpp, ...commonValues } = formValue;

      switch (type) {
        case InternalPressureProfileType.CUSTOMIPP:
          return customIpp ? { type, ...customIpp, ...commonValues } : null;
        case InternalPressureProfileType.PERICALLINK:
          return pericalLinkIpp ? { type, ...pericalLinkIpp, ...commonValues } : null;
        case InternalPressureProfileType.DISPLACEMENTFLUID:
          return displacementFluidIpp ? { type, ...displacementFluidIpp, ...commonValues } : null;
        case InternalPressureProfileType.PRESSURETEST:
          return pressureTestIpp ? { type, ...pressureTestIpp, ...commonValues } : null;
        case InternalPressureProfileType.SSSV:
          return sssvIpp ? { type, ...sssvIpp, ...commonValues } : null;
        case InternalPressureProfileType.GENERALFLUID:
          return generalFluidIpp ? { type, ...generalFluidIpp, ...commonValues } : null;
        case InternalPressureProfileType.FULLEVAC:
          return fullEvacIpp ? { type, ...fullEvacIpp, ...commonValues } : null;
        case InternalPressureProfileType.PARTIALEVAC:
          return partialEvacByMdIpp ? { type, ...partialEvacByMdIpp, ...commonValues } : null;
        case InternalPressureProfileType.WCDIPP:
          return wcdIpp ? { type, ...wcdIpp, ...commonValues } : null;
        case InternalPressureProfileType.FLUIDDROP:
          return lostReturnsFluidDropIpp ? { type, ...lostReturnsFluidDropIpp, ...commonValues } : null;
        case InternalPressureProfileType.LOSTRETURNSWATER:
          return lostReturnsWaterIpp ? { type, ...lostReturnsWaterIpp, ...commonValues } : null;
        case InternalPressureProfileType.GASKICK:
          return circulatedKickGasIpp ? { type, ...circulatedKickGasIpp, ...commonValues } : null;
        case InternalPressureProfileType.LIQUIDKICK:
          return circulatedKickLiquidIpp ? { type, ...circulatedKickLiquidIpp, ...commonValues } : null;
        case InternalPressureProfileType.CFOG:
          return cfogIpp ? { type, ...cfogIpp, ...commonValues } : null;
      }
    }
  });

  public ipps: SelectItem[] = [
    { label: 'Custom', value: 'CustomInternalPressures' },
    { label: 'Perical Link', value: 'ThermalLink' },
    { label: 'Displacement Fluid', value: 'DisplacementFluid' },
    { label: 'Pressure Test', value: 'PressureTest' },
    { label: 'SSSV/DHCV Test', value: 'SubSurfaceSafetyValveTest' },
    { label: 'General Fluid Profile', value: 'GeneralFluidProfile' },
    { label: 'Full Evacuation', value: 'FullEvacuation' },
    { label: 'Partial Evacuation By MD', value: 'PartialEvacuationByMd' },
    // { label: 'Partial Evacuation By TVD', value: { id: 'PartialEvacuationByTvd' } },
    { label: 'Lost Returns (Fluid Drop)', value: 'FluidDrop' },
    { label: 'Lost Returns With Water', value: 'LostReturnsWithWater' },
    { label: 'Circulated Kick: Gas', value: 'GasKick' },
    { label: 'Circulated Kick: Liquid', value: 'LiquidKick' },
    { label: 'Gas Influx: Casing Full Of Gas', value: 'CasingFullOfGas' },
    // { label: 'Gas Influx: Gas Over Mud - N/A', value: 'GasOverMud' },
    // { label: 'Gas Influx: Mud Over Gas - N/A', value: 'MudOverGas' },
    { label: 'Worst Case Discharge', value: 'WorstCaseDischarge' }
  ];

  public InternalPressureProfileType: typeof InternalPressureProfileType = InternalPressureProfileType;

  // Inputs for subforms
  public nextString: WellComponent;
  public maxPlugDepth: number;
  public nextShoeDepth: number;
  public nextStringMudWeight: number;
  public influxDepthDefault: number;
  public previousShoeDepth: number;
  public smallestPipeId: number;
  public porePressures: Array<PoreFrac>;
  public fracPressures: Array<PoreFrac>;
  public isLoading: boolean;

  constructor(
    private wellConfigService: WellConfigService,
    private _poreAndFracService: PoreAndFracService,
    private _fluidsService: FluidsService,
  ) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.setDefaults();
  }

  private setDefaults() {
    const sources: Observable<any>[] = [
      this.wellConfigService.getTubulars() as Observable<WellComponent[]>,
      this._poreAndFracService.getPorePressures(),
      this._poreAndFracService.getFractureGradient()
    ];

    forkJoin(sources).pipe(
      map(([tubulars, porePressures, fracPressures]) => {
        let selectedStringIndex = tubulars.findIndex(x => x.id === this.currentString.id);
        this.nextString = tubulars[selectedStringIndex + 1];
        const previousString = selectedStringIndex > 0 ? tubulars[selectedStringIndex - 1] : this.currentString;
        const lastStringIndex = tubulars.length - 1;
        const lastString = tubulars[lastStringIndex];
  
        if ((selectedStringIndex === lastStringIndex || selectedStringIndex === lastStringIndex - 1) && lastString.type === 'Tubing') {
          this.ipps = this.ipps.filter(x => x.value !== "GasKick")
          this.ipps = this.ipps.filter(x => x.value !== "LiquidKick")
          this.ipps = this.ipps.filter(x => x.value !== "FluidDrop")
          this.ipps = this.ipps.filter(x => x.value !== "LostReturnsWithWater")
          this.ipps = this.ipps.filter(x => x.value !== "CasingFullOfGas")
        }
  
        this.maxPlugDepth = Math.min(this.currentString.shoeMd, this.currentString.topOfCementMd);
        this.previousShoeDepth = previousString.shoeMd;
        this.smallestPipeId = this.currentString.stringSections[this.currentString.stringSections.length - 1].insideDiameter;
        this.nextShoeDepth = this.nextString?.shoeMd ?? this.currentString.shoeMd;
        const nextStringAnnularFluidId = this.nextString?.annularFluidId ?? null;
        this.influxDepthDefault = Math.max(this.currentString.shoeMd, this.nextShoeDepth);
  
        if (nextStringAnnularFluidId) {
          this._fluidsService.getFluidById(nextStringAnnularFluidId).subscribe(fluid => {
            this.nextStringMudWeight = fluid.state['nominalDensity'];
          });
        }
  
        this.porePressures = porePressures;
        this.fracPressures = fracPressures;
  
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  // getHeight(e) {
  //   if (e.id == 'GasKick' || e.id == 'LiquidKick') {
  //     return 'min-height: 430px;';
  //   } else {
  //     return 'min-height: 300px;';
  //   }
  // }
}
