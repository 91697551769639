<form [formGroup]="form.formGroup">
  <div class="field">
    <label>Choke Name</label>
    <div class="p-inputgroup">
      <input
        type="text"
        pInputText
        [formControlName]="form.formControlNames.name"
        id="name"
      />
    </div>
  </div>
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Choke </ng-template>
        <div class="field">
          <label>Critical Flow Factor (Liquid)</label>
          <div class="p-inputgroup">
            <p-inputNumber
              [minFractionDigits]="1"
              formControlName="criticalLiquidFlowFactor"
            ></p-inputNumber>
          </div>
          <p *ngIf="form.formGroup.controls.criticalLiquidFlowFactor.invalid && form.formGroup.value.criticalLiquidFlowFactor" class="error">Allowed range: {{ 0 }} - {{ 1 }}</p>
        </div>

        <div class="field">
          <label>Critical Flow Factor (Vapor)</label>
          <div class="p-inputgroup">
            <p-inputNumber
              [minFractionDigits]="1"
              formControlName="criticalVaporFlowFactor"
            ></p-inputNumber>
          </div>
          <p *ngIf="form.formGroup.controls.criticalVaporFlowFactor.invalid && form.formGroup.value.criticalVaporFlowFactor" class="error">Allowed range: {{ 0 }} - {{ 1 }}</p>
        </div>

        <div class="field">
          <label>Piping Geometry Factor</label>
          <div class="p-inputgroup">
            <p-inputNumber
              [minFractionDigits]="1"
              formControlName="pipingGeometryFactor"
            ></p-inputNumber>
          </div>
        </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header"> Cv Schedule </ng-template>
      <div class="grid">
        <div class="col-5">
          <div class="p-field">
            <label>% Open</label>
            <div class="p-inputgroup">
              <p-inputNumber
                (onInput)="interpolateCv($event)"
                [minFractionDigits]="1"
                [disabled]="flowCoefficientSchedule?.length <= 1"
              ></p-inputNumber>
            </div>
          </div>
        </div>

        <div class="col-5">
          <div class="p-field">
            <label>Interpolated Cv</label>
            <div class="p-inputgroup">
              <p-inputNumber
                disabled="true"
                placeholder="{{ interpolatedCv }}"
              ></p-inputNumber>
            </div>
          </div>
        </div>

        <div *ngIf="chokesUiEntity$" class="col-12">
          <p-inputSwitch
            (onChange)="tablePlotToggle($event)"
            [(ngModel)]="chokesUiEntity$.selectedResultsDisplay"
            [ngModelOptions]="{standalone: true}"
            binary="true"
          ></p-inputSwitch>
          <label class="plotLabel">Table/Plot</label>
        </div>
      </div>
      <app-xy-line-plot
        *ngIf="chokesUiEntity$?.selectedResultsDisplay"
        [plotData]="plot.data"
        [xAxisTitle]="xAxisTitle"
        [yAxisTitle]="yAxisTitle"
        [plotName]="plotName"
        [downloadPlotName]="downloadPlotName"
        [reverseAutoRange]="true"
        [plotTitle]="'Chokes'"
        [componentHeight]="chokesComponentHeight"
      ></app-xy-line-plot>
      <div [style.display]="!chokesUiEntity$?.selectedResultsDisplay ? 'block' : 'none'">
        <br />
        <ng-table-grid-cmp
          tableName="Flow Coefficient Schedule"
          [tableHeight]="tableHeight"
          [tableData]="flowCoefficientSchedule"
          [columnDefinitions]="columnDefinitions"
          [newRowFormGroup]="newDataRow"
          [inputFields]="inputFields"
          (dataChange)="onFlowScheduleChange($event)"
          [isDynamicLoaded]="true"
          [enableVirtualScroll]="false"
          [tableHeight]="tableHeight"
        ></ng-table-grid-cmp>
      </div>
    </p-tabPanel>
  </p-tabView>
</form>
