<form [formGroup]="form.formGroup">
  <div class="defaultButton">
    <img
      (click)="applyDefaults()"
      title="Apply Defaults"
      src="../../assets/icons/default-icon-white.png"
    />
  </div>
  <div class="grid">
    <div class="col">
      <p-fieldset legend="Volumetric Composition">
        <div class="field">
          <label for="baseBrine">Base Water</label>
          <div class="p-inputgroup">
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="brines"
              [formControlName]="form.formControlNames.baseBrine"
              optionLabel="state.name"
              appendTo="body"
            ></p-dropdown>
            <p-inputNumber
              id="waterFraction"
              [formControlName]="form.formControlNames.waterFraction"
              required
              [minFractionDigits]="1"
              (onInput)="validatePercentage('waterFraction', $event)"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p
            *ngIf="
              form.formGroup.controls.waterFraction.invalid &&
              form.formGroup.controls.waterFraction.value
            "
            class="error"
          >
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="field">
          <label for="baseOil">Base Oil</label>
          <div class="p-inputgroup">
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="baseOilTypes"
              [formControlName]="form.formControlNames.baseOil"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
            ></p-dropdown>
            <p-inputNumber
              placeholder="0"
              id="oilFraction"
              [formControlName]="form.formControlNames.oilFraction"
              required
              [minFractionDigits]="1"
              (onInput)="validatePercentage('oilFraction', $event)"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p
            *ngIf="
              form.formGroup.controls.oilFraction.invalid &&
              form.formGroup.controls.oilFraction.value
            "
            class="error"
          >
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="field">
          <label for="highDensitySolids">High Density Solids</label>
          <div class="p-inputgroup">
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="highDensityMudSolidsTypes"
              [formControlName]="form.formControlNames.highDensityMudSolidsType"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
            ></p-dropdown>
            <p-inputNumber
              placeholder="0"
              id="highDensitySolidsFraction"
              [formControlName]="
                form.formControlNames.highDensitySolidsFraction
              "
              required
              [minFractionDigits]="1"
              (onInput)="
                validatePercentage('highDensitySolidsFraction', $event)
              "
            ></p-inputNumber>
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p
            *ngIf="
              form.formGroup.controls.highDensitySolidsFraction.invalid &&
              form.formGroup.controls.highDensitySolidsFraction.value
            "
            class="error"
          >
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="field">
          <label for="lowDensitySolids">Low Density Solids</label>
          <div class="p-inputgroup">
            <p-dropdown
              [disabled]="isReadOnly"
              [options]="lowDensityMudSolidsTypes"
              [formControlName]="form.formControlNames.lowDensityMudSolidsType"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
            ></p-dropdown>
            <p-inputNumber
              placeholder="0"
              id="lowDensitySolidsFraction"
              [formControlName]="form.formControlNames.lowDensitySolidsFraction"
              required
              [minFractionDigits]="1"
              (onInput)="validatePercentage('lowDensitySolidsFraction', $event)"
            ></p-inputNumber>
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p
            *ngIf="
              form.formGroup.controls.lowDensitySolidsFraction.invalid &&
              form.formGroup.controls.lowDensitySolidsFraction.value
            "
            class="error"
          >
            Allowed range: 0 - 100 %
          </p>
        </div>

        <p-toolbar>
          <div class="mr-2">
            <label>Total: {{ totalPercent }} %</label>
          </div>
        </p-toolbar>
        <p *ngIf="totalPercent !== 100" class="error">
          Total percent must be equal to 100%
        </p>
        <br />

        <div class="field">
          <label for="densityTemperature">Density Temperature</label>
          <div class="p-inputgroup">
            <p-inputNumber
              id="densityTemperature"
              [formControlName]="form.formControlNames.densityTemperature"
              [minFractionDigits]="1"
            >
            </p-inputNumber>
            <span class="p-inputgroup-addon">{{ tempUnit }}</span>
            <label
              class="calculatedDensityLabel"
              *ngIf="form.formGroup.controls.nominalDensity.value"
              >Density:
              {{
                form.formGroup.controls.nominalDensity.value | number : "1.0-2"
              }}
              {{ densityUnit }}</label
            >
          </div>
          <p
            *ngIf="form.formGroup.controls.densityTemperature.invalid"
            class="error"
          >
            Allowed range: {{ temperatureValidation.min }} -
            {{ temperatureValidation.max }} {{ tempUnit }}
          </p>
          <br *ngIf="form.formGroup.controls.densityTemperature.invalid" />
        </div>
      </p-fieldset>

      <br />

      <div class="mr-2">
        <label>Show Base Oil Correlations</label>
        <p-inputSwitch (onChange)="showBaseOils()"></p-inputSwitch>
      </div>
      <br *ngIf="showBaseOil" />

      <p-fieldset *ngIf="showBaseOil" legend="Base Oil Density Correlation">
        <div class="grid">
          <div class="col">
            <div class="field">
              <label for="a0">a0 x 10</label><label class="sup">4</label>
              <div class="p-inputgroup">
                <input disabled="true" pInputText value="{{ a0 }}" required />
              </div>
            </div>
            <div class="field">
              <label for="a1">a1 x 10</label><label class="sup">8</label>
              <div class="p-inputgroup">
                <input disabled="true" pInputText value="{{ a1 }}" required />
              </div>
            </div>
            <div class="field">
              <label for="a2">a2 x 10</label><label class="sup">13</label>
              <div class="p-inputgroup">
                <input disabled="true" pInputText value="{{ a2 }}" required />
              </div>
            </div>
          </div>
        <div class="col">
          <div class="field">
            <label for="b0">b0 x 10</label>
            <div class="p-inputgroup">
              <input disabled="true" pInputText value="{{ b0 }}" required />
            </div>
          </div>
          <div class="field">
            <label for="b1">b1 x 10</label><label class="sup">6</label>
            <div class="p-inputgroup">
              <input disabled="true" pInputText value="{{ b1 }}" required />
            </div>
          </div>
          <div class="field">
            <label for="b2">b2 x 10</label><label class="sup">12</label>
            <div class="p-inputgroup">
              <input disabled="true" pInputText value="{{ b2 }}" required />
            </div>
          </div>
        </div>
      </div>
      </p-fieldset>
      <div>
        <br />
        <p-fieldset legend="Rheology">
          <div class="col">
            <div class="field">
              <label for="plasticViscosity">Plastic Viscosity</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="plasticViscosity"
                  [formControlName]="form.formControlNames.plasticViscosity"
                  [minFractionDigits]="1"
                ></p-inputNumber>
                <span class="p-inputgroup-addon">cP</span>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.plasticViscosity.invalid &&
                  form.formGroup.controls.plasticViscosity.value
                "
                class="error"
              >
                Allowed range: 0 - 2000 cP
              </p>
            </div>

            <div class="field">
              <label for="yieldPoint">Yield Point</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="yieldPoint"
                  [formControlName]="form.formControlNames.yieldPoint"
                  [minFractionDigits]="1"
                ></p-inputNumber>
                <span class="p-inputgroup-addon">lbf/100ft²</span>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.yieldPoint.invalid &&
                  form.formGroup.controls.yieldPoint.value
                "
                class="error"
              >
                Allowed range: 0 - 2000 lbf/100ft²
              </p>
            </div>

            <div class="field">
              <label for="rheologyTemperature">Temperature</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="rheologyTemperature"
                  [formControlName]="form.formControlNames.rheologyTemperature"
                  [minFractionDigits]="1"
                ></p-inputNumber>
                <span class="p-inputgroup-addon">{{ tempUnit }}</span>
              </div>
              <p
                *ngIf="
                  form.formGroup.controls.rheologyTemperature.invalid &&
                  form.formGroup.controls.rheologyTemperature.value
                "
                class="error"
              >
                {{ temperatureValidation.min }} -
                {{ temperatureValidation.max }} {{ tempUnit }}
              </p>
            </div>
          </div>
        </p-fieldset>
      </div>
    </div>
  </div>
</form>
