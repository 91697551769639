
  export class PipeReference {

    constructor(init?: Partial<PipeReference>) {
      Object.assign(this, init);
    }

    id: string;
    outsideDiameter: number;
    weightPerFoot: number;
    insideDiameter: number;
    wallThickness: number;
    driftDiameter: number;
    discriminator: string;
  }
