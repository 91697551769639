import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'allowed-range',
    styles: [
    `.error {
      color: red;
      font-size: 11px;
      margin-top: 0px;
    }`,
   `p {
      height: 20px;
      width: 120px;
      margin-bottom: 0px;
    }`
  ],
    template: `
    <p *ngIf="invalid" class="error">
        Allowed range: {{from}} - {{to}}
    </p>
    `,
  })
  export class AllowedRangeComponent implements OnInit {
    
    @Input()
    invalid : boolean;

    @Input()
    from: number;
    
    @Input()
    to: number;
  
    ngOnInit() {}

    constructor() {}
  }