<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div #toolbarDiv class="flex">
    <div class="mr-2">
      <p-selectButton
        [options]="resultsView"
        visible="true"
        class="resultsViewButton"
        [(ngModel)]="pressureResultsStore.state.selectedResultsView"
        (onChange)="resultsViewToggle($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>

    <div class="mr-2">
      <p-selectButton
        [options]="resultsDisplay"
        [(ngModel)]="pressureResultsStore.state.selectedResultDisplay"
        (onChange)="onSelectedResultDisplayUpdated($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>

    <div class="flex grid=-nogutter" *ngIf="pressureResultsStore.state?.selectedResultDisplay === 'plot'">
      <div class="mr-2">
        <p-selectButton
          [options]="depthView"
          [(ngModel)]="pressureResultsStore.state.selectedDepthView"
          (onChange)="onSelectedDepthViewUpdated($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>
  </div>

  <app-single-load-plot
    [yAxisTitle]="yAxisTitle"
    [xAxisTitle]="xAxisTitle"
    [columnDefs]="columnDefs"
    [data]="results"
    *ngIf="pressureResultsStore.state?.selectedResultDisplay === 'plot'"
    [selectedDepthView]="pressureResultsStore.state?.selectedDepthView"
    [loadCaseResultField]="selectedLoadCase"
    [depthUnit]="depthUnit"
    [plotName]="plotName"
    [plotTitle]="'Pressures'"
    [componentHeight]="componentHeight"
  ></app-single-load-plot>

  <div [style.display]="pressureResultsStore.state?.selectedResultDisplay === 'grid' ? 'block' : 'none'">
    <br />
    <app-single-load-grid
      [rowData]="results"
      [columnDefinitions]="columnDefinitions"
      tableName="Pressures"
      [tableHeight]="tableHeight"
      gridName="Pressures"
    ></app-single-load-grid>
  </div>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br />
    <br />
  </p-dialog>
</app-tool-loader>
