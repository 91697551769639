<div *ngIf="user" class="profileContainer">
  <div class="grid grid-nogutter">
    <p-card class="col-6 mx-auto">
      <div class="grid grid-nogutter">
        <div class="flex justify-content-center col-12">
          <h1>{{ user?.profile?.firstName }} {{ user?.profile?.lastName }}</h1>
        </div>
        <!-- <p-divider></p-divider> -->
        <hr class="rounded" style=" margin-bottom: 10px; width: 100%;" />

        <div class="col-12">
          <span
            class="updatePwrdLnk"
            type="button"
            (click)="onSetUserPassword()"
            >Update Password</span
          >
        </div>

        <div class="col-12">
          <dl>
            <dt>Company:</dt>
            <dd>{{ user?.profile?.organization }}</dd>
            <dt>Email:</dt>
            <dd>{{ user?.profile?.email }}</dd>
            <dt>Auth-ID:</dt>
            <dd>{{ user.authId.replace("auth0|", "") }}</dd>
            <dt>Licensed:</dt>
            <dd>{{ user.isLicensed }}</dd>
            <dt>Global Admin:</dt>
            <dd>{{ user?.profile?.isGlobalAdmin ?? false }}</dd>
            <dt>Admin:</dt>
            <dd>{{ user?.roles?.admin ?? false }}</dd>
            <dt>Read-Only:</dt>
            <dd>{{ user.roles.readOnly }}</dd>
          </dl>
        </div>
      </div>
    </p-card>
  </div>
</div>
