import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UdtPlotComponent } from '../wellbore-inputs/components/udt-plot/udt-plot.component';
import { UndisturbedTemperatureComponent } from '../wellbore-inputs/components/undisturbed-temperature/undisturbed-temperature.component';
import { TrajectoryGridComponent } from '../wellbore-inputs/components/trajectory-grid/trajectory-grid.component';
import { InterpolationToolComponent } from '../wellbore-inputs/components/interpolation-tool/interpolation-tool.component';
import { CementingLandingComponent } from '../wellbore-inputs/components/cementing-landing/cementing-landing.component';
import { WellTypeDatumsComponent } from '../wellbore-inputs/components/well-type-datums/well-type-datums.component';
import { WellConfigurationComponent } from '../wellbore-inputs/components/well-configuration/well-configuration.component';
import { PackersComponent } from '../wellbore-inputs/components/packers/packers.component';

import { SharedModule } from './shared.module';
import { PerforationsComponent } from '../wellbore-inputs/components/perforations/perforations.component';
import { FormationTopsComponent } from '../wellbore-inputs/components/formation-tops/formation-tops.component';
import { TrajectoryPlotContainerComponent } from '../wellbore-inputs/components/trajectory-plot/trajectory-plot-container.component';
import { ThreeDeePlotComponent } from '../wellbore-inputs/components/trajectory-plot/3dPlot/3Dplot.component';
import { TwoDeePlotComponent } from '../wellbore-inputs/components/trajectory-plot/2dPlot/2Dplot.component';
import { SchematicInfoComponent } from '../wellbore-inputs/components/well-schematic/schematic-info.component';
import { WellSchematicComponent } from '../wellbore-inputs/components/well-schematic/well-schematic.component';
import { DrillstringComponent } from '../wellbore-inputs/components/drillstring/drillstring.component';
import { WellheadComponent } from '../wellbore-inputs/components/wellhead/wellhead.component';
import { ExpansionJointComponent } from '../wellbore-inputs/components/packers/expansion-joint/expansion-joint.component';
import { PackerDataComponent } from '../wellbore-inputs/components/packers/packer-data/packer-data.component';
import { ChokesComponent } from '../perical/components/chokes/chokes.component';
import { ChokeDataComponent } from '../perical/components/chokes/choke-data/choke-data.component';

const components =  [
  PackersComponent,
  TrajectoryGridComponent,
  TrajectoryPlotContainerComponent,
  ThreeDeePlotComponent,
  TwoDeePlotComponent,
  InterpolationToolComponent,
  UdtPlotComponent,
  CementingLandingComponent,
  UndisturbedTemperatureComponent,
  WellConfigurationComponent,
  WellSchematicComponent,
  SchematicInfoComponent,
  WellTypeDatumsComponent,
  PerforationsComponent,
  FormationTopsComponent,
  DrillstringComponent,
  WellheadComponent,
  ExpansionJointComponent,
  PackerDataComponent,
  ChokesComponent,
  ChokeDataComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ...components
  ]
})
export class WellboreInputsModule { }
