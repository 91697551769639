import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, TreeNode } from 'primeng/api';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { LauncherSharedService } from '../../services/launcher-shared.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  public activeItem: MenuItem;
  public catalogSubMenu: Array<TreeNode>;
  public navbarItems: MenuItem[];
  public menuSelected: string;
  public wellboreInputsSubMenu: Array<TreeNode>;
  public stressSubMenu: Array<TreeNode>;
  public thermalSubMenu: Array<TreeNode>;
  public apbSubMenu: Array<TreeNode>;

  @Output()
  public onCloseSidenav: EventEmitter<string>;

  constructor(
    private _messenger: MediatorService,
    private _route: ActivatedRoute,
    private _launcherSharedService: LauncherSharedService
  ) {
    this.onCloseSidenav = new EventEmitter();

    this.navbarItems = [
      { label: 'Well Explorer', icon: '../../assets/icons/icons8-oil-platform-100.png', command: (event) => this.setMenuComponent('wellExplorer') },
      { label: 'Perifor Inputs', icon: '../../assets/icons/icons8-input-80.png', command: (event) => this.setMenuComponent('wellboreInputs') },
      { label: 'Perical (Thermal)', color: 'filter: invert(25%) sepia(98%) saturate(7450%) hue-rotate(358deg) brightness(113%) contrast(113%);', icon: '../../assets/icons/icons8-fire-90.png', command: (event) => this.setMenuComponent('thermal') },
      { label: 'Perinix (APB)', color: 'filter: invert(56%) sepia(65%) saturate(2792%) hue-rotate(81deg) brightness(118%) contrast(131%);', icon: '../../assets/icons/icons8-pressure-100.png', command: (e) => this.setMenuComponent('apb') },
      { label: 'Perivis (Stress)', color: 'filter: sepia(100%) saturate(4216%) hue-rotate(113deg) brightness(78%) contrast(111%);', icon: '../../assets/icons/icons8-sigma-96.png', command: (event) => this.setMenuComponent('stress') },
      { label: 'Catalogs', icon: '../../assets/icons/icons8-catalog-256.png', command: (event) => this.setMenuComponent('database') },
    ];

    this.catalogSubMenu = [
      { data: { label: 'Formations', identifier: 'formationsCatalog', icon: '../../assets/icons/Formations.png' }, selectable: false },
      { data: { label: 'Hard Cement', identifier: 'hardCementCatalog', icon: '../../assets/icons/Hard Cement.png' }, selectable: false },
      {
        label: 'Pipes', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Temperature Deration', identifier: 'temperatureDeration', icon: '../../assets/icons/Undisturbed Temperature.png' }, selectable: false },
          { data: { label: 'Grades', identifier: 'gradesCatalog', icon: '../../assets/icons/SettingsBlack.png' }, selectable: false },
          { data: { label: 'Pipes', identifier: 'pipesCatalog', icon: '../../assets/icons/PipesBlack.png' }, selectable: false },
          { data: { label: 'Connections', identifier: 'connectionsCatalog', icon: '../../assets/icons/PipesBlack.png' }, selectable: false }
        ]
      }
    ];

    this.wellboreInputsSubMenu = [
      { data: { label: 'Well Type & Datums', identifier: 'wellType', icon: '../../assets/icons/well-explorer.png' }, selectable: false },
      {
        label: 'Trajectory', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Trajectory Input', identifier: 'trajectoryGrid', icon: '../../assets/icons/TrajectoryBlack.png' }, selectable: false },
          { data: { label: 'Trajectory Plot', identifier: 'trajectoryPlot', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' }, selectable: false },
          { data: { label: 'MD => TVD Converter', identifier: 'interpolationTool', icon: '../../assets/icons/TrajectoryBlack.png' }, selectable: false },
        ]
      },
      {
        label: 'UDT', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'UDT Input', identifier: 'undisturbedTemperature', icon: '../../assets/icons/Undisturbed Temperature.png' }, selectable: false },
          { data: { label: 'UDT Plot', identifier: 'udtPlot', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' }, selectable: false },
        ]
      },
      {
        label: 'Wellbore Inputs', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Formation Tops', identifier: 'formationTops', icon: '../../assets/icons/Formations.png' } },
          { data: { label: 'Fluids', identifier: 'fluids', icon: '../../assets/icons/Brines.png' } },
          { data: { label: 'Well Configuration', identifier: 'wellConfiguration', icon: '../../assets/icons/WellConfigurationBlack.png' }, selectable: false },
          // { data: { label: 'Well Schematic', identifier: 'wellSchematic', icon: '../../assets/icons/Schematic.png' }, selectable: false },
          { data: { label: 'Well Schematic', identifier: 'wellSchematic', icon: '../../assets/icons/Schematic.png' }, selectable: false },
          { data: { label: 'Cementing & Landing', identifier: 'cementSelector', icon: '../../assets/icons/Hard-Cement.png' }, selectable: false },
          { data: { label: 'Drill String', identifier: 'drillstring', icon: '../../assets/icons/PipesBlack.png' }, selectable: false },
          { data: { label: 'Wellhead', identifier: 'wellhead', icon: '../../assets/icons/PipesBlack.png' }, selectable: false },
          { data: { label: 'Packers', identifier: 'packers', icon: '../../assets/icons/PackersBlack.png' }, selectable: false },
        ]
      },
    ];

    this.stressSubMenu = [
      {
        label: 'Perivis Inputs', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          // {label: 'PPFG', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Pore Pressure', identifier: 'porePressure', icon: '../../assets/icons/Pressures1.png' } },
          { data: {label: 'Fracture Gradient', identifier: 'fracPressure', icon: '../../assets/icons/Pressures1.png' }},
          { data: {label: 'PPFG Plot', identifier: 'ppfgPlot', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' }},
          // ]},
          { data: { label: 'Additional Doglegs', identifier: 'additionalDoglegs', icon: '../../assets/icons/TrajectoryBlack.png' }, selectable: false },
          { data: { label: 'Perivis Options', identifier: 'stressOptions', icon: '../../assets/icons/SettingsBlack.png' } },
          { data: { label: 'Design Factors', identifier: 'designFactors', icon: '../../assets/icons/SettingsBlack.png' } },
          { data: { label: 'Cementing & Landing', identifier: 'cementSelector', icon: '../../assets/icons/Hard-Cement.png' }, selectable: false },
          { data: { label: 'Packers', identifier: 'packers', icon: '../../assets/icons/PackersBlack.png' }, selectable: false },
          { data: { label: 'Wellbore Displacement', identifier: 'wellboreDisplacement', icon: '../../assets/icons/LoadInput.png' }, selectable: false },
          { data: { label: 'Pre-Install Loads', identifier: 'preInstallLoads', icon: '../../assets/icons/LoadInput.png' } },
          { data: { label: 'Post-Install Loads', identifier: 'postInstallLoads', icon: '../../assets/icons/LoadInput.png' } },
          { data: { label: 'Load P&T Profiles', identifier: 'pressuresTemperatures', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } }
        ]
      },
      {
        label: 'Perivis Results', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Design Limits Plot', identifier: 'designLimitsPlot', icon: '../../assets/icons/Ellipse.png' } },
          { data: { label: 'Packer Envelope', identifier: 'packerOperatingEnvelope', icon: '../../assets/icons/Ellipse.png' } },
          { data: { label: 'Packer Results Table', identifier: 'packerTableResults', icon: '../../assets/icons/PackersBlack.png' } },
          { data: { label: 'Pressures', identifier: 'pressureResults', icon: '../../assets/icons/Pressures1.png' } },
          { data: { label: 'Barlow Burst', identifier: 'singleLoadBurst', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'API Collapse', identifier: 'singleLoadCollapse', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Axial Forces', identifier: 'singleLoadAxial', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Forces/Displacements', identifier: 'displacementResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Safety Factors', identifier: 'singleLoadSafetyFactors', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Connection SFs', identifier: 'singleConnectionLoadSafetyFactors', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Lift-Off', identifier: 'liftoffResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } }
        ]
      }
    ];

    this.thermalSubMenu = [
      {
        label: 'Perical Inputs', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Perforations', identifier: 'perforations', icon: '../../assets/icons/Perforations.png' } },
          { data: { label: 'Fluids', identifier: 'fluids', icon: '../../assets/icons/Brines.png' } },
          { data: { label: 'Chokes', identifier: 'chokes', icon: '../../assets/icons/Choke.png' } },
          { data: { label: 'Choke Performance Utility', identifier: 'chokesPerformanceUtility', icon: '../../assets/icons/Pressures1.png' } },
          { data: { label: 'Reservoir Performance Utility', identifier: 'reservoirPerformanceUtility', icon: '../../assets/icons/Pressures1.png' } },
          { data: { label: 'Perical Options', identifier: 'pericalOptions', icon: '../../assets/icons/SettingsBlack.png' } },
          { data: { label: 'Thermal Operations', identifier: 'thermalOperations', icon: '../../assets/icons/Operation.png' } }
        ]
      },
      {
        label: 'Perical Results', type: 'group', expandedIcon: 'pi pi-folder-open', collapsedIcon: 'pi pi-folder', expanded: true, children: [
          { data: { label: 'Temperatures', identifier: 'thermalTemperatureResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Pressures', identifier: 'thermalPressureResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Annular Results', identifier: 'detailedAnnularResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
          { data: { label: 'Operation Envelope', identifier: 'operationEnvelope', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } }
        ]
      }
    ];

    this.apbSubMenu = [
      { data: { label: 'Formation Tops', identifier: 'formationTops', icon: '../../assets/icons/Formations.png' } },
      { data: { label: 'APB Inputs', identifier: 'apbInputs', icon: '../../assets/icons/LoadInput.png' } },
      { data: { label: 'APB Results', identifier: 'apbResults', icon: '../../assets/icons/Pressures1.png' } },
      { data: { label: 'APB Results Grid', identifier: 'apbGridResults', icon: '../../assets/icons/ChartTypeLineInsertDialog.webp' } },
    ];

  }

  async ngOnInit(): Promise<void> {
    // If the user lands on the well explorer / designs route, show the well explorer opened.
    await this._route.url.subscribe(routeUrl => {
      if (routeUrl.length == 1 && routeUrl[0].path == "designs") {
        this.setMenuComponent('wellExplorer');
      }
    })

    this.activeItem = this.navbarItems[0];
  }

  public collapseSidenav() {
    this.onCloseSidenav.emit("close");
  }

  public setMenuComponent(menuComponent: string): void {
    this.menuSelected = this.menuSelected !== menuComponent ? menuComponent : "";
    this._messenger.publish(new GridItemResizedMessage("none", 0, 0));
    let sidebarOpen = this.menuSelected !== "";
    this._launcherSharedService.setData(null, null, sidebarOpen);
  }

  public handleClose() {
    this.menuSelected = "";
    this._messenger.publish(new GridItemResizedMessage("none", 0, 0));
  }
}
