import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Auth0Profile, User } from '../components/user-admin-page/user-model';
import { WellExplorerItem } from '../models/well-explorer.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
    providedIn: 'root'
})
export class UsersAdminService {

    constructor(private http: HttpClient) { }

    public getAuth0User(email:string): Observable<Auth0Profile> {
        return this.http.get<Auth0Profile>(`${PeriforApiEndpoint}/UsersAdmin/auth0-user?email=${email}`);
    }

    public getAllCompanies(): Observable<Array<WellExplorerItem>> {
        return this.http.get<Array<WellExplorerItem>>(`${PeriforApiEndpoint}/WellExplorer/companies`);
    }

    public getAllUsers(): Observable<Array<User>> {
        return this.http.get<Array<User>>(`${PeriforApiEndpoint}/UsersAdmin/users`);
    }

    public addUser(model: any): Observable<any> {
        return this.http.post(`${PeriforApiEndpoint}/UsersAdmin/add`, model);
    }

    public updateUser(id: string, model: any): Observable<any> {
        return this.http.put(`${PeriforApiEndpoint}/UsersAdmin/update/${id}`, model);
    }

    public deleteUser(userId:string): Observable<any> {
        return this.http.delete(`${PeriforApiEndpoint}/UsersAdmin/delete?userId=${userId}`);
    }

    public updateRoles(id: string, model: any): Observable<any> {
        return this.http.put(`${PeriforApiEndpoint}/UsersAdmin/roles/${id}`, model);
    }

    public toggleLicense(id:string): Observable<any> {
        return this.http.put(`${PeriforApiEndpoint}/UsersAdmin/license/${id}`, {});
    }

    public sendInvite(id: string): Observable<any> {
      return this.http.post(`${PeriforApiEndpoint}/UsersAdmin/send-invite/${id}`, {});
    }

}
