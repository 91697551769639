<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="flex">
    <div class="mr-2">
        <p-selectButton
        [options]="resultsView"
        visible="true"
        class="resultsViewButton"
        [(ngModel)]="pressTempStore.state.selectedResultsView"
        (onChange)="resultsViewToggle($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>

    <div class="flex grid=-nogutter" *ngIf="pressTempStore.state?.selectedResultsView !== 'temperaturePoints'">
      <div class="mr-2">
        <p-selectButton
        [options]="depthView"
        [(ngModel)]="pressTempStore.state.selectedDepthView"
        (onChange)="depthViewToggle($event)"
        [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>
  </div>

  <app-xy-line-plot
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [plotTitle]="'Load P&T Profiles'"
  ></app-xy-line-plot>
</app-tool-loader>
