import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { AdvancedMud, BaseOil, BlackOil, Brine, CementSlurry, CO2Fluid, Fluid, FluidType, StandardMud, Vle } from 'src/app/perical/models/fluid.model';

export interface FluidForm {
  id: string;
  isInUse: boolean;
  name: string;
  type: FluidType;
  standardMud: Partial<StandardMud>;
  brine: Partial<Brine>;
  blackOil: Partial<BlackOil>;
  baseOil: Partial<BaseOil>;
  cementSlurry: Partial<CementSlurry>;
  vle: Partial<Vle>;
  advancedMud: Partial<AdvancedMud>;
  co2Fluid: Partial<CO2Fluid>;
}

@Component({
  selector: 'app-fluid-data',
  templateUrl: './fluid-data.component.html',
  styles: [`
    input {
      font-size: 12px;
      height: 30px;
      width: 100%;
    }

    label {
      font-size: 12px;
      height: 20px;
      margin-bottom: 0px;
      user-select: none;
    }

    :host ::ng-deep .p-fieldset-legend {
      font-size: 11px;
      padding: 6px;
      border-radius: 8px;
      user-select: none;
    }

    :host ::ng-deep .p-fieldset .p-fieldset-content {
      padding: 0;
      border-radius: 8px;
    }

    .idClass {
      display: flex;
      padding-bottom: 5px;
    }

    .idClass button {
      height: 12px;
      padding-top: 0;
    }
  `]
})
export class FluidDataComponent {

  constructor() {}

  private input$: Subject<Fluid | null | undefined> = new Subject();
  @Input() set fluid(fluid: Fluid | undefined) {
    this.input$.next(fluid);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  private _selectedFluidId: string;

  @Input()
  public fluidsComponentHeight: number;

  @Input()
  public isReadOnly: boolean;

  @Input() isAltus: boolean;

  @Input('phaseEnvelopeCalculated')
  public phaseEnvelopeCalculated: boolean;

  @Input() fluids: Fluid[] | null | undefined;

  @Input() userUnits: UserUnitsModel;

  @Output() fluidUpdate: EventEmitter<Fluid> = new EventEmitter();

  public FluidType: typeof FluidType = FluidType;
  public isPureCo2: boolean;
  public co2ChangeTrigger: boolean;

  public fluidTypes = [
    { label: 'Brine', value: 'brine' },
    { label: 'Standard Mud', value: 'standardMud' },
    { label: 'Black Oil', value: 'blackOil' },
    { label: 'CO2 Fluid', value: 'co2Fluid' },
    { label: 'Base Oil', value: 'baseOil' },
    { label: 'Cement Slurry', value: 'cementSlurry' },
    { label: 'VLE', value: 'vle' },
    { label: 'Advanced Mud', value: 'advancedMud' }
  ];

  public form = createForm<Fluid, FluidForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.fluidUpdate,
    formControls: {
      id: new UntypedFormControl(null),
      isInUse: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null),
      standardMud: new UntypedFormControl(null),
      brine: new UntypedFormControl(null),
      blackOil: new UntypedFormControl(null),
      baseOil: new UntypedFormControl(null),
      cementSlurry: new UntypedFormControl(null),
      vle: new UntypedFormControl(null),
      advancedMud: new UntypedFormControl(null),
      co2Fluid: new UntypedFormControl(null)
    },
    toFormGroup: (obj: Fluid | null): FluidForm => {
      if (!obj) {
        return null;
      }

      this._selectedFluidId = obj.id;
      const { id, isInUse, state, ...commonValues } = obj;

      if (obj.state['type'] === 'co2Fluid') {
        this.isPureCo2 = obj.state['co2withImpurities'];
        this.co2ChangeTrigger = !this.co2ChangeTrigger;
      }

      if (obj.state.type === "cementSlurry") {
        Object.entries(obj.state).forEach(res => {
          if ((res[0] === 'fann600' || res[0] === 'fann300' || res[0] === 'fann200'
            || res[0] === 'fann100' || res[0] === 'fann6' || res[0] === 'fann3') && res[1] === 0) {
              obj.state[res[0]] = null;
          }
        });
      }

      return {
        id,
        isInUse,
        name: state.name,
        type: state.type,
        standardMud: state.type === FluidType.STANDARDMUD ? state : null,
        brine: state.type === FluidType.BRINE ? state : null,
        blackOil: state.type === FluidType.BLACKOIL ? state : null,
        baseOil: state.type === FluidType.BASEOIL ? state : null,
        cementSlurry: state.type === FluidType.CEMENTSLURRY ? state : null,
        vle: state.type === FluidType.VLE ? state : null,
        advancedMud: state.type === FluidType.ADVANCEDMUD ? state : null,
        co2Fluid: state.type === FluidType.CO2FLUID ? {id, ...state} : null
      };
    },
    fromFormGroup: (formValue: FluidForm): Fluid => {
      const { standardMud, brine, blackOil, baseOil, cementSlurry, vle, advancedMud, co2Fluid, type, name, isInUse, id } = formValue;
      switch (type) {
        case FluidType.STANDARDMUD:
          return standardMud ? { state: {type, name, ...this.objectWithoutKey(standardMud, 'name')} , id, isInUse } : null;
        case FluidType.BRINE:
          return brine ? { state: {type, name, ...this.objectWithoutKey(brine, 'name')}, id, isInUse } : null;
        case FluidType.BLACKOIL:
          return blackOil ? { state: {type, name, ...this.objectWithoutKey(blackOil, 'name')}, id, isInUse } : null;
        case FluidType.BASEOIL:
          return baseOil ? { state: {type, name, ...this.objectWithoutKey(baseOil, 'name')}, id, isInUse } : null;
        case FluidType.CEMENTSLURRY:
          return cementSlurry ? { state: {type, name, ...this.objectWithoutKey(cementSlurry, 'name')}, id, isInUse } : null;
        case FluidType.ADVANCEDMUD:
          return advancedMud ? { state: {type, name, ...this.objectWithoutKey(advancedMud, 'name')}, id, isInUse } : null;
        case FluidType.VLE:
          return vle ? { state: {type, name, ...this.objectWithoutKey(vle, 'name')}, id, isInUse} : null;
        case FluidType.CO2FLUID:
          return co2Fluid ? { state: {id, type, name, ...this.objectWithoutKey(co2Fluid, 'name')}, id, isInUse} : null;
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  })

  protected objectWithoutKey(object, key) {
    const {[key]: deletedKey, ...otherKeys} = object;
    return otherKeys;
  }

  public copyFluidId() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this._selectedFluidId));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
