<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <app-tool-loader [isLoading]="isLoading" positionUnset="true">
        <form *ngIf="pericalOptionsForm" [formGroup]="pericalOptionsForm" bg-dark class="w-full">
            <div class="field verticalSpacingClass">
                <label for="maximumGridSpacing">Vertical Grid Spacing</label>
                <div class="p-inputgroup">
                    <p-inputNumber
                    [minFractionDigits]="1"
                    formControlName="maximumGridSpacing"
                  ></p-inputNumber>
                    <span class="p-inputgroup-addon">{{ verticalGridUnit }}</span>
                </div>
                <p *ngIf="pericalOptionsForm.controls.maximumGridSpacing.value && pericalOptionsForm.controls.maximumGridSpacing.invalid" class="error">
                    Allowed range: {{ this.gridSpacingValidation.min }} -
                    {{ this.gridSpacingValidation.max }}
                </p>
            </div>

            <div *ngIf="!hideGridRefinement" class="disableRefinementClass">
                <input
                  style="height: 13px; width: 20px;"
                  type="checkbox"
                  binary="true"
                  formControlName="disableInterfaceRefinement"
                >
                <label style="font-size: 14px; padding-left: 5px;">Disable Interface Refinement (Altus only)</label>
            </div>

            <div class="field">
                <label for="successiveRelationFactor">Successive Relaxation Factor (Energy Terms)</label>
                <div class="p-inputgroup">
                    <p-inputNumber
                    [minFractionDigits]="1"
                    formControlName="successiveRelationFactor"
                  ></p-inputNumber>
                </div>
                <p *ngIf="pericalOptionsForm.controls.successiveRelationFactor.value && pericalOptionsForm.controls.successiveRelationFactor.invalid" class="error">Allowed range: 0.01 - 2</p>
            </div>

            <div class="field">
                <label for="latentHeatDampingFactor">Latent Heat Damping Factor (Stability)</label>
                <div class="p-inputgroup">
                    <p-inputNumber
                    [minFractionDigits]="1"
                    formControlName="latentHeatDampingFactor"
                  ></p-inputNumber>
                </div>
                <p *ngIf="pericalOptionsForm.controls.latentHeatDampingFactor.value && pericalOptionsForm.controls.latentHeatDampingFactor.invalid" class="error">Allowed range: 0.01 - 1000</p>
            </div>

            <div class="field">
                <input
                  style="height: 13px; width: 20px;"
                  type="checkbox"
                  binary="true"
                  formControlName="includeLatentHeatPhaseChange"
                >
                <label style="font-size: 14px; padding-left: 5px;">Include Latent Heat of Phase Change</label>
            </div>

        </form>
    </app-tool-loader>
</p-scrollPanel>