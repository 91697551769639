import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Overpull, PreInstallLoadType } from 'src/app/perivis/models/load-case.model';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface OverpullForm {
  OverpullForce: number;
  IsFlotation: boolean;
  InternalFluidDensity: number;
  HasBottomCap: boolean;
  HasFlotationPlug: boolean;
  PlugDepth: number;
  FluidDensity: number;
  type: PreInstallLoadType;
}

@Component({
  selector: 'app-overpull-details',
  template: `
    <form [formGroup]="form.formGroup" bg-dark>
      <div class="field">
          <label for="tempAtPerfs">Overpull Force</label>
          <div class="p-inputgroup">
            <input
              [type]="'number'"
              pInputText
              [formControlName]="form.formControlNames.OverpullForce"
            />
            <span class="p-inputgroup-addon">{{ forceUnit }}</span>
          </div>
          <p *ngIf="form.formGroup.controls.OverpullForce.value && form.formGroup.controls.OverpullForce.invalid" class="error">Allowed range: {{ forceValidation.min }} - {{ forceValidation.max }} {{ forceUnit }}</p>
        </div>

        <hr class="rounded" style="height: 2px" />

        <div class="field">
          <label for="tempAtPerfs">Internal Fluid Density</label>
          <div class="p-inputgroup">
            <input
              [type]="'number'"
              pInputText
              [formControlName]="form.formControlNames.InternalFluidDensity"
            />
            <span class="p-inputgroup-addon">{{ densityUnit }}</span>
          </div>
          <p *ngIf="form.formGroup.controls.InternalFluidDensity.value && form.formGroup.controls.InternalFluidDensity.invalid" class="error">Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}</p>
        </div>

        <p-fieldset>
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <p-inputSwitch
                (onChange)="checkFlotation()"
                [formControlName]="form.formControlNames.IsFlotation"
                binary="true"
              ></p-inputSwitch>
              <label class="flotationLabel">Flotation</label>
            </div>
          </ng-template>

          <div class="field">
            <input
              style="height: 13px; width: 20px;"
              type="checkbox"
              binary="true"
              [formControlName]="form.formControlNames.HasBottomCap"
            >
            <label [class]="isFlotation ? '' : 'disabled'" style="font-size: 14px; padding-left: 5px;">Bottom end cap or float plug</label>
          </div>

          <div class="field">
            <input
              style="height: 13px; width: 20px;"
              type="checkbox"
              binary="true"
              [formControlName]="form.formControlNames.HasFlotationPlug"
              (change)="hasPlugChange()"
            >
            <label [class]="isFlotation ? '' : 'disabled'" style="font-size: 14px; padding-left: 5px;">Has Plug</label>
          </div>

          <div class="field">
            <label [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'" for="tempAtPerfs">Plug Depth</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.PlugDepth"
              />
              <span [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'" class="p-inputgroup-addon">{{ depthUnit }} MD</span>
            </div>
            <p *ngIf="form.formGroup.controls.PlugDepth.value && form.formGroup.controls.PlugDepth.invalid" class="error">Allowed range: {{ this.hangerMd.toFixed(2) }} - {{ this.shoeMd.toFixed(2) }} {{ this.depthUnit }}</p>
          </div>

          <div class="field">
            <label [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'" for="tempAtPerfs">Fluid Density (below plug)</label>
            <div class="p-inputgroup">
              <input
                [type]="'number'"
                pInputText
                [formControlName]="form.formControlNames.FluidDensity"
              />
              <span [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'" class="p-inputgroup-addon">{{ densityUnit }}</span>
            </div>
            <p *ngIf="form.formGroup.controls.FluidDensity.value && form.formGroup.controls.FluidDensity.invalid" class="error">Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}</p>
          </div>

      </p-fieldset>
    </form>
  `,
  styleUrls: ['./overpull-details.component.scss'],
  providers: subformComponentProviders(OverpullDetailsComponent)
})
export class OverpullDetailsComponent implements AfterViewInit {

  constructor() {}

  @Input() userUnits: UserUnitsModel;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  public forceUnit: string;
  public forceValidation: { min: number, max: number };
  public densityUnit: string;
  public densityValidation: { min: number, max: number };
  public depthUnit: string;
  public isFlotation: boolean;
  public hasFlotationPlug: boolean;

  ngAfterViewInit(): void {
    this.forceUnit = Units.lib[this.userUnits.force].symbol;
    this.densityUnit = Units.lib[this.userUnits.density].symbol;
    this.depthUnit = this.userUnits.longLengths;

    switch (this.forceUnit) {
      case 'lbf':
        this.forceValidation = { min: 0, max: 10000000 };
        break;
      case 'tonne':
        this.forceValidation = { min: 0, max: 4535 };
        break;
      case 'kgf':
        this.forceValidation = { min: 0, max: 4535923 };
        break;
    }
    this.densityValidation = { min: GetValueFromPpg(1.04, this.densityUnit), max: GetValueFromPpg(25, this.densityUnit) };

    this.form.formGroup.controls.OverpullForce.setValidators([Validators.min(this.forceValidation.min), Validators.max(this.forceValidation.max), Validators.required]);
    this.form.formGroup.controls.InternalFluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required]);
    this.form.formGroup.controls.FluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
    this.form.formGroup.controls.PlugDepth.setValidators([Validators.min(this.hangerMd), Validators.max(this.shoeMd)]);

    if (!this.form.formGroup.controls.InternalFluidDensity.value) {
      this.form.formGroup.controls.InternalFluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.FluidDensity.value) {
      this.form.formGroup.controls.FluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.PlugDepth.value) {
      this.form.formGroup.controls.PlugDepth.setValue(this.shoeMd, { emitEvent: false });
    }

    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }

    this.checkFlotation();
  }

  public form = createForm<OverpullForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      OverpullForce: new UntypedFormControl(null),
      IsFlotation: new UntypedFormControl(false),
      InternalFluidDensity: new UntypedFormControl(null),
      HasBottomCap: new UntypedFormControl(false),
      HasFlotationPlug: new UntypedFormControl(false),
      PlugDepth: new UntypedFormControl(null),
      FluidDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: Overpull | null): OverpullForm => {
      if (!obj) {
        return null;
      }
  
      return {
        OverpullForce: obj.OverpullForce,
        IsFlotation: obj.IsFlotation,
        InternalFluidDensity: obj.InternalFluidDensity,
        HasBottomCap: obj.HasBottomCap,
        HasFlotationPlug: obj.HasFlotationPlug,
        PlugDepth: obj.PlugDepth,
        FluidDensity: obj.FluidDensity,
        type: obj.type
      }
    },
    fromFormGroup: (formValue: OverpullForm): Overpull => {
      return {
        OverpullForce: formValue.OverpullForce,
        IsFlotation: formValue.IsFlotation,
        InternalFluidDensity: formValue.InternalFluidDensity,
        HasBottomCap: formValue.HasBottomCap,
        HasFlotationPlug: formValue.HasFlotationPlug,
        PlugDepth: formValue.PlugDepth ? formValue.PlugDepth : this.shoeMd,
        FluidDensity: formValue.FluidDensity ? formValue.FluidDensity : this.annularFLuidDensity,
        type: PreInstallLoadType.OVERPULL
      }
    }
  });

  public checkFlotation() {
    if (this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.HasBottomCap.enable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.enable({ emitEvent: false });
      this.hasPlug();
      this.isFlotation = true;
    } else {
      this.form.formGroup.controls.HasBottomCap.disable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.disable({ emitEvent: false });
      this.doesntHavePlug();
      this.isFlotation = false;
    }
  }

  public hasPlugChange() {
    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }
  }

  private hasPlug() {
    if (!this.form.formGroup.controls.PlugDepth.value) {
      this.form.formGroup.controls.PlugDepth.setValue(+this.shoeMd.toFixed(2), { emitEvent: false });
    }
    if (this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.enable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.enable({ emitEvent: false });
      this.hasFlotationPlug = true;
    }
  }

  private doesntHavePlug() {
    if (!this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.disable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.disable({ emitEvent: false });
      this.hasFlotationPlug = false;
    }
  }
}