
export class WellType {

  public constructor(init?: Partial<WellType>) {
    this.type = init && init.type || "Land";
    this.drillFloorElevation = init && init.drillFloorElevation || 0;
    this.wellheadDepth = init && init.wellheadDepth || 0;
    this.wellheadElevation = init && init.wellheadElevation || 0;
    this.waterDepth = init && init.waterDepth || 0;
    this.wellheadDepthFromDrillFloor = init && init.wellheadDepthFromDrillFloor || 0;
  }

  type: string;
  drillFloorElevation: number;
  wellheadDepthFromDrillFloor: number;
  wellheadElevation: number;
  wellheadDepth: number;
  waterDepth : number;

  get mudlineDepth(): number{
    return this.drillFloorElevation + this.waterDepth;
  }
  
}
