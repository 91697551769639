
  <app-tool-loader
    class="cemLanding"
    [isLoading]="isLoading"
    positionUnset="true"
  >
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <p-dialog
      [draggable]="false"
      [(visible)]="configMissing"
      [closable]="false"
    >
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well
      Configuration Missing: Please specify at least one string <br />
      <br />
    </p-dialog>

    <form *ngIf="cementingLandingForm" [formGroup]="cementingLandingForm" bg-dark>
      <div class="grid">
        <div class="col">
          <p-fieldset legend="Cementing Inputs">
            <div [hidden]="isLoading">
              <p-fieldset legend="Lead Slurry">
                <div class="field col-12">
                  <label for="leadSlurryDensity">Slurry Density</label>
                  <div class="p-inputgroup">
                    <input
                      [type]="'number'"
                      pInputText
                      formControlName="leadSlurryDensity"
                      id="leadSlurryDensity"
                      required
                      onfocus="this.select()"
                      [attr.disabled]="false"
                    />
                    <span class="p-inputgroup-addon">{{ densityUnits }}</span>
                  </div>
                  <p
                    *ngIf="
                      cementingLandingForm.controls.leadSlurryDensity.invalid
                    "
                    class="error"
                  >
                    Allowed: {{ slurryDensityValidation.min }} -
                    {{ slurryDensityValidation.max }} {{ densityUnits }}
                  </p>
                </div>
              </p-fieldset>
              <br />
              <p-fieldset>
                <ng-template pTemplate="header">
                  <div class="flex align-items-center">
                    <p-inputSwitch
                      (onChange)="onTailChange($event)"
                      formControlName="hasTailCement"
                      id="hasTailCement"
                      binary="true"
                    ></p-inputSwitch>
                    <label class="tailLabel">Tail Slurry</label>
                  </div>
                </ng-template>

                <div class="field col-12">
                  <label for="tailSlurryDensity">Slurry Density</label>
                  <div class="p-inputgroup">
                    <input
                      [type]="'number'"
                      pInputText
                      formControlName="tailSlurryDensity"
                      id="tailSlurryDensity"
                      required
                      onfocus="this.select()"
                    />
                    <span class="p-inputgroup-addon">{{ densityUnits }}</span>
                  </div>
                  <p
                    *ngIf="
                      cementingLandingForm.controls.tailSlurryDensity.invalid
                    "
                    class="error"
                  >
                    Allowed: {{ slurryDensityValidation.min }} -
                    {{ slurryDensityValidation.max }} {{ densityUnits }}
                  </p>
                </div>
                <div class="field col-12">
                  <label for="tailSlurryLength">Slurry Length</label>
                  <div class="p-inputgroup">
                    <input
                      (change)="changeHasTailSlurry($event)"
                      [type]="'number'"
                      pInputText
                      formControlName="tailSlurryLength"
                      id="tailSlurryLength"
                      required
                      onfocus="this.select()"
                    />
                    <span class="p-inputgroup-addon">{{
                      slurryLengthUnit
                    }}</span>
                  </div>
                  <p
                    *ngIf="
                      cementingLandingForm.controls.tailSlurryLength.invalid
                    "
                    class="error"
                  >
                    Allowed: 0 - {{ maxTailSlurryLength }} {{ slurryLengthUnit }}
                  </p>
                </div>
              </p-fieldset>

              <div class="field col-10">
                <label for="displacementFluidDensity">Displacement Fluid</label>
                <div class="p-inputgroup">
                  <input
                    [type]="'number'"
                    pInputText
                    formControlName="displacementFluidDensity"
                    id="displacementFluidDensity"
                    required
                    onfocus="this.select()"
                  />
                  <span class="p-inputgroup-addon">{{ densityUnits }}</span>
                </div>
                <p
                  *ngIf="
                    cementingLandingForm.controls.displacementFluidDensity
                      .invalid
                  "
                  class="error"
                >
                  Allowed: {{ displacementDensityValidation.min }} -
                  {{ displacementDensityValidation.max }} {{ densityUnits }}
                </p>
              </div>
            </div>

            <div>
              <label for="hardCement">Hard Cement</label>
                <p-dropdown
                  [title]="hardCementJson"
                  [options]="_hardCement"
                  appendTo="body"
                  formControlName="cement"
                  [placeholder]="getPlaceholderText(cementingLandingForm.controls.cement.value)"
                  >
                <ng-template pTemplate="selectedItem">
                  {{ getPlaceholderText(cementingLandingForm.controls.cement) }}
                </ng-template>
                <ng-template let-cement pTemplate="item">
                  {{ cement.name }}
                </ng-template>
              </p-dropdown>
            </div>
          </p-fieldset>
        </div>

        <div class="col">
          <p-fieldset legend="Landing Details">
            <div class="field col-12">
              <label for="pickupAfterCementSet">Pickup/Slackoff On TOC </label>
              <span>
                <i
                  title="Pickup (+)/Slackoff (-)"
                  class="pi pi-info-circle tooltip-icon"
                ></i>
              </span>
              <div class="p-inputgroup">
                <p-inputNumber
                  formControlName="pickupAfterCementSet"
                  id="pickupAfterCementSet"
                  required
                  onfocus="this.select()"
                ></p-inputNumber>
                <span class="p-inputgroup-addon">{{ forceUnits }}</span>
              </div>
            </div>
            <div class="field col-12">
              <label for="slackoffForceOnBottom"
                >Slackoff Force On Bottom
              </label>
              <span>
                <i
                  title="Pickup (-)/Slackoff (+) &#13;This option can be used to approximate known friction loads encountered during tripping the pipe into the hole, &#13;or slackoff prior to cementing with the pipe fully landed out in the bottom of the as-drilled borehole."
                  class="pi pi-info-circle tooltip-icon"
                ></i>
              </span>
              <div class="p-inputgroup">
                <input
                  [type]="'number'"
                  pInputText
                  formControlName="slackoffForceOnBottom"
                  id="slackoffForceOnBottom"
                  required
                  onfocus="this.select()"
                />
                <span class="p-inputgroup-addon">{{ forceUnits }}</span>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>
    </form>
  </p-scrollPanel>
  </app-tool-loader>