<form [formGroup]="form.formGroup">
  <div class="grid grid-nogutter">
    <div class="col-4">
      <input
        id="name"
        [formControlName]="form.formControlNames.name"
        required
        pInputText
      >
    </div>
    <div class="col-2">
      <p-inputNumber
        id="fraction"
        [formControlName]="form.formControlNames.fraction"
        required
        [minFractionDigits]="1"
      ></p-inputNumber>
    </div>
    <div class="col-3">
      <p-inputNumber
        id="molecularWeight"
        [formControlName]="form.formControlNames.molecularWeight"
        required
        [minFractionDigits]="1"
      ></p-inputNumber>
    </div>
    <div class="col-3">
      <p-inputNumber
        id="specificGravity"
        [formControlName]="form.formControlNames.specificGravity"
        required
        [minFractionDigits]="1"
      ></p-inputNumber>
    </div>
  </div>
</form>
