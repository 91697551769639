<form [formGroup]="form.formGroup">
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Operation Details </ng-template>
      <div class="grid">
        <div class="col">
          <div class="field">
            <label for="fluid">Fluid</label>
            <p-dropdown
                  [disabled]="isReadOnly"
                  [options]="fluids"
              [formControlName]="form.formControlNames.fluid"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.fluid?.state.name }}
              </ng-template>
              <ng-template let-fluid pTemplate="item">
                {{ fluid.state.name }}
              </ng-template>
            </p-dropdown>
          </div>
          <div class="field">
            <label for="pressure">Pressure</label>
            <div class="p-inputgroup">
              <p-dropdown
                    [disabled]="isReadOnly"
                    [options]="pressureLocations"
                [formControlName]="form.formControlNames.pressureLocation"
                optionLabel="name"
                optionValue="value"
                appendTo="body"
              ></p-dropdown>
              <input
                id="pressure"
                pInputText
                [formControlName]="form.formControlNames.pressure"
                required
              />
              <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
              <p
                *ngIf="
                  form.formGroup.controls.pressure.value &&
                  form.formGroup.controls.pressure.invalid
                "
                class="error"
              >
                Allowed range: {{ pressureValidation.min }} -
                {{ pressureValidation.max }} {{ pressureUnit }}
              </p>
            </div>
          </div>
          <div class="field">
            <label for="inletTemperature">Inlet Temperature</label>
            <div class="p-inputgroup">
              <input
                id="inletTemperature"
                pInputText
                [formControlName]="form.formControlNames.inletTemperature"
                required
              />
              <span class="p-inputgroup-addon">{{ tempUnit }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.inletTemperature.value &&
                form.formGroup.controls.inletTemperature.invalid
              "
              class="error"
            >
              Allowed range: {{ temperatureValidation.min }} -
              {{ temperatureValidation.max }} {{ tempUnit }}
            </p>
          </div>
        </div>
        <div class="col">
          <div class="field">
            <label for="circulationMd">Circulation Depth</label>
            <div class="p-inputgroup">
              <input
                id="circulationMd"
                pInputText
                [formControlName]="form.formControlNames.circulationMd"
                required
              />
              <span class="p-inputgroup-addon">{{ depthUnit }}</span>
            </div>
            <p
              *ngIf="form.formGroup.controls.circulationMd.invalid"
              class="error"
            >
              Max value: {{ tubingShoe }}{{ depthUnit }}
            </p>
          </div>
          <div class="field">
            <label for="flowDirection">Circulation Direction</label>
            <p-dropdown
                  [disabled]="isReadOnly"
                  [options]="circulationDirections"
              [formControlName]="form.formControlNames.circulationDirection"
              optionLabel="name"
              optionValue="value"
              appendTo="body"
            ></p-dropdown>
          </div>
          <div class="field">
            <div [ngSwitch]="true">
              <app-standard-fluid
                [formControlName]="form.formControlNames.standardFluidFlow"
                [userUnits]="userUnits"
              ></app-standard-fluid>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</form>
