<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-dropdown
    appendTo="body"
    [options]="stringSections"
    [(ngModel)]="designLimitsPlotStore.state.selectedStringSection"
    optionLabel="label"
    optionValue="label"
    (onChange)="selectedStirngSectionUpdated($event, $event.originalEvent)"
    *ngIf="!isLoading"
  ></p-dropdown>

  <app-xy-line-plot
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [plotTitle]="'Design Limits Plot'"
    [reverseAutoRange]="true"
    [downloadPlotName]="downloadPlotName"
    [plotOrientation]="''"
  ></app-xy-line-plot>

  <p-dialog
    [draggable]="false"
    [visible]="isDialogVisible"
    [closable]="false"
    *ngIf="!isLoading"
  >
    <div *ngIf="needToCalculate">
      <i class="pi pi-exclamation-circle"></i> Please calculate Perivis to see
      results
    </div>
    <div *ngIf="configMissing">
      <i class="pi pi-exclamation-circle"></i> Well Configuration Missing:
      Please specify at least one string
    </div>
    <br />
  </p-dialog>
</app-tool-loader>
