import { Component, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { EditUserPermissions } from "./user-model";

@Component({
  selector: 'edit-permissions-form',
  template: `
      <form [formGroup]="form.formGroup">
        <div class="form-container">

          <user-permissions-form [formControlName]="form.formControlNames.roles"></user-permissions-form>
       
          <button pButton type="button" (click)="save()" class="p-button-success">Save</button>
        </div>

      </form>
    `
})
export class EditPermissionsFormComponent {

  private input$: Subject<EditUserPermissions | undefined> = new Subject();

  constructor() { }

  @Input() set userPermissions(userPermissions: EditUserPermissions | undefined) {
    this.input$.next(userPermissions);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() permissionsUpdated: Subject<EditUserPermissions> = new Subject();

  manualSave$: Subject<void> = new Subject();

  public form = createForm<EditUserPermissions, EditUserPermissions>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.permissionsUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      id: new UntypedFormControl(),
      roles: new UntypedFormControl()
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  save() {
    this.manualSave$.next();
  }

}