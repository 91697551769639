<form [formGroup]="form.formGroup">
  <p-panel>
    <ng-template pTemplate="header">
      Temperature: &nbsp;
      <p-dropdown
        [disabled]="isReadOnly"
        appendTo="body"
        [options]="temps"
        [formControlName]="form.formControlNames.type"
      ></p-dropdown>
    </ng-template>
    <ng-template pTemplate="body">
      <div [ngSwitch]="form.formGroup.value.type">
        <app-bhct-temp-profile
          *ngSwitchCase="TemperatureProfileType.BHCT"
          [formControlName]="form.formControlNames.bhctTempProfile"
          [currentString]="currentString"
          [loadName]="loadName"
          [userUnits]="userUnits"
        ></app-bhct-temp-profile>
        <app-perical-link-temp-profile
          [isReadOnly]="isReadOnly"
          *ngSwitchCase="TemperatureProfileType.THERMALLINK"
          [formControlName]="form.formControlNames.pericalLinkTempProfile"
          [thermalOperations]="thermalOperations"
        ></app-perical-link-temp-profile>
        <app-undisturbed-temp-profile
          *ngSwitchCase="TemperatureProfileType.UNDISTURBED"
          [formControlName]="form.formControlNames.undisturbedTempProfile"
        ></app-undisturbed-temp-profile>
        <app-custom-temp-profile
          *ngSwitchCase="TemperatureProfileType.CUSTOMTEMP"
          [formControlName]="form.formControlNames.customTempProfile"
          [wellType]="wellType"
          [currentString]="currentString"
          [shoeDepth]="shoeDepth"
          [userUnits]="userUnits"
          [wellTd]="wellTd"
        ></app-custom-temp-profile>
      </div>
    </ng-template>
  </p-panel>
</form>
