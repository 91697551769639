import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { WellheadModel, WellheadResult } from '../components/wellhead/wellhead.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class WellheadService {

  constructor(private _http: HttpClient) { }

  getWellheadForTubular(): Observable<WellheadResult> {
    return this._http.get<WellheadResult>(`${PeriforApiEndpoint}/wellhead`);
  }

  updateWellheadForTubular(model: WellheadModel): Observable<boolean> {
    return this._http.put<boolean>(`${PeriforApiEndpoint}/wellhead`, model);
  }
}
