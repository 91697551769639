<div class="formationTops-form">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div class="flex">
      <div class="mr-2">
        <p-selectButton
          [options]="tablePlot"
          [(ngModel)]="formationTopsStore.state.tablePlotSelected"
          (onChange)="onTablePlotSelect($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>

      <div class="mr-2">
        <p-selectButton
          *ngIf="formationTopsStore.state.tablePlotSelected === 'plot'"
          [options]="northingUdt"
          [(ngModel)]="formationTopsStore.state.northingUdtSelected"
          (onChange)="onNorthingUdtSelect($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>

    <br *ngIf="formationTopsStore.state.tablePlotSelected != 'plot'" />

    <form [formGroup]="formationTopsForm" novalidate>
      <div class="grid" [style.display]="formationTopsStore.state.tablePlotSelected === 'table' ? 'block' : 'none'">
        <div class="col">
          <ng-container formArrayName="formationTops">
            <p-table
              [scrollable]="true"
              [scrollHeight]="tableHeight"
              styleClass="p-datatable-gridlines"
              [columns]="columnDefs"
              [resizableColumns]="true"
              [value]="formationTops.controls"
              class="formationTops"
              dataKey="value.id"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" pResizableColumn> {{ col.header }} </th>
                  <th>
                    <p-button
                      [disabled]="formationTops.invalid"
                      label="Add"
                      class="p-button-success p-button-sm addButton"
                      (click)="onAddFormation()"
                    ></p-button>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-formationTopFg let-rIdx="rowIndex">
                <ng-container [formGroupName]="rIdx">
                  <tr>
                    <td>
                      <div class="p-inputgroup">
                        <p-inputNumber
                          formControlName="formationTop"
                          [minFractionDigits]="1"
                          title="Formation Top"
                        ></p-inputNumber>
                        <validation-error [isValid]=" isControlInvalid(formationTopFg, 'formationTop')" [errorMsg]=" getControlErrors(formationTopFg, 'formationTop')"></validation-error>
                    </div>
                    </td>
                    <td  style="width: 50%;" class="dropdownClass">
                      <p-dropdown
                        [title]="showJson(formationTopFg.get('formation').value)"
                        [options]="_formations"
                        appendTo="body"
                        formControlName="formation"
                        [placeholder]="getPlaceholderText(formationTopFg.get('formation').value)"
                        dataKey="name"
                      >
                        <ng-template let-formation pTemplate="selectedItem">
                          {{ getPlaceholderText(formation) }}
                        </ng-template>
                        <ng-template let-formation pTemplate="item">
                          {{ formation.name }}
                        </ng-template>
                      </p-dropdown>
                    </td>

                    <td style="width: 95px">
                      <button
                        pButton
                        class="p-button-text p-button-sm p-button-danger delete-btn"
                        title="Remove Formation Top"
                        icon="pi pi-trash"
                        [disabled]="rIdx == 0"
                        (click)="onDeleteFormation(rIdx)"
                      ></button>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </div>
    </form>

    <app-xy-line-plot
      *ngIf="formationTopsStore.state.tablePlotSelected === 'plot'"
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="'Formation Tops'"
      [componentHeight]="componentHeight"
    ></app-xy-line-plot>
  </app-tool-loader>
</div>
