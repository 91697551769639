import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngneat/elf';
import { SelectItem } from 'primeng/api';
import { lastValueFrom, Subscription } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Units } from 'src/app/core/services/unit-library';
import { DlsOverridesService } from 'src/app/perivis/services/dls-override.service';
import { NgTableGridComponent } from 'src/app/shared/components/ng-table-grid/ng-table-grid.component';
import { valuesIncreaseValidator } from 'src/app/shared/components/ng-table-grid/shared-grid.validators';
import { DlsOveridesUi, DlsOverrideModel } from 'src/app/shared/models/dlsOverride.model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { TotalDepthResult, TrajectoryModel } from 'src/app/shared/models/trajectory.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';

@Component({
  selector: 'app-dls-overrides',
  templateUrl: './dls-overrides.component.html',
  styles: [`
    .plot {
      position: relative;
      height: 98%;
    }
    .dls {
        user-select: none;
    }
  `]
})
export class DlsOverridesComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription;
  private totalDepth: TotalDepthResult;

  public isLoading: boolean;
  public newRowInputForm: UntypedFormGroup;
  public tableHeight: string;
  public dlsOverrides: Array<DlsOverrideModel>;
  public columnDefinitions: Array<{ field: string, header: string }>;
  public tablePlot: SelectItem[] = [
    { label: 'Table', value: 'table' },
    { label: 'Plot', value: 'plot' }
  ];
  public trajectories: Array<TrajectoryModel>;
  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'additionalDlsPlot';
  public downloadPlotName = 'additional_dls_plot';
  public componentHeight: number;
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public newDataRow(): UntypedFormGroup {
    return new UntypedFormGroup({
      measuredDepth: new UntypedFormControl("", [Validators.required, valuesIncreaseValidator("measuredDepth")]),
      dlsOverride: new UntypedFormControl("", [Validators.required])
    });
  }

  @ViewChild("dlsOverridesTable")
  public dlsOverridesTable: NgTableGridComponent<DlsOverrideModel>;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.dlsOverridesStore = this._storeService.createStore(this.componentId, new DlsOveridesUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public dlsOverridesStore: Store;

  constructor(
    private _messenger: MediatorService,
    private _dlsOverrideService: DlsOverridesService,
    private _trajectoryService: TrajectoryService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
  }

  public inputFields = [
    { name: 'measuredDepth', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
    { name: 'dlsOverride', minFractions: 2, maxFractions: 6, formatDecimals: 4 }
  ];

  async ngOnInit(): Promise<void> {
    this.dlsOverrides = await lastValueFrom(this._dlsOverrideService.getDlsOverrides()) || [this.newDataRow() as any];
    this.trajectories = await lastValueFrom(this._trajectoryService.getTrajectoryPoints());
    this.totalDepth = await lastValueFrom(this._trajectoryService.getTotalDepth());
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    const dlsUnit = Units.lib[uu.doglegSeverity].symbol;

    this.columnDefinitions = [
      { field: 'measuredDepth', header: `Measured Depth (${uu.longLengths})` },
      { field: 'dlsOverride', header: `Additional Dogleg (${dlsUnit})` }
    ];

    this.yAxisTitle = `MD (${uu.longLengths})`;
    this.xAxisTitle = `Dogleg (${dlsUnit})`;
    this.plotData();

    this.isLoading = false;
  }

  public async tablePlotToggle(e) {
    this.dlsOverridesStore.update(state => ({ ...state, tablePlotSelected: e.value }));
    this.plotData();
  }

  async plotData() {
    if (this.dlsOverridesStore.state.tablePlotSelected == 'table') {
      return;
    }
    this.plot.data = [];
    const trajectory = await lastValueFrom(this._trajectoryService.getTrajectoryPoints());
    trajectory.forEach((t) => {
      let dlsOverride = this.dlsOverrides.find((d) => d.measuredDepth >= t.measuredDepth)?.dlsOverride;
      if (dlsOverride) {
        t.doglegSeverity += dlsOverride;
      }

      this.dlsOverrides.forEach((d) => {
        let depth = d.measuredDepth;
        if (trajectory.findIndex((x) => x.measuredDepth == depth) == -1) {
          let dls = d.dlsOverride;
          trajectory.push({ measuredDepth: depth, doglegSeverity: dls, inclination: 0, azimuth: 0, trueVerticalDepth: 0, northing: 0, easting: 0 });
        }
      });
    });

    let additPoints = {
      x: this.dlsOverrides.map(x => x.dlsOverride),
      y: this.dlsOverrides.map(x => x.measuredDepth),
      name: 'Additional Dogleg',
      line: {
        color: 'red',
        dash: 'dot'
      },
      width: 7
    }

    // Insert point above additPoints.x[0]
    additPoints.x.splice(0, 0, this.dlsOverrides[0].dlsOverride);
    additPoints.y.splice(0, 0, 0);

    // Add point at the bottom of additPoints
    additPoints.x.push(0);
    additPoints.y.push(this.totalDepth.measuredDepth);

    // Sort trajectory
    trajectory.sort((a, b) => a.measuredDepth - b.measuredDepth);

    const dogleg = {
      x: trajectory.map(x => x.doglegSeverity),
      y: trajectory.map(x => x.measuredDepth),
      name: 'Total Dogleg'
    }

    for (let x = additPoints.x.length - 1; x > 0; x--) {
      additPoints.x.splice(x, 0, additPoints.x[x]);
      additPoints.y.splice(x, 0, additPoints.y[x - 1]);
    }

    for (let i = dogleg.x.length - 1; i > 0; i--) {
      dogleg.x.splice(i, 0, dogleg.x[i]);
      dogleg.y.splice(i, 0, dogleg.y[i - 1]);
    }

    this.plot.data.push(dogleg);
    this.plot.data.push(additPoints);
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Additional Doglegs") {
        this.tableHeight = (e.itemHeight - 100) + 'px';
        this.componentHeight = e.itemHeight - 90;
      }
    }));
  }

  public onDlsOverrideChanged(v: { dataRows: Array<DlsOverrideModel>, reload: boolean }) {
    let valueToSave: Array<DlsOverrideModel> = v.dataRows.length == 0 ?
      [{ measuredDepth: this.totalDepth.measuredDepth, dlsOverride: 0 }] :
      v.dataRows;

    this._dlsOverrideService.updateDlsOverrides(valueToSave).subscribe(async (r) => {
      if (v.reload) {
        this.dlsOverrides = [...valueToSave];
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
