import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AppNotificationService } from './app-notification.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private _notifier = this.injector.get(AppNotificationService);

    constructor(
        private injector: Injector,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let msg = this.handleErrorResponse(error);
                    return throwError(msg);
                })
            )
    }

    private handleErrorResponse(error:HttpErrorResponse){

        let errorMessage = '';

        if (!navigator.onLine) {
            errorMessage = 'No Internet Connection';
        }

        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error - ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code - ${error.status}\nMessage: ${error.message}`;
        }

        // 302: Found Redirect
        if (error.status == 302 || error.url.includes("/login")) {
            window.location.href = "/login";
            return "Error: Redirecting";
        }

        // 400 Bad Request
        if (error.status == 400) {
          if (error.error[0].startsWith('An unexpected error occured')) {
            return;
          }
            this._notifier.showError(`Bad Request: ${errorMessage}`);
            return "Bad Request";
        }

        // 401: Unauthorized
        if (error.status == 401) {
            this._notifier.showWarning(`Not Authenticated: You will be redirected to login.`);
            return "Not Authenticated";
        }

        // 403: Forbidden
        if (error.status == 403) {
            this._notifier.showWarning(`Unauthorized access. Please contact your administrator.`);
            this.router.navigateByUrl('/designs');
            return "Forbidden";
        }

        // 422: Unprocessable entity
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
        if (error.status == 422) {
          let errors = error.error as Array<string>;
          if (errors[0].startsWith('Current tubular not set') || errors[0].startsWith('There must be at least one casing/tubing')) {
            return;
          }
          this._notifier.showWarning(`Unprocessable Entity: ${errors.join("\n")}`);
          return;
      }

        // 404: Not Found
        if (error.status == 404) {
            this._notifier.showWarning(`Not Found: Requested item does not exist.`);
            return "Not Found";
        }

        // Catch everything else
        this._notifier.showError(`${errorMessage}`)
        return errorMessage;
    }

}
