import { AuthGuard } from '@auth0/auth0-angular';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from '../core/components/user-profile/profile.component';
import { AuthCallbackPageComponent } from '../core/components/auth-callback/auth-callback-page.component';
import { LoginPageComponent } from '../core/components/login/login-page.component';
import { ChangelogComponent } from '../core/components/changelog/changelog.component';
import { WellDesignerPageComponent } from '../core/components/well-designer-page/well-designer-page.component';
import { AboutComponent } from '../core/components/about/about.component';
import { UserUnitsComponent } from '../core/components/user-units/user-units.component';
import { UserAdminComponent } from '../core/components/user-admin-page/user-admin.component';

/*
* Note: Implemented callback url so AuthGuard doesn't fail on redirect.
* https://github.com/auth0/auth0-angular/issues/55
*/
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'callback', component: AuthCallbackPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'users', component: UserAdminComponent }, //TODO: add auth canActivate: [AuthGuard]
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'profile/units', component: UserUnitsComponent, canActivate: [AuthGuard]},
  { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard]},
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard]},
  { path: 'designs', component: WellDesignerPageComponent, canActivate: [AuthGuard] },
  { path: 'designs/:designId', component: WellDesignerPageComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: 'login'} // Redirect any unknown urls back to login
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
