import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { FormationTop } from 'src/app/perical/models/formation.model';
import { environment } from 'src/environments/environment';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class FormationTopService {
  constructor(private http: HttpClient) { }

  getAllFormationTops(skipUnits: boolean = false): Observable<FormationTop[]> {
    return this.http.get<FormationTop[]>(`${PeriforApiEndpoint}/formationTops?skipUnits=${skipUnits}`).pipe(
      map(data => data.map(data => new FormationTop(data)))
    );
  }

  deleteFormation(formationTopId: FormationTop): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/formationTops/${formationTopId}`);
  }

  setFormationTop(formationTop: FormationTop): Observable<FormationTop> {
    return this.http.post<FormationTop>(`${PeriforApiEndpoint}/formationTops`, formationTop);
  }

  editFormationTop(formationTop: FormationTop): Observable<FormationTop> {
    let formationTopId = formationTop.id;
    if (!this.isGuid(formationTopId)) {
      formationTopId = formationTop.id['id'];
      formationTop.id = formationTopId;
    }

    return this.http.put<FormationTop>(`${PeriforApiEndpoint}/formationTops/${formationTopId}`, formationTop);
  }

  private isGuid(value) {
    var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    var match = regex.exec(value);
    return match != null;
  }
}
