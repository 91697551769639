import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CementingLandingService {

  constructor(private http: HttpClient) {
  }

  getCementingLanding(): Observable<CementingLanding> {
    return this.http.get<CementingLanding>(`${PeriforApiEndpoint}/cementinglanding`);
  }

  setCementingLanding(cementingLanding: CementingLanding) {
    return this.http.put(
      `${PeriforApiEndpoint}/cementinglanding`,
      cementingLanding,
      httpOptions
    );
  }

}
