import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { VleHeavyWeightHydrocarbonComponent } from 'src/app/perical/models/fluid.model';

@Component({
  selector: 'app-heavy',
  templateUrl: './heavy.component.html',
  styleUrls: ['./heavy.component.scss'],
  providers: subformComponentProviders(HeavyComponent)
})
export class HeavyComponent {

  constructor() {}

  public form = createForm<VleHeavyWeightHydrocarbonComponent>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null, [Validators.required]),
      fraction: new UntypedFormControl(null, [Validators.required]),
      molecularWeight: new UntypedFormControl(null, [Validators.required]),
      specificGravity: new UntypedFormControl(null, [Validators.required])
    }
  });
}
