<div class="ng-table-cmp" [formGroup]="dataRowsForm">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
  <ng-container formArrayName="dataArray">
    <p-table
      #dataTable
      styleClass="p-datatable-gridlines"
      [columns]="columnDefinitions"
      [value]="pTableData"
      [exportFilename]="tableName"
      [exportFunction]="exportFunction"
      [(contextMenuSelection)]="selectedRowIdx"
      [contextMenu]="cm"
      [scrollable]="true"
      [scrollHeight]="tableHeight"
      [virtualScroll]="virtualScroll"
      [virtualScrollItemSize]="28"
      minBufferPx="800"
      maxBufferPx="1800"
      stateStorage="local"
      [stateKey]="componentId"
    >
      <ng-template pTemplate="header" let-columns>
        <!-- <tr title="Right click in cell and choose copy from browser context menu or use ctrl + v."></tr> -->
        <tr *ngIf="tableHeader">
          <th class="row-index"></th>
          <th [attr.colspan]='columnDefinitions.length + 1'>
            <span>{{ tableHeader }}</span>
          </th>
        </tr>

        <tr>
          <th class="row-index">
            <button
              pButton
              type="button"
              class="p-button-sm p-button-secondary"
              title="Download CSV"
              icon="pi pi-download"
              (click)="dataTable.exportFunction()"
            ></button>
          </th>
          <th *ngFor="let col of columns" class="align-content-center">
            {{ col.header }}
          </th>
          <th class="td-btn-col resetHeader">
            <p-button
              label="Reset"
              class="p-button-danger p-button-sm deleteButton"
              (click)="clearTable()"
              title="Clears all form data and resets table"
            ></p-button>
          </th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rowData
        let-rIdx="rowIndex"
        let-columns="columns"
      >
        <ng-container [formGroupName]="rIdx">
          <tr style="height: 28px">
            <td class="row-index" *ngIf="rIdx == 0">{{ rIdx + 1 }}</td>
            <td class="row-index" *ngIf="rIdx > 0" [pContextMenuRow]="rIdx">
              {{ rIdx + 1 }}
            </td>

            <td
              [class.firstRow]="
                rIdx === 0 &&
                (tableName === 'Trajectory' || tableName === 'Pore Pressure' || tableName === 'Fracture Gradient')
              "
              *ngFor="let inputField of inputFields"
              pEditableColumn
              [pEditableColumnField]="inputField.name"
              [pEditableColumnRowIndex]="rIdx"
              [pEditableColumn]="rowData"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber
                    [formControlName]="inputField.name"
                    [minFractionDigits]="inputField.minFractions"
                    [maxFractionDigits]="inputField.maxFractions"
                    (paste)="onPaste($event, rIdx)"
                    (onFocus)="$event.target.select()"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(rowData, inputField.name)"
                    [errorMsg]="getControlErrors(rowData, inputField.name)"
                  ></validation-error>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData
                      .get(inputField.name)
                      ?.value?.toLocaleString("en-US", {
                        maximumFractionDigits: inputField.formatDecimals
                      })
                  }}
                  <validation-error
                    *ngIf="rowData"
                    [isValid]="isControlInvalid(rowData, inputField.name)"
                    [errorMsg]="getControlErrors(rowData, inputField.name)"
                  ></validation-error>
                </ng-template>
              </p-cellEditor>
            </td>

            <td
              *ngFor="let calcField of calculatedFields"
              class="calculated-row"
            >
              {{
                getDictValue(rowData)[calcField.name]?.toLocaleString("en-US", {
                  maximumFractionDigits: calcField.formatDecimals
                })
              }}
            </td>

            <td class="td-btn-col">
              <button
                pButton
                class="p-button-text p-button-sm p-button-danger delete-btn"
                title="Delete"
                tabindex="-1"
                icon="pi pi-trash"
                (click)="onDelete(rIdx)"
                [disabled]="rIdx == 0"
              ></button>
            </td>
          </tr>
        </ng-container>

        <ng-container [formGroup]="newDataRowForm">
          <tr
            style="height: 28px"
            *ngIf="rIdx == dataFormArray.length - 1"
            focusedRow
          >
            <td class="row-index">{{ rIdx + 2 }}</td>
            <ng-container *ngFor="let inf of inputFields; let i = index">
              <td>
                <p-inputNumber
                  #inputFocusFieldRef
                  [minFractionDigits]="inf.minFractions"
                  [maxFractionDigits]="inf.maxFactions"
                  [formControlName]="inf.name"
                  (onBlur)="addRow()"
                  (paste)="onPaste($event)"
                  (keydown.enter)="$event.target.blur()"
                  (onFocus)="$event.target.select()"
                ></p-inputNumber>
              </td>
            </ng-container>
            <td
              *ngFor="let calcField of calculatedFields"
              [attr.colspan]="columnDefinitions?.length - inputFields.length + 1"
            ></td>
            <td class="td-btn-col"></td>
          </tr>
        </ng-container>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr style="height: 35px" *ngIf="calculating">
          <th colspan="0">
            <span>&nbsp;</span>
            <span>Calculating {{ tableName }}..</span>
          </th>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
