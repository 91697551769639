import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { ExternalPressureProfileType, GeneralEPP, InterpolationToPorePressureEPP } from '../../models/external-pressure-profile.model';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { porePressureMustBeToShoeValidator, porePressureNotSpecifiedValidator } from 'src/app/perivis/shared/validators/pore-pressure.validator';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface InterpolationToPorePressureEPPForm {
  type: ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE;
  generalEpp: GeneralEPP;
}

@Component({
  selector: 'app-interpolation-to-pore-pressure-epp',
  templateUrl: './interpolation-to-pore-pressure-epp.component.html',
  providers: subformComponentProviders(InterpolationToPorePressureEppComponent)
})
export class InterpolationToPorePressureEppComponent implements OnChanges, AfterViewInit, OnInit {

  @Input() currentString: any;
  @Input() porePressures: PoreFrac[]
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;

  public form = createForm<InterpolationToPorePressureEPP, InterpolationToPorePressureEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE),
      generalEpp: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: InterpolationToPorePressureEPP): InterpolationToPorePressureEPPForm => {
      return {
        type: ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE,
        generalEpp: obj
      };
    },
    fromFormGroup: (formValue: InterpolationToPorePressureEPPForm): InterpolationToPorePressureEPP => {
      return {
        type: ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity
      };
    }
  });

  constructor() { }

  ngOnInit(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
  }

  ngAfterViewInit(): void {
    this.form.formGroup.addValidators(porePressureMustBeToShoeValidator(this.porePressures, this.shoeDepth));
    this.form.formGroup.addValidators(porePressureNotSpecifiedValidator(this.porePressures));
  }

  ngOnChanges(): void {
    this.form.formGroup.updateValueAndValidity();
    this.form.formGroup.hasError('porePressureMustBeToShoe')
  }
}
