import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Choke, ChokePerformance, ChokePerformanceResult, Chokes } from '../models/chokes.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class ChokesService {

  constructor(private http: HttpClient) { }

  getChokes(): Observable<Chokes> {
    return this.http.get<Chokes>(`${PeriforApiEndpoint}/thermalOperations/chokes`);
  }

  updateChokes(choke: Choke): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/chokes`, choke);
  }

  calculateChokePerformance(chokePerformance: ChokePerformance): Observable<ChokePerformanceResult> {
    return this.http.post<ChokePerformanceResult>(`${PeriforApiEndpoint}/thermalOperations/chokePerformance`, chokePerformance);
  }
}
