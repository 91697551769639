export class Apb {
  apbAnnuli: ApbAnnulus[];
  finalOperation: FinalOperation;
}

export class ApbAnnulus {
  tubularStringId: string;
  name: string;
  isVented: boolean;

  constructor() {
    this.tubularStringId = '';
    this.name = '';
    this.isVented = false;
  }
}

export class FinalOperation {
  id: string;
  name: string;
}

export class ApbResult {
  annulusName: string;
  pressureBuildUp: number;
  fluidExpansionVolume: number;
}

export class ApbInputUi {
  public constructor(init?: Partial<ApbInputUi>) {
    this.selectedAnnulus = init && init.selectedAnnulus || new ApbAnnulus();
  }
  selectedAnnulus: ApbAnnulus;
}

export class ApbResultsUi {
  public constructor(init?: Partial<ApbResultsUi>) {
    this.selectedResultsDisplay = init && init.selectedResultsDisplay || 'grid';
    this.apbAfeSelected = init && init.apbAfeSelected || 'apb';
  }
  selectedResultsDisplay: String;
  apbAfeSelected: string
}

export class ApbResultsGridUi {
  public constructor(init?: Partial<ApbResultsGridUi>) {
    this.selectedRadiusType = init && init.selectedRadiusType || 'outerRadiusChange';
  }
  selectedRadiusType: String;
}
