import { DashboardModel, UiComponentModel, WorkspacesModel } from '../models/dashboard.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private _http: HttpClient) { }

  getDashboard(): Observable<DashboardModel> {
    return this._http.get<DashboardModel>(`${PeriforApiEndpoint}/dashboards`).pipe(
      map(data =>  new DashboardModel(data))
    );
  }

  getWorkspaces(designId: string) {
    return this._http.get<WorkspacesModel>(`${PeriforApiEndpoint}/dashboards/workspaces/${designId}`);
  }

  cloneDashboard(designToClone: string): Observable<boolean> {
    return this._http.post<boolean>(`${PeriforApiEndpoint}/dashboards/clone`, { designId: designToClone } );
  }

  insertWorkspace(model: {workspaceIndex: number}): Observable<boolean> {
    return this._http.put<boolean>(`${PeriforApiEndpoint}/dashboards/workspace`, model);
  }

  deleteWorkspace(workspaceIndex: number): Observable<boolean> {
    return this._http.delete<boolean>(`${PeriforApiEndpoint}/dashboards/workspace/${workspaceIndex}`);
  }

  updateUiComponents(uiComponents: Array<UiComponentModel>): Observable<string> {
    return this._http.put<string>(`${PeriforApiEndpoint}/dashboards/workspace/ui-components`, {
      uiComponents: uiComponents
    });
  }

  renameWorkspace(workspaceIndex:number, name: string): Observable<boolean> {
    return this._http.put<boolean>(`${PeriforApiEndpoint}/dashboards/workspace/name`,
        {
          workspaceIndex,
          name: name
        }
    );
  }

  reIndexWorkspace(workspaceIndex:number, newWorkspaceIndex: number): Observable<boolean> {
    return this._http.put<boolean>(`${PeriforApiEndpoint}/dashboards/workspace/indexes`,
        {
          workspaceIndex,
          newWorkspaceIndex
        }
    );
  }

}
