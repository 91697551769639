<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="tempDeration">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <div class="grid">
      <div class="col-5">
        <p-listbox
          [options]="tempDerations"
          [(ngModel)]="selectedTempDeration"
          optionLabel="name"
          (onChange)="onSelect($event)"
        >
        <ng-template pTemplate="header">
          <p-button
            class="p-button-success p-button-sm addItem"
            label="Add Profile"
            (click)="addItem()"
          ></p-button>
        </ng-template>

        <ng-template let-item pTemplate="item">
          <div class="profile-item grid grid-nogutter fluid-item align-items-center">
            <div class="col-8">{{ item.name }}</div>
            <div class="col-4" [style]="{ 'text-align': 'right' }">
            <button
              class="pi pi-trash p-button p-button-text p-button-sm p-button-danger buttons"
              pTooltip="Delete Profile"
              (click)="deleteProfile(item)"
          ></button>
          </div>
        </div>
        </ng-template>
        </p-listbox>
      </div>

      <div class="grid right">
        <div class="col">
          <div class="field">
            <label for="name">Name</label>
            <input
              id="name"
              type="text"
              pInputText
              value="{{selectedTempDeration?.name}}"
              (change)="onNameChange($event)"
              autocomplete="off"
              #nameField
            >
          </div>
          <div class="field">
            <ng-table-grid-cmp
            tableName="Temperature Deration"
            [tableHeight]="tableHeight"
            [tableData]="selectedTempDeration?.temperatureDerationProfile"
            [columnDefinitions]="columnDefinitions"
            [newRowFormGroup]="newDataRow"
            [inputFields]="inputFields"
            (dataChange)="onTemperatureDerationChange($event)"
            [isDynamicLoaded]="true"
            [componentId]="componentId"
          ></ng-table-grid-cmp>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-scrollPanel>
