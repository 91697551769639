import { Component, Input, Output } from "@angular/core";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { AddUserAccount } from "./user-model";
import { WellExplorerItem } from "../../models/well-explorer.model";

@Component({
  selector: 'add-user-form',
  template: `
      <form [formGroup]="form.formGroup">
        <div class="form-container">

          <div class="field">
            <label>Companies</label>
            <p-multiSelect placeholder="Select Company" [options]="companies" [formControlName]="form.formControlNames.companies" optionLabel="name" display="chip" dataKey="id"></p-multiSelect>
            <p class="error" *ngIf="form.formGroup.controls.companies.errors?.['required']">Companies are required.</p>
          </div>

          <user-profile-form 
            [showAdminInputs]="showAdminInputs"
            [formControlName]="form.formControlNames.profile"
            (emailChanged)="onEmailChanged($event)">
           ></user-profile-form>

          <div *ngIf="showPassword" class="field">
            <label>Password</label>
            <span>
              <i title="User can later change the password if needed." class="pi pi-info-circle infoClass"></i>
            </span>
            <p-password [feedback]=false [formControlName]="form.formControlNames.password" [toggleMask]="true" [minlength]="8"></p-password>
            <p class="error" *ngIf="form.formGroup.controls.password.errors?.['required']">Password is required.</p>
            <p class="error" *ngIf="form.formGroup.controls.password.value && form.formGroup.controls.password.hasError('strongPassword')">Password is not strong enough.</p>
          </div>

          <br>

          <span class="buttonCol">
            <div class="saveButton">
              <button pButton type="button" (click)="save()" class="p-button-success">Save</button>
            </div>
          </span>

        </div>

      </form>
    `,
  styles: [`
     .error {
        color: red;
        font-size: 11px;
        height: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        user-select: none;
      }
      .infoClass {
        padding-left: 3px;
      }

      form {
        width: 350px;
      }

      :host ::ng-deep .p-multiselect {
        width: 100%;
      }
      :host ::ng-deep label {
        font-size: .70em;
        height: 10px;
        user-select: none;
      }
    `]
})
export class AddUserFormComponent {

  private _showPassword: boolean;
  private input$: Subject<AddUserAccount | undefined> = new Subject();

  @Input()
  companies: Array<WellExplorerItem>;

  @Input()
  set showPassword(showPassword: boolean) {
    this._showPassword = showPassword;
    if (this._showPassword) {
      this.form.formGroup.controls.password.setValidators([Validators.required, this.strongPasswordValidator]);
    } else {
      this.form.formGroup.controls.password.removeValidators([Validators.required, this.strongPasswordValidator]);
    }
  };

  get showPassword(): boolean {
    return this._showPassword;
  }

  @Input() set userProfile(userProfile: AddUserAccount | undefined) {
    this.input$.next(userProfile);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  private _showAdminInputs : boolean;
  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  @Output()
  userUpdated: Subject<AddUserAccount> = new Subject();

  @Output()
  emailChanged: Subject<string> = new Subject();

  manualSave$: Subject<void> = new Subject();

  constructor() { }

  public onEmailChanged(email: string) {
    this.emailChanged.next(email);
  }

  public form = createForm<AddUserAccount, AddUserAccount>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      companies: new UntypedFormControl([Validators.required]),
      profile: new UntypedFormControl(null),
      password: new FormControl(null)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000),
    toFormGroup: (obj: AddUserAccount): AddUserAccount => {
      return { ...obj };
    }
  });

  save() {
    this.manualSave$.next();
  }

  strongPasswordValidator(control) {
    const value = control.value;

    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);

    const isValid = hasUppercase && hasLowercase && hasDigit && hasSpecialChar && value.length >= 8;

    if (!isValid) {
      return { strongPassword: true };
    }
    return null;
  }
}
