export class WellSchematicUi {
  public constructor(init?: Partial<WellSchematicUi>) {
    this.currentStageOnly = init && init.currentStageOnly || false;
    this.drawNoLabels = init && init.drawNoLabels || false;
    // this.drawWater = init && init.drawWater || false;
  }
  currentStageOnly: boolean;
  drawNoLabels: boolean;
  drawWater: boolean;
}

export interface IPipeSection {
    top: number,
    btm: number,
    diameter: number
}

export class StringSection {
    outerDiameter: number;
    innerDiameter: number;
    btm: number;

    constructor(od: number, id: number, btm: number) {
        this.outerDiameter = od;
        this.innerDiameter = id;
        this.btm = btm;
    }
}

export class WellSchematicItemBase {

    protected _odBounds: paper.Path.Rectangle;
    private _idBounds: paper.Path.Rectangle;
    private _labelGroup: paper.Group;
    public units: { short: string, long: string };
    public tubularStringId: string;
    public name: string;
    public color: string;
    public labelColor: string;
    public outerDiameter: number;
    public innerDiameter: number;
    public top: number;
    public btm: number;

    public labelSide: "left" | "right";
    public labelVert: "top" | "btm";

    // Add child items, so we can call generateLabels after everything is drawn.
    public children: Array<WellSchematicItemBase>;

    get idBounds(): paper.Path.Rectangle {
        return this._idBounds;
    }

    get odBounds(): paper.Path.Rectangle {
        return this._odBounds;
    }

    get length(): number {
        return (this.btm - this.top);
    }

    get wallThickness(): number {
        return (this.outerDiameter - this.innerDiameter) / 2;
    }

    get labelGroup(): paper.Group {
        return this._labelGroup;
    }

    getLabelPosition(odBounds: paper.Path.Rectangle): Array<number> {
        let x = this.labelSide == "left"
            ? (odBounds?.bounds?.left ?? 0) - 5
            : (odBounds?.bounds?.right ?? 0) + 5;
        let y = this.labelVert == "top" ? odBounds?.bounds.top : odBounds?.bounds.bottom;
        return [x, y];
    }

    constructor(init: Partial<WellSchematicItemBase>) {
        Object.assign(this, init);

        this.labelSide = "left";
        this.labelVert = "btm";
        this.labelColor = "black";
        this.children = [];
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler: Function): Array<paper.Path> {
        // Adds tiny fraction to the inner rectangle so if paths are sliced a thin bridge isn't left between polygons.
        const rectangleExtender = 0.0001;

        let topLeftOd = new ps.Point(0 + centeringOffset, this.top);
        let topLeftId = new ps.Point(this.wallThickness + centeringOffset, this.top - rectangleExtender);
        let outerPipeRect = new ps.Size(this.outerDiameter, this.length + rectangleExtender);
        let innerPipeRect = new ps.Size(this.innerDiameter, this.length - rectangleExtender);

        let odRect = new ps.Rectangle(topLeftOd, outerPipeRect);
        let idRect = new ps.Rectangle(topLeftId, innerPipeRect);

        this._odBounds = new ps.Path.Rectangle(odRect);
        this._odBounds.name = this.name;
        this._odBounds.fillColor = new ps.Color(this.color);

        this._idBounds = new ps.Path.Rectangle(idRect);
        this._idBounds.fillColor = new ps.Color(this.color);

        if (onClickHandler) {
            this._odBounds.onClick = (e => onClickHandler(e, this.tubularStringId, this._odBounds, this._idBounds));
            this._idBounds.onClick = (e => onClickHandler(e, this.tubularStringId, this._odBounds, this._idBounds));
        }

        return [this._odBounds, this._idBounds];
    }

    public generateLabel(ps: paper.PaperScope): paper.Group {

        if (!this.name) {
            return null; // Label not being used
        }

        let odBounds = ps.project.getItem({ name: this.name }) as paper.Path.Rectangle;

        let label = new ps.PointText({
            fillColor: this.labelColor,
            fontFamily: "Arial, Verdana",
            fontSize: 10.5,
            fontWeight: 400,
            content: `${this.name}`,
            position: this.getLabelPosition(odBounds)
        });

        this.adjustLabelsOnCollision(ps, label);

        let txtBackground = new ps.Path.Rectangle(label.bounds);
        txtBackground.fillColor = new ps.Color('white');
        txtBackground.sendToBack();

        // Draw a leader line
        if (odBounds) {
            let labelAnchor = label.bounds.rightCenter;
            let objectAnchor = odBounds.bounds.bottomLeft;
            if (this.labelVert == "btm" && this.labelSide == "right") {
                objectAnchor = odBounds.bounds.bottomRight;
                labelAnchor = label.bounds.leftCenter;
            }
            if (this.labelVert == "top" && this.labelSide == "left") {
                objectAnchor = odBounds.bounds.topLeft;
                labelAnchor = label.bounds.rightCenter;
            }
            if (this.labelVert == "top" && this.labelSide == "right") {
                objectAnchor = odBounds.bounds.topRight;
                labelAnchor = label.bounds.leftCenter;
            }
            var textLeaderLine = new ps.Path.Line({
                from: labelAnchor,
                to: objectAnchor,
                strokeColor: 'black',
                strokeWidth: .35
            });
            textLeaderLine.strokeScaling = false;
            textLeaderLine.dashArray = [3, 5];
            textLeaderLine.sendToBack();
        }

        this._labelGroup = new ps.Group([textLeaderLine, txtBackground, label]);
        this._labelGroup.name = "TextGroup";
        return this._labelGroup;;
    }

    protected adjustLabelsOnCollision(ps: paper.PaperScope, pntTxt: paper.PointText): Array<paper.Item> {
        const txtElementName = "TextGroup";

        let overlaps = this.getOverlappingElements(ps, pntTxt);
        let totalIterations = 0;

        while (overlaps.length > 0) {

            if (totalIterations > 10) {
                break;
            }

            if (overlaps.some(x => x.name == txtElementName)) {

                pntTxt.bounds.bottom += pntTxt.bounds.height * 1.5;
                this.adjustLabelsOnCollision(ps, pntTxt); // Recurse to spread text out
            }

            let minCollisionX = Math.min(...overlaps.filter(c => c.name != txtElementName).map(lc => lc.bounds.x));
            let maxCollisionX = Math.max(...overlaps.filter(c => c.name != txtElementName).map(lc => lc.bounds.x));

            if (isFinite(minCollisionX) || isFinite(maxCollisionX)) {
                if (this.labelSide == "left") {
                    pntTxt.bounds.right -= (pntTxt.bounds.width / 6);
                }
                if (this.labelSide == "right") {
                    pntTxt.bounds.left += (pntTxt.bounds.width / 6);
                }
            }

            overlaps = this.getOverlappingElements(ps, pntTxt);
            totalIterations += 1;
        }

        return overlaps;
    }

    private getOverlappingElements(ps: paper.PaperScope, pntTxt: paper.PointText) {
        // Check for overlap
        let overlappingLabels = ps.project.activeLayer.children.filter(x => x.intersects(pntTxt));
        // Remove the match of our existing label
        return overlappingLabels.filter(x => x.id != pntTxt.id);
    }

    protected getMaxY(ps: paper.PaperScope) {
        return Math.max(...ps.project.activeLayer.children.map(x => x.bounds.y));
    }

}

class AnnularItemBase extends WellSchematicItemBase {

    private _annulusPath: paper.PathItem;

    public get annulusPath(): paper.PathItem {
        return this._annulusPath;
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);

        this._annulusPath = paths[0].subtract(paths[1], { trace: true });
        this._annulusPath.fillColor = new ps.Color(this.color);
        this._annulusPath.name = this.name;
        this._annulusPath.sendToBack();

        if (onClickHandler) { // Re-implement the click handler for this new path
            this._annulusPath.onClick = (e => onClickHandler(e, this.tubularStringId, this._annulusPath, this._annulusPath));
        }

        //Remove the original paths
        paths[0].remove();
        paths[1].remove();

        return [new ps.Path(this._annulusPath)];
    }
}

export class OpenHole extends WellSchematicItemBase {

    constructor(init: Partial<{ diameter: number, top: number, btm: number, color: string, name: string }>) {
        super({ ...init, innerDiameter: init.diameter, outerDiameter: init.diameter });
    }

}

export class Casing extends AnnularItemBase {

    public outerPipeProfile: { priorShoeMd: number, wellboreSections: Array<IPipeSection> };
    public packers: Array<{ depth: number, name: string }>;
    public labelToc: boolean;
    public hasShoe: boolean;
    public hasCement: boolean;
    public hasFluid: boolean;
    public topOfFluid: number;
    public topOfCement: number;
    public openHole: number;
    public cement: Fluid;
    public sections: Array<StringSection>;

    public get shoeWidth(): number {
        return (this.openHole - this.outerDiameter) / 2;
    }

    constructor(init?: Partial<Casing>) {
        super({ ...init, color: init.color || '#000' });
        Object.assign(this, init);
        this.topOfCement = Math.max(init.top, init.topOfCement);
        this.topOfFluid = init?.topOfFluid || init.top;
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler?: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);

        if (this.hasFluid && this.topOfCement != this.top) {
            let fluid = new Fluid({
                tubularStringId: this.tubularStringId,
                outerDiameter: this.openHole,
                innerDiameter: this.outerDiameter,
                top: this.topOfFluid,
                btm: Math.min(this.btm, this.topOfCement),
                color: '#e0ffff'
            });

            fluid.labelColor = this.labelColor;
            fluid.draw(ps, centeringOffset - this.shoeWidth, onClickHandler);

            // Add a little filler section if we have a break in tubing size such as where previous casing is wider than open whole.
            if (this.outerPipeProfile) {
                this.outerPipeProfile.wellboreSections.forEach(p => {
                    if (this.topOfFluid <= p.btm && this.openHole <= p.diameter) {
                        // Adds a filler section of fluid when we have mismatch diameters at the pervious shoe.
                        let fillerSection = new Fluid({
                            tubularStringId: this.tubularStringId,
                            outerDiameter: p.diameter,
                            innerDiameter: this.outerDiameter,
                            top: this.topOfFluid,
                            btm: Math.min(p.btm, this.topOfCement),
                            color: '#e0ffff'
                        });

                        fillerSection.draw(ps, centeringOffset - (p.diameter - this.outerDiameter) / 2, onClickHandler);
                        let mergedPath = fluid.annulusPath.unite(fillerSection.annulusPath);
                        mergedPath.sendToBack();
                        mergedPath.onClick = (e => onClickHandler(e, this.tubularStringId, mergedPath, mergedPath));
                    }
                })
            }
        }

        if (this.hasCement) {
            this.cement = new Fluid({
                tubularStringId: this.tubularStringId,
                outerDiameter: this.openHole,
                innerDiameter: this.outerDiameter,
                top: this.topOfCement,
                btm: this.btm,
                color: '#808080',
                name: `TOC ${this.topOfCement.toLocaleString("en-US")}${this.units.long}`
            });

            this.cement.labelColor = this.labelColor;
            this.cement.draw(ps, centeringOffset - this.shoeWidth, onClickHandler);
            if (this.outerPipeProfile) {
                this.outerPipeProfile.wellboreSections.forEach(p => {
                    if (this.topOfCement <= p.btm && this.openHole <= p.diameter) {
                        // Adds a filler section of cement when we have mismatch diameters at the pervious shoe.
                        let fillerCementSection = new Fluid({
                            tubularStringId: this.tubularStringId,
                            outerDiameter: p.diameter,
                            innerDiameter: this.outerDiameter,
                            top: this.topOfCement,
                            btm: p.btm,
                            color: '#808080'
                        });

                        fillerCementSection.draw(ps, centeringOffset - (p.diameter - this.outerDiameter) / 2, onClickHandler);
                        let mergedPath = this.cement.annulusPath.unite(fillerCementSection.annulusPath);
                        mergedPath.sendToBack();
                        mergedPath.onClick = (e => onClickHandler(e, this.tubularStringId, mergedPath, mergedPath));
                    }
                })
            }

            if (this.labelToc && this.topOfCement != 0) {
                this.children.push(this.cement);
            }
        }

        if (this.packers) {
            this.packers.forEach(p => {
                let opp = this.outerPipeProfile.wellboreSections.find(op => op.top <= p.depth && op.btm >= p.depth)
                let packer = new Packer({
                    outerDiameter: opp.diameter,
                    innerDiameter: this.outerDiameter,
                    top: p.depth,
                    name: `${p.name} ${p.depth.toLocaleString("en-US")}${this.units.long}`,
                    labelColor: this.labelColor,
                    units: this.units
                });

                packer.draw(ps, centeringOffset - (opp.diameter - this.outerDiameter) / 2, null);
                this.children.push(packer);
            });
        }

        if (this.hasShoe) {
            let outerPathRect = new ps.Rectangle(
                new ps.Point(centeringOffset - this.shoeWidth, this.top),
                new ps.Size(this.openHole, this.length)
            );
            let shoes = this.drawShoes(ps, outerPathRect);

            let mergedPath = this.annulusPath.unite(shoes);
            mergedPath.onClick = (e => onClickHandler(e, this.tubularStringId, mergedPath, mergedPath));
        }

        return paths;
    }

    private drawShoes(ps: paper.PaperScope, odRect: paper.Rectangle) {
        const widthConversionFactors = {
            'in': 1,
            'cm': 2.54,
            'mm': 25.4
        };
        const heightConversionFactors = {
            'ft': 1,
            'm': 0.3048
        };

        const widthFactor = widthConversionFactors[this.units.short];
        const heightFactor = heightConversionFactors[this.units.long];

        // Check the magnitude of the difference between width and height conversion factors
        const magnitude = Math.abs(Math.log10(widthFactor) - Math.log10(heightFactor));

        // Apply custom scale factor if the magnitude is larger than the threshold
        let customScaleFactor = 25;
        if (magnitude > 1) {
            customScaleFactor = .8;
        } else if (magnitude > .35) {
            customScaleFactor = 5;
        }

        // Adjust shoeWidth and shoeHeight using customScaleFactor
        var shoeWidth = this.shoeWidth;
        var shoeHeight = this.shoeWidth * heightFactor * customScaleFactor;

        // Create shoe paths using adjusted shoeWidth and shoeHeight
        let shoePathLeft = `
                M ${odRect.bottomLeft.x + shoeWidth - 0.01}, ${odRect.bottomLeft.y}
                l 0, ${-shoeHeight}
                l ${-shoeWidth} ${shoeHeight}
                `;
        var triangleLeft = ps.Path.create(shoePathLeft)

        let shoePathRight = `
                M ${odRect.bottomRight.x - shoeWidth + 0.01}, ${odRect.bottomRight.y}
                l  0, ${-shoeHeight},
                l ${shoeWidth}, ${shoeHeight}
                `;

        var triangleRight = ps.Path.create(shoePathRight)

        let shoes = triangleLeft.unite(triangleRight);
        shoes.fillColor = new ps.Color(this.color);
        return shoes;
    }

}

export class Liner extends Casing {

    public hasHanger: boolean;
    public hanger: Hanger;

    constructor(init?: Partial<Liner>) {
        super(init);
        this.hasHanger = init.hasHanger;
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler?: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);

        if (this.hasHanger) {
            let opp = this.outerPipeProfile.wellboreSections.find(op => op.btm >= this.top);
            if (opp) {
                this.hanger = new Hanger({ outerDiameter: opp.diameter, innerDiameter: this.outerDiameter, top: this.top, units: this.units });
                this.hanger.labelColor = this.labelColor;
                this.hanger.draw(ps, centeringOffset - (opp.diameter - this.outerDiameter) / 2, null);
                this.children.push(this.hanger);
            }
        }

        return paths;
    }

}

export class Tubing extends WellSchematicItemBase {

    public outerPipeProfile: { priorShoeMd: number, wellboreSections: Array<IPipeSection> };
    public packers: Array<{ depth: number, name: string }>;
    public hasFluid: boolean;
    public hasCement: boolean;
    public cement: Fluid;
    public topOfCement: number;
    public openHole: number;
    public labelToc: boolean;

    public get pipeWall(): number {
        return (this.openHole - this.outerDiameter) / 2;
    }

    constructor(init?: Partial<Tubing>) {
        super({ ...init, color: '#0000Ff' });
        Object.assign(this, init);
        this.labelSide = "right";
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);

        let odPath = paths[0];
        let idPath = paths[1];
        idPath.fillColor = new ps.Color('#DFDFDF'); // Same as schematic background

        if (this.hasFluid && this.topOfCement != this.top) {
            this.outerPipeProfile.wellboreSections.forEach(opp => {
                let fluid = new Fluid({
                    tubularStringId: this.tubularStringId,
                    outerDiameter: opp.diameter,
                    innerDiameter: this.outerDiameter,
                    top: opp.top,
                    btm: opp.btm,
                    color: '#e0ffff'
                });
                fluid.labelColor = this.labelColor;
                fluid.draw(ps, centeringOffset - (opp.diameter - this.outerDiameter) / 2, onClickHandler);
            });
        }

        if (this.hasCement) {
            this.outerPipeProfile?.wellboreSections?.forEach(opp => {
                if (opp.btm > this.topOfCement) {
                    this.cement = new Fluid({
                        tubularStringId: this.tubularStringId,
                        outerDiameter: opp.diameter,
                        innerDiameter: this.outerDiameter,
                        top: this.topOfCement,
                        btm: opp.btm,
                        color: '#808080',
                        name: `TOC ${this.topOfCement.toLocaleString("en-US")}${this.units.long}`
                    });

                    this.cement.labelColor = this.labelColor;
                    this.cement.draw(ps, centeringOffset - (opp.diameter - this.outerDiameter) / 2, onClickHandler);
                }
            });

            if (this.labelToc && this.topOfCement != 0) {
                this.children.push(this.cement);
            }
        }

        if (this.packers) {
            this.packers.forEach(p => {
                let opp = this.outerPipeProfile.wellboreSections.find(op => op.top <= p.depth && op.btm >= p.depth)
                let packer = new Packer({
                    outerDiameter: opp.diameter,
                    innerDiameter: this.outerDiameter,
                    top: p.depth,
                    name: `${p.name} ${p.depth.toLocaleString("en-US")}${this.units.long}`,
                    units: this.units
                });
                packer.labelColor = this.labelColor;
                packer.draw(ps, centeringOffset - (opp.diameter - this.outerDiameter) / 2, null);
                this.children.push(packer);
            });
        }
        odPath.bringToFront();
        idPath.bringToFront();

        return paths;
    }

}

export class Fluid extends AnnularItemBase {

    constructor(init: Partial<Fluid>) {
        super(init);
        this.tubularStringId = init.tubularStringId;
        this.labelSide = "left";
        this.labelVert = "top";
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler?: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);
        return paths;
    }
}

export class Packer extends WellSchematicItemBase {

    public drawTopFlush: boolean;

    constructor(init?: Partial<Packer>) {
        super({ ...init, color: "red" });
        this.btm = this.top + 8;
        this.labelSide = "right";
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);

        // Increase the vertical scale so they are more visible.
        let vertScale = (this.units.long === "ft") ? 8 : 2;
        let path = paths[0].subtract(paths[1]);
        path.fillColor = new ps.Color(this.color);

        if (this.drawTopFlush) { // Hangers need aligned at the top
            path.scale(1, vertScale, this.odBounds.bounds.topCenter);
        } else {// Packers need aligned at the bottom
            path.scale(1, vertScale, this.odBounds.bounds.bottomCenter);
        }

        if (onClickHandler) { // Re-implement the click handler for this new path
            path.onClick = (e => onClickHandler(e, this.tubularStringId, path, path));
        }

        //Calc some vectors for little crosses
        this.drawXforHanger(paths, ps, vertScale);

        //Remove the original paths
        paths[0].remove();
        paths[1].remove();

        return paths;
    }

    private drawXforHanger(paths: paper.Path[], ps: paper.PaperScope, verticalScale: number) {
        let topLeftL = paths[0].bounds.topLeft;
        let btmRightL = paths[1].bounds.bottomLeft;
        let btmLeftL = paths[0].bounds.bottomLeft;
        let topRightL = paths[1].bounds.topLeft;

        this.drawHashLine(ps, btmLeftL, topRightL, verticalScale);
        this.drawHashLine(ps, topLeftL, btmRightL, verticalScale);

        // Righthand side
        let topRightR = paths[0].bounds.topRight;
        let btmLeftR = paths[1].bounds.bottomRight;
        let btmRightR = paths[0].bounds.bottomRight;
        let topLeftR = paths[1].bounds.topRight;

        this.drawHashLine(ps, btmLeftR, topRightR, verticalScale);
        this.drawHashLine(ps, topLeftR, btmRightR, verticalScale);
    }

    private drawHashLine(ps: paper.PaperScope, leftPnt: paper.Point, rightPnt: paper.Point, verticalScale: number): paper.Path {
        let hashDiagnal = new ps.Path([leftPnt, rightPnt]);
        hashDiagnal.strokeColor = new ps.Color("black");
        hashDiagnal.strokeWidth = .25;
        if (this.drawTopFlush) {
            hashDiagnal.scale(1, verticalScale, this.odBounds.bounds.topCenter);
        } else {
            hashDiagnal.scale(1, verticalScale, this.odBounds.bounds.bottomCenter);
        }
        return hashDiagnal;
    }
}

export class Hanger extends Packer {
    constructor(init: Partial<Packer>) {
        super(init);
        this.top = init.top;
        this.btm = init.top + (init.units.long == "ft" ? 5 : 1.5);
        this.name = init.name;
        this.drawTopFlush = true;
        this.color = "orange";
    }
}

export class Perforation extends WellSchematicItemBase {

    constructor(init: Partial<{ od: number, top: number, name: string, units: { long: string, short: string } }>) {
        super({
            ...init,
            outerDiameter: init.od,
            innerDiameter: init.od,
            top: init.top - (init.units.long == "ft" ? 25 : 8.3),
            btm: init.top,
            color: '#E25822'
        });
        this.labelSide = "right";
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler?: Function): Array<paper.Path> {
        let paths = super.draw(ps, centeringOffset, onClickHandler);
        let odRect = paths[0];

        let perfPathRight = this.generatePerforationSvgPath();

        var triangleRight = ps.Path.create(perfPathRight);
        triangleRight.name = this.name; // Name the right side so it will be found by (paperJs find)  and labeled
        triangleRight.fillColor = new ps.Color(this.color);
        triangleRight.bringToFront();

        triangleRight.bounds.topLeft = odRect.bounds.topRight;

        let triangleLeft = triangleRight.copyTo(ps.project);
        triangleLeft.rotate(180);
        triangleLeft.bounds.topRight = odRect.bounds.topLeft;

        if (onClickHandler) { // Re-implement the click handler for this new path
            triangleRight.onClick = (e => onClickHandler(e, this.tubularStringId, triangleLeft, triangleRight));
        }

        //Remove the original paths
        paths[0].remove();
        paths[1].remove();

        return paths;
    }

    private generatePerforationSvgPath() {
        const widthConversionFactors = {
            'in': 1,
            'cm': 2.54,
            'mm': 25.4
        };
        const heightConversionFactors = {
            'ft': 1,
            'm': 0.3048
        };
        const widthFactor = widthConversionFactors[this.units.short];
        const heightFactor = heightConversionFactors[this.units.long];

        const dimensions = {
            x: 50 * widthFactor,
            y: 50 * heightFactor,
            z: 4 * widthFactor,
            p: 25 * heightFactor
        };
        return `m ${dimensions.x} 0 l 0 ${dimensions.y} l ${dimensions.z} -${dimensions.p}`;
    }
}

export class HorizontalLine extends WellSchematicItemBase {

    public width: number;

    constructor(init: Partial<{ verticalPosition: number, width: number, color: string, name: string, units: { long: string, short: string } }>) {
        super({
            ...init,
            outerDiameter: init.width,
            innerDiameter: init.width,
            top: init.verticalPosition,
            btm: init.verticalPosition,
        });

        this.outerDiameter = this.width * 2;
        this.innerDiameter = this.width * 2;

        this.width = init.width;
        this.labelSide = "right";
    }

    public draw(ps: paper.PaperScope, centeringOffset: number, onClickHandler?: Function): Array<paper.Path> {
        super.draw(ps, centeringOffset, onClickHandler);
        this.odBounds.strokeColor = new ps.Color(this.color);
        return [this.odBounds];
    }

}
