<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="p-toolbar-group-left">
    <div class="mr-2">
      <p-selectButton
        [options]="resultsDisplay"
        [(ngModel)]="selectedResultDisplay"
        (onChange)="toggleGridPlot($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div class="mr-2">
      <p-dropdown
        [options]="packers"
        appendTo="body"
        (onChange)="selectPacker($event)"
        [(ngModel)]="selectedPacker"
      ></p-dropdown>
    </div>
    <div class="mr-2">
      <p-dropdown
        *ngIf="selectedResultDisplay === 'plot'"
        [options]="resultTypes"
        appendTo="body"
        (onChange)="toggleResultsView($event)"
        [(ngModel)]="selectedResultView"
      ></p-dropdown>
    </div>
    <div *ngIf="isDisplacementSelected == true || selectedResultDisplay === 'grid'" class="mr-2">
      <p-dropdown
        [(ngModel)]="dispacementType"
        (onChange)="displacementTypeChange($event)"
        optionValue="value"
        optionLabel="label"
        [options]="displacementTypes"
        appendTo="body"
      ></p-dropdown>
    </div>
  </div>

  <p-dialog
    [draggable]="false"
    [(visible)]="needToCalculate"
    [closable]="false"
    *ngIf="!isLoading"
  >
    <i class="pi pi-exclamation-circle"></i> Please calculate Perivis to see results
    <br />
    <br />
  </p-dialog>

  <p-dialog
    [draggable]="false"
    [(visible)]="packersMissing"
    [closable]="false"
    *ngIf="!isLoading"
  >
    <i class="pi pi-exclamation-circle"></i> No Packers set on selected string.
    <br />
    <br />
  </p-dialog>

  <br *ngIf="selectedResultDisplay === 'grid'" />

  <app-xy-line-plot
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [legendBelowPlot]="true"
    [plotTitle]="'Packer Results Table'"
    *ngIf="selectedResultDisplay === 'plot'"
  ></app-xy-line-plot>

  <div [style.display]="selectedResultDisplay === 'grid' ? 'block' : 'none'">
    <app-single-load-grid
      [rowData]="results"
      [columnDefinitions]="cols"
      [tableName]="'Packer Results Table'"
      [gridName]="'Packer Results Table'"
    ></app-single-load-grid>
  </div>
</app-tool-loader>
