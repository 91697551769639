import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, timer, lastValueFrom } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Utilities } from 'src/app/core/services/utilities';
import { Perforation } from 'src/app/perical/models/perforation.model';
import { ThermalOperationsService } from 'src/app/perical/services/thermal-operations.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { WellConfigService } from 'src/app/shared/services/well-config.service';

@Component({
  selector: 'app-perforations',
  templateUrl: './perforations.component.html',
  styleUrls: ['./perforations.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class PerforationsComponent implements OnInit, AfterViewInit, OnDestroy {

  public columnDefs: Array<any>;
  public tableHeight: string;

  private _perforations: Array<Perforation>
  public selectedPerforation: Perforation;
  private _subscriptions: Subscription;
  public perforationsForm: UntypedFormGroup;
  public perforationFg: UntypedFormGroup;
  private _designId: string;

  get perforations(): UntypedFormArray {
    return this.perforationsForm.get("perforations") as UntypedFormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  // Validation
  public shoeDepth: number;
  public hangerMd: number;
  public perforationDefaultDepth: number;
  public isLoading : boolean;
  public configMissing: boolean;

  // State
  @Input()
  public componentId: string;

  constructor(
    private _messenger: MediatorService,
    private _thermalOperationsService: ThermalOperationsService,
    private _formBuilder: UntypedFormBuilder,
    private _wellConfigService: WellConfigService,
    private _store: StoreService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.perforationsForm = this._formBuilder.group({
      perforations: this._formBuilder.array([]),
    });
   }

  async ngOnInit(): Promise<void> {
    this._designId = (await this._store.get<any>(StorageKeys.DESIGN)).id;
    let uu = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);

    this.columnDefs = [
      { field: 'name', header: 'Name' },
      { field: 'measuredDepth', header: `Depth (${uu.longLengths} MD)` },
      { field: 'temperature', header: `UDT (${uu.temperature})` }
    ];

    this.getDataAndDefaults(false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: { action: string, designId: string }) {
    if (data.action == PeriforOnChangeMessages.REFRESH_UDT_PLOT || data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.getDataAndDefaults(true);
    }
  }

  private getDataAndDefaults(isSignalR: boolean) {
    this._wellConfigService.getStringsList().subscribe({
      next: (tubulars) => {
      if (tubulars.length > 0) {
        this.getDefaults();
        this._subscriptions.add(this._thermalOperationsService.getPerforations().subscribe(perforations => {
          this._perforations = perforations;
          this.populateFormData(isSignalR);
        }));
      } else {
        this.configMissing = true;
        this.isLoading = false;
      }
    },
    error: () => {
      this.isLoading = false;
    }});
  }

  private populateFormData(isSignalR: boolean) {
    this._perforations.forEach((data, idx) => {
      let perforationFg = this.newPerforation(false);
      if (!isSignalR) {
        this.perforations.push(perforationFg);
      }
      this.perforations.controls[idx].setValue(data, { emitEvent: false });
    });
    this.isLoading = false;
  }

  private newPerforation(isNewPerforation: boolean): UntypedFormGroup {
    this.perforationFg = new UntypedFormGroup({
      id: new UntypedFormControl(Utilities.generateFakeGuid()), // Setting a unique id here so that prime-ng p-table has a data key for the expand and collapse functionality.
      designId : new UntypedFormControl(this._designId),
      name: new UntypedFormControl(null, Validators.required),
      measuredDepth: new UntypedFormControl(this.perforationDefaultDepth, [Validators.required, Validators.max(this.perforationDefaultDepth)]),
      temperature: new UntypedFormControl({value: null, disabled: true})
    });

    let debouncedValueChanges = this.perforationFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSavePerforationRow(v, this.perforationFg)));

    if (isNewPerforation) {
      this.perforationFg.controls.measuredDepth.patchValue(this.perforationFg.controls.measuredDepth.value);
    }

    this.perforationFg.markAllAsTouched();

    return this.perforationFg;
  }

  private async handleSavePerforationRow(v: any, perforationRecord: UntypedFormGroup): Promise<void> {
    if (perforationRecord.valid && !this.isLoading && v.measuredDepth <= this.perforationDefaultDepth) {
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        var newEntityId = await lastValueFrom(this._thermalOperationsService.setPerforations(new Perforation(v)));
        perforationRecord.get("id").patchValue(newEntityId, { emitEvent: false });
      } else {
        v.designId = this._designId;
        await lastValueFrom(this._thermalOperationsService.updatePerforation(new Perforation(v)));
      }

      this.clearFormArray(this.perforations);
      this.getDataAndDefaults(false);
    }
  }

  getDefaults() {
    this.isLoading = true;
    this._wellConfigService.getTubulars().subscribe({
      next: (tubulars) => {
      if (tubulars.length < 1) {
        this.configMissing = true;
        return;
      }

      this.configMissing = false;
      this.perforationDefaultDepth = Math.max(...tubulars.map(x => x.shoeMd));
      this.perforationFg?.controls.measuredDepth.setValidators(Validators.max(this.perforationDefaultDepth));
      // this.isLoading = false;
    },
    error: () => {
      this.isLoading = false;
      this.configMissing = true;
    }});
  }

  public onAddPerforation(idx?: number): void {
    if (this.perforations.invalid) {
      return;
    }
    this.perforations.insert(idx || 0, this.newPerforation(true));
  }

  public onDeletePerforation(idx: number): void {
    let perforationId = this.perforations.controls[idx].value.id;
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete the selected perforation?',
      accept: async () => {
        if (perforationId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._thermalOperationsService.deletePerforation(perforationId));
        } else {
          this._perforations = this._perforations.filter(val => val.id !== perforationId);
        }

        this.perforations.removeAt(idx);
        }
    });
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Perforations") {
        this.tableHeight = (e.itemHeight - 62) + 'px';
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
