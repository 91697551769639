import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login-page',
  template: `
    <body>
      <div class="grid justify-content-center align-items-center grid-nogutter" style="height: 100%">
        <div class="col-3">
          <p-card>
            <div class="flex flex-column align-items-center">

              <img class="img" src="../../assets/images/Perifor-with-Well-Circle.png" />

              <ng-container *ngIf="!(auth.isAuthenticated$ | async)">
                <p>Welcome! Log in to get started.</p>
                <p-button label="Login" (click)="login()"></p-button>
              </ng-container>

            </div>
          </p-card>
        </div>
      </div>
    </body>
  `,
  styles: [`
    body {
      background-image: url("../../../../assets/images/Perifor-Background.png");
      background-color: #444745;
      border: none;
    }
  `]
})
export class LoginPageComponent {
  constructor(public auth: AuthService) { }

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/designs'
      }
    });
  }
}
