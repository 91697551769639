import { Component, Input, OnInit } from '@angular/core';
import { ApbService } from '../../services/apb.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription } from 'rxjs';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { ApbResultsGridUi } from '../../models/apb.model';
import { zipObject } from 'lodash';

@Component({
  selector: 'app-apb-results-grid',
  templateUrl: './apb-results-grid.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }
  `]
})
export class ApbResultsGridComponent implements OnInit {
  public isLoading: boolean;
  public radiusTypes: Array<{ label: string, value: string }> = [
    { label: "Outer Radius Change", value: "outerRadiusChange" },
    { label: "Inner Radius Change", value: "innerRadiusChange" }
  ];
  public cols: any[];
  public depthUnit: string;
  public tableHeight: string;
  public resultsGrid: any[];
  public results: any[];
  public needToCalculate: boolean;

  private _subscriptions: Subscription;
  private _data: any;
  private radiusUnit: string;
  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.apbResultsGridStore = this._storeService.createStore(this.componentId, new ApbResultsGridUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public apbResultsGridStore: Store;

  constructor(
    private _apbService: ApbService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("APB Results Grid")) {
        this.tableHeight = (e.itemHeight - 90) + 'px';
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = uu.longLengths;
    this.radiusUnit = uu.shortLengths;

    this.radiusTypes = [
      { label: "Outer Radius Change", value: "outerRadiusChange" },
      { label: "Inner Radius Change", value: "innerRadiusChange" }
    ];

    this.getApbResults();
  }

  private signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_APB_RESULTS) {
      this.getApbResults();
    }
  }

  private getApbResults() {
    this.isLoading = true;
    this.needToCalculate = false;
    this._apbService.getApbResultsGrid().subscribe((data) => {
      if (data[0] == null) {
        this.resultsGrid = [];
        this.cols = [];
        this.isLoading = false;
        this.needToCalculate = true;
        return;
      }
      this._data = data;
      this.parseData(data);

      this.isLoading = false;
    });
  }

  private parseData(data: any) {
    if (data) {
      let radiusIndex = data[0].findIndex((r: any) => toCamelCaseString(r.name) === this.apbResultsGridStore.state.selectedRadiusType);
      let headers = data[0][radiusIndex].headers;

      this.cols = headers.map(h => {
        return {
          field: toCamelCaseString((h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})`) ? h.split(' ').shift() : h),
          header: h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})` ? h : h + ` (${this.radiusUnit})`,
          valueFormatter: (params: any) => h.includes('MD') || h.includes('TVD') ? this.formatDecimal(params, 100) : this.formatDecimalNew(params, 4)
        };
      });

      this.results = data[0][radiusIndex].rows;

      this.resultsGrid = this.results.map(r => zipObject(this.cols.map(c => c.field), r));
    }
  }

  public onRadiusTypeChange(event: any) {
    this.apbResultsGridStore.update(state => ({...state, selectedRadiusType: event.value}));
    this.parseData(this._data);
  }

  private formatDecimal(value: any, valueDivider: number) {
    return value || value === 0
      ? (Math.trunc(value * valueDivider) / valueDivider).toLocaleString('en-US')
      : null;
  }

  private formatDecimalNew(value: any, numDecimalPoints: number): string {
    return value || value > 0 ? (value.toFixed(numDecimalPoints)).toLocaleString('en-US') : value;
  }
}
