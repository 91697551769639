import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Fluid } from '../models/fluid.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class FluidsService {

  constructor(private http: HttpClient) { }

  getFluids(): Observable<Fluid[]> {
    return this.http.get<Fluid[]>(`${PeriforApiEndpoint}/fluids`);
  }

  getFluidById(fluidId: string): Observable<Fluid> {
    return this.http.get<Fluid>(`${PeriforApiEndpoint}/fluids/${fluidId}`);
  }

  createFluid(fluid: Fluid): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/fluids`, fluid.state);
  }

  updateFluid(fluid: Fluid): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/fluids/${fluid.id}`, fluid.state);
  }

  deleteFluid(fluidId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/fluids/${fluidId}`)
  }

  cloneFluid(fluidId: string, fluidName: string): Observable<Partial<Fluid>> {
    return this.http.post<Partial<Fluid>>(`${PeriforApiEndpoint}/fluids/${fluidId}/clone`, {name: fluidName});
  }

  calculateGerg(fluid: Fluid){
    return this.http.post<string>(`${PeriforApiEndpoint}/fluids/${fluid.id}/gerg-calc`, fluid.state);
  }

  getPhaseEnvelope(fluidId: string){
    return this.http.get<string>(`${PeriforApiEndpoint}/fluids/${fluidId}/phase-envelope`);
  }

}
