import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-tree-level-dialog',
  templateUrl: './delete-tree-level-dialog.component.html',
  styles: [`
    .p-field-checkbox, h4 {
        user-select: none;
    }

    .grid {
      align-items: center;
    }
  `]
})
export class DeleteTreeLevelDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.level = this.config.data.level;
    this.levelName = this.config.data.levelName;
    this.parentName = this.config.data.parentName;
  }

  accept: boolean;
  level: string;
  levelName: string;
  parentName: string;

  onConfirm(): void {
    this.ref.close('confirmed');
  }

  onCancel(): void {
    this.ref.close();
  }
}
