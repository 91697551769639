import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { LinearInterpSingle } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { CirculatedKickGasIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface CirculatedKickGasIppForm {
  type: InternalPressureProfileType.GASKICK;
  influxDepth: UomQuantityInput;
  gasGravity: UomQuantityInput;
  kickVolume: UomQuantityInput;
  kickIntensity: UomQuantityInput;
  maxMudWeight: UomQuantityInput;
  fractureDepth: UomQuantityInput;
  fractureGradient: UomQuantityInput;
  fractureMarginOfError: UomQuantityInput;
  drillPipeOd: UomQuantityInput;
}

@Component({
  selector: 'app-circulated-kick-gas-ipp',
  templateUrl: './circulated-kick-gas-ipp.component.html',
  styles: [`
    .leftFieldset {
      width: 50%;
    }

    ::ng-deep p-tabView .p-tabview-nav-container a {
      padding: 8px !important;
    }
  `],
  providers: subformComponentProviders(CirculatedKickGasIppComponent)
})
export class CirculatedKickGasIppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() influxDepthDefault: number;
  @Input() nextStringMudWeight: number;
  @Input() smallestPipeId: number;
  @Input() fracPressures: PoreFrac[];
  @Input() shoeDepth: number;
  @Input() nextShoeDepth: number;
  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public defaultFracGradient: number;
  public densityLabel: string;
  public volumeLabel: string;
  public maxMudWeightValidation: { min: number, max: number };
  public marginOfErrorValidation: { min: number, max: number };
  public fractureGradientValidation: { min: number, max: number };
  public kickIntensityValidation: { min: number, max: number };
  public kickVolumeValidation: { min: number, max: number };
  public kickVolumeDefault: number;
  public kickIntensityDefault: number;
  public defaultDrillPipeOd: number;

  public form = createForm<CirculatedKickGasIPP, CirculatedKickGasIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.GASKICK),
      influxDepth: new UntypedFormControl(null),
      gasGravity: new UntypedFormControl(null),
      kickVolume: new UntypedFormControl(null),
      kickIntensity: new UntypedFormControl(null),
      maxMudWeight: new UntypedFormControl(null),
      fractureDepth: new UntypedFormControl(null),
      fractureGradient: new UntypedFormControl(null),
      fractureMarginOfError: new UntypedFormControl(null),
      drillPipeOd: new UntypedFormControl(null),
    },
    toFormGroup: (obj: CirculatedKickGasIPP | null): CirculatedKickGasIppForm | null => {
      if (!obj) {
        return null
      }

      return {
        type: obj.type,
        influxDepth: { control: obj.influxDepth },
        gasGravity: { control: obj.gasGravity },
        kickVolume: { control: obj.kickVolume },
        kickIntensity: { control: obj.kickIntensity },
        maxMudWeight: { control: obj.maxMudWeight },
        fractureDepth: { control: obj.fractureDepth },
        fractureGradient: { control: obj.fractureGradient },
        fractureMarginOfError: { control: obj.fractureMarginOfError },
        drillPipeOd: { control: obj.drillPipeOd }
      }
    },
    fromFormGroup: (formValue: CirculatedKickGasIppForm): CirculatedKickGasIPP | null => {
      const { influxDepth, gasGravity, kickVolume, kickIntensity, maxMudWeight, fractureDepth, fractureGradient, fractureMarginOfError, drillPipeOd, ...commonValues } = formValue;
      return {
        influxDepth: +influxDepth?.control,
        gasGravity: +gasGravity?.control,
        kickVolume: +kickVolume?.control,
        kickIntensity: +kickIntensity?.control,
        maxMudWeight: +maxMudWeight?.control,
        fractureDepth: +fractureDepth?.control,
        fractureGradient: +fractureGradient?.control,
        fractureMarginOfError: +fractureMarginOfError?.control,
        drillPipeOd: +drillPipeOd?.control,
        ...commonValues
      }
    }
  })

  constructor(private _trajectoryService: TrajectoryService) { }

  ngAfterViewInit(): void {
    this.getEmwDefaultFrac(this.currentString.shoeMd);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
    this.volumeLabel = this.userUnits.volume;

    switch (this.densityLabel) {
      case 'ppg':
        this.maxMudWeightValidation = { min: 0.1, max: 25 };
        this.marginOfErrorValidation = { min: 0, max: 10 };
        this.fractureGradientValidation = { min: 7, max: 25 };
        this.kickIntensityValidation = { min: 0, max: 5 };
        this.kickIntensityDefault = 0.5;
        break;
      case 'kg/m³':
      case 'g/L':
        this.maxMudWeightValidation = { min: 11.98, max: 2995 };
        this.marginOfErrorValidation = { min: 0, max: 1198 };
        this.fractureGradientValidation = { min: 838, max: 2995 };
        this.kickIntensityValidation = { min: 0, max: 599 };
        this.kickIntensityDefault = 60;
        break;
      case 'g/cm³':
      case 'kg/l':
        this.maxMudWeightValidation = { min: 0.012, max: 3 };
        this.marginOfErrorValidation = { min: 0, max: 1.2 };
        this.fractureGradientValidation = { min: 0.84, max: 3 };
        this.kickIntensityValidation = { min: 0, max: 0.6 };
        this.kickIntensityDefault = 0.06;
        break;
    }

    switch (this.volumeLabel) {
      case 'bbl':
        this.kickVolumeValidation = { min: 0, max: 500 };
        this.kickVolumeDefault = 50;
        break;
      case 'm³':
        this.kickVolumeValidation = { min: 0, max: 79.4 };
        this.kickVolumeDefault = 8;
        break;
      case 'l':
        this.kickVolumeValidation = { min: 0, max: 79494 };
        this.kickVolumeDefault = 8000;
        break;
    }

    switch (this.userUnits.shortLengths) {
      case 'in':
        this.defaultDrillPipeOd = 3.5;
        break;
      case 'cm':
        this.defaultDrillPipeOd = 8.89;
        break;
      case 'mm':
        this.defaultDrillPipeOd = 88.9;
        break;
    }

    this.validators = {
      influxDepth: [Validators.min(this.currentString.shoeMd), Validators.max(Number.MAX_VALUE), Validators.required],
      gasGravity: [Validators.min(0), Validators.max(2), Validators.required],
      kickVolume: [Validators.min(this.kickVolumeValidation.min), Validators.max(this.kickVolumeValidation.max), Validators.required],
      kickIntensity: [Validators.min(this.kickIntensityValidation.min), Validators.max(this.kickIntensityValidation.max), Validators.required],
      maxMudWeight: [Validators.min(this.maxMudWeightValidation.min), Validators.max(this.maxMudWeightValidation.max), Validators.required],
      fractureDepth: [Validators.min((this.shoeDepth)), Validators.max(this.nextShoeDepth), Validators.required],
      fractureGradient: [Validators.min(this.fractureGradientValidation.min), Validators.max(this.fractureGradientValidation.max), Validators.required],
      fractureMarginOfError: [Validators.min(this.marginOfErrorValidation.min), Validators.max(this.marginOfErrorValidation.max), Validators.required],
      drillPipeOd: [Validators.min(0), Validators.max(this.smallestPipeId), Validators.required]
    }

    if (changes.currentString || changes.fracPressures) {
      this.getEmwDefaultFrac(this.currentString.shoeMd);
    }
  }

  getEmwDefaultFrac(depth): void {
    this._trajectoryService.getTvdFromMd(depth).subscribe(tvd => {
      if (this.fracPressures?.length > 1) {
        if (depth < this.currentString?.hangerMd) {
          return;
        }
        this.defaultFracGradient = +LinearInterpSingle(tvd, this.fracPressures, this.userUnits).toFixed(2);
      } else {
        this.defaultFracGradient = this.currentString.annularFluid.state.nominalDensity + 2;
      }
      if (!this.form.formGroup.controls.fractureGradient.value?.control) {
        this.form.formGroup.controls.fractureGradient.setValue( { control: this.defaultFracGradient } );
      }
    });
  }
}
