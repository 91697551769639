<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div #toolbarDiv class="p-toolbar-group-left">
    <div class="mr-2">
      <p-selectButton
      [options]="modes"
      [(ngModel)]="axialResultsStore.state.selectedMode"
      (onChange)="onModeChange($event)"
      [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div class="mr-2">
      <p-selectButton
        [options]="resultsDisplay"
        [(ngModel)]="axialResultsStore.state.selectedResultDisplay"
        (onChange)="onSelectedResultDisplayUpdated($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    <div class="flex grid-nogutter" *ngIf="axialResultsStore.state?.selectedResultDisplay === 'plot'">
      <div class="mr-2">
        <p-selectButton
        [options]="depthView"
        [(ngModel)]="axialResultsStore.state.selectedDepthView"
        (onChange)="depthViewToggle($event)"
        [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </div>
    <div class="mr-2">
      <app-single-load-results
        [isAxialForce]="true"
        [loadCases]="loadCases"
        (loadCaseSelected)="setLoadCase($event)"
        [stateStore]="axialResultsStore"
        [signalRChange]="signalRChange"
      ></app-single-load-results>
    </div>
  </div>

  <app-single-load-plot
    [yAxisTitle]="yAxisTitle"
    [xAxisTitle]="xAxisTitle"
    [columnDefs]="columnDefs"
    [data]="results"
    *ngIf="axialResultsStore.state?.selectedResultDisplay === 'plot'"
    [selectedDepthView]="axialResultsStore.state?.selectedDepthView"
    [depthUnit]="longLengthUnit"
    [plotName]="plotName"
    [plotTitle]="'Axial Forces Results'"
    [componentHeight]="componentHeight"
  ></app-single-load-plot>

  <div>
    <br>
    <app-single-load-grid
      *ngIf="axialResultsStore.state?.selectedResultDisplay === 'grid'"
      [rowData]="results"
      [columnDefinitions]="columnDefinitions"
      tableName="Single Load Axial"
      [tableHeight]="tableHeight"
    ></app-single-load-grid>
  </div>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>
