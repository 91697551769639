<div class="mr-2">
  <p-selectButton
    [options]="plotView"
    [(ngModel)]="trajectoryPlotStore.state.selectedPlotView"
    (onChange)="selectPlotView($event)"
    [allowEmpty]="false"
  ></p-selectButton>
</div>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <div #plotElement class="plotContainer">

    <ThreeDeePlot
      [hidden]="trajectoryPlotStore.state.selectedPlotView != '3d' || isLoading"
      [xyzData]="trajectoryXyzData"
      [trajectoryPlotStore]="trajectoryPlotStore"
    ></ThreeDeePlot>

    <TwoDeePlot
      *ngIf="trajectoryPlotStore.state.selectedPlotView != '3d' && !isLoading"
      [selectedPlot]="trajectoryPlotStore.state.selectedPlotView"
      [trajectoryData]="trajectoryData"
      [userUnits]="uu"
    ></TwoDeePlot>

  </div>

</app-tool-loader>
