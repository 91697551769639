import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GradeCatalog } from '../../models/grade-catalog.model';
import { TemperatureDerationCatalog } from '../../models/temperature-deration-catalog.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/temperatureDerationCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TemperatureDerationService {

  constructor(private http: HttpClient) { }

  createTemperatureDerations(tempDeration: TemperatureDerationCatalog): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}`, tempDeration);
  }

  getTemperatureDerations(): Observable<Array<TemperatureDerationCatalog>> {
    return this.http.get<Array<TemperatureDerationCatalog>>(`${PeriforApiEndpoint}`).pipe(
      map(data => data.map(data => new TemperatureDerationCatalog(data)))
    );
  }

  getTemperatureDerationsById(profileId: string): Observable<TemperatureDerationCatalog> {
    return this.http.get<TemperatureDerationCatalog>(`${PeriforApiEndpoint}${profileId}`);
  }

  updateTemperatureDeration(model: TemperatureDerationCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteTempereatureDeration(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
