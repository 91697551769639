export class DlsOverrideModel {
    public measuredDepth: number;
    public dlsOverride: number;
}

export class DlsOveridesUi {
  public constructor(init?: Partial<DlsOveridesUi>) {
    this.tablePlotSelected = init && init.tablePlotSelected || 'table';
  }
  tablePlotSelected: string;
}
