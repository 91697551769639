import { FormationCementCatalog } from "src/app/catalogs/models/formations-cement-catalog.model";

export class FormationTop {
  public constructor(init?: Partial<FormationTop>) {
    Object.assign(this, init);
  }

  id: string;
  formationTop: number;
  formation: FormationCementCatalog;
}
