import { Component } from '@angular/core';

@Component({
  selector: 'app-changelog',
  template: `
    <div class="changelogContainer">
      <div class="p-grid p-nogutter p-fluid">
        <p-card class="p-col-6 p-mx-auto">
          <div class="p-col-12">
            <markdown class="changelogMarkdown"
              ngPreserveWhitespaces
              [src]="'../../../../assets/CHANGELOG_161.md'"
            ></markdown>
          </div>
        </p-card>
      </div>
    </div>
  `,
  styles: [`
      .changelogContainer {
      background-image: url("../../../../assets/images/Perifor-Background.png");
      height: 100%;
      overflow-y: scroll;
    }

    :host ::ng-deep a {
      color: lightgreen;
    }

    :host ::ng-deep h2 {
      color: lightgreen;
    }
  `]
})

export class ChangelogComponent {
  constructor() { }
}
