import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT, } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { MediatorService } from './shared/services/mediator.service';
import { OpenSearchComponentDropdown } from './shared/models/mediator-messages.model';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public environment: string;
  public idleState: string;
  public timedOut: boolean;

  /** Idle time in seconds */
  private _defaultIdle : number;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'F3' || (event.ctrlKey && event.code === 'KeyF')) {
      this._mediator.publish(new OpenSearchComponentDropdown());
      event.preventDefault();
    }
  }

  constructor(
    public auth: AuthService,
    private _idle: Idle,
    private _messageService: MessageService,
    private _mediator: MediatorService,
    @Inject(DOCUMENT) private _document
  ) {

    this.environment = this.getEnvironment();
    this._defaultIdle = (this.environment == "dev" || this.environment == 'local') ? 60 * 60 : 15 * 60 

    this.initializeIdleTimer(_idle);

    this.auth.user$?.subscribe((user) => {
      if (user) {
        _idle.watch();
        this.timedOut = false;
      } else {
        _idle.stop();
      }
    });

  }

  handleIdleEnd() {
    this._messageService.clear("idle");
    this.resetIdleWatch();
  }

  handleTimeout() {
    this.idleState = 'Timed out';
    this.timedOut = true;
    this.auth.logout({ logoutParams: {returnTo: this._document.location.origin }});
  }

  handleIdleStart() {
    this.idleState = 'Idle session detected.';
    this._messageService.add({ 
      key:"idle", 
      life: 1000, 
      sticky: true, 
      severity: 'warn', 
      summary: 'Idle Session' 
    });
  }

  handleTimeoutWarning(countdown: number) {
    this.idleState = `Session will time out in ${countdown} seconds.`;
  }

  onIdleWarningClose() { 
    this.resetIdleWatch();
  }

  resetIdleWatch() {
    this.idleState = '';
    this.timedOut = false;
    this._idle.watch();
  }

  getEnvironment(): string {
    let url = this._document.location.href;
    var env = "";
    if (url.includes("perifor-dev")) {
      env = "dev";
    }
    if (url.includes("perifor-uat")) {
      env = "uat";
    }
    if (url.includes("bsee")) {
      env = "prod-bsee";
    }
    if (url.includes("localhost")) {
      env = "local";
    }
    return env;
  }

  private initializeIdleTimer(_idle: Idle) {
    _idle.setIdle(this._defaultIdle);
    _idle.setTimeout(15); // seconds
    _idle.onIdleEnd.subscribe(() => this.handleIdleEnd());
    _idle.onTimeout.subscribe(() => this.handleTimeout());
    _idle.onIdleStart.subscribe(() => this.handleIdleStart());
    _idle.onTimeoutWarning.subscribe((countdown) => this.handleTimeoutWarning(countdown));

    _idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }
}
