import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PipesCatalog } from 'src/app/catalogs/models/pipes-catalog.model';

const PeriforApiEndpoint_PipesCatalog = `${environment.PeriforEndpoint}/api/pipesCatalog`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

export class PipesUpdatedMessage {
  constructor(public pipesCatalog: PipesCatalog) {}
}

@Injectable({
  providedIn: 'root'
})
export class PipesService {

  constructor(private http: HttpClient) { }

  public getPipeOds(): Observable<any> {
    return this.http.get<any>(`${PeriforApiEndpoint_PipesCatalog}/ods`);
  }

  public getPipes(od:string=""): Observable<Array<PipesCatalog>> {
    return this.http.get<Array<PipesCatalog>>(`${PeriforApiEndpoint_PipesCatalog}?${od}`).pipe(
      map(data => data.map(data => new PipesCatalog(data)))
    );
  }

  addPipe(model: PipesCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint_PipesCatalog}`, model);
  }

  updatePipe(model: PipesCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint_PipesCatalog}/${model.id}`, model, httpOptions);
  }

  deletePipe(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint_PipesCatalog}/${id}`);
  }

}
