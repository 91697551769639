<div *ngIf="units" class="unitsContainer">

  <div class="grid grid-nogutter">

    <p-card class="col-6 mx-auto">

      <div class="header">
        <h1>Units</h1>
      </div>
      <span>
        <p-button class="mr-1 mb-1" label="Reset to API" icon="pi pi-refresh" (onClick)="resetToApi()" styleClass="p-button-danger p-button-outlined"></p-button>
        <p-button class="pl-1 mr-1 mb-1" label="Reset to SI" icon="pi pi-refresh" (onClick)="resetToSi()" styleClass="p-button-danger p-button-outlined"></p-button>
      </span>
      <p-divider></p-divider>

        <div class="grid grid-nogutter">

          <div class="col">
            <div class="p-card-content">
                <div class="field">
                  <label for="longLengths">Length</label>
                  <p-dropdown
                    [(ngModel)]="units.longLengths"
                    [options]="longLengthUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="shortLengths">Diameter</label>
                  <p-dropdown
                    [(ngModel)]="units.shortLengths"
                    [options]="shortLengthUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="linearDensity">Weight per length</label>
                  <p-dropdown
                    [(ngModel)]="units.linearDensity"
                    [options]="linearDensityUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="temperature">Temperature</label>
                  <p-dropdown
                    [(ngModel)]="units.temperature"
                    [options]="temperatureUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="pressure">Pressure</label>
                  <p-dropdown
                    [(ngModel)]="units.pressure"
                    [options]="pressureUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="stressPressure">Stress Pressure</label>
                  <p-dropdown
                    [(ngModel)]="units.stressPressure"
                    [options]="stressPressureUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="doglegSeverity">Dogleg Severity</label>
                  <p-dropdown
                    [(ngModel)]="units.doglegSeverity"
                    [options]="dlsUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="temperatureGradient">Temperature Gradient</label>
                  <p-dropdown
                    [(ngModel)]="units.temperatureGradient"
                    [options]="tempGradientOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>
            </div>
          </div>

          <div class="col">
            <div class="p-card-content">

                <div class="field">
                  <label for="density">Liquid Density</label>
                  <p-dropdown
                    [(ngModel)]="units.density"
                    [options]="densityUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="density">Solids Density</label>
                  <p-dropdown
                    [(ngModel)]="units.solidsDensity"
                    [options]="solidsDensityUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="force">Force</label>
                  <p-dropdown
                    [(ngModel)]="units.force"
                    [options]="forceUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Thermal Conductivity</label>
                  <p-dropdown
                    [(ngModel)]="units.thermalConductivity"
                    [options]="thermalConductivityUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Specific Heat Capacity</label>
                  <p-dropdown
                    [(ngModel)]="units.specificHeatCapacity"
                    [options]="specificHeatCapacityUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Thermal Expansion Coefficient</label>
                  <p-dropdown
                    [(ngModel)]="units.coefficientOfThermalExpansion"
                    [options]="thermalExpansionCoeffUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Gas Gradient</label>
                  <p-dropdown
                    [(ngModel)]="units.gasGradient"
                    [options]="gasGradientOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Standard Fluid Flow Rate</label>
                  <p-dropdown
                    [(ngModel)]="units.standardFluidFlow"
                    [options]="standardFluidFlowUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

            </div>
          </div>

          <div class="col">
            <div class="p-card-content">

                <div class="field">
                  <label>Hydrocarbon Flow Rate</label>
                  <p-dropdown
                    [(ngModel)]="units.hydrocarbonFlow"
                    [options]="hydrocarbonFlowUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Gas Flow Rate</label>
                  <p-dropdown
                    [(ngModel)]="units.gasInjectionRate"
                    [options]="gasInjectionRateUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Mass Flow Rate</label>
                  <p-dropdown
                    [(ngModel)]="units.massFlow"
                    [options]="massFlowOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Slurry Flow Rate</label>
                  <p-dropdown
                    [(ngModel)]="units.slurryFlow"
                    [options]="slurryFlowRateOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Volume</label>
                  <p-dropdown
                    [(ngModel)]="units.volume"
                    [options]="volumeUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label>Area</label>
                  <p-dropdown
                    [(ngModel)]="units.area"
                    [options]="areaUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>
                
                <div class="field">
                  <label for="speed">Speed</label>
                  <p-dropdown
                    [(ngModel)]="units.speed"
                    [options]="speedUnitOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="enthalpy">Enthalpy</label>
                  <p-dropdown
                    [(ngModel)]="units.enthalpy"
                    [options]="enthalpyOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>

                <div class="field">
                  <label for="heat">Heat</label>
                  <p-dropdown
                    [(ngModel)]="units.heat"
                    [options]="heatOptions"
                    appendTo="body"
                    optionValue="key"
                  ></p-dropdown>
                </div>
            </div>
          </div>

        </div>

        <span class="buttonsCol">
        <form class="cancelButtonForm" (ngSubmit)="onCancel()">
          <button pRipple pButton class="p-button-warning p-button-outlined" type="submit">Cancel</button>
        </form>

        <form (ngSubmit)="onUserMetadataFormSubmit()">
          <button pRipple pButton class="p-button-success p-button-outlined" type="submit">Save</button>
        </form>
      </span>

    </p-card>
  </div>
</div>
