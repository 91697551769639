<form [formGroup]="form.formGroup">
  <!-- <div class="p-field-radiobutton"> -->
  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Level"
      [unitLabel]="userUnits.longLengths + ' MD'"
      [formControlName]="form.formControlNames.fluidLevelMd"
      [control]="'fluidLevelMd'"
      [default]="currentString.shoeMd"
      [validators]="validators.fluidLevelMd"
      title="Allowed range: {{ currentString.hangerMd }} - {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
    ></uom-quantity-input>
  </div>

  <!-- <br> -->
  <!-- <div class="p-field-radiobutton">
<p-radioButton name="EvacuationType" formControlName="EvacuationType" value="percentSelect"></p-radioButton>
    <input type="number" pInputText formControlName="PercentEvacuated" id="percentEvacuated" />
    <label for="percentEvacuated">% Evacuated</label>
</div> -->
  <!-- <br> -->
  <!-- <div class="evacuationDensity"> -->

  <div class="field col-12">
    <uom-quantity-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [default]="currentString.annularFluid?.state.nominalDensity"
      [validators]="validators.fluidDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
    ></uom-quantity-input>
  </div>

  <!-- https://www.youtube.com/watch?v=yV37fjc6G1k -->
  <!-- <div class="ui-g">
<p-radioButton name="projGroup" formControlName="projectType" label="Fluid Evacuated" value="B"></p-radioButton>

<p-radioButton name="projGroup" formControlName="projectType" label="% Evacuated" value="F"></p-radioButton>
</div> -->
</form>
