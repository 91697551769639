import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CustomEPP, ExternalPressureProfile, ExternalPressureProfileType, FluidToShoeEPP, InterpolationToPorePressureEPP, MudAndWetCementEPP, MudAndWetCementMixWaterEPP, MudAndPorePressureEPP, PericalLinkEPP, PorePressureEPP } from '../models/external-pressure-profile.model';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

export interface ExternalPressureProfileForm {
  type: ExternalPressureProfileType;
  customEpp: CustomEPP;
  pericalLinkEpp: PericalLinkEPP;
  fluidToShoeEpp: FluidToShoeEPP;
  mudAndWetCementEpp: MudAndWetCementEPP;
  mudAndCementMixWaterEpp: MudAndWetCementMixWaterEPP;
  interpolationToPorePressureEpp: InterpolationToPorePressureEPP;
  mudAndPorePressureEpp: MudAndPorePressureEPP;
  porePressureEpp: PorePressureEPP;
}

@Component({
  selector: 'app-external-pressure-profiles',
  templateUrl: './external-pressure-profiles.component.html',
  styleUrls: ['./external-pressure-profiles.component.scss'],
  providers: subformComponentProviders(ExternalPressureProfilesComponent)
})
export class ExternalPressureProfilesComponent implements OnInit {

  @Input() shoeDepth: number;
  @Input() currentString: any;
  @Input() cement: CementingLanding;
  @Input() porePressures: PoreFrac[];
  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;
  @Input() isReadOnly: boolean;

  public defaultHangerPressure: number;

  public form = createForm<ExternalPressureProfile, ExternalPressureProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      customEpp: new UntypedFormControl(null),
      pericalLinkEpp: new UntypedFormControl(null),
      fluidToShoeEpp: new UntypedFormControl(null),
      mudAndWetCementEpp: new UntypedFormControl(null),
      mudAndCementMixWaterEpp: new UntypedFormControl(null),
      interpolationToPorePressureEpp: new UntypedFormControl(null),
      mudAndPorePressureEpp: new UntypedFormControl(null),
      porePressureEpp: new UntypedFormControl(null)
    },
    toFormGroup: (obj: ExternalPressureProfile | null): ExternalPressureProfileForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        customEpp: obj.type === ExternalPressureProfileType.CUSTOMEPP ? obj : null,
        pericalLinkEpp: obj.type === ExternalPressureProfileType.PERICALLINK ? obj : null,
        fluidToShoeEpp: obj.type === ExternalPressureProfileType.FLUIDTOSHOE ? obj : null,
        mudAndWetCementEpp: obj.type === ExternalPressureProfileType.MUDANDWETCEMENT ? obj : null,
        mudAndCementMixWaterEpp: obj.type === ExternalPressureProfileType.MUDANDCEMENTMIXWATER ? obj : null,
        interpolationToPorePressureEpp: obj.type === ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE ? obj : null,
        mudAndPorePressureEpp: obj.type === ExternalPressureProfileType.MUDANDPOREPRESSURE ? obj : null,
        porePressureEpp: obj.type === ExternalPressureProfileType.POREPRESSURE ? obj : null
      };
    },
    fromFormGroup: (formValue: ExternalPressureProfileForm): ExternalPressureProfile | null => {
      const { type, customEpp, pericalLinkEpp, fluidToShoeEpp, mudAndWetCementEpp, mudAndCementMixWaterEpp, interpolationToPorePressureEpp, mudAndPorePressureEpp, porePressureEpp, ...commonValues } = formValue;

      switch (type) {
        case ExternalPressureProfileType.CUSTOMEPP:
          return customEpp ? { type, ...customEpp, ...commonValues } : null;
        case ExternalPressureProfileType.PERICALLINK:
          return pericalLinkEpp ? { type, ...pericalLinkEpp, ...commonValues } : null;
        case ExternalPressureProfileType.FLUIDTOSHOE:
          return fluidToShoeEpp ? { type, ...fluidToShoeEpp, ...commonValues } : null;
        case ExternalPressureProfileType.MUDANDWETCEMENT:
          return mudAndWetCementEpp ? { type, ...mudAndWetCementEpp, ...commonValues } : null;
        case ExternalPressureProfileType.MUDANDCEMENTMIXWATER:
          return mudAndCementMixWaterEpp ? { type, ...mudAndCementMixWaterEpp, ...commonValues } : null;
        case ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE:
          return interpolationToPorePressureEpp ? { type, ...interpolationToPorePressureEpp, ...commonValues } : null;
        case ExternalPressureProfileType.MUDANDPOREPRESSURE:
          return mudAndPorePressureEpp ? { type, ...mudAndPorePressureEpp, ...commonValues } : null;
        case ExternalPressureProfileType.POREPRESSURE:
          return porePressureEpp ? { type, ...porePressureEpp, ...commonValues } : null;
      }
    }
  });

  public epps: Array<SelectItem> = [
    { label: 'Custom', value: 'CustomExternalPressures' },
    { label: 'Perical Link', value: 'ThermalLink' },
    { label: 'Fluid to Shoe', value: 'MudToShoe' },
    { label: 'Mud and Wet Cement', value: 'MudAndWetCement' },
    { label: 'Mud and Cement Mix-Water', value: 'MudAndCementMixWater' },
    { label: 'Interpolation to Pore Pressure', value: 'InterpolationToPorePressure' },
    { label: 'Mud to TOC/PP in Cement', value: 'MudAndPorePressure' },
    { label: 'Pore Pressure', value: 'PorePressure' }
  ];

  public ExternalPressureProfileType: typeof ExternalPressureProfileType = ExternalPressureProfileType;

  constructor() { }

  ngOnInit(): void {
    if (!this.currentString['hasCement']) {
      this.epps = this.epps.filter(x => x.value !== "MudAndWetCement");
      this.epps = this.epps.filter(x => x.value !== "MudAndCementMixWater");
      this.epps = this.epps.filter(x => x.value !== "InterpolationToPorePressure");
      this.epps = this.epps.filter(x => x.value !== "MudAndPorePressure");
      this.epps = this.epps.filter(x => x.value !== "PorePressure");
    }
  }
}
