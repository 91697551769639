<div class="sidebarMenuGroupContainer">
  <p-card>

    <p-toolbar>
      <div class="header">
        <h4 *ngIf="!solidColorLabel" class="firstPart">PERI</h4>
        <h4 [style.color]="labelColor">{{suffixLabel}}</h4>
        <h4 [style.color]="labelColor">{{solidColorLabel}}</h4>
      </div>

      <div class="p-toolbar-group-right">
        <p-button class="collapseButton" icon="pi pi-angle-double-left" (onClick)="collapseSubMenu()"></p-button>
      </div>
    </p-toolbar>

    <p-tree [scrollHeight]="scrollHeight" [value]="menuItems">
      <ng-template pTemplate="group" let-node>
        <b><u class="menuItem">{{ node.label }}</u></b>
      </ng-template>
      <ng-template pTemplate="default" let-node>
        <img class="img" src="{{ node.data.icon }}" />
        <span class="menuItem" (click)="onClick(node.data.identifier)"> {{ node.data.label }}</span>
      </ng-template>
    </p-tree>

  </p-card>
</div>
