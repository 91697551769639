<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <form *ngIf="wellheadSlumpForm" [formGroup]="wellheadSlumpForm" bg-dark>
    <div class="field checkboxClass">
        <input
          style="height: 13px; width: 20px;"
          type="checkbox"
          binary="true"
          formControlName="applyDisplacementToPostInstallLoads"
        >
      <label> Apply displacement to Post-Install loads</label>
    </div>

    <div class="field">
      <label for="surfaceAmbientTemp">Wellhead Slump</label>
      <i
        title="Upward Movement (+)/Downward Movement (-)"
        class="pi pi-info-circle tooltip-icon"
        style="padding-left: 5px;"
      ></i>
      <div class="p-inputgroup">
        <p-inputNumber
          [minFractionDigits]="1"
          formControlName="wellheadSlump"
          placeholder="0"
        ></p-inputNumber>
        <span class="p-inputgroup-addon">{{ units.short }}</span>
      </div>
      <p *ngIf="wellheadSlumpForm.controls.wellheadSlump.invalid" class="error">
        Allowed range: {{ this.slumpValidation.min }} -
        {{ this.slumpValidation.max }}
    </p>
    </div>

    <!-- <br> -->

    <!-- <ng-table-grid-cmp
      tableName="Wellhead Slump"
      [tableData]="formationDisplacements"
      [tableHeight]="tableHeight"
      [columnDefinitions]="columnDefinitions"
      [newRowFormGroup]="newDataRow"
      [inputFields]="inputFields"
      [isDynamicLoaded]="true"
      (dataChange)="formationChange($event)"
      [tableHeader]="'Formation'"
    ></ng-table-grid-cmp> -->
  </form>
</p-scrollPanel>