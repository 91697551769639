import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngneat/elf';
import { UIEntitiesRef, addEntities, selectEntity, updateEntities } from '@ngneat/elf-entities';
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { Choke, Chokes, ChokesUi, FlowCoefficientSchedule } from 'src/app/perical/models/chokes.model';
import { LinearInterpSingleVal } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';

@Component({
  selector: 'app-choke-data',
  templateUrl: './choke-data.component.html',
  styleUrls: ['./choke-data.component.scss']
})
export class ChokeDataComponent implements OnChanges {

  public chokeData: Chokes;
  public tableHeight: string;
  public flowCoefficientSchedule: Array<FlowCoefficientSchedule>;
  public plot = {
    data: [],
    layout: {},
    config: {}
  };
  public xAxisTitle = '% Open or Travel';
  public yAxisTitle = 'Flow Coefficient (Cv)';
  public plotName = 'flowCoefficientSchedule';
  public downloadPlotName = 'flow_coefficient_schedule';
  public interpolatedCv: number;

  public get columnDefinitions(): Array<{ field: string, header: string }> {
    return [
      { field: 'percentOpen', header: `Open/Travel (%)` },
      { field: 'flowCoefficient', header: `Cv` }
    ];
  }

  public inputFields = [
    { name: 'percentOpen', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
    { name: 'flowCoefficient', minFractions: 2, maxFractions: 6, formatDecimals: 3 }
  ];

  public newDataRow() : UntypedFormGroup {
    return new UntypedFormGroup({
      percentOpen: new UntypedFormControl(0, [Validators.required, Validators.max(100), Validators.min(0)]),
      flowCoefficient: new UntypedFormControl(0, [Validators.required])
    });
  }

  @Input()
  public chokesStore: Store;
  public chokesUiEntity$: ChokesUi;

  private input$: Subject<Choke | null | undefined> = new Subject();
  private _choke: Choke;
  @Input() set choke(choke: Choke | undefined) {
    this.input$.next(choke);
    this._choke = choke;
  }

  get choke(): Choke {
    return this._choke;
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Input()
  public chokesComponentHeight: number;

  @Output() chokeUpdate: EventEmitter<Choke> = new EventEmitter();

  constructor() { }

  ngOnChanges(e: any): void {
    if (e.choke?.currentValue?.flowCoefficientSchedule) {
      this.flowCoefficientSchedule = e.choke?.currentValue?.flowCoefficientSchedule || [this.newDataRow() as any];
    }
    this.plotEnvelope();
    this.tableHeight = this.chokesComponentHeight + 'px';

    if(this.chokesStore && e.choke.currentValue) {
      this.chokesStore.pipe(selectEntity(e.choke.currentValue.name, { ref: UIEntitiesRef})).subscribe(chokeUi => {
        this.chokesUiEntity$ = chokeUi;
        if (!this.chokesUiEntity$ && e.choke.currentValue.name) {
          this.chokesStore.update(addEntities( {id: e.choke.currentValue.name, selectedResultsDisplay: false}, { ref: UIEntitiesRef }));
          this.chokesStore.pipe(selectEntity(e.choke.currentValue.name, { ref: UIEntitiesRef})).subscribe(chokeUI => this.chokesUiEntity$ = chokeUI);
        }
      })
    }
  }

  public tablePlotToggle(event: any) {
    this.chokesStore.update(updateEntities(this.choke.name, (choke) => ({...choke, selectedResultsDisplay: event.checked}), { ref: UIEntitiesRef }));
  }

  public onFlowScheduleChange(v: { dataRows: Array<FlowCoefficientSchedule>, reload: boolean }) {
    this.form.formGroup.value['flowCoefficientSchedule'] = v.dataRows;
    this.chokeUpdate.emit(this.form.formGroup.value);
  }

  private plotEnvelope() {
    this.plot.data = [{
      y: this.flowCoefficientSchedule?.map(x => x.flowCoefficient),
      x: this.flowCoefficientSchedule?.map(x => x.percentOpen),
    }];
  }

  public form = createForm<Choke>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.chokeUpdate,
    formControls: {
      name: new FormControl('', [Validators.required]),
      criticalLiquidFlowFactor: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1)]),
      criticalVaporFlowFactor: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1)]),
      pipingGeometryFactor: new FormControl(null, [Validators.required]),
      flowCoefficientSchedule: new FormControl(null, [Validators.required]),

    },
    fromFormGroup: (formValue: Choke | null): Choke => {
      const { ...commonValues } = formValue;

      return {
        ...commonValues
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  public interpolateCv(event: Event) {
    let value = event['value'];
    let flowCoefficientSchedule = this.form.formGroup.value['flowCoefficientSchedule'];

    let percentOpen = flowCoefficientSchedule.map(x => x.percentOpen);
    let flowCoefficientScheduleValues = flowCoefficientSchedule.map(x => x.flowCoefficient);

    this.interpolatedCv = +LinearInterpSingleVal(value, percentOpen, flowCoefficientScheduleValues).toFixed(2);
  }
}
