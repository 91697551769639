import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validateCustomProfile(currentString: any, wellTd: number): ValidatorFn {


  return (control: AbstractControl): ValidationErrors | null => {
    const gridData = control.value;
    let lastPointAboveShoe;
    if (gridData) {
      const lastDepth = gridData[gridData.length - 1]?.measuredDepth;
      const firstDepth = gridData[0]?.measuredDepth;
      lastPointAboveShoe = lastDepth < currentString?.shoeMd || !lastDepth || firstDepth > currentString.hangerMd || lastDepth > wellTd;
    }

    return lastPointAboveShoe ? { lastPointAboveShoe: true } : null;
  }
}
