import { ExternalPressureProfile } from "./external-pressure-profile.model";
import { InternalPressureProfile } from "./internal-pressure-profile.model";
import { TemperatureProfile } from "./temperature-profile.model";

export class PostInstallLoad {
  public constructor(init?: Partial<PostInstallLoad>) {
    Object.assign(this, init);
  }

  public index: number;
  public name: string;
  public type: string;
  public internalPressureProfile: InternalPressureProfile;
  public externalPressureProfile: ExternalPressureProfile;
  public temperatureProfile: TemperatureProfile;
  public overpullForce?: number;
  public runInHoleSpeed?: number;
  public pumpPressure?: number;
}

export class PostInstallLoadUi {
  public constructor(init?: Partial<PostInstallLoadUi>) {
    this.tablePlotSelected = init && init.tablePlotSelected || 'plot';
    this.drawerOpen = init && init.drawerOpen || false;
    this.pressTempSelected = init && init.pressTempSelected || 'press';
  }
  tablePlotSelected: string;
  drawerOpen: boolean;
  pressTempSelected: string;
}


