<form [formGroup]="form.formGroup">
  <div class="generalContainer">
    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>
    <label class="field col-12">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }}
      {{ densityLabel }}
    </label>
    <br />
    <label class="field col-12">
      Top Of Cement: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.topOfCementMd.toFixed(2) }} {{ userUnits.longLengths }}
    </label>
    <br />
    <label class="field col-12">
      Lead Slurry Density: &nbsp;
      {{ cement?.leadSlurryDensity }} {{ densityLabel }}</label>
    <br *ngIf="cement?.tailSlurryLength > 0" />
    <label *ngIf="cement?.tailSlurryLength > 0" class="p-field p-col-12">
      Tail Slurry Density: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryDensity.toFixed(2) }}
      {{ densityLabel }}
    </label>
    <br *ngIf="cement?.tailSlurryLength > 0" />
    <label *ngIf="cement?.tailSlurryLength > 0" class="p-field p-col-12">
      Tail Slurry Length: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryLength.toFixed(2) }}
      {{ userUnits.longLengths }}
    </label>
    <br />
    <br />
    <label class="field col-12">
      Assumed Cement Mix-Water density: {{ mixWaterDensity.toFixed(2) }} {{ densityLabel }}
    </label>
  </div>
</form>
