<div class="pore-frac-cmp">
  <div class="toggle-btns">
    <div class="mr-2">
      <p-selectButton
        [options]="tablePlot"
        [(ngModel)]="poreFracStore.state.tablePlotSelected"
        (onChange)="tablePlotToggle($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>

    <div class="mr-2">
      <p-selectButton
        *ngIf="poreFracStore.state?.tablePlotSelected === 'plot'"
        [options]="pressureEmw"
        [(ngModel)]="poreFracStore.state.pressureEmwSelected"
        (onChange)="pressureEmwToggle($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
  </div>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div [style.display]="poreFracStore.state?.tablePlotSelected === 'table' ? 'block' : 'none'">
      <ng-table-grid-cmp
        #poreFracPressureTable
        [tableName]="pressureType === 'porePressure' ? 'Pore Pressure' : 'Fracture Gradient'"
        [tableHeight]="pressureType == 'porePressure' ? tableHeightPore: tableHeightFrac"
        [tableData]="pressures"
        [columnDefinitions]="columnDefinitions"
        [newRowFormGroup]="newDataRow"
        [inputFields]="inputFields"
        [calculatedFields]="calculatedFields"
        (dataChange)="onPorePressuresChanged($event)"
        [virtualScroll]="true"
      ></ng-table-grid-cmp>
    </div>

    <app-xy-line-plot
      *ngIf="poreFracStore.state?.tablePlotSelected === 'plot'"
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="plotTitle"
      [componentHeight]="componentHeight"
    ></app-xy-line-plot>
  </app-tool-loader>
</div>
